import { combineReducers } from 'redux'

import { get as getBrowseCategories, browseCategories } from './browseCategoriesStore'
import {
  get as getInspirations,
  getNextPage as getNextPageInspirations,
  inspirations,
} from './inspirationsStore'

/* =============
 -   ACTIONS   -
 ============= */

const actions = {
  getBrowseCategories,
  getInspirations,
  getNextPageInspirations,
}

/* =============
 -  REDUCERS   -
 ============= */

const reducers = combineReducers({
  browseCategories,
  inspirations,
})

export default {
  actions,
  reducers,
}
