import React from 'react'
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/components/connect'

import { SrcParam } from 'constants/enums'
import { addUrlParams } from 'library/utils'
import Href from 'units/Href'

import './BestSellersPriceTable.scss'

const BestSellersPriceTable = ({ seoBestSellers }) => {
  if (!seoBestSellers.showSection) {
    return null
  }
  const lastUpdateOn = new Date(new Date().getTime() - (24 * 60 * 60 * 1000))
  /* eslint-disable no-nested-ternary */
  return (
    <div itemScope itemType="https://schema.org/Table">
      <h2 itemProp={ seoBestSellers.taxon_name } className="priceTable__title">
      { seoBestSellers.display_categories_price_list ?
        seoBestSellers.price_list_title : seoBestSellers.taxon_name ?
        `${seoBestSellers.taxon_name} Price List` : 'Price List' }
      </h2>
      <table className="priceTable">
        <thead>
          <tr>
            <th className="priceTable__column-name">
              { seoBestSellers.display_categories_price_list ? 'Category Name' : 'Product Name' }
            </th>
            <th> { seoBestSellers.display_categories_price_list ? 'Starting From' : 'Price' } </th>
          </tr>
        </thead>
        <tbody>
          {
            seoBestSellers.products.map(product => (
              <tr key={ product.sku } >
                <td className="priceTable__product-name">
                  <Href
                    to={
                      addUrlParams(
                        product.path,
                        { src: SrcParam.priceListWidget },
                      )
                    }
                    isExternal
                  >
                    { product.name }
                  </Href>
                </td>
                <td>
                    { product.display_discount_price }
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      <p className="priceTable__last-updated-date">
        Data last updated on { lastUpdateOn.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' }) }
      </p>
    </div>
  )
}

BestSellersPriceTable.propTypes = {
  seoBestSellers: PropTypes.object,
}

export default connect(state => ({
  seoBestSellers: state.pageData.seoBestSellers,
}))(BestSellersPriceTable)
