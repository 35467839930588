import Spree from 'api/spree'
import { PromiseState } from 'constants/enums'

/* =============
 -   ACTIONS   -
 ============= */

export function get(slug, infoName) {
  const spree = new Spree()

  return {
    type: 'GET_PRODUCT_INFO',
    payload: spree.product.info.get(slug),
    meta: {
      name: infoName,
    },
  }
}

/* =============
 -  REDUCERS   -
 ============= */

export function info(state = {}, action) {
  const data = {
    ...state,
  }
  const infoBlockName = (action.meta ? action.meta.name : null)

  switch (action.type) {
    case 'GET_PRODUCT_INFO_PENDING':
      data[infoBlockName] = {
        promiseState: PromiseState.PENDING,
      }
      return data

    case 'GET_PRODUCT_INFO_FULFILLED':
      data[infoBlockName] = {
        ...action.payload,
        promiseState: PromiseState.FULFILLED,
      }
      return data

    case 'GET_PRODUCT_INFO_REJECTED':
      data[infoBlockName] = {
        promiseState: PromiseState.REJECTED,
      }
      return data

    case 'GET_PRODUCT_DETAILS_PENDING':
      return {}

    default:
      return state
  }
}
