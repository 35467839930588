import Endpoints from 'settings/endpoints'
import BaseAPI from '../../BaseAPI'

export default class Similar extends BaseAPI {
  get(productId, params) {
    return this.client.get(
      Endpoints.product.similar(productId),
      params,
    )
  }
}
