/* eslint-disable sort-class-members/sort-class-members */
/* global gapi */
import Spree from 'api/spree'
import { loadScript } from '../utils/DOMUtils'
import Endpoints from '../../settings/endpoints'

export default class GoogleOAuth {
  static requiredScopes = 'profile email';

  constructor(clientId) {
    if (!clientId) {
      console.error('A google client id must be specified.')
    }

    this.clientId = clientId
  }

  loadProvider() {
    if ('gapi' in window && 'auth2' in gapi) {
      return Promise.resolve()
    }
    return loadScript(Endpoints.googleSDK)
      .then(() => new Promise((resolve) => {
        gapi.load('auth2', resolve)
      }))
  }

  /**
   *
   * @see https://developers.google.com/identity/sign-in/web/reference#gapiauth2initparams
   * @returns {*}
   */
  init() {
    if (gapi.auth2.getAuthInstance()) {
      return Promise.resolve()
    }

    return gapi.auth2
      .init({
        client_id: this.clientId,
        scope: GoogleOAuth.requiredScopes,
      })
      .then(() => {
        GoogleOAuth.isInited = true
        return Promise.resolve()
      })
  }

  /**
   * @param user - Instance of a GoogleUser
   * @see https://developers.google.com/identity/sign-in/web/reference#googleusergetbasicprofile
   */
  _getPersonalInformation(user) {
    const profile = user.getBasicProfile()
    return {
      uid: profile.getId(),
      name: profile.getName(),
      picture: profile.getImageUrl(),
      email: profile.getEmail(),
    }
  }

  /**
   * Filters user auth information to a
   * format is accepted by `loginWithOAuth()`
   * in Spree Auth API wrapper.
   *
   * @example (incomplete)
   * { access_token: xxx, expiresIn: yyy, userId: zzz }
   * to
   * { accessToken: xxx, uid: zzz }
   *
   * @param {Object} user information object
   * @return {Object} Spree-Auth compatible user information
   */

  _formatToSpree(userInfo) {
    const spree = new Spree()

    return {
      provider: spree.auth.OAUTH_PROVIDER.GOOGLE,
      accessToken: userInfo.id_token, // misnomer
      email: userInfo.email,
      name: userInfo.name,
      uid: userInfo.uid,
    }
  }

  _getCurrentUserInformation() {
    const authInstance = gapi.auth2.getAuthInstance()
    const currentUser = authInstance.currentUser.get()
    return this._formatToSpree({
      ...currentUser.getAuthResponse(),
      ...this._getPersonalInformation(currentUser),
    })
  }

  /**
   * @see https://developers.google.com/identity/sign-in/web/reference#googleauthsignin
   * @returns {*|Promise.<TResult>}
   */
  login() {
    const authInstance = gapi.auth2.getAuthInstance()
    if (this.isLoggedIn()) {
      return Promise.resolve(this._getCurrentUserInformation())
    }
    // Although `authInstance.signIn()` is supposed
    // to return a Promise, in practice, it doesn't
    // happen at times. Hence we Promise.resolve() it.
    return Promise
      .resolve(authInstance.signIn())
      .then(() => Promise.resolve(this._getCurrentUserInformation()))
  }

  /**
   * @see https://developers.google.com/identity/sign-in/web/reference#googleauthsignout
   * @returns {*}
   */
  logout() {
    return gapi.auth2
      .getAuthInstance()
      .signOut()
  }

  /**
   * @see https://developers.google.com/identity/sign-in/web/reference#googleauthissignedinget
   * @returns {boolean}
   */
  isLoggedIn() {
    return gapi.auth2
      .getAuthInstance()
      .isSignedIn
      .get()
  }

  /**
   * Checks if a user is signed in and
   * has previously authorized the app
   * with required permissions.
   *
   * @returns {Promise.<boolean>}
   */
  isAppAuthorized() {
    const currentUser = gapi.auth2.getAuthInstance().currentUser.get()
    return Promise.resolve(
      this.isLoggedIn() &&
      currentUser.hasGrantedScopes(GoogleOAuth.requiredScopes),
    )
  }
}
