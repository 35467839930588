/* eslint-disable sort-class-members/sort-class-members */

import cookie from 'cookie'

/**
 * A wrapper that adds a few extra helpful
 * methods over the npm package "cookie".
 *
 * @see https://github.com/jshttp/cookie
 */
export default class Cookie {

  /**
   * Parse the given cookie header string into an object
   * The object has the various cookies as keys(names) => values
   *
   * @param {string} str
   * @param {object} [options]
   * @returns {object}
   * @see https://github.com/jshttp/cookie#cookieparsestr-options
   */
  static parse = cookie.parse;

  /**
   * Serialize the a name value pair into a cookie string
   * suitable for http headers. An optional options object
   * specified cookie parameters.
   *
   * serialize('foo', 'bar', { httpOnly: true })
   *   => "foo=bar; httpOnly"
   *
   * @param {string} name
   * @param {string} val
   * @param {object} [options]
   * @returns {string}
   * @see https://github.com/jshttp/cookie#cookieserializename-value-options
   */
  static serialize = cookie.serialize;

  /**
   * Reads cookies from document and
   * gives a parsed key value object.
   *
   * @returns {Object}
   */
  static getAll() {
    return cookie.parse(document.cookie)
  }

  /**
   * Serializes a name value pair into a cookie string
   * and injects it into the document.
   *
   * @param {string} name
   * @param {string|boolean|number} value
   * @param options - All options supported
   * by cookie.serialize are accepted.
   */
  static set(name, value, options) {
    // setting path="/" if path is not passed
    // to make available the cookie across the domain
    document.cookie = cookie.serialize(name, value, { path: '/', ...options })
  }

  /**
   * Returns the value of a cookie by its name.
   *
   * @param {string} name
   * @returns {string} cookie value
   */
  static get(name) {
    return Cookie.getAll()[name]
  }

  /**
   * Unsets a cookie's value, in effect
   * deleting it from the document
   *
   * @param {string} name
   */

  static unset(name) {
    Cookie.set(name, '', { expires: new Date(0) })
  }
}
