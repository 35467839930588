export default {
  login: '/api/login',
  users: '/api/users',
  bestSellers: '/api/variants/best-sellers/taxons',
  bestSellersDefault: '/api/variants?q[product_taxons_permalink_eq]=urban-ladder-bestsellers',
  clientConfigurations: '/api/client_configurations',
  EMIs: '/api/payments/emi_options',
  experiments: '/api/v1/experiments/assignment',
  bestEMIs: '/api/payments/emi_options/best_emis',
  giftCard: '/api/gift-cards',
  home: {
    browseCategoriesRelativePath: 'app-home-popular-category',
    inspirations: '/api/inspirations.json',
  },
  interlinks: '/api/interlinking_module',
  listing: {
    leaf: '/api/variants',
    nonleaf: '/api/family_pages',
  },
  cities: '/api/cities',
  offers: '/api/offers',
  pages: '/api/pages',
  personalizedContentBlocks: '/api/personalized-content-blocks',
  product: {
    details: '/api/products',
    info: '/api/page_content',
    // sofaTrialBannerRelativePath: 'sofas-by-material',
    similar: productId => `/api/products/${productId}/related`,
    availableStores: '/api/variants/available-stores',
    faq: (taxonId, productId) => `/api/faqs?per_page=5&filter[tags][][tag_type]=taxon&filter[tags][][tag_id]=${taxonId}&filter[tags][][tag_type]=product&filter[tags][][tag_id]=${productId}`,
  },
  recommendations: {
    similarLooks: '/api/recommendations/similar/looks',
  },
  wishlist: {
    get: '/api/wishlists/default',
    add: '/api/wishlists/default/wished_products',
    remove: '/api/wishlists/default/wished_products/null',
  },

  serviceability: '/api/v1/serviceability',
  stores: {
    all: '/api/furniture-stores',
    byCity: cityName => `/api/furniture-stores/${cityName}`,
    details: (type, city, location) => `/api/${type}/${city}/${location}`,
  },
  zipcodeServiceability: '/zipcodes/check_serviceability',
  services: '/services',
  serviceSubscription: '/api/subscribe',
  snippets: '/api/snippets',
  // sofaTrial: '/api/services/sofa_trial_products',
  subscription: '/subscribe',
  suggestions: '/api/search/suggestions',
  taxonomies: '/api/taxonomies/2/taxons?app=mweb',
  taxon: permalink => `/api/taxon/${permalink}?app=mweb`,
  testimonials: '/api/testimonials/related',
  uploads: '/api/uploads',
  variants: '/api/variants',
  order: {
    history: '/api/order_history',
    cancellationEligibility: orderNumber => (
      `/api/orders/${orderNumber}/cancellation_eligibility`
    ),
    cancel: orderNumber => (
      `/api/orders/${orderNumber}/cancel_request`
    ),
    details: orderNumber => (
      `/api/orders/${orderNumber}/order_history`
    ),
    detailsWithValidation: '/api/orders/details',
  },
  storyTelling: '/api/v1/variants',
  scheduleCallAPI: '/schedule-call/api',

  // Third party
  facebookSDK: 'https://connect.facebook.net/en_US/all.js',
  googleSDK: 'https://apis.google.com/js/api:client.js',
  razorpayWidget: 'https://cdn.razorpay.com/widgets/affordability/affordability.js',
  razorpayOffers: '/api/services/config/razorpay_offers'
}
