/**
 * A list of unicode character codes for common symbols.
 * Get Official symbol names from http://unicode-table.com/en
 */
const Unicode = {
  // ₹
  INDIAN_RUPEE_SIGN: '\u20B9',

  // «
  LEFT_POINTING_DOUBLE_ANGLE_QUOTATION_MARK: '\u00AB',

  // »
  RIGHT_POINTING_DOUBLE_ANGLE_QUOTATION_MARK: '\u00BB',

  // nbsp;
  NO_BREAK_SPACE: '\u00A0',

}

export default Unicode

