import debounce from 'lodash/debounce'
import React from 'react'
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare'
import ReactDOM from 'react-dom'

import { Orientation } from 'constants/enums'
import { Debounce } from 'settings/values'

const Infinite =
  ComposedComponent => class InfiniteComponent extends React.Component {
    static propTypes = {
      onLoadMore:
      PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.bool,
      ]),
      threshold: PropTypes.number,
      orientation: PropTypes.oneOf(Object.values(Orientation)),
      lastLoadedPage:
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    };

    static defaultProps = {
      orientation: Orientation.VERTICAL,
    };

    componentDidMount() {
      this.attachListeners()
    }

    shouldComponentUpdate(nextProps, nextState) {
      return shallowCompare(this, nextProps, nextState)
    }

    componentDidUpdate(prevProps) {
      if (prevProps.lastLoadedPage !== this.props.lastLoadedPage) {
        this.attachListeners()
      }
    }

    componentWillUnmount() {
      this.removeListeners()
    }

    attachListeners() {
      if (this.props.orientation === Orientation.HORIZONTAL) {
        const ele = this.getThisDOMNode()
        ele.addEventListener('scroll', this.handleScroll)
      } else {
        window.addEventListener('scroll', this.handleScroll)
      }
    }

    removeListeners() {
      if (this.props.orientation === Orientation.HORIZONTAL) {
        const ele = this.getThisDOMNode()
        ele.removeEventListener('scroll', this.handleScroll)
      } else {
        window.removeEventListener('scroll', this.handleScroll)
      }
    }

    onLoadMore() {
      this.removeListeners()
      if (this.props.onLoadMore) {
        this.props.onLoadMore()
      }
    }

    handleScroll = debounce(() => {
      if (this.crossedThreshold() === true) {
        this.onLoadMore()
      }
    }, Debounce.scroll.WAIT_TIME, {
      leading: true,
      trailing: true,
      maxWait: Debounce.scroll.MAX_WAIT_TIME,
    });

    crossedThreshold() {
      const { orientation, threshold } = this.props
      const ele = this.getThisDOMNode()
      const rect = ele.getBoundingClientRect()

      if (orientation === Orientation.HORIZONTAL) {
        return ele.scrollWidth - (ele.scrollLeft + rect.width) < threshold
      }
      return rect.bottom - window.innerHeight < threshold
    }

    getThisDOMNode() {
    // eslint-disable-next-line react/no-find-dom-node
      return this.ele || ReactDOM.findDOMNode(this)
    }

    render() {
    // eslint-disable-next-line no-unused-vars
      const { onLoadMore, ...others } = this.props
      return <ComposedComponent { ...others } />
    }
}

export default Infinite
