import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare'

import './Overlay.scss'

export default class Overlay extends React.Component {
  static propTypes = {
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    onClick: () => {},
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (this.props.isActive !== newProps.isActive) {
      this.handleToggleOverlay(newProps.isActive)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState)
  }

  handleToggleOverlay(isActive) {
    if (isActive) {
      document.body.classList.add('noscroll')
    } else {
      document.body.classList.remove('noscroll')
    }
  }

  render() {
    const { isActive, onClick, className } = this.props

    return (
      <div
        onTouchMove={ onClick }
        onClick={ onClick }
        className={ classNames(
          className,
          'full-page overlay',
          { active: isActive },
        ) }
      />
    )
  }
}
