import Spree from 'api/spree'
import { PromiseState } from 'constants/enums'

/* =============
 -   ACTIONS   -
 ============= */

export function getWishlist() {
  const spree = new Spree()
  return {
    type: 'GET_WISHLIST',
    payload: spree.wishlist.get(),
  }
}

export function wishlist(state = { data: { variants: [] } }, action) {
  switch (action.type) {
    case 'GET_WISHLIST_PENDING':
      return {
        ...state,
        promiseState: PromiseState.PENDING,
      }

    case 'GET_WISHLIST_FULFILLED': {
      const { variants } = action.payload.data
      variants.forEach((variant) => {
        variant.discount_price = variant.discounted_price
      })
      return {
        ...state,
        ...action.payload,
        products: variants,
        promiseState: PromiseState.FULFILLED,
      }
    }
    case 'GET_WISHLIST_REJECTED':
      return {
        ...state,
        promiseState: PromiseState.REJECTED,
        error: action.payload,
      }
    default:
      return state
  }
}
