import React from 'react'

import './TestimonialLoaderCard.scss'

const TestimonialLoaderCard = () => (
  <div className="testimonial-loader">
    <div className="testimonial-loader__source">
      <div className="testimonial-loader__author-image" />
      <div className="testimonial-loader__source-description">
        <div className="testimonial-loader__author-name" />
        <div className="testimonial-loader__author-source" />
      </div>
    </div>
    <div className="testimonial-loader__product">
      <div className="testimonial-loader__product-image" />
    </div>
    <div className="testimonial-loader__product-details">
      <div className="testimonial-loader__product-name" />
      <div className="testimonial-loader__product-link" />
    </div>
    <div className="testimonial-loader__text">
      <div className="testimonial-loader__text-line" />
      <div className="testimonial-loader__text-line" />
    </div>
  </div>
)

export default TestimonialLoaderCard
