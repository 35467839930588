import times from 'lodash/times'
import React from 'react'

import TestimonialLoaderCard from './TestimonialLoaderCard'

import './TestimonialsLoaderPanel.scss'

const TestimonialsLoaderPanel = () => (
  <div className="testimonials-loader-panel">
    { times(5, i =>
      <TestimonialLoaderCard key={ i } />,
    ) }
  </div>
)

export default TestimonialsLoaderPanel
