import classNames from 'classnames'
import debounce from 'lodash/debounce'
import React from 'react'
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare'
import { Helmet } from "react-helmet";
import connect from 'react-redux/lib/components/connect'
import { provideHooks } from 'redial'

import AppDownloadBanner from 'components/AppDownloadBanner'
import EmailSignupBottomSheet from 'components/EmailSignupBottomSheet'
import Footer from 'components/Footer'
import MockSearchBar from 'components/MockSearchBar'
import Header from 'components/smart/Header'

import Analytics from 'library/analytics'
import { addUrlParams, isWebView } from 'library/utils'
import CookieUtils from 'library/utils/cookieUtils'
import UserPreferences from 'library/storage/UserPreferences'
import { LoginState } from 'constants/enums'
import { Debounce } from 'settings/values'
import Actions from 'store/actions'
import Spree from 'api/spree'

import 'stylesheets/index.scss'
import './App.scss'
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';

class App extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    location: PropTypes.object,
    dispatch: PropTypes.func,
    routes: PropTypes.array,
    city: PropTypes.string,
  };

  static childContextTypes = {
    pageType: PropTypes.string,
  };

  state = {
    isSearchCollapsed: false,
    /**
     * Keeps track of when the mock
     * search needs to be morphed
     * into its header equivalent
     */
    isSearchOpen: false,
    shouldFooterSectionsLoad: false,
    /**
     * Due to view caching, we cannot determine if
     * a user is coming from a web-view on the server side.
     * ∴ We have to live with a discard and client re-render :(
     */
    isWebView: isServer() ? false : isWebView(),
    enableEmailSignupSheet: false,
  };

  getChildContext() {
    const { routes } = this.props
    const routeData = routes[routes.length - 1].data
    const pageType = routeData ? routeData.pageType : null

    return {
      pageType: pageType || 'UL',
    }
  }

  componentDidMount() {
    const { dispatch } = this.props
    const spree = new Spree()

    dispatch(Actions.saveContextInformation({
      isWebView: isServer() ? false : isWebView(),
    }))

    if (spree.auth.getLoginState() === LoginState.LOGGED_IN) {
      dispatch(Actions.getWishlist())
    }

    // Extend cookie expiry by another time unit.
    spree.auth.renewAuthCookies()

    // Sync cookie store with redux store.
    const authInfo = spree.auth.getAuthInfoFromCookies()

    dispatch(Actions.setAuthInformation(authInfo))
    if (!isWebView()) {
      setTimeout(() => {
        this.mockSearchBarBCR = this.mockSearchBar.getBoundingClientRect()
        this.pageContainerBCR = this.pageContainer.getBoundingClientRect()
        window.addEventListener('scroll', this.monitorSearchState)
      }, 0)

      this.loadFooterSections()
    }

    UserPreferences
      .getPreference(UserPreferences.preference.EMAIL)
      .then((userEmail) => {
        const hasSeenEmailSignupSheet = !!UserPreferences
          .getTemporaryPreference(
            UserPreferences.preference.HAS_SEEN_EMAIL_SIGNUP_SHEET,
          )

        const hasGivenEmail = userEmail ||
          new Spree().auth.getLoginState() !== LoginState.NEVER_LOGGED_IN

        this.setState({
          isWebView: isWebView(),
          enableEmailSignupSheet: !hasSeenEmailSignupSheet &&
            !hasGivenEmail && window.GOOGLE_CONTENT_EXPERIMENTS_VARIATION === 0,
        })
      })

    this.fireIdentifyEvent(authInfo)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location !== this.props.location) {
      const { routes, params } = nextProps

      this.setState({
        isSearchOpen: false,
        isSearchCollapsed: false,
        shouldFooterSectionsLoad: false,
      })

      this.props.dispatch({
        type: 'SPA_LOCATION_CHANGE',
      })

      this.props.dispatch(
        Actions.getPersonalizedContentBlocks(
          location.pathname,
          { ...routes[routes.length - 1].data, ...params }
        )
      )
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState)
  }

  componentDidUpdate() {
    this.removeListeners()

    if (!this.state.isWebView) {
      this.loadFooterSections()
    }
  }

  componentWillUnmount() {
    this.removeListeners()
  }

  shouldCollapseSearch = () => {
    const scrollTop = window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop

    return scrollTop > (this.mockSearchBarBCR.bottom - this.pageContainerBCR.top)
  }

  // This method is added to fill userCity for the existing sessions
  // Remove this method after September 2018
  fireIdentifyEvent = (authInfo) => {
    Analytics.identify(LoginState.LOGGED_IN, authInfo)

    const { city } = this.props
    const { ul_analytics_identify } = CookieUtils.getAll()

    if (authInfo.loginState !== LoginState.LOGGED_IN ||
      !!ul_analytics_identify ||
      (!authInfo.userCity && !city)) {
      return
    }

    if (!authInfo.userCity) {
      authInfo.userCity = city
    }

    const expiryDate = new Date()
    expiryDate.setMonth(expiryDate.getMonth() + 6)
    CookieUtils.set('ul_analytics_identify', 1, { expires: expiryDate })
  }

  loadFooterSections = () => {
    const { shouldFooterSectionsLoad } = this.state
    if (!shouldFooterSectionsLoad && !this.handleFooterInViewPort()) {
      this.attachListeners()
    }
  };

  monitorSearchState = () => {
    this.setState({ isSearchCollapsed: this.shouldCollapseSearch() })
  };

  attachListeners() {
    window.addEventListener('scroll', this.handleWindowScroll)
  }

  removeListeners() {
    window.removeEventListener('scroll', this.handleWindowScroll)
  }

  handleFooterInViewPort = () => {
    const rect = this.Footer.getBoundingClientRect()
    let shouldFooterSectionsLoad = this.state.shouldFooterSectionsLoad
    if (!shouldFooterSectionsLoad && rect.top <= window.innerHeight) {
      this.removeListeners()
      this.setState({
        shouldFooterSectionsLoad: true,
      })
      shouldFooterSectionsLoad = true
    }
    return shouldFooterSectionsLoad
  };

  handleWindowScroll = debounce(() => {
    this.handleFooterInViewPort()
  }, Debounce.scroll.WAIT_TIME, {
    leading: true,
    trailing: true,
    maxWait: Debounce.scroll.MAX_WAIT_TIME,
  });

  handleSearchModeClose = () => {
    this.setState({
      isSearchCollapsed: this.shouldCollapseSearch(), isSearchOpen: false,
    })
  };

  handleMockSearchClick = () => {
    this.setState({ isSearchOpen: true })
  };

  render() {
    const { children, location } = this.props
    const {
      isSearchCollapsed,
      isSearchOpen,
      isWebView,
      shouldFooterSectionsLoad,
      enableEmailSignupSheet,
    } = this.state

    return (
      <div
        id="app-container__content"
        className={
          classNames({ 'app-container__content--webview': isWebView })
        }
      >
        <ErrorBoundary>
          <Helmet title="Urbanladder" />
          {
            !isWebView && (
              <div style={{ marginBottom: "50px" }}>
                <AppDownloadBanner />
                <Header
                  searchQuery={location.query.keywords}
                  currentRoute={addUrlParams(location.pathname, location.query)}
                  isSearchCollapsed={isSearchCollapsed}
                  isExternalSearchOpen={isSearchOpen}
                  onSearchClose={this.handleSearchModeClose}
                />
              </div>
            )
          }

          <div
            className="page-container"
            // eslint-disable-next-line react/jsx-no-bind
            ref={(pageContainer) => {
              this.pageContainer = pageContainer
            }}
          >
            {
              !isWebView && (
                <MockSearchBar
                  childRef={msb => (this.mockSearchBar = msb)}
                  isOpen={isSearchOpen}
                  searchQuery={location.query.keywords}
                  onClick={this.handleMockSearchClick}
                />
              )
            }
            {children}
          </div>
          {
            !isWebView &&
            enableEmailSignupSheet &&
            <EmailSignupBottomSheet />
          }
          {
            !isWebView && (
              <Footer
                childRef={f => (this.Footer = f)}
                shouldFooterSectionsLoad={shouldFooterSectionsLoad}
              />
            )
          }
        </ErrorBoundary>
      </div>
    )
  }
}

const connectedApp = connect(state => ({
  city: state.user.context.city,
}))(App)

export default provideHooks({
  fetch: ({ dispatch, path, data, params }) => dispatch(() =>
    Promise.all([
      dispatch(Actions.getNavigation()),
      dispatch(
        Actions.getPersonalizedContentBlocks(path, { ...data, ...params })
      ),
    ])),
})(connectedApp)
