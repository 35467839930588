/* eslint-disable comma-dangle */
export default class BaseAPI {

  constructor(client) {
    this.client = client
  }

  /*
   * Checks the types of function parameters
   * @param {[]} params — arguments to
   * @param {[]} types
   * @private
   */
  _checkType(params, types) {
    if (process.env.NODE_ENV === 'development') {
      const typeOf = function typeOf(obj) {
        return {}
          .toString
          .call(obj)
          .match(/\s(\w+)/)[1]
          .toLowerCase()
      }

      const args = [...params]

      for (let i = 0; i < types.length; ++i) {
        if (typeOf(args[i]) !== 'undefined' && typeOf(args[i]) !== types[i]) {
          throw new TypeError(
            `param ${i} must be of type ${types[i]}, got ${typeOf(args[i])}`
          )
        }
      }
    }
  }

  /**
   * Checks if a object has certain keys.
   * Scans one level deep only.
   */
  _schemaCheck(object, keys) {
    const isValid = keys.every(key => key in object)
    if (!isValid) {
      console.error('Schema check failed. ' +
        'Supplied params must have the following properties', keys)
    }
    return isValid
  }
}
