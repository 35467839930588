import React from 'react'
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare'

import Href from 'units/Href'
import Ripple from 'units/Ripple'
import handlesBackNavigation from 'components/HandleBackNavigation'

import { addUrlParams } from 'library/utils'

class AutoSuggestionListItem extends React.Component {
  static propTypes = {
    queryText: PropTypes.string.isRequired,
    suggestionText: PropTypes.string.isRequired,
    pushHistoryState: PropTypes.func,
    popHistoryState: PropTypes.func,
    replaceHistoryState: PropTypes.func,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState)
  }

  handleAutoSuggestionClick = (e) => {
    // Replace #search state added by opening of search mode
    this.props.replaceHistoryState(e.target.getAttribute('href'))
    e.preventDefault()
  };

  render() {
    const { queryText, suggestionText, ...other } = this.props
    const startIndex = suggestionText.indexOf(queryText)
    const endIndex = startIndex + queryText.length
    const hrefProps = {
      to: addUrlParams('/products/search', { keywords: suggestionText }),
    }

    return (
      <li { ...other }>
        <Ripple>
          <Href { ...hrefProps } onClick={ this.handleAutoSuggestionClick }>
            { suggestionText.substring(0, startIndex) }
            <span className="autosuggestion__highlight">
              { suggestionText.substring(startIndex, endIndex) }
            </span>
            { suggestionText.substring(endIndex) }
          </Href>
        </Ripple>
      </li>
    )
  }
}

export default handlesBackNavigation(AutoSuggestionListItem)
