import Spree from 'api/spree'
import { PromiseState } from 'constants/enums'
import { Widget } from 'settings/values'

/* =============
 -   ACTIONS   -
 ============= */

export function get(productId, params) {
  const spree = new Spree()
  const options = {
    ...params,
    per_page: Widget.ITEMS_PER_PAGE,
  }

  return {
    type: 'GET_SIMILAR_PRODUCTS',
    payload: spree.product.similar.get(productId, options),
  }
}

export function getNextPage(productId) {
  return (dispatch, getState) => {
    const { product } = getState()

    if (product.similar.current_page !== product.similar.pages) {
      return dispatch(
        get(productId, { page: product.similar.current_page + 1 }),
      )
    }
    return Promise.resolve()
  }
}

/* =============
 -  REDUCERS   -
 ============= */

export function similar(state = {
  products: [],
}, action) {
  let similarProducts = []

  switch (action.type) {
    case 'GET_SIMILAR_PRODUCTS_PENDING':
      return {
        ...state,
        promiseState: PromiseState.PENDING,
      }

    case 'GET_SIMILAR_PRODUCTS_FULFILLED':
      if (action.payload.current_page === 1) {
        similarProducts = action.payload.products
      } else {
        similarProducts = state.products.concat(action.payload.products)
      }
      return {
        ...state,
        ...action.payload,
        products: similarProducts,
        promiseState: PromiseState.FULFILLED,
      }

    case 'GET_SIMILAR_PRODUCTS_REJECTED':
      return {
        ...state,
        promiseState: PromiseState.REJECTED,
        error: action.payload,
      }

    case 'SPA_LOCATION_CHANGE':
      return {
        products: [],
      }

    default:
      return state
  }
}
