import React from 'react'
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/components/connect'

import Href from 'units/Href'

import Unicode from 'constants/unicode'
import { shouldProxyPermalink } from 'library/utils'

import Analytics from 'library/analytics'
import Event from 'library/analytics/eventFactory'

import './InterlinksPanel.scss'

const Interlinks = ({ interlinks = {}, parentTaxonPage}) => {
  const interlinkValues = Object.values(interlinks)
  const footer_title = "Browse Popular " + parentTaxonPage + "Categories"

  if (interlinkValues.length) {
    return (
      <div className="interlinks clearfix">
        <div className="header-title">{ footer_title }</div>
        {
          Object.values(interlinks).map(interlink => (
            <Href
              key={ interlink.id }
              to={ `/${interlink.permalink}` }
              className="interlinks__link col__lg--1-3 col__md--1-2 col__sm--1-2"
              isProxied={ shouldProxyPermalink(interlink.permalink) }
            >
              <div className="interlinks__name" onClick={() => {
                let eventObj = {
                  category: Event.category.POPULAR_CATEGORIES,
                  product_category: [interlink.name || ''],
                  page_section: footer_title,
                  page_location: window.location.pathname,
                  label: 'interlinks'
                }
                Analytics.categoryClicked(eventObj)
              }}>
                { interlink.name }
              </div>

              <div className="interlinks__description">
                {
                    interlink.count ? `${interlink.count}+ Options` : Unicode.NO_BREAK_SPACE
                  }
              </div>
            </Href>
            ),
          )
        }
      </div>
    )
  }

  return null
}

Interlinks.propTypes = {
  interlinks: PropTypes.object,
  parentTaxonPage: PropTypes.string
}

export default connect(state => ({
  interlinks: state.interlinks.categories,
}))(Interlinks)
