import Spree from 'api/spree'
import { PromiseState } from 'constants/enums'

/* =============
 -   ACTIONS   -
 ============= */

export function get(snippetId) {
  const spree = new Spree()

  return {
    type: 'GET_SNIPPET',
    payload: spree.snippets.get(snippetId),
    meta: { snippetId },
  }
}

/* =============
 -  REDUCERS   -
 ============= */

export function snippets(state = {}, action) {
  switch (action.type) {
    case 'GET_SNIPPET_PENDING':
      return {
        ...state,
        [action.meta.snippetId]: {
          promiseState: PromiseState.PENDING,
        },
      }

    case 'GET_SNIPPET_FULFILLED': {
      const { snippet } = action.payload.data
      return {
        ...state,
        [snippet.id]: {
          promiseState: PromiseState.FULFILLED,
          ...snippet,
        },
      }
    }

    case 'GET_SNIPPET_REJECTED':
      return {
        ...state,
        [action.meta.snippetId]: {
          promiseState: PromiseState.REJECTED,
          error: action.payload,
        },
      }

    default:
      return state
  }
}
