/* eslint-disable sort-class-members/sort-class-members */
import Endpoints from 'settings/endpoints'
import BaseAPI from '../../BaseAPI'

export default class Recommendations extends BaseAPI {
  similarSets(sku) {
    const params = {
      sku,
      similarity_type: 'filterable_properties',
    }
    return this.client.get(Endpoints.recommendations.similarLooks, params)
  }
}
