/* eslint-disable sort-class-members/sort-class-members */
import Endpoints from 'settings/endpoints'
import BaseAPI from '../../BaseAPI'

export default class Variants extends BaseAPI {
  details(skus, options) {
    const params = {
      taxonomy_v: '2',
      version: '1.0',
      sku_group: skus.join(','),
      ...options,
    }
    return this.client.get(Endpoints.variants, params)
  }
}
