import React from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames'
import Button, { Fill, Type, Size } from 'units/Button'
import JumpingDots from 'units/JumpingDots'

import './ProgressButton.scss'

/**
 * A thin wrapper over the Button
 * component to show progress status.
 */
const ProgressButton = ({
  showProgress,
  defaultText,
  progressText,
  className,
  ...other
}) => (
  <Button
    showProgress={ showProgress }
    disabled={ showProgress }
    { ...other }
    className={ classNames('progress-button', className) }
  >
    <span>{ showProgress ? progressText : defaultText }</span>
    <span className="progress-button__dots">
      { showProgress && <JumpingDots theme={ JumpingDots.theme.LIGHT } /> }
    </span>
  </Button>
)

ProgressButton.fill = Fill
ProgressButton.type = Type
ProgressButton.size = Size


ProgressButton.propTypes = {
  className: PropTypes.string,
  showProgress: PropTypes.bool,
  defaultText: PropTypes.string,
  progressText: PropTypes.string,
}

export default ProgressButton
