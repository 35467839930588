/* eslint-disable camelcase */
// ∵ API Response uses underscore_cased variables

import Spree from 'api/spree'
import { PromiseState } from 'constants/enums'
import { Widget } from 'settings/values'

/* =============
 -   ACTIONS   -
 ============= */

export function get(options) {
  const spree = new Spree()

  return (dispatch, getState) => {
    const params = {
      ...getState().pageData.testimonials.params,
      ...options,
      per_page: Widget.ITEMS_PER_PAGE,
    }
    return dispatch({
      type: 'GET_TESTIMONIALS',
      payload: spree.testimonials.get(params),
    })
  }
}

export function getNextPage() {
  return (dispatch, getState) => {
    const { pageData: { testimonials: { current_page, pages } } } = getState()
    if (current_page !== pages) {
      return dispatch(get({ page: current_page + 1 }))
    }
    return Promise.resolve()
  }
}

export function init(values) {
  return {
    type: 'INIT_TESTIMONIALS',
    options: {
      showSection: true,
      ...values,
    },
  }
}
/* =============
 -  REDUCERS   -
 ============= */

export function testimonials(
  state = {
    showSection: false,
    testimonials: [],
  }, action,
) {
  let testimonialsObj = {}

  switch (action.type) {
    case 'GET_TESTIMONIALS_PENDING':
      return {
        ...state,
        promiseState: PromiseState.PENDING,
      }

    case 'GET_TESTIMONIALS_FULFILLED':
      if (action.payload.current_page === 1) {
        testimonialsObj = action.payload.testimonials
      } else {
        testimonialsObj = state.testimonials.concat(action.payload.testimonials)
      }
      return {
        ...state,
        ...action.payload,
        testimonials: testimonialsObj,
        promiseState: PromiseState.FULFILLED,
      }

    case 'GET_TESTIMONIALS_REJECTED':
      return {
        ...action.payload,
        promiseState: PromiseState.REJECTED,
        error: action.payload,
      }

    case 'SPA_LOCATION_CHANGE':
      return {
        showSection: false,
        testimonials: [],
      }

    case 'INIT_TESTIMONIALS':
      return {
        ...state,
        ...action.options,
      }

    default:
      return state
  }
}
