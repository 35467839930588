import actions from './actions'
import labels from './labels'
import categories from './categories'
import dimensions from './dimensions'
import eventNames from './eventNames';

export default {
  action: actions,
  label: labels,
  category: categories,
  dimension: dimensions,
  eventName: eventNames,
}
