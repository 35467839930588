/* eslint-disable camelcase */
// ∵ API Response uses underscore_cased variables

import { PromiseState } from 'constants/enums'
import Spree from 'api/spree'

/* =============
 -   ACTIONS   -
 ============= */

export function getSuggestions(prefix) {
  const spree = new Spree()

  return {
    type: 'GET_SEARCH_SUGGESTIONS',
    payload: spree.search.suggest(prefix),
  }
}

/* =============
 -  REDUCERS   -
 ============= */

export function searchSuggestions(state = { suggestions: [] }, action) {
  switch (action.type) {
    case 'GET_SEARCH_SUGGESTIONS_PENDING':
      return {
        ...state,
        promiseState: PromiseState.PENDING,
      }

    case 'GET_SEARCH_SUGGESTIONS_FULFILLED':
      return {
        ...state,
        promiseState: PromiseState.FULFILLED,
        /**
         * The API returns `{}` instead of `[]`
         * for empty result set.
         * Handle this case so it doesn't break things.
         */
        suggestions: Array.isArray(action.payload.data.suggestions) ?
                     action.payload.data.suggestions : [],
      }

    case 'GET_SEARCH_SUGGESTIONS_REJECTED':
      return {
        ...state,
        promiseState: PromiseState.REJECTED,
        error: action.payload,
      }

    default:
      return state
  }
}
