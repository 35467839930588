import classNames from 'classnames'
import connect from 'react-redux/lib/components/connect'
import React from 'react'
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare'

import Href from 'units/Href'
import IconButton from 'units/IconButton'
import FaceIcon from 'units/icons/FaceIcon'
import LoginIcon from 'units/icons/LoginIcon'
import LogoutIcon from 'units/icons/LogoutIcon'
import OrdersIcon from 'units/icons/OrdersIcon'
import OrderTrackIcon from 'units/icons/OrderTrackIcon'
import ProfileIcon from 'units/icons/ProfileIcon'
import VoucherIcon from 'units/icons/VoucherIcon'

import { LoginState, SrcParam, ProfileActionsList } from 'constants/enums'
import Actions from 'store/actions'
import Analytics from 'library/analytics'
import { anscestorHasClass } from 'library/utils/DOMUtils'
import HistoryState from 'library/utils/historyUtils'

import './ProfileActions.scss'

class ProfileActions extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    loginState: PropTypes.string,
    currentRoute: PropTypes.string,
    recordClickEvents: PropTypes.func,
  };

  constructor(props) {
    super(props)
    this.historyState = new HistoryState({
      name: 'profile-actions',
      onBackPress: this.setDropDownClose,
    })
  }

  state = {
    isDropDownOpen: false,
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextState) {
    const { isDropDownOpen } = this.state

    if (!isDropDownOpen && nextState.isDropDownOpen) {
      this.historyState.add()
    }

    if (isDropDownOpen && !nextState.isDropDownOpen) {
      this.historyState.removeIfPossible()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState)
  }

  setDropDownClose = () => {
    this.setState({ isDropDownOpen: false })
    document.removeEventListener('click', this.closeDropDown, true)
    this.historyState.removeIfPossible()
  }

  closeDropDown = (event) => {
    if (!anscestorHasClass(event.target, 'profile-actions')) {
      event.preventDefault()
      this.setDropDownClose()
    }
  }

  openDropDown = () => {
    this.setState({ isDropDownOpen: true })
    document.addEventListener('click', this.closeDropDown, true)
    this.historyState.add()
  }

  handleToggleDropDownClick = () => {
    const isOpen = this.state.isDropDownOpen
    if (isOpen) {
      this.setDropDownClose()
    } else {
      this.openDropDown()
    }
  }

  handleLogout = (e) => {
    this.props.dispatch(Actions.logout())

    Analytics.identify(LoginState.LOGGED_OUT)
    this.props.recordClickEvents(e, ProfileActionsList.logout)
  };

  render() {
    const { currentRoute, loginState, recordClickEvents } = this.props
    const { isDropDownOpen } = this.state

    return (
      <div className="profile-actions">
        <IconButton onClick={ this.handleToggleDropDownClick }>
          <FaceIcon />
        </IconButton>
        <div
          className={ classNames('profile-actions__items',
            { 'profile-actions__items--hide': !isDropDownOpen }) }
          onClick={ this.handleToggleDropDownClick }
        >
          { loginState === LoginState.LOGGED_IN ? (
            <div>
              <Href 
                  onClick={(e) => recordClickEvents(e, ProfileActionsList.orders)}
                  className="profile-actions__item" to="/orders" shouldReplaceState>
                <OrdersIcon />
                <span className="profile-actions__item-label">{ProfileActionsList.orders}</span>
              </Href>

              <Href
                className="profile-actions__item"
                to={ `/orders?src=${SrcParam.trackOrder}` }
                shouldReplaceState
                onClick={(e) => recordClickEvents(e,  ProfileActionsList.track_order)}
              >
                <OrderTrackIcon />
                <span className="profile-actions__item-label">{ProfileActionsList.track_order}</span>
              </Href>

              <Href
                 onClick={(e) => recordClickEvents(e, ProfileActionsList.vouchers)}
                className="profile-actions__item" to="/vouchers" isProxied shouldReplaceState>
                <VoucherIcon />
                <span className="profile-actions__item-label">{ProfileActionsList.vouchers}</span>
              </Href>

              <Href 
                 onClick={(e) => recordClickEvents(e, ProfileActionsList.profile)}
                className="profile-actions__item" to="/profile" isProxied shouldReplaceState>
                <ProfileIcon />
                <span className="profile-actions__item-label">{ProfileActionsList.profile}</span>
              </Href>

              <Href
                className="profile-actions__item"
                onClick={ this.handleLogout }
                to="/logout"
                isProxied
                shouldReplaceState
              >
                <LogoutIcon />
                <span className="profile-actions__item-label">{ProfileActionsList.logout}</span>
              </Href>
            </div>
          ) : (
            <Href
              className="profile-actions__item"
              to={ { pathname: '/login', state: { redirectTo: currentRoute } } }
              shouldReplaceState
              onClick={(e) => recordClickEvents(e, ProfileActionsList.login)}
            >
              <LoginIcon />
              <span className="profile-actions__item-label">{ProfileActionsList.login}</span>
            </Href>
          ) }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  loginState: state.user.auth.loginState,
})

export default connect(mapStateToProps)(ProfileActions)
