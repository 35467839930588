import React from 'react'
import IndexRoute from 'react-router/lib/IndexRoute'
import Route from 'react-router/lib/Route'
import Router from 'react-router/lib/Router'

import Store from 'store/configureStore'
import Spree from 'api/spree'
import { LoginState } from 'constants/enums'
import config from 'settings/config'

import App from './handlers/App'
import AmpPages from './handlers/AmpPages'
import HealthCheck from './components/Health'

/**
 * It's not possible to determine the login state of
 * the user on the server, since our views are cached.
 */
function isUserLoggedIn() {
  if (isServer()) {
    return false
  }
  const spree = new Spree()
  return spree.auth.getLoginState() === LoginState.LOGGED_IN
}

function isWebView() {
  const store = Store.get()
  const storeData = store.getState()
  return storeData.user.context.isWebView
}

/**
 * ∴ This will throw a React checksum mismatch error
 * when a logged in user navigates to `/login` :(
 */
function redirectIfLoggedIn(nextState, replace) {
  if (isUserLoggedIn()) {
    const { state } = nextState.location
    const isValidState = state && state.redirectTo
    replace(isValidState ? state.redirectTo : config.defaultLoginRedirectPath)
  }
}

function mandateOrderVerification(nextState, replace) {
  if (!isUserLoggedIn()) {
    // For older Catalog Apps, handle VA-333.
    if (isWebView()) {
      return
    }

    replace({
      pathname: '/orders/auth',
      query: nextState.location.query,
    })
  }
}

function redirectToOrderHistoryIfLoggedIn(nextState, replace) {
  if (isUserLoggedIn()) {
    replace({
      pathname: '/orders',
      query: nextState.location.query,
    })
  }
}

function checkOrderAuth(nextState, replace) {
  const { state } = nextState.location
  const orderAuthenticated = state && state.orderAuthenticated

  if (!orderAuthenticated) {
    const { orderNumber } = nextState.params
    replace(`/orders?order_number=${orderNumber}`)
  }
}

function requireLogin(redirectTo) {
  return (nextState, replace) => {
    if (!isUserLoggedIn()) {
      replace({
        pathname: '/login',
        state: { redirectTo },
      })
    }
  }
}

const route = (leafTaxons, nonLeafTaxons) => (
  <Router>
    <Route
      path="/amp/:slug"
      component={ AmpPages }
    />
    <Route exact path="/health_checks/elb" component={ HealthCheck }></Route>
    <Route exact path="/" component={ App }>
      <IndexRoute
        getComponent={(nextProps, cb) =>
          require.ensure([], require =>
            cb(null, require('./handlers/Home').default),"Home"
          )
        }
        data={ { pageType: 'Home', allTaxons: { ...leafTaxons, ...nonLeafTaxons } } }
      />

      <Route path="/login" 
      getComponent={(nextProps, cb) =>
        require.ensure([], require =>
          cb(null, require('./handlers/Login').default),"Login"
        )
      }
       onEnter={ redirectIfLoggedIn } />

      <Route path="/products/search" 
      getComponent={(nextProps, cb) =>
        require.ensure([], require =>
          cb(null, require('./handlers/Search').default),"Search"
        )
      }
      data={ { pageType: 'Search' } } />

      <Route
        path="/products/:productSlug"
        getComponent={(nextProps, cb) =>
          require.ensure([], require =>
            cb(null, require('./handlers/Product').default),"Product"
          )
        }
        data={ { pageType: 'Product' } }
      />

      {/* <Route
        path="/home-trial-sofas"
        component={ SofaTrial }
        data={ { pageType: 'Sofa Trial' } }
      /> */}

      <Route
        path="/orders"
        getComponent={(nextProps, cb) =>
          require.ensure([], require =>
            cb(null, require('./handlers/Order').default),"OrderHistory"
          )
        }
        data={ { pageType: 'Order History' } }
        onEnter={ mandateOrderVerification }
      />

      <Route
        path="/orders/auth"
        onEnter={ redirectToOrderHistoryIfLoggedIn }
        getComponent={(nextProps, cb) =>
          require.ensure([], require =>
            cb(null, require('./handlers/Order/OrderAuth').default),"OrderAuth"
          )
        }
      />

      <Route
        path="/orders/:orderNumber/details"
        getComponent={(nextProps, cb) =>
          require.ensure([], require =>
            cb(null, require('./handlers/Order/Details').default),"OrderDetails"
          )
        }
        onEnter={ checkOrderAuth }
      />

      <Route
        path="/wishlist"
        getComponent={(nextProps, cb) =>
          require.ensure([], require =>
            cb(null, require('./handlers/Wishlist').default),"Wishlist"
          )
        }
        onEnter={ requireLogin('/wishlist') }
      />

      <Route
        path="/sets/:setsSlug"
        getComponent={(nextProps, cb) =>
          require.ensure([], require =>
            cb(null, require('./handlers/Sets').default),"Sets"
          )
        }
        data={ { pageType: 'Looks Details' } }
      />

      <Route
        path="/furniture-stores(/:city)"
        getComponent={(nextProps, cb) =>
          require.ensure([], require =>
            cb(null, require('./handlers/StoresListing').default),"StoresListing"
          )
        }
        data={ { pageType: 'Stores Listing' } }
      />

      <Route
        path="/all-furniture-stores(/:city)"
        getComponent={(nextProps, cb) =>
          require.ensure([], require =>
            cb(null, require('./handlers/StoresListing').default),"StoresListing"
          )
        }
        data={ { pageType: 'Stores Listing' } }
      />

      <Route
        path="/furniture-stores/:city/:location"
        getComponent={(nextProps, cb) =>
          require.ensure([], require =>
            cb(null, require('./handlers/Store').default),"StoreDetails"
          )
        }
        data={ { pageType: 'Furniture Store', type: 'furniture-stores' } }
      />

      <Route
        path="/sofa-stores/:city/:location"
        getComponent={(nextProps, cb) =>
          require.ensure([], require =>
            cb(null, require('./handlers/Store').default),"StoreDetails"
          )
        }
        data={ { pageType: 'Sofa Store', type: 'sofa-stores' } }
      />

      { /* Place all other routes above this */ }
      {
        Object.keys(nonLeafTaxons).map(permalink => (
          <Route
            key={ nonLeafTaxons[permalink].id }
            path={ `/${permalink}` }
            getComponent={(nextProps, cb) =>
              require.ensure([], require =>
                cb(null, require('./handlers/NonleafListing').default),"NonleafListing"
              )
            }
            data={ { pageType: 'Listing', ...nonLeafTaxons[permalink] } }
          />
        ))
      }
      {
        Object.keys(leafTaxons).map(permalink => (
          <Route
            key={ leafTaxons[permalink].id }
            path={ `/${permalink}` }
            getComponent={(nextProps, cb) =>
              require.ensure([], require =>
                cb(null, require('./handlers/LeafListing').default),"LeafListing"
              )
            }
            data={ { pageType: 'Listing', ...leafTaxons[permalink] } }
          />
        ))
      }
      <Route status={ 404 } path="*" 
      getComponent={(nextProps, cb) =>
        require.ensure([], require =>
          cb(null, require('./handlers/NotFound').default),"NotFound"
        )
      }
      />
    </Route>
  </Router>
)

export default route
