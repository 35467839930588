/* eslint-disable camelcase */
// ∵ API Response uses underscore_cased variables

import Spree from 'api/spree'
import { HomePage as HomePageConfig } from 'settings/values'
import { PromiseState, Status } from 'constants/enums'

/* =============
 -   ACTIONS   -
 ============= */

export function get(options) {
  const spree = new Spree()

  const extendedOptions = {
    per_page: HomePageConfig.INSPIRATIONS_PER_PAGE,
    ...options,
  }

  return {
    type: 'GET_INSPIRATIONS',
    payload: spree.home.inspirations.get(extendedOptions),
  }
}

export function getNextPage(options) {
  return (dispatch, getState) => {
    const { home } = getState()
    const inspirationsValue = home.inspirations
    if (!inspirationsValue.current_page) {
      return false
    }
    if (
      inspirationsValue.current_page <
        HomePageConfig.INSPIRATION_PAGES_LENGTH &&
      inspirationsValue.current_page < inspirationsValue.pages
    ) {
      return dispatch(
        get({
          ...options,
          page: inspirationsValue.current_page + 1,
        }),
      )
    }
    return Promise.resolve()
  }
}

/* =============
 -  REDUCERS   -
 ============= */

export function inspirations(state = { inspirations: [] }, action) {
  let payloadData = {}

  switch (action.type) {
    case 'GET_INSPIRATIONS_PENDING':
      return {
        ...state,
        promiseState: PromiseState.PENDING,
      }

    case 'GET_INSPIRATIONS_FULFILLED':
      if (action.payload.status === Status.SUCCESS) {
        payloadData = action.payload.data
        if (payloadData.current_page !== 1 && payloadData.inspirations) {
          payloadData.inspirations =
            state.inspirations.concat(payloadData.inspirations)
        }
      }

      return {
        ...state,
        promiseState: PromiseState.FULFILLED,
        ...payloadData,
      }

    case 'GET_INSPIRATIONS_REJECTED':
      return {
        ...state,
        promiseState: PromiseState.REJECTED,
        error: action.payload,
      }

    default:
      return state
  }
}
