/* eslint-disable sort-class-members/sort-class-members */
import Endpoints from 'settings/endpoints'
import BaseAPI from '../../BaseAPI'

/**
 * A key-value store of client configurations.
 */
export default class ClientConfiguration extends BaseAPI {
  get(key) {
    super._checkType([key], ['string'])
    return this.client.get(Endpoints.clientConfigurations, {
      key,
      client: 'web',
    })
  }

  getAll() {
    return this.client.get(Endpoints.clientConfigurations, { client: 'web' })
  }
}
