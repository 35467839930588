import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types';

import { Direction } from 'constants/enums'

import './AnimatedArrowIcon.scss'

/**
 * A chevron icon, with its dancing shoes on!
 */
const AnimatedArrowIcon = ({ direction, className, ...other }) => (
  <div
    className={ classNames('animated-arrow', `animated-arrow--${direction}`, className) }
    { ...other }
  >
    <div className="animated-arrow__flap" />
    <div className="animated-arrow__flap" />
  </div>
)

AnimatedArrowIcon.propTypes = {
  className: PropTypes.string,
  /**
   * Change in direction is animated.
   */
  direction: PropTypes.oneOf([Direction.UP, Direction.DOWN]),
}

AnimatedArrowIcon.defaultProps = {
  direction: Direction.DOWN,
}

export default AnimatedArrowIcon
