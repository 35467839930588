import Endpoints from 'settings/endpoints'
import BaseAPI from '../../BaseAPI'

export default class Wishlist extends BaseAPI {
  get() {
    const wishlistPromise = this.client.get(
      Endpoints.wishlist.get,
      null,
      null,
      { credentials: 'same-origin' },
    )


    // When a user signs-up, their wishlist isn't
    // created in the back end. The API response can't
    // be changed because the app will break. :\
    return wishlistPromise.then((wishlist) => {
      const updatedWishlist = { ...wishlist }
      if (!updatedWishlist.data) {
        updatedWishlist.data = {
          name: 'My Wishlist',
          is_private: false,
          is_default: true,
          total_count: 0,
          variants: [],
          wished_products: [],
        }
        return updatedWishlist
      }
      return wishlist
    })
  }

  add(variantId) {
    const paramToSend = {
      variant_id: variantId,
    }
    return this.client.post(
      Endpoints.wishlist.add,
      paramToSend,
      null,
      { credentials: 'same-origin' },
    )
  }

  remove(variantId) {
    const paramToSend = {
      variant_id: variantId,
    }
    return this.client.delete(
      Endpoints.wishlist.remove,
      paramToSend,
      null,
      { credentials: 'same-origin' },
    )
  }
}
