import Spree from 'api/spree'
import { PromiseState } from 'constants/enums'

export function get() {
  const apiClient = new Spree()
  return {
    type: 'GET_ORDERS',
    payload: apiClient.orderHistory.get(),
  }
}

export function fetchOrder(orderNumber) {
  const apiClient = new Spree()
  return {
    type: 'GET_ORDER_HISTORY',
    payload: apiClient.orderHistory.getOrderDetails(orderNumber),
  }
}

/* Reducers */
export function orderHistory(ordersState = {}, action) {
  switch (action.type) {
    case 'GET_ORDERS_PENDING':
      return {
        ...ordersState,
        ...action.payload,
        promiseState: PromiseState.PENDING,
      }
    case 'GET_ORDERS_FULFILLED':
      return {
        ...ordersState,
        ...action.payload,
        promiseState: PromiseState.FULFILLED,
      }
    case 'GET_ORDERS_REJECTED':
      return {
        ...ordersState,
        promiseState: PromiseState.REJECTED,
        error: action.payload,
      }
    case 'GET_ORDER_HISTORY_FULFILLED': {
      const orderNumber = action.payload.data.order.order_number
      const updatedCurrentOrders = ordersState.data.current_orders.map(
        (order) => {
          if (order.order_number !== orderNumber) {
            return order
          }
          return action.payload.data.order
        },
      )
      return {
        data: {
          current_orders: updatedCurrentOrders,
          completed_orders: ordersState.data.completed_orders,
        },
        status: ordersState.status,
        promiseState: ordersState.promiseState,
      }
    }
    default:
      return ordersState
  }
}
