export default {

  // Misc
  getCardViewModeLabel: viewMode => `${viewMode}.Toggle`,

  // Homepage
  BROWSE_COMPLETE_CATALOG: 'Browse_Complete_Catalogue',
  EXPLORE_COMPLETE_CATALOG: 'Explore_Complete_Catalogue',
  BROWSE_CATEGORIES_SEE_LESS: 'SeeLess',
  BROWSE_CATEGORIES_SEE_MORE: 'SeeMore',

  // Hamburger
  HAMBURGER_MENU: 'Menu',

  // Taxon Card
  TAXON_CARD_IMAGE: 'Taxon.Image.Click',
  TAXON_CARD_DATA: 'Taxon.Data.Click',

  // Filters
  FILTER_PANEL_TOGGLE: 'FilterPanel.Toggle',
  getPropertyFilterLabel: (name, value) => `Filter.${name}.${value}.Click`,
  getRangeFilterLabel: (
    name,
    values,
  ) => `Filter.${name}.${values.min}-${values.max}.Click`,
  getFilterClearLabel: name => `Filter.${name}.Clear`,
  getFilterToggleLabel: name => `Filter.${name}.Toggle`,

  // Sort
  SORT_PANEL_TOGGLE: 'SortPanel.Toggle',
  getSortLabel: value => `Sort.${value}`,

  // Out of Subscription
  getOOSOpenLabel: (
    prefix = '',
    productName,
  ) => `${prefix}${productName}.OOSBtn.Click`,
  getOOSSubmitLabel: (
    prefix = '',
    productName,
  ) => `${prefix}${productName}.OOSForm.Submit`,
  getOOSSubmitSuccessLabel: (prefix = '', productName) =>
    `${prefix}${productName}.OOSForm.Submit.Success`,
  getOOSSubmitFailureLabel: (prefix = '', productName) =>
    `${prefix}${productName}.OOSForm.Submit.Failed`,

  getPanelActionLabel: (title, action) => `${title}.${action}`,

  // Services
  TEST_EMAIL: 'test@gmail.com',

  BAJAJ_FINSERV_EMI_DETAILS: 'Bajaj Finserv',
  EMIS: 'emis',
  WARRANTY: 'warranty',
  INFO_BLOCKS: 'info blocks',
  SOCIAL_SHARE: 'social share',
  BAJAJ_FINSERV: 'bfl',
  ADD_TO_CART: 'add to cart',

  VIDEO_CALL_SCHEDULE: 'video_call_schedule',

  PRODUCT_PROPERTY: 'product property',
  PRODUCT_VIEWED: 'product viewed'
}
