import React, { Component } from 'react'
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare'

import Spree, { SpreeError } from 'api/spree'
import { PromiseState, SubscriptionType } from 'constants/enums'
import handlesBackNavigation from 'components/HandleBackNavigation'
import UserPreferences from 'library/storage/UserPreferences'
import { serializeForm } from 'library/utils'
import Config from 'settings'
import Button from 'units/Button'
import Modal from 'units/Modal'
import ProgressButton from 'units/ProgressButton'
import TextInput from 'units/TextInput'

import './SubscriptionModal.scss'

class SubscriptionModal extends Component {

  static propTypes = {
    productSKU: PropTypes.string,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    subscriptionType: PropTypes.string,
    taxon: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }

  state = {
    promiseState: null,
    subscribeMessage: null,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isOpen } = this.props

    if (!isOpen && nextProps.isOpen) {
      this.resetSubscription()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isOpen && this.state.isOpen) {
      this.emailInput.focus()
    }
  }

  resetSubscription = () => {
    this.setState({
      promiseState: null,
      subscribeMessage: null,
    })
  };

  handleModalClose = () => {
    this.props.onClose()
    this.resetSubscription()
  };

  handleSubscriptionSubmit = () => {
    const spree = new Spree()
    this.setState({ promiseState: PromiseState.PENDING })
    const formData = serializeForm(this.subscriptionForm)
    if (!formData.phone) {
      delete formData.phone
    }

    spree.services.subscribe(formData)
      .then((res) => {
        this.setState({
          subscribeMessage: res.success,
          promiseState: PromiseState.FULFILLED,
        })

        UserPreferences
          .setPreference(UserPreferences.preference.EMAIL, formData.email)
      })
      .catch((err) => {
        const errorMessage = err instanceof SpreeError ?
                                  err.error.message :
                                  'Something went wrong'
        this.setState({
          subscribeMessage: errorMessage,
          promiseState: PromiseState.REJECTED,
        })
      })
  };

  handleSubscribeFormSubmit = (e) => {
    e.preventDefault()
  };

  get taxonSubscriptionHiddenFields() {
    const { taxon } = this.props

    return (
      <span>
        <input type="hidden" name="cat_id" defaultValue={ taxon.id } />
        <input type="hidden" name="cat_name" defaultValue={ taxon.name } />
        <input
          type="hidden"
          name="sub_source"
          defaultValue={ Config.subSource.interestList }
        />
      </span>
    )
  }

  get productSubscriptionHiddenFields() {
    const { productSKU } = this.props

    return (
      <span>
        <input type="hidden" name="sku" defaultValue={ productSKU } />
        <input
          type="hidden"
          name="sub_source"
          defaultValue={ Config.subSource.productSoldout }
        />
      </span>
    )
  }

  render() {
    const { isOpen, subscriptionType, onClose } = this.props
    const {
      promiseState,
      subscribeMessage,
    } = this.state

    return (
      <Modal
        isOpen={ isOpen }
        onClose={ onClose }
      >
        {promiseState === PromiseState.FULFILLED ?
          <p>{subscribeMessage}</p> :
          (<form
            ref={ oosf => (this.subscriptionForm = oosf) }
            onSubmit={ this.handleSubscribeFormSubmit }
          >
            <TextInput
              name="email"
              placeholder="Email Address"
              ref={ e => (this.emailInput = e) }
              type="email"
              required
              validateType={ TextInput.validation.EMAIL }
            />
            <TextInput
              autoComplete="tel"
              name="phone"
              placeholder="Mobile Number (Optional)"
              type="tel"
            />
            {
              subscriptionType === SubscriptionType.TAXON ?
              this.taxonSubscriptionHiddenFields :
              this.productSubscriptionHiddenFields
            }
          </form>
          )
        }
        {
          promiseState === PromiseState.REJECTED &&
          <span className="subscription-modal__error">{subscribeMessage}</span>
        }
        {
          <footer className="subscription-modal__footer">
            {
              <Button
                type={ Button.type.TRANSPARENT }
                onClick={ this.handleModalClose }
                size={ Button.size.SMALL }
                className="subscription-modal__cancel"
              >
                { promiseState === PromiseState.FULFILLED ? 'Okay' : 'Cancel' }
              </Button>
            }
            {
              promiseState !== PromiseState.FULFILLED && <ProgressButton
                defaultText={
                  subscriptionType === SubscriptionType.TAXON ?
                  'Subscribe' : 'Notify Me'
                }
                progressText={
                  subscriptionType === SubscriptionType.TAXON ?
                  'Subscribing' : 'Processing'
                  }
                showProgress={ promiseState === PromiseState.PENDING }
                onClick={ this.handleSubscriptionSubmit }
              />
            }
          </footer>
          }
      </Modal>
    )
  }
}

export default handlesBackNavigation(SubscriptionModal)
