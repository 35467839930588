import Endpoints from 'settings/endpoints'
import BaseAPI from '../../BaseAPI'

export default class GiftCards extends BaseAPI {
  create(giftCardDetails) {
    return this.client.post(Endpoints.giftCard, {
      gift_card: giftCardDetails,
    })
  }
}
