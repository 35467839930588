import React from 'react'
import PropTypes from 'prop-types';

import infinite from 'components/Infinite'
import ProductList from 'components/Listing/ProductList'

import { Orientation, SrcParam } from 'constants/enums'

import './BestSellersDOM.scss'

const BestSellersDOM = ({ bestSellers }) => (
  <ProductList
    productCardsData={ bestSellers.products }
    isWidget
    srcParam={ SrcParam.bestSellers }
    orientation={ Orientation.HORIZONTAL }
    isWishlistButtonShown={ false }
    isListing={ false }
  />
)

BestSellersDOM.propTypes = {
  bestSellers: PropTypes.object,
}

export default infinite(BestSellersDOM)
