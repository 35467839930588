import { ContentBlock } from 'constants/enums'
import Endpoints from 'settings/endpoints'

import BaseAPI from '../../BaseAPI'


export default class contentBlocks extends BaseAPI {
  getPersonalizedContentBlocks(path, options) {
    const params = {
      device_type: "mobile",
      platform: "web",
      path: path,
    }

    if (options && options.pageType) {
      params["page_type"] = options.pageType.toLowerCase()

      if (options.pageType === "Listing") {
        params["category"] = options.permalink
      } else if(options.pageType === "Product") {
        params["product_slug"] = options.productSlug
      }
    }

    return this.client.get(Endpoints.personalizedContentBlocks, params)
  }
}
