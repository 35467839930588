import React from 'react'
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare'
import FlipMove from 'react-flip-move'

import AutoSuggestionListItem from './AutoSuggestionListItem'
import './AutoSuggestionList.scss'

export default class AutoSuggestionList extends React.Component {

  static propTypes = {
    suggestions: PropTypes.arrayOf(PropTypes.object).isRequired,
    highlightText: PropTypes.string,
    onItemClick: PropTypes.func,
    onOverlayClick: PropTypes.func,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState)
  }

  render() {
    const {
      suggestions,
      highlightText,
      onItemClick,
      onOverlayClick,
    } = this.props
    const queryText = highlightText.toLowerCase()

    return (
      <div className="autosuggestion">
        <div className="autosuggestion__overlay" onClick={ onOverlayClick } />
        <FlipMove
          enterAnimation="none"
          leaveAnimation="none"
          typeName="ul"
          className="autosuggestion__list"
          duration={ 80 }
        >
          {
            suggestions
              .filter(suggestion => suggestion.name.indexOf(queryText) > -1)
              .map(suggestion =>
                <AutoSuggestionListItem
                  key={ suggestion.name }
                  queryText={ queryText }
                  suggestionText={ suggestion.name }
                  onClick={ onItemClick }
                />,
              )
          }
        </FlipMove>
      </div>
    )
  }
}
