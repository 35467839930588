export function getShortMonthNames() {
  return [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct',
    'Nov', 'Dec',
  ]
}

/**
 * Get indexes of months including and after
 * the current month
 * @returns {Array}
 */

export function getFutureMonths() {
  const months = []
  for (let i = new Date().getMonth() + 1; i <= 12; i++) {
    months.push(i)
  }

  return months
}

/**
 * Get all possible dates in a given month.
 * If in current month, get only non-past dates.
 * @param month {number}
 * @returns {Array}
 */

export function getFutureDatesInMonth(month) {
  const year = new Date().getFullYear()
  const datesInMonth = new Date(year, month, 0).getDate()
  const currentMonth = new Date().getMonth() + 1
  let futureDates = []

  for (let i = 1; i <= datesInMonth; i++) {
    futureDates.push(i)
  }

  // If current month, remove bygone dates
  if (month === currentMonth) {
    futureDates = futureDates.slice(new Date().getDate() - 1)
  }

  return futureDates
}

export function getNextTenDaysDates() {
  const nextTenDays = []

  for (let i = 1; i <= 10; i++) {
    const currentDate = new Date(new Date().getTime() + (24 * i) * 60 * 60 * 1000)
    const day = currentDate.getDate()
    const month = currentDate.getMonth() + 1
    const year = currentDate.getFullYear()
    const date = `${day}/${month}/${year}`
    nextTenDays.push(date)
  }

  return nextTenDays
}

export function getTimeSlots() {
  const timeSlots = [
    '11:30 AM - 12:00 PM',
    '12:00 PM - 12:30 PM',
    '12:30 PM - 01:00 PM',
    '01:00 PM - 01:30 PM',
    '01:30 PM - 02:00 PM',
    '02:00 PM - 02:30 PM',
    '02:30 PM - 03:00 PM',
    '03:00 PM - 03:30 PM',
    '03:30 PM - 04:00 PM',
    '04:00 PM - 04:30 PM',
    '04:30 PM - 05:00 PM',
    '05:00 PM - 05:30 PM',
    '05:30 PM - 06:00 PM',
    '06:00 PM - 06:30 PM',
    '06:30 PM - 07:00 PM',
    '07:00 PM - 07:30 PM',
  ]

  return timeSlots
}
