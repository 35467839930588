export const DefaultOption = 'default'

export const options = [
  {
    label: 'Recommended',
    value: 'default',
  },
  {
    label: 'Price: Low to High',
    value: 'price_asc',
  },
  {
    label: 'Price: High to Low',
    value: 'price_desc',
  },
  {
    label: 'New Arrivals',
    value: 'new_arrival',
  },
  {
    label: 'Discount',
    value: 'discount_perc_desc',
  },
]
