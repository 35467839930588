import Endpoints from 'settings/endpoints'
import BaseAPI from '../../BaseAPI'

export default class Details extends BaseAPI {
  getFaq(params) {
    return this.client.get(
      Endpoints.product.faq(params.taxon_id, params.product_id), params
    )
  }

  get(slug, options) {
    const params = {
      taxonomy_v: '2',
      version: '1.1',
      meta: true,
      ...options,
    }
    return this.client.get(`${Endpoints.product.details}/${slug}`, params)
  }
}
