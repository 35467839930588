/**
 * A store for all textual and numerical
 * value based configurations
 */

import { ContentBlock } from 'constants/enums'


export const Debounce = {
  input: {
    MAX_WAIT_TIME: 300,
    WAIT_TIME: 150,
  },
  scroll: {
    MAX_WAIT_TIME: 120,
    WAIT_TIME: 120,
  },
}

// key "seo_meta_info" not camel-cased to match API response
/* eslint-disable max-len, react/no-unknown-property */
export const HomePage = {
  INSPIRATION_PAGES_LENGTH: 10,
  INSPIRATIONS_PER_PAGE: 2,
}

export const InfiniteLoad = {
  ITEMS_PER_PAGE: 8,
  LOAD_CYCLE_LENGTH: 3,
  offset: {
    grid: 300,
    list: 400,
  },
}

export const RangeFilter = {
  MAX_STEP: 100,
  MIN_STEP: 50,
}

export const Snippet = {
  homepage: {
    EMAIL_SIGNUP_BANNER: 1397,
  },
  giftCard: {
    FAQ: 1510,
    TERMS: 1511,
    PURCHASE_FORM_MESSAGE: 1570,
  },
  APP_DOWNLOAD_BOTTOM_SHEET: 1400,
  LOGIN_BOTTOM_SHEET: 1572,
  BAJAJ_EMI_PROCESS: 1579,
}

export const TextCrop = {
  [ContentBlock.position.AFTER_PAGEHEADER]: 18,
  [ContentBlock.position.SECTION_1]: 60,
}

export const Transition = {
  card: {
    FLIP_TIME: 100,
  },
  fade: {
    APPEAR_TIMEOUT: 300,
    ENTER_TIMEOUT: 400,
    LEAVE_TIMEOUT: 300,
  },
}

export const Widget = {
  ITEMS_PER_PAGE: 5,
  TESTIMONIALS_TEXT_LIMIT: 115,
  SEO_BEST_SELLER_LIMIT: 10,
}

export const Vibration = {
  LOW: 20,
  MEDIUM: 40,
  HIGH: 60,
}
