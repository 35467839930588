import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare'
import RegexPatterns from 'constants/regex'
import JumpingDots from 'units/JumpingDots'

import Ripple from 'units/Ripple'

import './TextInput.scss'

export default class TextInput extends React.Component {
  /**
   * Only store validations for patterns
   * that HTML5 forms don't already support.
   */
  static validation = {
    PINCODE: {
      pattern: RegexPatterns.PINCODE_IN,
      message: 'Please enter a valid 6 digit pincode.',
    },
    MOBILE_NUMBER: {
      pattern: RegexPatterns.MOBILE_NUMBER_IN,
      message: 'Please enter a valid 10 digit mobile number.',
    },
    EMAIL: {
      pattern: RegexPatterns.EMAIL,
      message: 'Please enter a valid email.',
    },
    ACCOUNT_HOLDER_NAME: {
      pattern: RegexPatterns.ACOOUNT_HOLDER_NAME,
      message: 'Special characters are not allowed.',
    },
    GIFT_CARD_NAME: {
      pattern: RegexPatterns.GIFT_CARD_NAME,
      message: 'Special characters are not allowed.',
    },
  };

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState)
  }

  // eslint-disable-next-line getter-return
  get validationMessage() {
    const {
      required, validateType, showValidation, error,
    } = this.props
    if (!showValidation) {
      return
    }

    if (required && !this.value()) {
      // eslint-disable-next-line consistent-return
      return 'Please fill this field.'
    } else if (validateType && !validateType.pattern.test(this.value())) {
      // eslint-disable-next-line consistent-return
      return validateType.message
    } else if (error) {
      // eslint-disable-next-line consistent-return
      return error
    }
  }

  focus() {
    this.input.focus()
  }

  select() {
    this.input.select()
  }

  value() {
    // Return empty string if the component hasn't mounted yet.
    return this.input ? this.input.value : ''
  }

  isValid = () => {
    const { required, validateType } = this.props

    return !(
      (required && !this.value()) ||
      (validateType && !validateType.pattern.test(this.value()))
    )
  }

  render() {
    const {
      containerClass,
      className,
      showProgress,
      validateType,
      showValidation,
      required,
      error,
      ...others
    } = this.props

    return (
      <div
        className={ classNames('text-input-container', containerClass) }
      >
        <Ripple
          disabled={ this.props.disabled || this.props.readOnly }
        >
          <input
            type="text"
            className={
              classNames(
                'text-input', className,
                {
                  'text-input--error': showValidation && !this.isValid(),
                },
              )
            }
            ref={ (i) => {
              this.input = i
            } }
            required={ required }
            pattern={ validateType && validateType.pattern.source }
            { ...others }
          />
          {
            showProgress && (
              <div className="text-input-dots">
                <JumpingDots />
              </div>
            )
          }
        </Ripple>
        <span className="text-input__error-msg">
          { this.validationMessage }
        </span>
      </div>
    )
  }
}


TextInput.propTypes = {
  className: PropTypes.string,
  containerClass: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  showProgress: PropTypes.bool,
  showValidation: PropTypes.bool,
  validateType: PropTypes.object,
  error: PropTypes.string,
}
