import Spree from 'api/spree/index'
import { PromiseState } from 'constants/enums'

/* =============
 -   ACTIONS   -
 ============= */

export function getEMIOptions(options) {
  const spree = new Spree()

  return {
    type: 'GET_EMI_OPTIONS',
    payload: spree.emis.getOptions(options),
  }
}


export function getBestEMIOptions(options) {
  const spree = new Spree()

  return {
    type: 'GET_BEST_EMI_OPTIONS',
    payload: spree.emis.getBestOptions(options),
  }
}


/* =============
 -  REDUCERS   -
 ============= */

const initialState = {
  emis: {
    emi_options: [],
    promiseState: PromiseState.PENDING,
  },
  bestEmis: {},
}

export default function financeOptions(state = initialState, action) {
  switch (action.type) {
    case 'GET_EMI_OPTIONS_PENDING':
      return {
        ...state,
        emis: {
          ...state.emis,
          promiseState: PromiseState.PENDING,
        },
      }

    case 'GET_EMI_OPTIONS_FULFILLED':
      return {
        ...state,
        emis: {
          ...state.emis,
          ...action.payload.data,
          promiseState: PromiseState.FULFILLED,
        },
      }

    case 'GET_EMI_OPTIONS_REJECTED':
      return {
        ...state,
        emis: {
          ...state.emis,
          promiseState: PromiseState.REJECTED,
        },
      }

    case 'GET_BEST_EMI_OPTIONS_PENDING':
      return {
        ...state,
        bestEmis: {
          ...state.bestEmis,
          promiseState: PromiseState.PENDING,
        },
      }

    case 'GET_BEST_EMI_OPTIONS_FULFILLED':
      return {
        ...state,
        bestEmis: {
          ...state.bestEmis,
          ...action.payload.data,
          promiseState: PromiseState.FULFILLED,
        },
      }

    case 'GET_BEST_EMI_OPTIONS_REJECTED':
      return {
        ...state,
        bestEmis: {
          ...state.bestEmis,
          promiseState: PromiseState.REJECTED,
        },
      }

    default:
      return state
  }
}
