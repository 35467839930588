import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import cx from 'classnames'
import Spree from 'api/spree'
import { PromiseQueue } from 'library/utils/promiseUtils'

import Actions from 'store/actions'
import IconButton from 'units/IconButton'
import WishlistIcon from 'units/icons/WishlistIcon'
import WishlistActiveIcon from 'units/icons/WishlistActiveIcon'
import Button from 'units/Button'

import './WishlistButton.scss'

const WishlistButtonPropTypes = {
  isInWishlist: PropTypes.bool.isRequired,
  variantId: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  className: PropTypes.string,
  size: PropTypes.string,
  dispatch: PropTypes.func,
}

function withWishlistToggle(WrappedComponent) {
  return class extends Component {
    static propTypes = WishlistButtonPropTypes

    constructor(props) {
      super(props)

      this.promiseQueue = new PromiseQueue({ concurrency: 1 })
    }

    state = {
      isInWishlist: this.props.isInWishlist,
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (
        (nextProps.variantId !== this.props.variantId) ||
        (!this.props.isInWishlist && nextProps.isInWishlist)
      ) {
        this.setState({ isInWishlist: nextProps.isInWishlist })
      }
    }

    toggleWishlistStatus = (variantId, nextState) => {
      const { dispatch } = this.props

      const spree = new Spree()
      let wishlistPromise

      if (nextState === true) {
        wishlistPromise = spree.wishlist.add(variantId)
      } else {
        wishlistPromise = spree.wishlist.remove(variantId)
      }
      return wishlistPromise
        .then(() => dispatch(Actions.getWishlist()))
        .catch((err) => {
          console.error(err)
          this.setState({
            // Revert if the update failed
            isInWishlist: !nextState,
          })
        })
    }

    handleWishlistButtonClick = (e) => {
      const { variantId, onClick } = this.props
      const { isInWishlist } = this.state

      if (onClick) {
        onClick(e, !isInWishlist)
      }

      if (e.defaultPrevented) return

      this.setState({
        // Optimistcally update the wishlist state
        isInWishlist: !isInWishlist,
      })

      // Maintain the order of network calls when
      // the button is clicked multiple times in succession
      this.promiseQueue.add(
        () => this.toggleWishlistStatus(variantId, !isInWishlist),
      )
    }

    render() {
      const { className, size } = this.props
      const { isInWishlist } = this.state
      return (
        <WrappedComponent
          className={ className }
          isInWishlist={ isInWishlist }
          onClick={ this.handleWishlistButtonClick }
          size = { size }
        />
      )
    }
  }
}

export const WishlistButton = connect(({ wishlist }) => ({ wishlist }))(
  withWishlistToggle(
    ({ isInWishlist, variantId, className, ...others }) => (
      <Button
        fill={ isInWishlist ? 'full' : 'none' }
        className={ className }
        { ...others }
      >
        { isInWishlist ? 'Wishlisted' : 'Wishlist' }
      </Button>
    ),
  ))

export const WishlistIconButton = connect(({ wishlist }) => ({ wishlist }))(
  withWishlistToggle(
    ({ isInWishlist, variantId, className, size, ...others }) => (
      <IconButton
        className={ cx(className, 'wishlist-icon-button') }
        { ...others }
      >
        { isInWishlist ? (
          <WishlistActiveIcon
            className="wishlist-icon-button__icon--active"
            size = { size }
          />
        ) : <WishlistIcon
              size = { size } 
            />
        }
      </IconButton>
    )),
)

WishlistButton.propTypes = WishlistButtonPropTypes
WishlistIconButton.propTypes = WishlistButtonPropTypes
