class GlobalRequestHeaders {
  static headers = {}

  static get() {
    return GlobalRequestHeaders.headers
  }

  static set(headers) {
    GlobalRequestHeaders.headers = {
      ...GlobalRequestHeaders.headers,
      ...headers,
    }
  }

  static reset() {
    GlobalRequestHeaders.headers = {}
  }
}

export default GlobalRequestHeaders

