import cloneDeep from 'lodash/cloneDeep'
import { LOCATION_CHANGE } from 'react-router-redux'

import Actions from 'store/actions'

/* =============
 -   ACTIONS   -
 ============= */
export function applyFilters(filters) {
  return dispatch => Promise.resolve(
    dispatch({
      type: 'APPLY_FILTERS',
      filters,
    }),
  )
}

// eslint-disable-next-line max-params
export function applyListingFilters(filters, permalink, isLeaf, options) {
  return dispatch => dispatch(applyFilters(filters))
    .then(() => dispatch(Actions.getListing(permalink, isLeaf, options)))
}

export function applySort(sort) {
  return dispatch => Promise.resolve(
    dispatch({
      type: 'APPLY_SORT',
      sort,
    }),
  )
}

// eslint-disable-next-line max-params
export function applyListingSort(sort, permalink, isLeaf, options) {
  return dispatch => dispatch(applySort(sort))
    .then(() => dispatch(Actions.getListing(permalink, isLeaf, options)))
}

export function applySearchFilters(filters, query, options) {
  return dispatch => dispatch(applyFilters(filters))
    .then(() => dispatch(Actions.getSearchResults(query, options)))
}

/* =============
 -  REDUCERS   -
 ============= */

export function filter(state = {}, action) {
  switch (action.type) {
    case 'APPLY_FILTERS':
      return {
        ...state,
        filters: cloneDeep(action.filters),
      }

    case 'APPLY_SORT':
      return {
        ...state,
        sort: action.sort,
      }

    case LOCATION_CHANGE:
      return {}

    default:
      return state
  }
}
