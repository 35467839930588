/* eslint-disable max-len */
/**
 * A store for commonly used regex
 * form validation patterns.
 *
 * Suffix corresponding ISO Locale prefix
 * if the pattern is locale dependent
 *
 * Some helpful validation patterns can be found here
 * @see https://github.com/chriso/validator.js
 */

export default {
  EMAIL: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  MOBILE_NUMBER_IN: /^[789]\d{9}$/,
  PINCODE_IN: /^[1-9][0-9]{5}$/,
  PRODUCT_PAGE_PATH: /\/products\/(?!search).*/,
  ACOOUNT_HOLDER_NAME: /^[a-zA-Z0-9 ]+$/,
  GIFT_CARD_NAME: /^[^-\s][a-zA-Z0-9_\s-]+$/,
  IFSC_CODE: /^[A-Za-z]{4}0[A-Z0-9a-z]{6}$/,
}

