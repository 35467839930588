/* eslint-disable camelcase */
// ∵ API Response uses underscore_cased variables
import Spree from 'api/spree'
import { ClientConfiguration, PromiseState } from 'constants/enums'
import GiftCardConfig from 'handlers/GiftCard/giftcard.config'

/* =============
 -   ACTIONS   -
 ============= */

export function getConfig() {
  const spree = new Spree()

  return {
    type: 'GET_GIFTCARD_CONFIG',
    payload: spree.clientConfiguration
      .get(ClientConfiguration.GIFT_CARD_CONFIG),
  }
}

export function setTheme(theme) {
  return {
    type: 'SET_THEME',
    payload: theme,
  }
}

export function setAmount(amount) {
  return {
    type: 'SET_AMOUNT',
    payload: amount,
  }
}

export function setDeliveryDate(date) {
  return {
    type: 'SET_DELIVERY_DATE',
    payload: date,
  }
}

export function setDeliveryMonthAndYear({ month, year }) {
  return {
    type: 'SET_DELIVERY_MONTH_AND_YEAR',
    payload: { month, year },
  }
}

export function setCardType(type) {
  return {
    type: 'SET_CARD_TYPE',
    payload: type,
  }
}

export function setFormDetails(formDetails) {
  return {
    type: 'SET_FORM_DETAILS',
    payload: formDetails,
  }
}

export function setFormDirty(isDirty) {
  return {
    type: 'SET_FORM_DIRTY',
    payload: isDirty,
  }
}

/* =============
 -  REDUCERS   -
 ============= */
const initialState = {
  config: { occasions: [] },
  selectedMonth: null,
  selectedDate: null,
  selectedCardType: GiftCardConfig.TYPE.ECARD,
  selectedTheme: {},
  formDetails: {},
}

export default function giftCard(state = initialState, action) {
  switch (action.type) {
    case 'GET_GIFTCARD_CONFIG_PENDING':
      return {
        ...state,
        config: {
          ...state.config,
          promiseState: PromiseState.PENDING,
        },
      }

    case 'GET_GIFTCARD_CONFIG_FULFILLED':
      return {
        ...state,
        config: {
          promiseState: PromiseState.FULFILLED,
          ...action.payload.data.gift_card_config,
        },
      }

    case 'GET_GIFTCARD_CONFIG_REJECTED':
      return {
        ...state,
        config: {
          ...state.config,
          promiseState: PromiseState.REJECTED,
        },
      }

    case 'SET_THEME':
      return {
        ...state,
        selectedTheme: action.payload,
      }

    case 'SET_AMOUNT':
      return {
        ...state,
        selectedAmount: action.payload,
      }

    case 'SET_CARD_TYPE':
      return {
        ...state,
        selectedCardType: action.payload,
      }

    case 'SET_DELIVERY_MONTH_AND_YEAR':
      return {
        ...state,
        selectedMonth: action.payload.month,
        selectedYear: action.payload.year,
      }

    case 'SET_DELIVERY_DATE':
      return {
        ...state,
        selectedDate: action.payload,
      }

    case 'SET_FORM_DETAILS':
      return {
        ...state,
        isFormDirty: false,
        formDetails: action.payload,
      }

    case 'SET_FORM_DIRTY':
      return {
        ...state,
        isFormDirty: action.payload,
      }

    default:
      return state
  }
}
