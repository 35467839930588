import fetch from 'isomorphic-fetch'

import { serializeParams, addUrlParams, handleFetchResponse } from 'library/utils'
import GlobalRequestHeaders from './GlobalRequestHeaders'

export default class Client {

  constructor(token, baseURL) {
    this.token = token
    this.baseURL = baseURL || ''
  }

// eslint-disable-next-line max-params
  get(endpoint, params, headers, options) {
    const fetchURL = `${addUrlParams(`${this.baseURL}${endpoint}`, params)}`

    return fetch(fetchURL, {
      ...options,
      headers: {
        Accept: 'application/json',
        'X-Spree-Token': this.token,
        ...headers,
        ...GlobalRequestHeaders.get(),
      },
    }).then((response) => {
      return handleFetchResponse(response)
    })
  }

// eslint-disable-next-line max-params
  post(endpoint, params, headers, options) {
    const postHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      ...headers,
    }
    return fetch(`${this.baseURL}${endpoint}`, {
      ...options,
      method: 'post',
      headers: { ...postHeaders, 'X-Spree-Token': this.token },
      body: serializeParams(params),
    }).then((response) => {
      return handleFetchResponse(response)
    })
  }

  // eslint-disable-next-line max-params
  delete(endpoint, params, headers, options) {
    const fetchURL = `${addUrlParams(`${this.baseURL}${endpoint}`, params)}`

    const deleteHeaders = {
      Accept: 'application/json',
      'X-Spree-Token': this.token,
      ...headers,
    }
    return fetch(fetchURL, {
      ...options,
      method: 'delete',
      headers: deleteHeaders,
    }).then((response) => {
      return handleFetchResponse(response)
    })
  }

}
