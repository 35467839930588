import Endpoints from 'settings/endpoints'
import BaseAPI from '../../BaseAPI'

export default class Order extends BaseAPI {

  getCancellationEligibility(orderNumber, params) {
    const endpoint = Endpoints.order.cancellationEligibility(orderNumber)
    return this.client.get(
      endpoint,
      params,
      null,
      { credentials: 'same-origin' },
    )
  }

  cancel(orderNumber, params) {
    const endpoint = Endpoints.order.cancel(orderNumber)
    return this.client.post(
      endpoint,
      params,
      null,
      { credentials: 'same-origin' },
    )
  }

  details(orderData) {
    const { orderNumber, phoneNumber } = orderData
    return this.client.get(Endpoints.order.detailsWithValidation, {
      order_number: orderNumber,
      phone_number: phoneNumber,
    })
  }
}
