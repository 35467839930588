import Endpoints from 'settings/endpoints'
import BaseAPI from '../../BaseAPI'

export default class Interlinks extends BaseAPI {
  get(permalink) {
    const params = {
      taxonomy_v: 2,
    }

    if (permalink) {
      params.taxon_permalink = permalink
    }
    return this.client.get(Endpoints.interlinks, params)
  }
}
