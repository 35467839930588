import { parseURL } from 'library/utils'

/**
 * Manage back button behaviour and  history
 * states for components such as modals, bottom sheets etc.
 */
export default class HistoryState {
  constructor({ name, kind, onBackPress }) {
    this.kind = kind
    this.name = name
    this.onBackPress = onBackPress
  }

  add() {
    const currentHash = window.location.hash
    const newHash = currentHash ?
      `${currentHash}+${this.name}` : `#${this.name}`

    // Set timeout here insures that
    // the pushState does not race with any
    // other history manipulation operation
    setTimeout(() => {
      window.history.pushState(null, null, newHash)
    }, 0)

    setTimeout(() => {
      window.addEventListener('hashchange', this._handleHashChange)
    }, 100)
  }

  _handleHashChange = (e) => {
    const { hash } = parseURL(e.oldURL)
    const stateHash = hash.substring(1)
    if (stateHash) {
      const states = stateHash.split('+')
      if (states[states.length - 1] === this.name) {
        this.onBackPress()
        window.removeEventListener('hashchange', this._handleHashChange)
      }
    }
  }


  removeIfPossible = () => {
    const { hash } = window.location
    const currentHash = hash.substring(1)

    if (currentHash) {
      const states = currentHash.split('+')
      if (states[states.length - 1] === this.name) {
        window.removeEventListener('hashchange', this._handleHashChange)
        window.history.back()
        states.pop()
        const newLocation = states.length ?
          `#${states.join('+')}` : window.location.href.split('#')[0]
        window.history.replaceState({}, '', newLocation)
      }
    }
  }
}
