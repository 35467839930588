import React from 'react'
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare'

/**
 * A HOC that supplies additional props
 * to its child which enable it to override
 * the default page navigation behaviour
 * on back button press.
 * Addition and removal of states bypass react-router.
 *
 * @deprecated Use `library/historyUtils` instead.
 */
const HandlesBackNavigation = ComposedComponent =>
  class HandlesBackButtonNavigation extends React.Component {
    static contextTypes = {
      router: PropTypes.object,
    };

    constructor(props) {
      super(props)
      this.callback = null
    }

    shouldComponentUpdate(nextProps, nextState) {
      return shallowCompare(this, nextProps, nextState)
    }

    /**
     * Adds a history state.
     *
     * We bypass react router, so that handler lifecycle events
     * do not get called again.
     *
     * @param {string} [fragment=displayName] - Hash
     * fragment to append to the URL.
     *
     * @callback onPopState - Callback for
     * back button press.
     */
    pushHistoryState = (fragment, onPopState) => {
      if (!window.history && window.history.pushState) {
        return
      }

      // Lets show warnings in dev mode only
      if (process.env.NODE_ENV === 'development') {
        if (typeof fragment === 'undefined') {
          console.error('Specify a fragment to append while pushing state.')
        }
        if (fragment.startsWith('#')) {
          console.error("You don't need to prefix a fragment with '#'.")
        }
      }

      this.callback = () => {
        window.removeEventListener('popstate', this.callback)
        if (onPopState) {
          onPopState()
        }
      }

      window.history.pushState(null, '', `#${fragment}`)
      window.addEventListener('popstate', this.callback)
    };

    /**
     * Removes previous history state.
     *
     * We bypass react router, so that handler lifecycle events
     * do not get called again.
     */
    popHistoryState() {
      if (!window.history && window.history.pushState) {
        return
      }
      window.history.back(1)
    }

    /**
     * Utilises react router.
     *
     * @param url - Can be a string or a object
     * accepted by react router
     */
    replaceHistoryState = (url) => {
      this.context.router.replace(url)
      if (this.callback) {
        window.removeEventListener('popstate', this.callback)
        this.callback = null
      }
    };

    render() {
      return (
        <ComposedComponent
          { ...this.props }
          pushHistoryState={ this.pushHistoryState }
          popHistoryState={ this.popHistoryState }
          replaceHistoryState={ this.replaceHistoryState }
        />
      )
    }
  }

export default HandlesBackNavigation
