function getTaxonData(taxon, ancestory) {
  const { id, name, permalink } = taxon
  const lineage = [...ancestory]
  return {
    id,
    name,
    permalink,
    lineage,
  }
}

export default function splitListing(navigation) {
  const leafTaxons = {}
  const nonLeafTaxons = {}

  const ancestory = [{
    id: 0,
    name: 'Home',
    permalink: '/',
  }]

  const resolveTaxons = (taxons) => {
    /* ===  LISTING ==== */
    taxons.forEach((taxon) => {
      ancestory.push({
        id: taxon.id,
        name: taxon.name,
        permalink: taxon.permalink,
      })
      const isNonLeaf = taxon.taxons && taxon.taxons.length > 0
      if (isNonLeaf) {
        nonLeafTaxons[taxon.permalink] = getTaxonData(taxon, ancestory)
        resolveTaxons(taxon.taxons)
      } else {
        leafTaxons[taxon.permalink] = getTaxonData(taxon, ancestory)
      }
      ancestory.pop()
    })
  }

  resolveTaxons(navigation.taxons)

  return {
    leafTaxons,
    nonLeafTaxons,
  }
}
