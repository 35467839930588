import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';
import cx from 'classnames'
import TransitionGroup from 'react-addons-css-transition-group'

import Button from 'units/Button'
import CloseIcon from 'units/icons/CloseIcon'
import IconButton from 'units/IconButton'
import HistoryState from 'library/utils/historyUtils'
import { Transition } from 'settings/values'
import Overlay from 'units/Overlay'

import './Modal.scss'

export default class Modal extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    okText: PropTypes.string,
    onOkClick: PropTypes.func,
    onCancelClick: PropTypes.func,
    cancelText: PropTypes.string,
    className: PropTypes.string,
    subTitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  };

  constructor(props) {
    super(props)

    this.historyState = new HistoryState({
      name: 'modal',
      onBackPress: props.onClose,
    })
  }

  componentDidMount() {
    const { isOpen } = this.props

    if (isOpen) {
      this.historyState.add()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isOpen } = this.props

    if (!isOpen && nextProps.isOpen) {
      this.historyState.add()
    }

    if (isOpen && !nextProps.isOpen) {
      this.historyState.removeIfPossible()
    }
  }

  handleOverlayClick = () => {
    this.props.onClose()
    this.historyState.removeIfPossible()
  }

  handleOkClick = () => {
    const { onOkClick } = this.props
    if (onOkClick) {
      onOkClick()
    }
  }

  handleCancelClick = () => {
    const { onCancelClick } = this.props
    if (onCancelClick) {
      onCancelClick()
    }
  }

  render() {
    const {
      cancelText,
      children,
      isOpen,
      title,
      okText,
      className,
      subTitle,
    } = this.props

    return (
      <TransitionGroup
        transitionName="fade"
        transitionAppear
        transitionAppearTimeout={ Transition.fade.APPEAR_TIMEOUT }
        transitionEnterTimeout={ Transition.fade.ENTER_TIMEOUT }
        transitionLeaveTimeout={ Transition.fade.LEAVE_TIMEOUT }
      >
        {
          isOpen && (
            <div className="modal-container" key="modal">
              <Overlay isActive onClick={ this.handleOverlayClick } />

              <div className={ cx('modal-container__content', className) }>
                <IconButton
                  className="modal-container__close-btn"
                  onClick={ this.handleOverlayClick }
                  clickable
                >
                  <CloseIcon />
                </IconButton>
                {
                  title && subTitle ? (
                    <div>
                      <h2 style={ { marginBottom: 0 } }> { title }</h2>
                      <p className="modal-container__subtitle"> { subTitle }</p>
                    </div>
                  ) : (
                    <h2> { title }</h2>
                  )
                }
                { children }
                {
                  (okText || cancelText) &&
                  <footer className="modal-container__footer">
                    {
                      okText &&
                      <Button onClick={ this.handleOkClick } hasFullWidth>
                        { okText }
                      </Button>
                    }
                    {
                      cancelText &&
                      <Button onClick={ this.handleCancelClick } hasFullWidth>
                        { cancelText }
                      </Button>
                    }
                  </footer>
                }
              </div>
            </div>
          )
        }
      </TransitionGroup>
    )
  }
}

