/* eslint-disable max-statements */
import React from 'react'
import PropTypes from 'prop-types';
import Link from 'react-router/lib/Link'
import shallowCompare from 'react-addons-shallow-compare'

import handlesBackNavigation from 'components/HandleBackNavigation'

import './Href.scss'

class Href extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    rel: PropTypes.string,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    /**
     * Open link in a new tab;
     */
    isExternal: PropTypes.bool,
    /**
     * Redirect user to hercules
     * for pages not yet in Birbul
     */
    isProxied: PropTypes.bool,
    itemProp: PropTypes.string,
    /**
     * Replace current state with the supplied
     * url, instead of adding a new state.
     */
    shouldReplaceState: PropTypes.bool,
    replaceHistoryState: PropTypes.func,
    popHistoryState: PropTypes.func,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    isExternal: false,
    isProxied: false,
    shouldReplaceState: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState)
  }

  handleLinkClick = (event) => {
    const {
      isExternal,
      isProxied,
      shouldReplaceState,
      to,
      onClick,
      replaceHistoryState,
      popHistoryState,
    } = this.props
    if (!shouldReplaceState) {
      if (onClick) {
        onClick(event)
      }
      return
    }

    event.preventDefault()
    if (!isExternal && !isProxied) {
      replaceHistoryState(to)
    }

    if (isProxied) {
      popHistoryState()

      // Popping state takes time
      // let's not navigate away before that
      setTimeout(() => {
        window.location = to
      }, 20)
    }

    if (onClick) {
      onClick()
    }
  };

  render() {
    const {
      children,
      isExternal,
      isProxied,
      to,
      shouldReplaceState, // eslint-disable-line no-unused-vars
      className,
      itemProp,
      rel,
    } = this.props

    const commonProps = { className, itemProp, rel }

    let link = ''
    if (isExternal) {
      link = (
        <a
          { ...commonProps }
          href={ to }
          onClick={ this.handleLinkClick }
          target="_blank"
          rel={ `${rel}` }
        >
          { children }
        </a>
      )
    } else if (isProxied) {
      link = (
        <a href={ to } onClick={ this.handleLinkClick } { ...commonProps }>
          { children }
        </a>
      )
    } else {
      link = (
        <Link to={ to } onClick={ this.handleLinkClick } { ...commonProps }>
          { children }
        </Link>
      )
    }
    return (link)
  }
}

export default handlesBackNavigation(Href)
