export default {
  LOGIN_STATE: {
    name: 'LoginState',
    dimensionNumber: 'dimension5',
    values: {
      LOGGED_IN: 'LoggedIn',
      LOGGED_OUT: 'LoggedOut',
      NEVER_LOGGED_IN: 'NeverLoggedIn',
    },
  },
  VARIANT_SKUS: 'dimension7',
  PAGE_TYPE: 'dimension2',
}

