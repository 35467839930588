import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/components/connect'

import BottomSheet from 'units/BottomSheet'
import Button from 'units/Button'
import Href from 'units/Href'
import TextInput from 'units/TextInput'
import SocialLoginButton from 'units/SocialLoginButton'
import ProgressButton from 'units/ProgressButton'

import SpreeError from 'api/spree/SpreeError'

import {
  PromiseState,
  LoginState,
  AuthenticationProvider,
} from 'constants/enums'
import Analytics from 'library/analytics'
import Event from 'library/analytics/eventFactory'
import rudderStackEvents from 'library/rudderstack/events';
import OAuth from 'library/oauth'
import { serializeForm } from 'library/utils'
import Action from 'store/actions'

import './LoginBottomSheet.scss'

class LoginBottomSheet extends PureComponent {

  static propTypes = {
    auth: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onLoginSuccess: PropTypes.func,
    messageSnippet: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  }

  static contextTypes = {
    pageType: PropTypes.string.isRequired,
  };

  static defaultProps = {
    messageSnippet: 'Please log in to continue',
  };

  constructor(props) {
    super(props)
    this.oauth = new OAuth({
      googleClientId: GlobalKey.googleClientId,
      facebookAppId: GlobalKey.facebookAppId,
    })
  }

  state = {
    content: {},
    isSignUpMode: false,
    showPassword: false,
    showSocialLoginButton: true,
  }

  getLoginInputData = () => {
    const userData = serializeForm(this.form)
    return {
      email: userData['spree_user[email]'],
      password: userData['spree_user[password]'],
    }
  };

  toggleSignUpMode = () => {
    const { isSignUpMode } = this.state

    Analytics.genericEvent({
      category: Event.category.LOGIN_BOTTOM_SHEET,
      action: isSignUpMode ? Event.action.SHOW_LOGIN_CLICK :
        Event.action.SHOW_SIGNUP_CLICK,
    })

    this.setState({
      isSignUpMode: !isSignUpMode,
      showPassword: false,
    })

    this.emailInput.focus()
  };

  toggleShowPasswordMode = () => {
    const { showPassword } = this.state

    Analytics.genericEvent({
      category: Event.category.LOGIN_BOTTOM_SHEET,
      action: showPassword ? Event.action.HIDE_PASSWORD_CLICK :
        Event.action.SHOW_PASSWORD_CLICK,
    })

    this.setState({ showPassword: !showPassword })
  };

  handleSuccessFulLogin = (provider) => {
    const { auth, onLoginSuccess } = this.props
    const userData = {
      provider,
      ...auth
    }

    Analytics.identify(LoginState.LOGGED_IN, auth)
    Analytics.genericEvent({
      event_name: Event.eventName.LOGGED_IN,
      category: Event.category.LOGIN_PAGE,
      action: Event.action.LOGIN_SUCCESS,
      nonInteraction: true,
      login_status: 'Success',
      user_data: userData,
    })
    onLoginSuccess()
  };

  handleSuccessFulSignup = (provider) => {
    const { auth, onLoginSuccess } = this.props

    const userData = {
      provider,
      ...auth
    }

    Analytics.identify(LoginState.LOGGED_IN, auth)
    Analytics.genericEvent({
      event_name: Event.eventName.SIGNED_UP,
      category: Event.category.LOGIN_PAGE,
      action: Event.action.LOGIN_SUCCESS,
      nonInteraction: true,
      sign_up_status: 'Success',
      user_data: userData,
    })
    onLoginSuccess()
  };

  handleSignUpFailure = (provider, failureReason) => {
    const { auth } = this.props
    
    const userData = {
      provider,
      failureReason,
      ...auth
    }

    Analytics.genericEvent({
      event_name: Event.eventName.SIGNED_UP,
      action: Event.action.CLICKED,
      nonInteraction: true,
      sign_up_status: 'Failure',
      user_data: auth,
    })
  }

  handleLoginFailure = (provider, failureReason) => {
    const { auth } = this.props

    const userData = {
      provider,
      failureReason,
      ...auth
    }

    Analytics.genericEvent({
      event_name: Event.eventName.LOGGED_IN,
      category: Event.category.LOGIN_PAGE,
      action: Event.action.CLICKED,
      nonInteraction: true,
      login_status: 'Failure',
      user_data: userData,
    })
  }

  handleSignup = (event) => {
    const { dispatch } = this.props
    const userData = this.getLoginInputData()
    const provider = AuthenticationProvider.UL_SITE

    dispatch(Action.signup(userData))
      .then(() => {
        this.setState({ isSignUpMode: false })

        return dispatch(Action.login(userData))
      })
      .then(this.handleSuccessFulSignup(provider))
      .catch((err) => {
        if (err instanceof SpreeError) {
          alert(err.message)
          this.emailInput.focus()
          this.emailInput.select()
        } else {
          alert(err)
          this.handleSignupFailure(provider, err.message)
        }
      })

    event.preventDefault()
  };

  handleLogin = (event) => {
    const { dispatch } = this.props
    const userData = this.getLoginInputData()
    const provider = AuthenticationProvider.UL_SITE

    dispatch(Action.login(userData))
      .then(this.handleSuccessFulLogin(provider))
      .catch((err) => {
        if (err instanceof SpreeError) {
          let errorMsg = err.message
          alert(errorMsg)
          console.error(errorMsg)
          this.passwordInput.focus()
          this.passwordInput.select()
        } else {
          console.error(err)
          alert(err)
        }
        this.handleLoginFailure(provider, err.message)
      })

    event.preventDefault()
  };

  handleFacebookOAuth = () => {
    const { dispatch } = this.props
    const { isSignUpMode } = this.state
    const that = this;
    const provider = AuthenticationProvider.FACEBOOK

    this.oauth
      .loadProviders()
      .then(this.oauth.initProviders)
      .then(() => {
        that.oauth
          .facebook
          .login()
          .then(userInfo => dispatch(Action.loginWithOAuth(userInfo)))
          .then(() => {
            if(isSignUpMode)
              this.handleSuccessFulSignup(provider)
            else
              this.handleSuccessFulLogin(provider)
          })
          .catch((err) => {
            if (err instanceof SpreeError) {
              alert(err.message)
            }
            console.error(err)
            if(isSignUpMode)
              this.handleSignUpFailure(provider, err.message)
            else 
              this.handleLoginFailure(provider, err.message)
          })
      })
  };

  handleGoogleOAuth = () => {
    const { dispatch } = this.props
    const { isSignUpMode } = this.state
    const that = this;
    const provider = AuthenticationProvider.GOOGLE

    this.oauth
      .loadProviders()
      .then(this.oauth.initProviders)
      .then(() => {
        that.oauth
          .google
          .login()
          .then(userInfo => dispatch(Action.loginWithOAuth(userInfo)))
          .then(() => {
            if(isSignUpMode)
              this.handleSuccessFulSignup(provider)
            else
              this.handleSuccessFulLogin(provider)
          })
          .catch((err) => {
            if (err instanceof SpreeError) {
              alert(err.message)
            }
            console.error(err)
            if(isSignUpMode)
              this.handleSignUpFailure(provider, err.message)
            else 
              this.handleLoginFailure(provider, err.message)
          })
      })
  };

  handleForgotPassword = () => {
    Analytics.genericEvent({
      category: Event.category.LOGIN_BOTTOM_SHEET,
      action: Event.action.FORGOT_PASSWORD_CLICK,
    })
  };


  handleTransitionEnd = (isOpen) => {
    if (isOpen) {
      Analytics.genericEvent({
        category: Event.category.LOGIN_BOTTOM_SHEET,
        action: Event.action.VIEWED,
        nonInteraction: true,
      })
    } else {
      Analytics.genericEvent({
        category: Event.category.LOGIN_BOTTOM_SHEET,
        action: Event.action.DISMISSED,
      })
    }
  }

  render() {
    const { auth, isOpen, onClose, messageSnippet } = this.props
    const { isSignUpMode, showPassword, showSocialLoginButton } = this.state

    return (
      <BottomSheet
        isOpen={ isOpen }
        showCloseButton={ false }
        onClose={ onClose }
        onTransitionEnd={ this.handleTransitionEnd }
      >

        <div className="login-bottom-sheet__content">
          <div>
            { messageSnippet }
          </div>
          <div className="header-title">
            { isSignUpMode ? 'Sign up using' : 'Social Sign In' }
          </div>
          <div className="login-bottom-sheet__social-login">
            <SocialLoginButton.Google
              onClick={ this.handleGoogleOAuth }
              hasProviderLoaded={ showSocialLoginButton }
              hasFullWidth
            />
            <SocialLoginButton.Facebook
              onClick={ this.handleFacebookOAuth }
              hasProviderLoaded={ showSocialLoginButton }
              hasFullWidth
            />
          </div>
          <div className="login-bottom-sheet__separator-text">
            or
          </div>
          <div className="login-bottom-sheet__email-login-heading">
            { isSignUpMode ? 'Sign up with us' : 'Login with your email Id' }
          </div>
          <form
            action={ isSignUpMode ? '/signup' : '/login' }
            className="login-bottom-sheet__email-login"
            method="POST"
            onSubmit={ isSignUpMode ? this.handleSignup : this.handleLogin }
            ref={ f => (this.form = f) }
          >
            <TextInput
              type="email"
              name="spree_user[email]"
              placeholder="Email Address"
              ref={ ti => (this.emailInput = ti) }
              required
              autoComplete="email"
              validateType={ TextInput.validation.EMAIL }
            />
            <div className="login-bottom-sheet__password-input">
              <TextInput
                type={ showPassword ? 'text' : 'password' }
                name="spree_user[password]"
                placeholder="Password"
                required
                ref={ pi => (this.passwordInput = pi) }
                autoComplete={ isSignUpMode ? 'new-password' :
                  'current-password' }
              />
              <div className="login-bottom-sheet__password-button-right">
                {
                  isSignUpMode ? (
                    <span onClick={ this.toggleShowPasswordMode }>
                      { showPassword ? 'Hide Password' : 'Show Password' }
                    </span>
                  ) : (
                    <Href
                      to="/resetpassword"
                      isProxied
                      onClick={ this.handleForgotPassword }
                    >
                      Forgot Password?
                    </Href>
                  )
                }
              </div>
            </div>
            <ProgressButton
              className="login-bottom-sheet__button"
              size={ Button.size.LARGE }
              hasFullWidth
              isSubmit
              showProgress={ auth.promiseState === PromiseState.PENDING }
              defaultText={ isSignUpMode ? 'Sign Up' : 'Log In' }
              progressText={ isSignUpMode ? 'Signing Up' : 'Logging In' }
            />
            <p>
              { isSignUpMode ? 'Already have an account?' :
                "Don't have an account?" }
              <span
                className="login-bottom-sheet__sign-up-button"
                onClick={ this.toggleSignUpMode }
              >
                { isSignUpMode ? 'Log in' : 'Sign Up' }
              </span>
            </p>
          </form>
        </div>
      </BottomSheet>
    )
  }
}

export default connect(state => ({
  auth: state.user.auth,
}))(LoginBottomSheet)
