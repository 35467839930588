import Spree from 'api/spree'

import { PromiseState } from 'constants/enums'

/* =============
 -   ACTIONS   -
 ============= */

export function get(params) {
  const spree = new Spree()

  return {
    type: 'GET_AVAILABLE_STORES',
    payload: spree.product.availableStores.get(params),
  }
}


/* =============
 -  REDUCERS   -
 ============= */

const initialState = {
  region: {},
  stores: [],
}

export function availableStores(state = initialState, action) {
  switch (action.type) {
    case 'GET_AVAILABLE_STORES_PENDING':
      return {
        ...state,
        promiseState: PromiseState.PENDING,
      }

    case 'GET_AVAILABLE_STORES_FULFILLED':
      return {
        ...state,
        region: action.payload.data.region,
        stores: action.payload.data.available_stores,
        promiseState: PromiseState.FULFILLED,
      }

    case 'GET_AVAILABLE_STORES_REJECTED':
      return {
        ...state,
        promiseState: PromiseState.REJECTED,
      }

    default:
      return state
  }
}
