import Spree from 'api/spree'
import { PromiseState } from 'constants/enums'

export function get(orderNumber, itemsForCancellation) {
  const apiClient = new Spree()
  return {
    type: 'GET_ORDER_CANCELLATION_ELIGIBILITY',
    payload: apiClient.order.getCancellationEligibility(orderNumber,
      {
        items_for_cancellation: itemsForCancellation
      }),
  }
}

export function cancellationEligibility(
  cancellationEligibilityState = {},
  action,
) {
  switch (action.type) {
    case 'GET_ORDER_CANCELLATION_ELIGIBILITY_PENDING':
      return {
        ...cancellationEligibilityState,
        ...action.payload,
        promiseState: PromiseState.PENDING,
      }
    case 'GET_ORDER_CANCELLATION_ELIGIBILITY_FULFILLED':
      return {
        ...cancellationEligibilityState,
        ...action.payload,
        promiseState: PromiseState.FULFILLED,
      }
    case 'GET_ORDER_CANCELLATION_ELIGIBILITY_REJECTED':
      return {
        ...cancellationEligibilityState,
        ...action.payload,
        promiseState: PromiseState.REJECTED,
      }
    default:
      return cancellationEligibilityState
  }
}
