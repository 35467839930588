import Spree from 'api/spree'
import { PromiseState } from 'constants/enums'

/* =============
 -   ACTIONS   -
 ============= */

export function get(type, city, location) {
  const spree = new Spree()

  return {
    type: 'GET_STORE_DETAILS',
    payload: spree.stores.get(type, city, location),
    meta: { city, location },
  }
}

/* =============
 -  REDUCERS   -
 ============= */

export function storeDetails(state = {}, action) {
  switch (action.type) {
    case 'GET_STORE_DETAILS_PENDING':
      return {
        ...state,
        promiseState: PromiseState.PENDING,
        details: {},
      }

    case 'GET_STORE_DETAILS_FULFILLED': {
      const storesData = action.payload.data
      return {
        ...state,
        promiseState: PromiseState.FULFILLED,
        details: storesData.store_details,
        metaDetails: storesData.meta_tags,
      }
    }

    case 'GET_STORE_DETAILS_REJECTED':
      return {
        ...state,
        promiseState: PromiseState.REJECTED,
        error: action.payload,
        details: {},
      }

    default:
      return state
  }
}
