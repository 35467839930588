import { combineReducers } from 'redux'

import { get as getSetDetails, getPartsDetails, details } from './detailsStore'
import { get as getSimilarSets, similarSets } from './similarSetsStore'

/* =============
 -   ACTIONS   -
 ============= */

const actions = {
  getSetDetails,
  getPartsDetails,
  getSimilarSets,
}

/* =============
 -  REDUCERS   -
 ============= */

const reducers = combineReducers({
  details,
  similarSets,
})

export default {
  actions,
  reducers,
}
