import Config from 'settings'

const StaticLinks = {
  furnitureCategories: [
    {
      name: 'Bed',
      link: '/bed',
    }, {
      name: 'Beds By Design',
      link: '/all-beds',
    }, {
      name: 'Sofa Set',
      link: '/sofa-set',
    }, {
      name: 'Wooden Sofa',
      link: '/wooden-sofas',
    }, {
      name: 'Sofas By Design',
      link: '/sofa',
    }, {
      name: 'Sofa Cum Bed',
      link: '/sofa-cum-bed',
    }, {
      name: 'Double Bed',
      link: '/double-beds',
    }, {
      name: 'Single Bed',
      link: '/single-beds',
    }, {
      name: 'Queen Size Bed',
      link: '/queen-size-bed',
    }, {
      name: 'King Size Bed',
      link: '/king-size-bed',
    }, {
      name: 'Chair',
      link: '/chair',
    }, {
      name: 'Wooden Table',
      link: '/tables',
    }, {
      name: 'Study Table',
      link: '/study-table',
    }, {
      name: 'Study Chair',
      link: '/study-chair',
    }, {
      name: 'Wardrobe',
      link: '/wardrobes',
    }, {
      name: 'Chest of Drawers',
      link: '/chest-of-drawers',
    }, {
      name: 'Futon',
      link: '/futon',
    }, {
      name: 'Loveseat',
      link: '/loveseats',
    }, {
      name: 'Dining Table Set',
      link: '/dining-table-set',
    }, {
      name: 'Dining Chair',
      link: '/dining-chairs',
    }, {
      name: 'Bookshelves',
      link: '/bookshelf',
    }, {
      name: 'Shoe Rack',
      link: '/shoe-rack',
    }, {
      name: 'TV Units',
      link: '/tv-units',
    }, {
      name: 'Recliners',
      link: '/recliners',
    }, {
      name: 'Furniture',
      link: '/furniture',
    }, {
      name: 'Office Furniture',
      link: '/office-furniture',
    }, {
      name: 'Office Table',
      link: '/office-tables',
    }, {
      name: 'Office Chair',
      link: '/office-chairs',
    }, {
      name: '2 Seater Sofa',
      link: '/two-seater-sofas',
    }, {
      name: 'TV Cupboard',
      link: '/tv-cupboard',
    }, {
      name: 'TV Showcase',
      link: '/tv-showcase',
    }, {
      name: 'TV Stand',
      link: '/tv-stand',
    }, {
      name: 'Center Table',
      link: '/center-tables',
    },
  ],
  furnitureByRoom: [
    {
      name: 'Living Room Furniture',
      link: '/living-room-furniture',
    }, {
      name: 'Bedroom Furniture',
      link: '/bedroom-furniture',
    }, {
      name: 'Dining Room Furniture',
      link: '/dining-furniture',
    }, {
      name: 'Study Room Furniture',
      link: '/study',
    }, {
      name: 'Bar Furniture',
      link: '/bar-furniture',
    }, {
      name: 'Balcony Furniture',
      link: '/balcony',
    },
  ],
  decorCategories: [
    {
      name: 'Home Decor',
      link: '/home-decor',
    },
    {
      name: 'Carpets',
      link: '/carpet',
    },
    {
      name: 'Mirrors',
      link: '/mirrors',
    },
    {
      name: 'Study Lamps',
      link: '/study-lamps',
    },
    {
      name: 'Table Lamps',
      link: '/table-lamps',
    },
    {
      name: 'Bed Sheets',
      link: '/bedsheets',
    },
    {
      name: 'Floor Lamps',
      link: '/floor-lamps',
    },
    {
      name: 'Wall Lights',
      link: '/wall-lights',
    },
    {
      name: 'Lighting',
      link: '/lighting',
    },
    {
      name: 'Ceiling Lights',
      link: '/ceiling-lights',
    },
    {
      name: 'Quilt',
      link: '/quilt',
    },
    {
      name: 'Wall Decor',
      link: '/wall-decors',
    },
    {
      name: 'Wall Mirror',
      link: '/wall-mirrors',
    },
    {
      name: 'Table Decor',
      link: '/table-furnishing',
    },
    {
      name: 'Table Cover',
      link: '/table-covers',
    },
    {
      name: 'Table Napkin',
      link: '/table-napkin',
    },
    {
      name: 'Table Mat',
      link: '/table-mat',
    },
    {
      name: 'Cushion Cover',
      link: '/cushion-cover',
    },
    {
      name: 'Table Runners',
      link: '/table-runners',
    },
    {
      name: 'Home Furnishing',
      link: '/home-furnishing',
    },
    {
      name: 'Wall Art',
      link: '/wall-art',
    },
    {
      name: 'Showpiece',
      link: '/showpieces',
    },
    {
      name: 'Artificial Plants',
      link: '/artificial-plants',
    },
    {
      name: 'Photo Frame',
      link: '/photo-frames',
    },
    {
      name: 'Candle Stand',
      link: '/candle-stand',
    },
    {
      name: 'Clocks',
      link: '/clocks',
    },
    {
      name: 'Wall Clocks',
      link: '/wall-clocks',
    },
    {
      name: 'Festive Lights',
      link: '/festive-lights',
    },
    {
      name: 'Candles',
      link: '/candles',
    },
    {
      name: 'Bath Mat',
      link: '/bath-mats',
    },
    {
      name: 'Vases',
      link: '/vases',
    },
    {
      name: 'Flower Vase',
      link: '/flower-vase',
    },
    {
      name: 'Bathroom Accessories',
      link: '/bathroom-accessories',
    },
    {
      name: 'Bathroom Mirrors',
      link: '/bathroom-mirrors',
    },
  ],

  mattressCategories: [
    {
      name: 'Mattresses',
      link: '/mattresses',
    }, {
      name: 'Single Bed Mattresses',
      link: ' /single-bed-mattress',
    }, {
      name: 'Double Bed Mattresses',
      link: '/double-bed-mattress',
    }, {
      name: 'King Size Mattresses',
      link: '/king-size-mattress',
    }, {
      name: 'Queen Size Mattresses',
      link: '/queen-size-mattress',
    }, {
      name: 'Foam Mattress',
      link: '/foam-mattress',
    }, {
      name: 'Coir Mattress',
      link: '/coir-mattress',
    }, {
      name: 'Latex Mattress',
      link: '/latex-mattress',
    }, {
      name: 'Spring Mattress',
      link: '/spring-mattress',
    }, {
      name: 'Orthopaedic Mattress',
      link: '/orthopaedic-mattress',
    },
  ],

  tablewareCategories: [
    {
      name: 'Shot Glass',
      link: '/shot-glass',
    },
    {
      name: 'Dinner Plates',
      link: '/dinner-plates',
    },
    {
      name: 'Baking Tray',
      link: '/baking-tray',
    },
    {
      name: 'Forks',
      link: '/fork',
    },
    {
      name: 'Dinner Set',
      link: '/dinner-set',
    },
    {
      name: 'Tumbler',
      link: '/tumbler',
    },
    {
      name: 'Drinking Glass',
      link: '/drinking-glass',
    },
    {
      name: 'Cutlery Holder',
      link: '/cutlery-holder',
    },
    {
      name: 'Tissue Holder',
      link: '/tissue-holder',
    },
    {
      name: 'Bottle Opener',
      link: '/bottle-opener',
    },
    {
      name: 'Spoons',
      link: '/spoons',
    },
    {
      name: 'Whiskey Glass',
      link: '/whiskey-glass',
    },
    {
      name: 'Kitchen Organizers',
      link: '/kitchen-organizers',
    },
    {
      name: 'Hot Pot',
      link: '/hot-pot',
    },
    {
      name: "Chef's Cap",
      link: '/chefs-cap',
    },
    {
      name: 'Glass Jars',
      link: '/glass-jars',
    },
    {
      name: 'Kitchen Gloves',
      link: '/kitchen-gloves',
    },
    {
      name: 'Kitchen Storage Containers',
      link: '/kitchen-storage-containers',
    },
    {
      name: 'Bar Tools',
      link: '/bar-tools',
    },
    {
      name: 'Masala Box',
      link: '/masala-box',
    },
    {
      name: 'Glass Bottles',
      link: '/glass-bottles',
    },
    {
      name: 'Coasters',
      link: '/coasters',
    },
    {
      name: 'Cutlery Set',
      link: '/cutlery-set',
    },
    {
      name: 'Knives Set',
      link: '/knives-set',
    },
    {
      name: 'Oven Gloves',
      link: '/oven-gloves',
    },
    {
      name: 'Aprons',
      link: '/aprons',
    },
    {
      name: 'Cake Stand',
      link: '/cake-stand',
    },
    {
      name: 'Fruit Basket',
      link: '/fruit-basket',
    },
    {
      name: 'Spice Box',
      link: '/spice-box',
    },
    {
      name: 'Beer Glass',
      link: '/beer-glass',
    },
    {
      name: 'Bowls',
      link: '/bowls',
    },
    {
      name: 'Oil Dispenser',
      link: '/oil-dispenser',
    },
    {
      name: 'Housewarming Gifts',
      link: '/housewarming-gifts',
    }
  ],

  footerLinks: [
    {
      title: 'Urban Ladder',
      links: [{ link: '/about-urban-ladder', text: 'About Us', isProxied: true },
        { link: '/furniture-stores', text: 'Visit Us', isProxied: false },
        { link: '/careers', text: 'Careers @ Urban Ladder', isProxied: true },
        { link: '/buddies', text: 'UL Buddies', isProxied: true },
        { link: '/blog', text: 'Blog', isExternal: true },
        { link: '/press-coverage', text: 'Press Coverage', isProxied: true },
        {
          link: 'https://www.linkedin.com/company/2482371/life/',
          text: 'Team',
          isExternal: true,
        }],
    },
    {
      title: 'Need Help?',
      links: [{ link: '/privacy-policy', text: 'Privacy Policy', isProxied: true },
        { link: '/security-payments', text: 'Fees and Payment', isProxied: true },
        { link: '/shipping-delivery', text: 'Shipping & Delivery', isProxied: true },
        { link: '/terms-of-use', text: 'Terms and Conditions', isProxied: true },
        { link: '/terms-of-offer-for-sale', text: 'Warranty, Return and Refund', isProxied: true },
        { link: '/contact', text: 'Contact Us', isProxied: true },
        { link: '/emi-credit-cards', text: 'EMI on Credit Cards', isProxied: true }],
    },
    {
      title: 'Explore More',
      links: [
        { link: '/refer_and_earn', text: 'Refer & Earn', isProxied: true },
      ],
    },
    {
      title: 'Contact Us',
      links: [{
        id: 'mobile_number',
        link: `tel:${Config.contactInfo.mobile}`,
        text: `Call Us - ${Config.contactInfo.mobile}`,
        isExternal: true,
      },
      {
        id: 'email',
        link: `mailto:${Config.contactInfo.email}`,
        text: `Mail Us - ${Config.contactInfo.email}`,
        isExternal: true,
      }],
    },
  ],
  registeredAddress: {
    companyName: 'Reliance Retail Limited',
    address: '3rd Floor, Court House, Lokmanya Tilak Marg, Dhobi Talao, Mumbai- 400 002, Maharashtra, India',
    cin: 'U01100MH1999PLC120563',
    grievanceContact: 'Akash Chauhan',
    grievanceContactEmail: 'hello@urbanladder.com',
  },
  social: {
    facebook: 'https://facebook.com/urbanladder',
    twitter: 'https://twitter.com/UrbanLadder',
    linkedin: 'https://in.linkedin.com/company/urban-ladder',
    instagram: 'https://instagram.com/urbanladder',
    youtube: 'https://youtube.com/user/urbanladder',
    pinterest: 'https://www.pinterest.com/urbanladder',
  },
}

export default StaticLinks
