import React from 'react'
import PropTypes from 'prop-types';
import infinite from 'components/Infinite'
import Testimonial from '../Testimonial'
import TestimonialLoaderCard from '../TestimonialsLoaderPanel/TestimonialLoaderCard'

import './TestimonialsDOM.scss'

const TestimonialsDOM = ({ testimonials, showLoader }) => {
  let footer = null
  if (showLoader) {
    footer = <TestimonialLoaderCard />
  }

  return (
    <div className="testimonials-panel">
      { testimonials.testimonials.map(testimonial =>
        <Testimonial
          key={ testimonial.image_small }
          testimonial={ testimonial }
        />,
        )
      }
      { footer }
    </div>
  )
}

TestimonialsDOM.propTypes = {
  testimonials: PropTypes.object,
  showLoader: PropTypes.bool.isRequired,
}

export default infinite(TestimonialsDOM)
