/* eslint-disable react/no-unused-prop-types */
/**
  * eslint does not count usage in nextProps, but it should.
  * The error, is therefore, a false positive.
  * @see https://github.com/yannickcr/eslint-plugin-react/issues/944
  */
import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types';

import Ripple from 'units/Ripple'
import AnimatedArrowIcon from 'units/AnimatedArrowIcon'

import { Align, Direction } from 'constants/enums'
import Analytics from 'library/analytics'
import Event from 'library/analytics/eventFactory'

import './AccordionItem.scss'

export default class AccordionItem extends React.Component {
  static propTypes = {
    index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isActive: PropTypes.bool,
    header: PropTypes.string,
    additionalHeaderElement: PropTypes.node,
    headerAlign: PropTypes.oneOf([Align.CENTER, Align.LEFT]),
    headerClassName: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    onHeaderClick: PropTypes.func,
    trackOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    shouldUpdate: PropTypes.bool,
    smoothScroll: PropTypes.bool,
    autonomous: PropTypes.bool,
    defaultOpen: PropTypes.bool,
    onShowProperties: PropTypes.func,
    showProperties: PropTypes.bool,
  };

  state = {
    isOpen: this.props.defaultOpen,
  };

  shouldComponentUpdate(nextProps) {
    return (
      nextProps.shouldUpdate ||
      (nextProps.isActive !== this.props.isActive)
    )
  }

  handleItemHeaderClick = () => {
    const {
      onHeaderClick,
      header,
      index,
      trackOpen,
      isActive,
      smoothScroll,
      autonomous,
      onShowProperties,
    } = this.props

    if (header === 'Properties') {
      onShowProperties()
    }

    if (onHeaderClick) {
      onHeaderClick(index)
    }

    if (autonomous) {
      this.setState({ isOpen: !this.state.isOpen })
    }

    if (smoothScroll) {
      setTimeout(() => {
        this.accordionItem.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }, 50)
    }

    if (trackOpen && !isActive) {
      Analytics.genericEvent({
        category: trackOpen.category,
        action: trackOpen.action || Event.action.OPEN,
        label: trackOpen.label || header,
        dimensions: trackOpen.dimensions,
      })
    }
  };

  render() {
    const {
      isActive,
      header,
      additionalHeaderElement,
      headerAlign,
      headerClassName,
      children,
      className,
      autonomous,
      showProperties,
    } = this.props

    const { isOpen } = this.state

    const shouldBeOpen = autonomous ? isOpen : isActive
    const additionalHeaderClass = classNames(
      'accordion-item__additional-header',
      { hide: !shouldBeOpen },
    )

    const additionalHeaderElementDOM = additionalHeaderElement ? (
      <div className={ additionalHeaderClass }>
        { additionalHeaderElement }
      </div>
    ) : ''

    return (
      <div
        className={ classNames('accordion-item', { active: showProperties || shouldBeOpen }, className) }
        ref={ ai => (this.accordionItem = ai) }
      >
        <Ripple className={ classNames('accordion-item__header', headerClassName) } onClick={ this.handleItemHeaderClick }>
          <AnimatedArrowIcon
            className="accordion-item__arrow"
            direction={ (showProperties || shouldBeOpen) ? Direction.UP : Direction.DOWN }
          />
          <div className={ classNames('accordion-item__caption', `text-${headerAlign}`) }>
            { header }
            { additionalHeaderElementDOM }
          </div>
        </Ripple>

        <div className="accordion-item__content">
          <div className="accordion-item__content-wrapper clearfix">
            { children }
          </div>
        </div>
      </div>
    )
  }
}

AccordionItem.defaultProps = {
  isActive: false,
  headerAlign: Align.LEFT,
  trackOpen: false,
  shouldUpdate: false,
  smoothScroll: true,
  defaultOpen: false,
}
