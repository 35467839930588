import React, { Component } from 'react'
import PropTypes from 'prop-types';
import cx from 'classnames'
import TransitionGroup from 'react-addons-css-transition-group'

import Overlay from 'units/Overlay'
import CloseIcon from 'units/icons/CloseIcon'
import IconButton from 'units/IconButton'

import HistoryState from 'library/utils/historyUtils'
import { Transition } from 'settings/values'
import './BottomSheet.scss'

export default class BottomSheet extends Component {
  static propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
    onTransitionEnd: PropTypes.func,
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    showCloseButton: PropTypes.bool,
  }

  static defaultProps = {
    showCloseButton: true,
  }

  constructor(props) {
    super(props)

    this.historyState = new HistoryState({
      name: 'bottom-sheet',
      onBackPress: props.onClose,
    })
  }

  componentDidMount() {
    const { isOpen } = this.props

    if (isOpen) {
      document.body.classList.add('noscroll')
      this.historyState.add()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isOpen } = this.props

    if (!isOpen && nextProps.isOpen) {
      this.isStable = false
      this.historyState.add()
    }

    if (isOpen && !nextProps.isOpen) {
      this.isStable = false
      this.historyState.removeIfPossible()
    }
  }

  handleClose = () => {
    const { onClose } = this.props

    if (this.isStable) {
      this.historyState.removeIfPossible()
      onClose()
    }
  }

  handleTransitionEnd = (e) => {
    if (e.target.classList.contains('bottom-sheet')) {
      const { isOpen, onTransitionEnd } = this.props
      this.isStable = true

      if (isOpen) {
        document.body.classList.add('noscroll')
      } else {
        document.body.classList.remove('noscroll')
      }

      if (onTransitionEnd) {
        onTransitionEnd(isOpen)
      }
    }
  }

  render() {
    const { children, isOpen, showCloseButton, title } = this.props

    return (
      <div className="bottom-sheet-container" key="bottom-sheet">
        <TransitionGroup
          transitionName="fade"
          transitionAppear
          transitionAppearTimeout={ Transition.fade.APPEAR_TIMEOUT }
          transitionEnterTimeout={ Transition.fade.ENTER_TIMEOUT }
          transitionLeaveTimeout={ Transition.fade.LEAVE_TIMEOUT }
        >
          {
            isOpen && (
              <Overlay
                key="overlay"
                isActive
                onClick={ this.handleClose }
              />
            )
          }
        </TransitionGroup>

        <section
          className={
            cx('bottom-sheet', {
              'bottom-sheet--open': isOpen,
            })
          }
          onTransitionEnd={ this.handleTransitionEnd }
        >
          {
            showCloseButton && (
              <IconButton
                className="bottom-sheet__close-btn"
                onClick={ this.handleClose }
                clickable
              >
                <CloseIcon />
              </IconButton>
            )
          }

          {
            title &&
            typeof title === 'string' ? (
              <h3 className="bottom-sheet__title">
                { title }
              </h3>
            ) : title
          }
          { children }
        </section>
      </div>
    )
  }
}
