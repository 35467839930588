import { routerReducer } from 'react-router-redux'
import { combineReducers } from 'redux'

import { filter as filters } from './stores/filtersStore'
import giftCards from './stores/giftCardStore'
import FinanceOptionsStore from './stores/financeOptionsStore'
import Home from './stores/homeStore'
import { navigation, interlinks, taxon } from './stores/navigationStore'
import PageStore from './stores/pageStore'
import Product from './stores/productStore'
import { listingResults } from './stores/productsStore'
import { faqDetails } from './stores/productStore/detailsStore'
import { snippets } from './stores/snippetsStore'
// import SofaTrialStore from './stores/sofaTrialStore'
import { searchSuggestions } from './stores/suggestionsStore'
import { storeDetails } from './stores/storeDetailsStore'
import { storesListing } from './stores/storesListingStore'
import { experimentVariant } from './stores/experimentStore'
import UserStore from './stores/userStore'
import OrderStore from './stores/orderStore'
import { wishlist } from './stores/wishlistStore'
import Sets from './stores/setsStore'
import { cities } from './stores/citiesStore'

export default combineReducers({
  // PageStore
  pageData: PageStore.reducers,

  // Filters
  filters,

  financeOptions: FinanceOptionsStore,

  // Experiment
  experimentVariant,

  // Home
  home: Home.reducers,

  // User store
  user: UserStore.reducers,

  // Listing and Search
  listingResults,

  // Navigation
  navigation,
  interlinks,
  taxon,

  // Cities
  cities,

  // Product
  product: Product.reducers,

  // Search Suggestions
  searchSuggestions,

  // Sofa Trials
  // sofaTrial: SofaTrialStore,

  // Routing
  routing: routerReducer,

  snippets,

  storeDetails,
  storesListing,

  // FAQ
  faqDetails,

  // Order
  order: OrderStore.reducers,

  // Gift Cards
  giftCards,

  // wishlist
  wishlist,

  sets: Sets.reducers,
})
