/**
 * Naming convention: lowercase, space separated action verbs,
 * qualify only if necessary
 */

export default {
  // Common actions
  CHANGED_SLIDE: 'changed_slide',
  CLICKED: 'clicked',
  DISMISSED: 'dismissed',
  OPEN: 'open',
  PANEL_OVERLAY_OPENED: 'popup_opened',
  TAXON_SHOWCASE_BANNER_CLICK: 'banner_click',
  TAXON_SHOWCASE_SEE_ALL_CLICK: 'top_see_all_click',
  VIEWED: 'viewed',
  ZOOMED: 'zoomed',
  INPUT_ENTERED: 'user_input',

  // Product Card
  PRODUCT_CARD_IMAGE_CLICK: 'image_click',
  PRODUCT_CARD_DATA_CLICK: 'data_click',

  // Product Page
  PRODUCT_ADD_TO_CART_CLICK: 'product_add_to_cart_clicked',

  // Login
  SHOW_PASSWORD_CLICK: 'show_password_clicked',
  HIDE_PASSWORD_CLICK: 'hide_password_clicked',
  FORGOT_PASSWORD_CLICK: 'forgot_password_clicked',

  SHOW_SIGNUP_CLICK: 'show_signup_clicked',
  SIGNUP_WITH_FACEBOOK_CLICK: 'signup_with_facebook_clicked',
  SIGNUP_WITH_GOOGLE_CLICK: 'signup_with_google_clicked',
  SIGNUP_WITH_EMAIL_CLICK: 'signup_with_email_clicked',
  SIGN_UP_SUCCESS: 'sign_up_success',

  SHOW_LOGIN_CLICK: 'Show Login Clicked',
  LOGIN_WITH_FACEBOOK_CLICK: 'Login with Facebook Click',
  LOGIN_WITH_GOOGLE_CLICK: 'Login with Google Click',
  LOGIN_WITH_EMAIL_CLICK: 'Login with Email Click',
  LOGIN_SUCCESS: 'Login Success',
  LOGGED_OUT: 'Logged Out',

  // Sofa Trial
  SOFA_TRIAL_SUCCESS: 'trial_sofa_success',
  SOFA_TRIAL_AVAILABLE: 'trial_available',
  SOFA_TRIAL_UNAVAILABLE: 'trial_not_available',
  SOFA_TRIAL_CONFIRM_SELECTION: 'confirm_selection',
  SOFA_TRIAL_BOOK_CLICK: 'book_now',

  CHECK_AVAILABILITY: 'check_availibility',

  // Order Cancellation
  REQUEST_CANCELLATION: 'request_cancellation',
  PROCEED_TO_ADDITIONAL_INPUT: 'proceed_to_additional_input',
  PROCEED_TO_CONFIRM: 'proceed_to_confirm',
  CONFIRM: 'confirm',
  CANCELLATION_REQUEST_SUCCESS: 'cancellation_request_success',
  CANCELLATION_REQUEST_FAILURE: 'cancellation_request_error',

  // Email Signup Banner
  EMAIL_SIGNUP_BANNER_INPUT_FOCUS: 'email_entry_intent',
  EMAIL_SUBSCRIBE: 'email_subcribe',

  // Email Signup Bottom Sheet
  EMAIL_SIGNUP_SHEET_CLOSE: 'email_signup_sheet_close',
  EMAIL_SIGNUP_SHEET_VIEWED: 'email_signup_sheet_viewed',

  // App Download Bottom Sheet
  APP_DOWNLOAD_SHEET_CLOSE: 'app_download_sheet_close',
  APP_DOWNLOAD_SHEET_VIEWED: 'app_download_sheet_viewed',

  // App Download Banner
  APP_DOWNLOAD_BANNER_INSTALL_CLICK: 'install_clicked',

  // Interstitial
  INTERSITIAL_DOWNLOAD: 'interstitial_download',

  // Gift Card
  GIFT_CARD_TYPE_SELECT: 'Type Selected',
  GIFT_CARD_THEME_SELECT: 'Theme Selected',
  GIFT_CARD_DETAILS_SECTION_NEXT_CLICK: 'Card Details Next Clicked',
  GIFT_CARD_AMOUNT_INPUT: 'Amount Input',
  GIFT_CARD_DATE_INPUT: 'Date Input',
  GIFT_CARD_FORM_DETAILS_CONFIRM_CLICK: 'Form Details Confirm Clicked',
  GIFT_CARD_PROCEED_TO_PAYMENT_CLICK: 'Proceed to Payment Clicked',
  GIFT_CARD_CHECKOUT_SUCCESS: 'Checkout Success',
  GIFT_CARD_CHECKOUT_FAILURE: 'Checkout Failure',

  // Product
  SELECTED_VARIANT: 'selected variant',
  VARIANT_SELECTED: 'Selection',
  ADDED_PRODUCT: 'added product',
  REMOVED_PRODUCT: 'removed product',
  CHANGED_PINCODE: 'changed pincode',

  // Store Details
  STORE_DETAILS_PRE_BOOK_VIEWED: 'Pre Book Consultation viewed',
  VIEW_MAP_CLICK: 'View Map Clicked',
  BOOK_CONSULTATION_CLICK: 'Book Consultation Clicked',

  TWO_IMAGES_BANNER: 'Two Images Clicked',
}
