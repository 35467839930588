export default {
  TYPE: {
    PHYSICAL: 'physical',
    ECARD: 'electronic',
  },

  PRESET_CARD_AMOUNTS: [
    1000,
    5000,
    10000,
  ],

  MIN_CARD_AMOUNT: 1000,
  MAX_CARD_AMOUNT: 200000,

  MAX_MESSAGE_CHARS: 200,

  PHYSICAL_CARD_ETA: '2 weeks',

  // Maximum no. of months in future that a
  // E-Gift card can be scheuled to be delivered
  VALID_FUTUTRE_MONTH_COUNT: 3,
}
