/* eslint-disable max-statements */
import Config from 'settings'
import BestSellers from './bestSellers'
import ClientConfiguration from './clientConfiguration'
import ContentBlocks from './contentBlocks'
import EMIs from './emis'
import Experiments from './experiments'
import Home from './home'
import Interlinks from './interlinks'
import Taxon from './taxon'
import Cities from './cities'
import Auth from './auth'
import GiftCards from './giftcards'
import Listing from './listing'
import Navigation from './navigation'
import Product from './product'
import Search from './search'
import Services from './services'
import Snippets from './snippets'
// import SofaTrial from './sofaTrial'
import SpreeError from './SpreeError'
import Stores from './stores'
import Subscription from './subscription'
import Testimonials from './testimonials'
import Uploads from './uploads'
import OrderHistory from './orderHistory'
import Order from './order'
import Wishlist from './wishlist'
import Variants from './variants'
import Recommendations from './recommendations'
import Offers from './offers'
import Pages from './pages'
import Client from '../client'
import StoryTelling from './storyTelling'
import ScheduleCall from './scheduleCall'
import RazorpayOffer from './razorpayOffers'

export default class Spree {
  constructor(token) {
    const client = new Client(
      token || Config.fallbackToken,
      isServer() ? GlobalKey.serverBaseURL : '',
    )

    this.bestSellers = new BestSellers(client)
    this.clientConfiguration = new ClientConfiguration(client)
    this.contentBlocks = new ContentBlocks(client)
    this.emis = new EMIs(client)
    this.experiments = new Experiments(client)
    this.giftCards = new GiftCards(client)
    this.home = {
      inspirations: new Home.Inspirations(client),
    }
    this.interlinks = new Interlinks(client)
    this.taxon = new Taxon(client)
    this.cities = new Cities(client)
    this.listing = new Listing(client)
    this.navigation = new Navigation(client)
    this.offers = new Offers(client)
    this.pages = new Pages(client)
    this.product = {
      deliverySLA: new Product.DeliverySLA(client),
      details: new Product.Details(client),
      info: new Product.Info(client),
      similar: new Product.Similar(client),
      availableStores: new Product.AvailableStores(client),
    }
    this.search = new Search(client)
    this.services = new Services(client)
    // this.sofaTrial = new SofaTrial(client)
    this.stores = new Stores(client)
    this.subscription = new Subscription(client)
    this.snippets = new Snippets(client)
    this.testimonials = new Testimonials(client)
    this.uploads = new Uploads(client)
    this.auth = new Auth(client)
    this.orderHistory = new OrderHistory(client)
    this.order = new Order(client)
    this.wishlist = new Wishlist(client)
    this.variants = new Variants(client)
    this.recommendations = new Recommendations(client)
    this.storyTelling = new StoryTelling(client)
    this.scheduleCall = new ScheduleCall(client)
    this.razorpayOffer = new RazorpayOffer(client)
  }
}

export { SpreeError }
