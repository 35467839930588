import {
  applyListingFilters,
  applyListingSort,
  applySearchFilters,
} from './stores/filtersStore'
import {
  getConfig as getGiftCardConfig,
  setAmount as setGiftCardAmount,
  setCardType as setGiftCardType,
  setTheme as setGiftCardTheme,
  setDeliveryDate as setGiftCardDeliveryDate,
  setDeliveryMonthAndYear as setGiftCardDeliveryMonthAndYear,
  setFormDetails as setGiftCardFormDetails,
  setFormDirty as setGiftCardFormDirty,
} from './stores/giftCardStore'
import Home from './stores/homeStore'
import { getNavigation, getInterlinks, getTaxonDetails } from './stores/navigationStore'
import PageStore from './stores/pageStore'
import { getCities } from './stores/citiesStore'
import { getBestEMIOptions, getEMIOptions } from './stores/financeOptionsStore'
import Product from './stores/productStore'
import {
  getListing,
  getNextPageListing,
  getSearchResults,
  getNextPageSearchResults,
} from './stores/productsStore'
import { get as getSnippet } from './stores/snippetsStore'
// import { getSofaTrialProducts } from './stores/sofaTrialStore'
import { get as getStoreDetails } from './stores/storeDetailsStore'
import { get as getStoresListing } from './stores/storesListingStore'
import { get as getexperimentVariant } from './stores/experimentStore'
import { getSuggestions } from './stores/suggestionsStore'
import UserStore from './stores/userStore'
import OrderStore from './stores/orderStore'
import { getWishlist } from './stores/wishlistStore'
import Sets from './stores/setsStore'

const actions = {

  // Experiment
  getexperimentVariant,

  // Cities
  getCities,

  // PageData
  ...PageStore.actions,

  // Product
  ...Product.actions,

  // Finance Options
  getBestEMIOptions,
  getEMIOptions,

  // User
  ...UserStore.actions,

  // Filters
  applyListingFilters,
  applyListingSort,
  applySearchFilters,

  // Home
  ...Home.actions,

  // Listing and Search
  getListing,
  getNextPageListing,
  getSearchResults,
  getNextPageSearchResults,

  // Navigation
  getNavigation,
  getInterlinks,
  getTaxonDetails,

  // Search
  getSuggestions,

  // Sofa Trials
  // getSofaTrialProducts,

  // Snippet
  getSnippet,

  // Stores
  getStoreDetails,
  getStoresListing,

  // Order
  ...OrderStore.actions,

  // Gift Cards
  getGiftCardConfig,
  setGiftCardAmount,
  setGiftCardType,
  setGiftCardDeliveryDate,
  setGiftCardDeliveryMonthAndYear,
  setGiftCardFormDetails,
  setGiftCardFormDirty,
  setGiftCardTheme,

  // Wishlist
  getWishlist,

  // Sets
  ...Sets.actions,
}

export default actions
