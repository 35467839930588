import Spree from 'api/spree/index'
import { PromiseState } from 'constants/enums'

/* =============
 -   ACTIONS   -
 ============= */

export function get(options) {
  const spree = new Spree()
  return {
    type: 'GET_STORY_TELLING',
    payload: spree.storyTelling.get(options),
  }
}


/* =============
 -  REDUCERS   -
 ============= */

export function storyTelling(state = {}, action) {
  switch (action.type) {
    case 'GET_STORY_TELLING_PENDING':
      return {
        ...state,
        promiseState: PromiseState.PENDING,
      }

    case 'GET_STORY_TELLING_FULFILLED':
      return {
        ...state,
        ...action.payload.data,
        promiseState: PromiseState.FULFILLED,
      }

    case 'GET_STORY_TELLING_REJECTED':
      return {
        ...state,
        promiseState: PromiseState.REJECTED,
      }

    default:
      return state
  }
}
