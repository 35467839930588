import cloneDeep from 'lodash/cloneDeep'
import { PromiseState } from 'constants/enums'
import classNames from 'classnames'
import React, { Component } from 'react'
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare'
import connect from 'react-redux/lib/components/connect'

import ContentBlock from 'components/ContentBlock'
import infinite from 'components/Infinite'
import {
  SubscriptionType,
  ClientConfiguration,
  ContentBlock as ContentBlockConstant,
} from 'constants/enums'
import Config from 'settings/config'
import Analytics from 'library/analytics'
import Event from 'library/analytics/eventFactory'
import CssConfig from 'stylesheets/settings/config.scss'
import ProductCard from 'components/ProductCard'
import ExposeFilter from 'components/Listing/ExposeFilter'
import SubscriptionModal from 'components/SubscriptionModal'
import InterestedRangeCard from '../InterestedRangeCard'
import './ProductList.scss'
import Spree from 'api/spree'
import arrayIntoChunks from '../../../library/utils/arrayIntoChunks'

class ProductList extends Component {
  static propTypes = {
    appliedFilters: PropTypes.object,
    filteredCount: PropTypes.number,
    children: PropTypes.node,
    isGrid: PropTypes.bool,
    isWidget: PropTypes.bool,
    productCardsData: PropTypes.arrayOf(PropTypes.object).isRequired,
    srcParam: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ]).isRequired,
    wishlist: PropTypes.object,
    availableFilters: PropTypes.object,
    showWishlistButton: PropTypes.bool,
    showInterestedRangeCard: PropTypes.bool,
    taxon: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      permalink: PropTypes.string,
    }),
    onWishlistClick: PropTypes.func,
    onFilterPanelOpen: PropTypes.func,
    breadcrumbInfo: PropTypes.arrayOf(PropTypes.object),
    pageTitle: PropTypes.string,
    onChange: PropTypes.func,
    onApply: PropTypes.func,
    perPage: PropTypes.number,
    isListing: PropTypes.bool,
    current_page: PropTypes.number,
  }

  state = {
    isSubscriptionModalOpen: false,
    activeProductSKU: null,
    cardsPerRow: 2,
    open_filters: Config.productCategory,

    currentCount: this.props.filteredCount,
    currentFilters: cloneDeep(this.props.appliedFilters) || {},
    testResultState: PromiseState.FULFILLED,

    chunkedProductsData: []
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      currentCount: nextProps.filteredCount,
      currentFilters: cloneDeep(nextProps.appliedFilters) || {},
    })
  }

  componentDidMount() {
    this.setCardsPerRow()
    window.addEventListener('resize', this.setCardsPerRow)
    const spree = new Spree()
    spree.clientConfiguration
      .get(ClientConfiguration.OPEN_FILTERS)
      .then((response) => {
        this.setState({ open_filters : response.data.birbul_open_filters ? JSON.parse(response.data.birbul_open_filters) : Config.productCategory})
      })  

      const { productCardsData, perPage, isListing } = this.props
      let newProductsCardsData = []
      if(isListing) {
        newProductsCardsData = arrayIntoChunks(productCardsData, perPage) 
      }

      this.setState({ chunkedProductsData: newProductsCardsData })
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isGrid !== this.props.isGrid) {
      this.setCardsPerRow()
    }

    if(prevProps.productCardsData != this.props.productCardsData) {
    const { productCardsData, perPage, isListing } = this.props
      let newProductsCardsData = []
      if(isListing) {
        newProductsCardsData = arrayIntoChunks(productCardsData, perPage) 
      }
      this.setState({ chunkedProductsData: newProductsCardsData })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setCardsPerRow)
  }

  setCardsPerRow = () => {
    const { isGrid } = this.props
    const lgScrMinWidth = parseInt(CssConfig.lgScrMinWidth)

    let cardsPerRow = 1
    if (isGrid) {
      cardsPerRow = window.innerWidth > lgScrMinWidth ? 3 : 2
    }

    this.setState({ cardsPerRow })
  }
  
  handleFilterButtonClick =(activeFilter) => {
    this.props.onFilterPanelOpen(activeFilter)
  }

  contentBlockDOM = (isRowEnding, numOfRows) => {
    const rowsInterval = Config.listingPageContentBlockRows

    return isRowEnding && (numOfRows % rowsInterval === 0) ?
      (<ContentBlock
        platform={ ContentBlockConstant.platform.MOBILE_WEB }
        position={ `SECTION_${numOfRows / rowsInterval}` }
      >
      </ContentBlock>) : null
  }

  corouselDOM = (isRowEnding, numOfRows, prodcutTypes) => {
    const { taxon } = this.props
    return (isRowEnding === false && numOfRows === 0 ) ?
      (<section className="product-category__section">
        <div className="product-category__content">
          {
            prodcutTypes.map(category => (
              <div key={ category.link } className="product-category__type">
                <a href={ category.link.includes("?")? category.link +"&src=open_filter":category.link +"?src=open_filter" }>
                  { category.name }
                </a>
              </div>
            ))
          }
        </div>
       </section>) : null
  }

  handleOOSNotifyClick = ({ sku }) => {
    this.setState({
      isSubscriptionModalOpen: true,
      activeProductSKU: sku,
    })
  }

  handleOOSModalClose = () => {
    this.setState({
      isSubscriptionModalOpen: false,
    })
  }

  handleProductClickedEvent = (cardData, index) => {
    const { breadcrumbInfo } = this.props
    Analytics.productClicked(cardData, index, breadcrumbInfo)
  }

  handleApply = () => {
    this.props.onApply(this.state.currentFilters)
  };

  //to handle clear of property filter
  handleClearPropertyFilter = (name) => {
    const currentFilters = cloneDeep(this.state.currentFilters) || {}
    currentFilters[name]=[]
    this.handleChange(currentFilters)
  };

  //to handle clear of range filter
  handleClearRangeFilter = (name) => {
    const currentFilters = cloneDeep(this.state.currentFilters) || {}
    delete currentFilters[name]
    this.handleChange(currentFilters)
  };

  handleChange = (newFilters) => {
    this.setState({
      currentFilters: newFilters,
    })
    this.testFilters(newFilters)
  };

  testFilters = ((newFilters) => {
    this.setState({ testResultState: PromiseState.PENDING })
    this.props.onChange(newFilters).then((response) => {
      if ('total_count' in response) {
        this.setState({
          currentCount: response.total_count,
          testResultState: PromiseState.FULFILLED,
        })
      }
    }).then(setTimeout(() => {
      this.handleApply()
    }, 500))
  });

  static contextTypes = {
    pageType: PropTypes.string.isRequired,
    widgetName: PropTypes.string,
  };

  /* eslint-disable max-statements */
  render() {
    const {
      isGrid,
      isWidget,
      productCardsData,
      srcParam,
      onWishlistClick,
      wishlist,
      showWishlistButton,
      showInterestedRangeCard,
      taxon,
      pageTitle,
      isListing
    } = this.props
    const {
      activeProductSKU,
      isSubscriptionModalOpen,
      cardsPerRow,
      chunkedProductsData
    } = this.state

    let cardClasses = ''
    if (isGrid) {
      cardClasses = 'col__lg--1-3 col__md--1-2 col__sm--1-2'
    } else if (isWidget && productCardsData.length === 2) {
      // cardClasses = 'col__lg--6 col__md--4 col__sm--5'
      cardClasses = 'col__lg--1-2 col__md--1-2 col__sm--1-2'
    }

    const productCardWrapClass = classNames(
      'product-card__wrap',
      { 'product-card__wrap--widget': isWidget },
    )

    /* eslint-disable no-param-reassign */
    if(isListing) {
    chunkedProductsData.map(productsData => {
      productsData.forEach((cardData) => {
        cardData.isInWishlist = wishlist.data.variants.some(variant => 
          variant.id === cardData.id)
      })
    })
  } else {
    productCardsData.forEach((cardData) => {
      cardData.isInWishlist = wishlist.data.variants.some(variant => 
        variant.id === cardData.id)
    })
  }

    let numOfRows = 0

    let corousel = null
    let filterlDOM = null
    let prodcutTypes = null
    if (taxon) {
      this.state.open_filters.forEach((product) => {
        if (product.name === taxon.permalink) {
          prodcutTypes = product.category
        }
      })
    }

    

    return (
      <div className={ productCardWrapClass }>
        {isListing ? chunkedProductsData.map((productsData, index) => {
          return <div key={index} className='product-lists' id={`product_list_${index+1}`}>
            { productsData.map((cardData, cardIndex) => {
              const isRowEnding = cardIndex + 1 === (numOfRows + 1) * cardsPerRow
              if (isRowEnding) {
                numOfRows += 1
              }
              const productCard = (
                <ProductCard
                  isWidget={ isWidget }
                  key={ cardData.id }
                  className={ cardClasses }
                  cardData={ cardData }
                  srcParam={ typeof srcParam === 'function' ? srcParam(cardData) : srcParam }
                  showWishlistButton={ showWishlistButton }
                  onWishlistClick={ onWishlistClick }
                  isInWishlist={ cardData.isInWishlist }
                  onOOSNotifyClick={ this.handleOOSNotifyClick }
                  taxon={ taxon }
                  pageTitle= { pageTitle }
                  cardIndex={ cardIndex }
                  onProductClickedEvent={ () => {
                    this.handleProductClickedEvent(cardData, cardIndex)
                  }}
                />
              )
              const contentBlock = this.contentBlockDOM(isRowEnding, numOfRows)
            if (prodcutTypes) {
              corousel = this.corouselDOM(isRowEnding, numOfRows, prodcutTypes)
            }
              if(this.props.availableFilters){
                filterlDOM =  <ExposeFilter 
                  isRowEnding = { isRowEnding }
                  numOfRows = { numOfRows }
                  appliedFilters = {this.props.appliedFilters}
                  availableFilters = {this.props.availableFilters}
                  handleFilterButtonClick = { this.handleFilterButtonClick }
                  handleClearRangeFilter = {this.handleClearRangeFilter}
                  handleClearPropertyFilter = { this.handleClearPropertyFilter }
                />
              }

              return [corousel, productCard, contentBlock, filterlDOM]
            })
            }
          </div>
        }) : <>
        {
          productCardsData.map((cardData, cardIndex) => {
            const isRowEnding = cardIndex + 1 === (numOfRows + 1) * cardsPerRow
            if (isRowEnding) {
              numOfRows += 1
            }

            const productCard = (
              <ProductCard
                isWidget={ isWidget }
                key={ cardData.id }
                className={ cardClasses }
                cardData={ cardData }
                srcParam={ typeof srcParam === 'function' ? srcParam(cardData) : srcParam }
                showWishlistButton={ showWishlistButton }
                onWishlistClick={ onWishlistClick }
                isInWishlist={ cardData.isInWishlist }
                onOOSNotifyClick={ this.handleOOSNotifyClick }
                taxon={ taxon }
                pageTitle= { pageTitle }
                cardIndex={ cardIndex }
                onProductClickedEvent={ () => {
                  this.handleProductClickedEvent(cardData, cardIndex)
                }}
              />
            )
            const contentBlock = this.contentBlockDOM(isRowEnding, numOfRows)
            if (prodcutTypes) {
              corousel = this.corouselDOM(isRowEnding, numOfRows, prodcutTypes)
            }
            if(this.props.availableFilters){
              filterlDOM =  <ExposeFilter 
                isRowEnding = { isRowEnding }
                numOfRows = { numOfRows }
                appliedFilters = {this.props.appliedFilters}
                availableFilters = {this.props.availableFilters}
                handleFilterButtonClick = { this.handleFilterButtonClick }
                handleClearRangeFilter = {this.handleClearRangeFilter}
                handleClearPropertyFilter = { this.handleClearPropertyFilter }
              />
            }
            return [corousel, productCard, contentBlock, filterlDOM]
          })
        }
        </>}
        {
          (productCardsData.length > 0 && showInterestedRangeCard) &&
          <InterestedRangeCard
            className={ cardClasses }
            taxon={ taxon }
          />
        }
        {
          <SubscriptionModal
            isOpen={ isSubscriptionModalOpen }
            onClose={ this.handleOOSModalClose }
            productSKU={ activeProductSKU }
            subscriptionType={ SubscriptionType.PRODUCT }
          />
        }
      </div>
    )
  }
}

const connectedProductList = connect(state => ({
  wishlist: state.wishlist,
}))(ProductList)

export default infinite(connectedProductList)

export { connectedProductList as SimpleProductList }
