/* eslint-disable sort-class-members/sort-class-members */
import Endpoints from 'settings/endpoints'
import BaseAPI from '../../BaseAPI'
import SpreeError from '../SpreeError'

/**
 * This API is used for placing orders for
 * Sofa Trial, consultations and other services.
 */
export default class Services extends BaseAPI {

  /** Submits a service order
   * @param {Object} params  { { address, sub_source,
   * service_sku, sku_string, pincode, city, firstname,
   * phoneno, payment_method, email_id } }
   * @returns {Promise}
   */
  post(details) {
    super._schemaCheck(details,
      [
        'sub_source', 'service_sku', 'sku_string',
        'pincode', 'city', 'firstname', 'phoneno', 'address',
        'payment_method', 'email_id',
      ],
    )

    return this.client.post(Endpoints.services, details)
  }

  subscribe(params) {
    super._schemaCheck(params, [
      'sub_source', 'email',
    ])

    return this.client.post(Endpoints.serviceSubscription, params)
  }

  getServiceability(pincode) {
    super._checkType([pincode], ['number'])

    const isEmptyObject = obj =>
    Object.keys(obj).length === 0 && obj.constructor === Object

    return this.client
               .get(`${Endpoints.zipcodeServiceability}/${pincode}.json`)
               // API returns empty object for invalid pincodes.
               // Normalizing behaviour to instead throw error
               .then((serviceabilityInfo) => {
                 if (isEmptyObject(serviceabilityInfo)) {
                   return Promise.reject(new SpreeError({
                     message: 'Invalid pincode',
                     status: 422,
                   }))
                 }

                 return Promise.resolve(serviceabilityInfo)
               })
  }
}
