import React, { useEffect, useRef } from 'react'

import Links from 'settings/links'
import AssetLinks from 'settings/assetLinks'
import Href from 'units/Href'
import Img from 'units/Img'
import { AspectRatio } from 'constants/enums'

import PlayStoreBadge from 'imgs/play-store-badge.svg'
import AppStoreBadge from 'imgs/app-store-badge.svg'

import './AppDownloadBanner.scss'
import Analytics from 'library/analytics'
import Event from 'library/analytics/eventFactory'

const AppDownloadBanner = () => {
  const targetRef = useRef(null);
  useEffect(() => {
    let observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          Analytics.genericEvent({
            category: Event.category.APP_DOWNLOAD,
            action: Event.action.VIEWED,
          })
        }
      });
    }, { threshold: 1 })
  
    if (targetRef?.current) {
      observer?.observe(targetRef?.current);
    }
  
    return () => {
      if (targetRef?.current) {
        observer?.unobserve(targetRef?.current);
      }
    }
  }, [])
  return (<div className="footer-app-download-banner">
    <div className="footer-app-download-banner__text">Download the app today</div>
    <Img
      alt="App download banner"
      src={ AssetLinks.FOOTER_APP_DOWNLOAD_BANNER_IMAGE }
      aspectRatio={ AspectRatio.TWO_ONE }
      imgData={ AssetLinks }
    />
    <div className="footer-app-download-banner__content">
      <div className="footer-app-download-banner__subtitle">Browse. Order. Track</div>
      <div className="footer-app-download-banner__title">Now on the go!</div>
      <Href to={ Links.APP_STORE_DOWNLOAD } isExternal onClick={(e) => {
        Analytics.genericEvent({
          category: Event.category.APP_DOWNLOAD,
          action: Event.category.CLICKED,
          label: 'App Store'
        })
      }}>
        <img
          alt="App store button"
          className="footer-app-download-banner__button"
          src={ AppStoreBadge }
        />
      </Href>
      <Href to={ Links.PLAY_STORE_DOWNLOAD } isExternal onClick={(e) => {
        Analytics.genericEvent({
          category: Event.category.APP_DOWNLOAD,
          action: Event.category.CLICKED,
          label: 'Play Store'
        })
      }}>
        <img
          alt="Play Store Button"
          className="footer-app-download-banner__button"
          src={ PlayStoreBadge }
        />
      </Href>
    </div>
  </div>
)
}

export default AppDownloadBanner
