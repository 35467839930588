import promises from 'es6-promise'
import raf from 'raf'

const requestAnimationFrame = {
  polyfill: raf.polyfill,
}

const smoothScroll = {
  polyfill: () => {
    if (!isServer()) {
      require('smoothscroll-polyfill')
    }
  },
}

/* Polyfills for ES2015 + built in methods */
const esMethods = {
  polyfill: () => {
    require('core-js/modules/es6.string.includes')
    require('core-js/modules/es7.array.includes')
    require('core-js/modules/es6.array.filter')
    require('core-js/modules/es6.array.find-index')
    require('core-js/modules/es6.array.find')
    // @see http://babeljs.io/docs/usage/caveats/#polyfills
    require('core-js/modules/es6.array.from')
    require('core-js/modules/es6.string.starts-with')
    require('core-js/modules/es6.string.ends-with')
  },
}

const Promise = {
  polyfill: promises.polyfill,
}

const CustomEvent = {
  polyfill: () => {
    if (!isServer()) {
      require('custom-event-polyfill')
    }
  },
}

const Polyfills = {
  requestAnimationFrame,
  smoothScroll,
  esMethods,
  Promise,
  CustomEvent,
}

export default Polyfills
