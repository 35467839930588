import { combineReducers } from 'redux'

import {
  get as getProductDetails,
  setVariantIndex,
  getSofaConfigurationDetails,
  details,
  getFaq,
} from './detailsStore'
import { get as getDeliverySLA, setDeliverySLA, deliverySLAData } from './deliverySLAStore'
import { get as getProductInfo, info } from './infoStore'
import {
  get as getSimilarProducts,
  getNextPage as getNextPageSimilarProducts,
  similar,
} from './similarProductsStore'
// import {
//   get as getSofaTrialBanner, sofaTrialBanner
// } from './sofaTrialBanner'
import { get as getOffers, offers } from '../offersStore'
import {
  get as getAvailableStores,
  availableStores,
} from './availableStoresStore'
import { get as getStoryTellingDetails, storyTelling } from './storyTellingStore'

/* =============
 -   ACTIONS   -
 ============= */

const actions = {
  getProductDetails,
  getSimilarProducts,
  getNextPageSimilarProducts,
  getSofaConfigurationDetails,
  // getSofaTrialBanner,
  getDeliverySLA,
  setDeliverySLA,
  getProductInfo,
  setVariantIndex,
  getOffers,
  getAvailableStores,
  getStoryTellingDetails,
  getFaq,
}

/* =============
 -  REDUCERS   -
 ============= */

const reducers = combineReducers({
  details,
  deliverySLAData,
  info,
  // sofaTrialBanner,
  offers,
  similar,
  availableStores,
  storyTelling,
})

export default {
  actions,
  reducers,
}
