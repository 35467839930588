import React from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames'
import './JumpingDots.scss'

const JumpingDots = ({ theme }) => (
  <div className={ classNames('jumping-dots', theme) }>
    <span />
    <span />
    <span />
  </div>
)

JumpingDots.theme = {
  LIGHT: 'jumping-dots--light',
  DARK: 'jumping-dots--dark',
}

JumpingDots.propTypes = {
  theme: PropTypes.oneOf(Object.values(JumpingDots.theme)),
}

JumpingDots.defaultProps = {
  theme: JumpingDots.theme.DARK,
}

export default JumpingDots
