import Endpoints from 'settings/endpoints'
import BaseAPI from '../../BaseAPI'

export default class EMIs extends BaseAPI {

  /**
   * Gets list of emi options available
   * for a price point and location
   * @param { { amount, pincode } }
   */

  getOptions(options) {
    return this.client.get(Endpoints.EMIs, options)
  }

  /**
   * Gets the best and best zero cost EMIs
   * @param { { amount, pincode } }
   */

  getBestOptions(options) {
    return this.client.get(Endpoints.bestEMIs, options)
  }
}
