import React from 'react'

import Button from 'units/Button'

import facebookIcon from 'imgs/logos/facebook-letter-logo.svg'
import googlePlusIcon from 'imgs/logos/google-letter-logo.svg'

import './SocialLoginButton.scss'

function socialLoginButtonFactory(provider, icon) {
// eslint-disable-next-line react/prop-types
  return ({ hasProviderLoaded, ...props }) => (
    <Button
      className="social-login__button"
      fill={ Button.fill.NONE }
      type={ Button.type.SECONDARY_MUTED }
      disabled={ !hasProviderLoaded }
      { ...props }
    >
      <span className="social-login__icon">
        <img src={ icon } alt="" />
      </span>
      <span className="social-login__provider">
        { provider }
      </span>
    </Button>
  )
}

export default {
  Google: socialLoginButtonFactory('Google', googlePlusIcon),
  Facebook: socialLoginButtonFactory('Facebook', facebookIcon),
}
