import { combineReducers } from 'redux'

import { login, loginWithOAuth, logout, signup, setAuthInformation, auth } from './authStore'

import { saveContextInformation, context } from './contextStore'


/* =============
 -   ACTIONS   -
 ============= */

const actions = {
  login,
  loginWithOAuth,
  logout,
  signup,
  setAuthInformation,
  saveContextInformation,
}

/* =============
 -  REDUCERS   -
 ============= */

const reducers = combineReducers({ auth, context })

export default {
  actions,
  reducers,
}
