import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/components/connect'

import BottomSheet from 'units/BottomSheet'
import Button from 'units/Button'
import TextInput from 'units/TextInput'
import SocialLoginButton from 'units/SocialLoginButton'
import ProgressButton from 'units/ProgressButton'

import Spree from 'api/spree'
import SpreeError from 'api/spree/SpreeError'

import {
  ClientConfiguration,
  PromiseState,
  LoginState,
  Upload,
  AuthenticationProvider,
} from 'constants/enums'
import Analytics from 'library/analytics'
import Event from 'library/analytics/eventFactory'
import OAuth from 'library/oauth'
import UserPreferences from 'library/storage/UserPreferences'
import { serializeForm } from 'library/utils'
import Config from 'settings/config'
import Action from 'store/actions'

import './EmailSignupBottomSheet.scss'

class EmailSignupBottomSheet extends PureComponent {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  }

  static contextTypes = {
    pageType: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props)
    this.oauth = new OAuth({
      googleClientId: GlobalKey.googleClientId,
      facebookAppId: GlobalKey.facebookAppId,
    })

    this.timeout = null
    this.hasSeenSheet = false
  }

  state = {
    content: {},
    shouldShow: false,
    showValidations: false,
    isSignupComplete: false,
  }

  componentDidMount() {
    window.addEventListener('load', this.init)
    document
      .addEventListener('visibilitychange', this.handleVisibilityChange)
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.init)
    clearTimeout(this.timerId)
    window.cancelIdleCallback(this.requestIdleCallbackId)
  }

  getBottomSheetData = () => {
    const spree = new Spree()
    const configPromise = spree
      .clientConfiguration
      .get(ClientConfiguration.EMAIL_SIGNUP_SHEET_TIMEOUT)

    const uploadsPromise = spree.uploads.get(Upload.EMAIL_SIGNUP_SHEET)

    const providerPromise = this.oauth
      .loadProviders()
      .then(this.oauth.initProviders)

    return Promise.all([configPromise, uploadsPromise, providerPromise])
  }


  startTimer = (time) => {
    this.timerId = setTimeout(() => {
      this.requestIdleCallbackId = requestIdleCallback(() => {
        let shouldShow = !window.hideBottomLogin;
        this.setState({ shouldShow: shouldShow }, () => {
          this.hasSeenSheet = true
        })
      }, { timeout: 2000 })
    }, time)
  }

  init = () => {
    this.getBottomSheetData()
      .then(([configResult, uploadResult]) => {
        this.setState({ content: uploadResult.uploads[0] })
        // time after which sheet should slide up in seconds
        this.timeout = parseInt(configResult.data[
          ClientConfiguration.EMAIL_SIGNUP_SHEET_TIMEOUT])

        this.startTimer(this.timeout * 1000)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  handleFormSubmit = (e) => {
    e.preventDefault()

    if (!this.emailSignupForm.checkValidity()) {
      return
    }

    const dataToSend = {
      email: serializeForm(this.emailSignupForm).email,
      subSource: Config.subSource.emailBottomSheet,
    }

    const spree = new Spree()
    this.setState({ promiseState: PromiseState.PENDING })

    spree
      .subscription
      .post(dataToSend)
      .then(() => {
        this.setState({ promiseState: PromiseState.FULFILLED })

        Analytics.genericEvent({
          category: Event.category.EMAIL_SIGNUP_SHEET,
          action: Event.action.EMAIL_SUBSCRIBE,
        })

        UserPreferences
          .setPreference(UserPreferences.preference.EMAIL, dataToSend.email)

        this.handleSuccessfulSignup()
      })
      .catch((err) => {
        this.setState({ promiseState: PromiseState.REJECTED })
        alert('Subscription unsuccessful.')
        console.error(err)
      })
  }

  handleSubmitButtonClick = () => {
    this.setState({ showValidations: true })
  }

  handleVisibilityChange = () => {
    clearTimeout(this.timerId)

    // When user's coming back to the site,
    // restart the timer that may have been
    // killed by the browser
    if (!this.hasSeenSheet && !document.hidden && this.timeout) {
      this.startTimer(this.timeout * 1000)
    }
  }

  handleBottomSheetClose = () => {
    this.setState({ shouldShow: false })
  }

  handleFacebookOAuth = () => {
    const { dispatch } = this.props
    const provider = AuthenticationProvider.FACEBOOK
    let userData = null

    Analytics.genericEvent({
      category: Event.category.EMAIL_SIGNUP_SHEET,
      action: Event.action.LOGIN_WITH_FACEBOOK_CLICK,
    })

    this.oauth
      .facebook
      .login()
      .then((userInfo) => {
        userData = userInfo
        dispatch(Action.loginWithOAuth(userInfo))
      })
      .then(() => this.handleSuccessfulLogin(userData, provider))
      .catch((err) => {
        alert(err instanceof SpreeError ? err.message : err)
        const failureReason =
          err instanceof SpreeError ? err.message : err.status
        this.handleLoginFailure(userData, provider, failureReason)
      })
  };

  handleGoogleOAuth = () => {
    const { dispatch } = this.props
    const provider = AuthenticationProvider.GOOGLE
    let userData = null

    Analytics.genericEvent({
      category: Event.category.EMAIL_SIGNUP_SHEET,
      action: Event.action.LOGIN_WITH_GOOGLE_CLICK,
    })

    this.oauth
      .google
      .login()
      .then((userInfo) => {
        userData = userInfo
        dispatch(Action.loginWithOAuth(userInfo))
      })
      .then(() => this.handleSuccessfulLogin(userData, provider))
      .catch((err) => {
        alert(err instanceof SpreeError ? err.message : err)
        const failureReason =
          err instanceof SpreeError ? err.message : err.error
        this.handleLoginFailure(userData, provider, failureReason)
      })
  };

  handleSuccessfulLogin = (userInfo, provider) => {
    const { auth } = this.props

    const userData = {
      name: auth.name ? auth.name : userInfo.name,
      email: auth.email ? auth.email : userInfo.email,
      provider,
    }

    Analytics.loggedIn(userData)
    Analytics.identify(LoginState.LOGGED_IN, auth)
    Analytics.genericEvent({
      category: Event.category.ALL,
      action: Event.action.LOGIN_SUCCESS,
      nonInteraction: true,
    })

    this.handleSuccessfulSignup()
  };

  handleLoginFailure = (userInfo, provider, failureReason) => {
    const userData = {
      name: userInfo ? userInfo.name : '',
      email: userInfo ? userInfo.email : '',
      provider,
      failureReason,
    }

    Analytics.loginFailed(userData)
  }

  handleSuccessfulSignup = () => {
    this.setState({ isSignupComplete: true })

    setTimeout(() => {
      this.setState({ shouldShow: false })
    }, 2000)
  }


  handleTransitionEnd = (isOpen) => {
    if (isOpen) {
      const preference = UserPreferences.preference.HAS_SEEN_EMAIL_SIGNUP_SHEET

      Analytics.genericEvent({
        category: this.context.pageType,
        action: Event.action.EMAIL_SIGNUP_SHEET_VIEWED,
        nonInteraction: true,
      })

      UserPreferences.setTemporaryPreference(preference, true)

      this.hasSeenSheet = true
    } else {
      Analytics.genericEvent({
        category: this.context.pageType,
        action: Event.action.EMAIL_SIGNUP_SHEET_CLOSE,
      })
    }
  }


  render() {
    const {
      isSignupComplete,
      showValidations,
      shouldShow,
      content,
      promiseState,
    } = this.state

    return (
      <BottomSheet
        isOpen={ shouldShow }
        showCloseButton={ false }
        onClose={ this.handleBottomSheetClose }
        onTransitionEnd={ this.handleTransitionEnd }
      >
        {
          isSignupComplete ? (
            <div>
              <div className="email-signup-bottom-sheet__content">
                <img
                  src={ content.url }
                  className="email-signup-bottom-sheet__img"
                  alt="newsletters"
                />
              </div>
              <div className="email-signup-bottom-sheet__sucess-msg">
                <h3>Done</h3>
                <p>
                  Check your inbox regularly
                  for dozes of awesomeness!
                </p>
              </div>
            </div>
          ) : (
            <div className="email-signup-bottom-sheet__content">
              <img
                src={ content.url }
                className="email-signup-bottom-sheet__img"
                alt="newsletters"
              />
              <div
                className="email-signup-bottom-sheet__text"
                dangerouslySetInnerHTML={ { __html: content.title } }
              />
              <div className="email-signup-bottom-sheet__social-login">
                <SocialLoginButton.Google
                  onClick={ this.handleGoogleOAuth }
                  hasProviderLoaded
                  hasFullWidth
                />
                <SocialLoginButton.Facebook
                  onClick={ this.handleFacebookOAuth }
                  hasProviderLoaded
                  hasFullWidth
                />
              </div>
              <form
                onSubmit={ this.handleFormSubmit }
                ref={ f => (this.emailSignupForm = f) }
                noValidate
              >
                <TextInput
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder="Or enter your email"
                  showValidation={ showValidations }
                  validateType={ TextInput.validation.EMAIL }
                  required
                />
                <ProgressButton
                  className="email-signup-bottom-sheet__submit-btn"
                  onClick={ this.handleSubmitButtonClick }
                  hasFullWidth
                  isSubmit
                  size={ Button.size.LARGE }
                  progressText="Submitting"
                  showProgress={ promiseState === PromiseState.PENDING }
                  defaultText="Submit"
                />
              </form>

              <Button
                className="email-signup-bottom-sheet__skip-btn"
                hasFullWidth
                type={ Button.type.TRANSPARENT }
                onClick={ this.handleBottomSheetClose }
              >
                Skip Signup
              </Button>
            </div>
          )
        }
      </BottomSheet>
    )
  }
}

const connectedSheet = connect(state => ({
  auth: state.user.auth,
}))(EmailSignupBottomSheet)

export default connectedSheet
