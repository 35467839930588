/* eslint-disable sort-class-members/sort-class-members */
import Endpoints from 'settings/endpoints'
import BaseAPI from '../../BaseAPI'

export default class Snippets extends BaseAPI {

  /**
   * @param {number} snippetId
   * @returns {Promise}
   */
  get(snippetId) {
    super._checkType([snippetId], ['number'])
    return this.client.get(`${Endpoints.snippets}/${snippetId}`)
  }

  /**
   * @param {[number]} snippetIds
   * @returns {Promise}
   */
  // NOTE: Does not work currently due to the difference in how
  // the backend expects arrays to be passed in URLs
  getAll(snippetIds) {
    super._checkType(snippetIds, snippetIds.map(() => 'number'))
    return this.client.get(Endpoints.snippets, { ids: snippetIds })
  }
}
