import classNames from 'classnames'
import debounce from 'lodash/debounce'
import React from 'react'
import PropTypes from 'prop-types';
import TransitionGroup from 'react-addons-css-transition-group'
import shallowCompare from 'react-addons-shallow-compare'
import connect from 'react-redux/lib/components/connect'
import Nav from 'components/Nav'
import FixedToolbar from 'units/FixedToolbar'
import HamburgerIcon from 'units/HamburgerIcon'
import Href from 'units/Href'
import IconButton from 'units/IconButton'
import CancelIcon from 'units/icons/CancelIcon'
import HelpIcon from 'units/icons/HelpIcon'
import SearchIcon from 'units/icons/SearchIcon'
import ShoppingCartIcon from 'units/icons/ShoppingCartIcon'
import WishlistIcon from 'units/icons/WishlistIcon'
import JumpingDots from 'units/JumpingDots'
import LoginBottomSheet from 'components/LoginBottomSheet'

import { Transition, Debounce } from 'settings/values'
import { Status, Position, CustomEvents } from 'constants/enums'
import Analytics from 'library/analytics'
import Event from 'library/analytics/eventFactory'
import Actions from 'store/actions'

import handlesBackNavigation from 'components/HandleBackNavigation'
import Logo from 'imgs/logos/logo-tiny.svg'

import AutoSuggestionList from './AutoSuggestionList'
import ProfileActions from './ProfileActions'

import './Header.scss'

class Header extends React.Component {
  static propTypes = {
    currentRoute: PropTypes.string.isRequired,
    searchQuery: PropTypes.string,
    dispatch: PropTypes.func,
    searchSuggestions: PropTypes.object.isRequired,
    isSearchCollapsed: PropTypes.bool.isRequired,
    isExternalSearchOpen: PropTypes.bool.isRequired,
    onSearchClose: PropTypes.func.isRequired,
    pushHistoryState: PropTypes.func,
    popHistoryState: PropTypes.func,
    replaceHistoryState: PropTypes.func,
    searchResults: PropTypes.object,
  };

  state = {
    navOpen: false,
    searchMode: false,
    typedQuery: this.props.searchQuery || '',
    suggestions: [],
    showLoginBottomSheet: false,
  };

  componentDidMount() {
    window.addEventListener(CustomEvents.OPEN_SIDEBAR, this.handleOpenNav)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Toggle search mode whenever mock search bar is clicked
    if (!this.props.isExternalSearchOpen && nextProps.isExternalSearchOpen) {
      setTimeout(() => {
        this.setState({ searchMode: true })
        this.props.pushHistoryState('search', this.handleSetSearchOffClick)
      }, 100)
    }

    if (this.props.searchQuery !== nextProps.searchQuery) {
      this.setState({ typedQuery: nextProps.searchQuery || '' })
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState)
  }

  componentWillUnmount() {
    window.removeEventListener(CustomEvents.OPEN_SIDEBAR, this.handleOpenNav)
  }

  fetchSuggestions = debounce(() => {
    const { dispatch } = this.props
    const { typedQuery } = this.state
    dispatch(Actions.getSuggestions(typedQuery))
  }, Debounce.input.WAIT_TIME, {
    maxWait: Debounce.input.MAX_WAIT_TIME,
    leading: false,
    trailing: true,
  });

  handleSetSearchOffClick = () => {
    this.setState({ searchMode: false })
    this.props.onSearchClose()
    let eventObj = {
      event_name: Event.eventName.ITEM_SEARCHED,
      category: Event.category.SEARCH,
      action: Event.action.INPUT_ENTERED,
      keywords: this.state?.typedQuery || '',
      current_route: this.props?.currentRoute || '',
      result_count: this.props.searchResults?.total_count || null,
    }
    Analytics.genericEvent(eventObj)
  };

  handleSearchButtonClick = () => {
    this.setState({ searchMode: true })
    this.props.pushHistoryState('search', this.handleSetSearchOffClick)
  };

  handleInputKeyPress = (event) => {
    const { value } = event.target
    const shouldFetchSuggestions = value && value.length > 1

    this.setState({ typedQuery: value })

    if (shouldFetchSuggestions) {
      this.fetchSuggestions()
    }
  };

  handleClearClick = () => {
    this.setState({
      typedQuery: '',
      suggestions: [],
    }, () => {
      this.searchInput.focus()
    })
  };

  handleOpenNav = () => {
    this.setState({ navOpen: true })
  };

  handleCloseNav = () => {
    this.setState({ navOpen: false })
  };

  handleSearchSubmit = (event) => {
    event.preventDefault()

    const { typedQuery } = this.state
    const trimmedQuery = typedQuery.trim()

    if (trimmedQuery) {
      this.props.replaceHistoryState({
        pathname: '/products/search',
        query: { keywords: trimmedQuery.replace(' ', '+') },
      })
    }

    this.handleSetSearchOffClick()
  };

  handleMenuClick = () => {
    this.setState({
      navOpen: !this.state.navOpen,
    })

    Analytics.genericEvent({
      category: Event.category.OPEN_SIDEBAR,
      action: Event.action.CLICKED,
      label: Event.label.HAMBURGER_MENU,
    })
  };

  handleInputFocus = () => {
    this.setState({
      ...this.state,
    }, () => {
      this.searchInput.value = this.state.typedQuery
    })
  };

  handleLoginSheetClose = () => {
    this.setState({ showLoginBottomSheet: false })
  }

  handleLoginSuccess = () => {
    this.setState({
      showLoginBottomSheet: false,
    })
  }

  render() {
    const {
      searchSuggestions,
      isSearchCollapsed,
      popHistoryState,
      currentRoute,
    } = this.props
    const {
      navOpen, searchMode, typedQuery, showLoginBottomSheet,
    } = this.state

    const recordClickEvents = (e, category) => {
      Analytics.genericEvent({
        event_name: Event.eventName.HEADER_ICON_CLICKED,
        icon_name: category,
        action: Event.action.CLICKED,
      })
    }

    const headerContentDefault = (
      <div className="header__content">
        <Href to="/" className="logo">
          <img src={ Logo } alt="logo" />
        </Href>
        <div
          className={ classNames(
            'header__right',
            isSearchCollapsed ? 'header__right--search-show' : 'header__right--search-hide',
          ) }
        >
          { /* Using <a> here, so that no new tab is opened */ }
          <a href="/help" onClick={(e) => recordClickEvents(e, Event.category.HELP_ICON)}>
            <IconButton>
              <HelpIcon />
            </IconButton>
          </a>

          <Href
            to="/wishlist"
            onClick={(e) => recordClickEvents(e, Event.category.WISHLIST_ICON)}
          >
            <IconButton>
              <WishlistIcon />
            </IconButton>
          </Href>

          <ProfileActions currentRoute={ currentRoute } recordClickEvents={ recordClickEvents } />          

          <Href to="/cart" isProxied onClick={(e) => recordClickEvents(e, Event.category.CART_ICON)}>
            <IconButton>
              <ShoppingCartIcon />
            </IconButton>
          </Href>

          <div className="search-icon">
            <IconButton onClick={ this.handleSearchButtonClick }>
              <SearchIcon />
            </IconButton>
          </div>
        </div>
        <LoginBottomSheet
          isOpen={ showLoginBottomSheet }
          onClose={ this.handleLoginSheetClose }
          onLoginSuccess={ this.handleLoginSuccess }
          messageSnippet="Please log in or sign up to view or create your wishlist"
        />
      </div>
    )
    /* eslint-disable jsx-a11y/no-autofocus */
    const headerContentSearch = (
      <div className="header__content">
        <div className="header__center">
          <form
            className="search-form"
            action="/products/search"
            onSubmit={ this.handleSearchSubmit }
          >
            <input
              autoFocus
              type="search"
              name="keywords"
              placeholder="Search Urban Ladder"
              value={ typedQuery }
              onChange={ this.handleInputKeyPress }
              onFocus={ this.handleInputFocus }
              ref={ s => (this.searchInput = s) }
              autoComplete="off"
            />
          </form>
        </div>
        <div className="header__right">
          <TransitionGroup
            transitionName="fade"
            transitionEnterTimeout={ Transition.fade.ENTER_TIMEOUT }
            transitionLeaveTimeout={ Transition.fade.LEAVE_TIMEOUT }
          >
            {
              typedQuery &&
              searchSuggestions.promiseState === Status.PENDING && (
                <JumpingDots />
              )
            }
          </TransitionGroup>

          <TransitionGroup
            transitionName="fade"
            transitionEnterTimeout={ Transition.fade.ENTER_TIMEOUT }
            transitionLeaveTimeout={ Transition.fade.LEAVE_TIMEOUT }
          >
            {
              typedQuery && (
                <IconButton onClick={ this.handleClearClick }>
                  <CancelIcon />
                </IconButton>
              )
            }
          </TransitionGroup>
        </div>
      </div>
    )

    return (
      <FixedToolbar
        className="header"
        anchorTo={ Position.TOP }
        shouldOverlay
      >
        <div className="header__action-bar">
          <HamburgerIcon
            isActive={ searchMode }
            onClick={ searchMode ? popHistoryState : this.handleMenuClick }
          />
          {
            searchMode ? headerContentSearch : headerContentDefault
          }
        </div>
        <Nav
          isActive={ navOpen }
          onNavClose={ this.handleCloseNav }
        />
        { searchMode &&
          <AutoSuggestionList
            suggestions={ searchSuggestions.suggestions }
            highlightText={ typedQuery }
            onItemClick={ this.handleSetSearchOffClick }
            onOverlayClick={ popHistoryState }
          />
        }
      </FixedToolbar>
    )
  }
}

const headerBackNav = handlesBackNavigation(Header)
export default connect(state => ({
  searchSuggestions: state.searchSuggestions,
  searchResults: state.listingResults.productResults,
}))(headerBackNav)