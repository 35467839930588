/**
 * A collections of utils that help with
 * DOM read, traversal or modification.
 */

/**
 * Finds the closest ancestor of a node
 * which is scrollabale.
 * @param {node} node
 *
 * @returns {boolean}
 */
// eslint-disable-next-line max-statements
export function scrollParent(node) {
  if (!node) {
    return document
  }

  const excludeStaticParent = node.style.position === 'absolute'
  const overflowRegex = /(scroll|auto)/
  let parent = node

  while (parent) {
    if (!parent.parentNode) {
      return node.ownerDocument || document
    }

    const style = window.getComputedStyle(parent)
    const { position } = style
    const { overflow } = style
    const overflowX = style['overflow-x']
    const overflowY = style['overflow-y']

    if (!(position === 'static' && excludeStaticParent)) {
      if (overflowRegex.test(overflow + overflowX + overflowY)) {
        return parent
      }

      parent = parent.parentNode
    }
  }

  return node.ownerDocument || document
}

/**
 * Checks if any ancestor of the element has a particular class
 * Support: IE 10+
 *
 * @param {node} element
 *
 * @param {string} classname
 *
 * @returns {boolean}
 */
export function anscestorHasClass(element, classname) {
  if (element.classList.contains(classname)) return true
  return (
    element.parentElement &&
    anscestorHasClass(element.parentElement, classname)
  )
}

/* eslint-disable jsdoc/check-param-names */
/**
 * Loads a external script into the DOM.
 * Support: IE 9+
 *
 * @param {string} src - URL of the script to load
 *
 * @param {boolean=true} isAsync — Sets the async attribute
 * on scripts, makes the load non-blocking.
 *
 * @returns {Promise}
 */

/* eslint-enable jsdoc/check-param-names */
export function loadScript(src, isAsync = true) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.type = 'text/javascript'
    script.async = isAsync
    script.onload = resolve
    script.onerror = reject
    document.head.appendChild(script)
  })
}

export function requestFullscreen() {
  if ('requestFullscreen' in document.documentElement) {
    document.documentElement.requestFullscreen()
  } else if ('webkitRequestFullscreen' in document.documentElement) {
    document.documentElement.webkitRequestFullscreen()
  } else if ('mozRequestFullScreen' in document.documentElement) {
    document.documentElement.mozRequestFullScreen()
  }
}

export function exitFullscreen() {
  if(document.fullscreenElement){
    if ('exitFullscreen' in document) {
      document.exitFullscreen()
    } else if ('webkitExitFullscreen' in document) {
      document.webkitExitFullscreen()
    } else if ('mozCancelFullScreen' in document) {
      document.mozCancelFullScreen()
    }
  }
}
