import Spree from 'api/spree/index'
import { PromiseState } from 'constants/enums'

/* =============
 -   ACTIONS   -
 ============= */

export function get(options) {
  const spree = new Spree()
  return {
    type: 'GET_OFFERS',
    payload: spree.offers.get(options),
  }
}


/* =============
 -  REDUCERS   -
 ============= */

export function offers(state = {}, action) {
  switch (action.type) {
    case 'GET_OFFERS_PENDING':
      return {
        ...state,
        promiseState: PromiseState.PENDING,
      }

    case 'GET_OFFERS_FULFILLED':
      return {
        ...state,
        ...action.payload.data,
        promiseState: PromiseState.FULFILLED,
      }

    case 'GET_OFFERS_REJECTED':
      return {
        ...state,
        promiseState: PromiseState.REJECTED,
      }

    default:
      return state
  }
}
