import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import promiseMiddleware from 'redux-promise-middleware'
import reducers from './reducers'

class Store {
  static storeInstance = null;

  static get() {
    if (Store.storeInstance) {
      return Store.storeInstance
    }
    Store.storeInstance = Store.configureStore()
    return Store.storeInstance
  }

  static configureStore() {
    const initialState = isServer() ? {} : window.__INITIAL_STATE__
    const store = createStore(reducers, initialState, compose(
      applyMiddleware(thunkMiddleware, promiseMiddleware()),
      !isServer() &&
      typeof window.devToolsExtension !== 'undefined' ?
      window.devToolsExtension() : f => f,
    ))

    // Enable Webpack hot module replacement for reducers
    if (module.hot) {
      module.hot.accept('./reducers', () => {
        /**
         * Need to figure out if we should reset
         * analytics array at all
         */
        // eslint-disable-next-line import/newline-after-import
        const nextReducer = require('./reducers').default([])
        store.replaceReducer(nextReducer)
      })
    }

    return store
  }

  static reset() {
    Store.storeInstance = Store.configureStore()
  }
}

export default Store
