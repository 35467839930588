import React from 'react'
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/components/connect'
import { provideHooks } from 'redial'
import { Helmet } from "react-helmet";

import Actions from 'store/actions'
import { getHelmetData, cleanUpSnippetLinks } from 'library/utils'
import { ContentBlock } from 'constants/enums'
import Config from 'settings/config'

import Logo from 'imgs/logos/logo-tiny.svg'
import BackButton from 'imgs/back-button.svg'
import ShoppingCart from 'imgs/icons/shopping-cart.svg'
import Search from 'imgs/icons/search.svg'

class AmpPages extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    listing: PropTypes.object,
    params: PropTypes.object.isRequired,
    contentBlocks: PropTypes.object.isRequired,
  }

  get ampPageDOM() {
    const { params, listing } = this.props
    const productsData = listing.products

    let titleName = (params.slug).replace(/-/g, ' ').toLowerCase()
      .split(' ')
    titleName = titleName.map(name => name.charAt(0).toUpperCase() +
      name.substring(1))

    const pageTitle = listing.seo_meta_info ?
      listing.seo_meta_info.title_name : titleName.join(' ')
    const totalCount = listing.total_count
    const { atfData, btfData } = this.getContentBlock()

    return (
      Config.ampPageProductsPermalink.includes(params.slug) ? (
        <div>
          <div style={ { margin: '12px 6px 6px', textAlign: 'center' } }>
            <h1
              style={ {
                margin: 0,
              } }
            >
              { pageTitle }
            </h1>
            <div
              style={ {
                color: '#999',
                fontSize: '14px',
              } }
            >
              { totalCount === undefined ?
                '' : `${totalCount} ${totalCount === 1 ? 'Product' : 'Products'}`
              }
            </div>
          </div>
          { atfData &&
            <div
              style={ {
              textAlign: 'center',
              padding: '0 15px 12px',
            } }
              dangerouslySetInnerHTML={ {
                __html: cleanUpSnippetLinks(atfData.body),
              } }
            />
          }
          <div style={ { border: '0.5px solid #EEE', background: '#EEE' } } >
          {productsData && productsData.map(product =>
            (<div key={ product.id }>
              <div>
                <div
                  style={ {
                    textAlign: 'center',
                    width: '50%',
                    border: '0.5px solid #EEE',
                    float: 'left',
                    boxSizing: 'border-box',
                    padding: '10px 10px 15px',
                    background: '#FFF',
                  } }
                >
                  <a href={ product.path }>
                    <div style={ { position: 'relative' } }>
                      <amp-img
                        src={ product.images.url }
                        class="contain"
                        layout="responsive"
                        width="100"
                        height="100"
                      />
                      { product.tag &&
                        <div
                          style={ {
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            padding: '1.5px 7px',
                            background: '#2F4858',
                            color: '#FFF',
                            fontSize: '10px',
                            lineHeight: '1.15',
                          } }
                        >
                          {product.tag}
                        </div>
                      }
                    </div>
                  </a>
                  <a
                    href={ '{{product.path}}' }
                    style={ {
                      textDecoration: 'none',
                      color: '#2f2F2F',
                    } }
                  >
                    <h2
                      style={ {
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        fontWeight: '500',
                        fontSize: '14px',
                        margin: '6px 0 0',
                        textTransform: 'none',
                        letterSpacing: 0,
                        textAlign: 'center',
                      } }
                    >
                      {product.product_name}
                    </h2>
                    <div
                      style={ {
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        color: '#999',
                        fontSize: '12px',
                        marginTop: '3px',
                      } }
                    >
                      {product.variant_type}
                    </div>
                    <div style={ {
                        marginTop: '3px',
                        fontSize: '14px',
                        fontWeight: '500',
                      } }
                    >
                        {product.display_price}
                    </div>
                  </a>
                </div>
              </div>
             </div>))}
          </div>

          <div
            style={ {
              width: '100%',
              height: '85px',
              display: 'flex',
              justifyContent: 'center',
              backgroundColor: '#EEEEEE',
              alignItems: 'center',
            } }
          >
          <div
            style={ {
                 border: 'none',
                 display: 'inline-block',
                 backgroundColor: 'rgba(51,51,51,0.75)',
                 color: '#fff',
                 padding: '15px 6px',
                 boxSizing: 'border-box',
                 height: '52px',
                 borderRadius: '24px',
               } }
          >
               <a
                 href={ `/${params.slug}` }
                 style={ {
                   color: '#fff',
                   textDecoration: 'none',
                   padding: '24px 32px',
                 } }
               >
                  SEE MORE
               </a>
          </div>
          </div>

          <div
            style={ {
              display: ' flex',
              justifyContent: 'center',
              margin: '20px',
            } }
          >
            <div
              style={ {
                width: '100px',
                height: '2px',
                background: '#EEE',
              } }
            />
          </div>
          { btfData &&
            <div
              style={ {
              textAlign: 'justify',
              padding: '0 12px 12px',
              fontSize: '14px',
              lineHeight: 1.4,
            } }
              dangerouslySetInnerHTML={ {
                __html: cleanUpSnippetLinks(btfData.body),
              } }
            />
          }
        </div>
      ) : (
        <div style={ {
          padding: '12px',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        } }
        >
          <h1>404</h1>
          <div style={ { fontSize: '14px' } }>
            Couldn't find the page you were looking for.
          </div>
        </div>
      )
    )
  }


  getContentBlock() {
    const { contentBlocks } = this.props

    let atfData = ''
    let btfData = ''

    let position = ContentBlock.position.AFTER_PAGEHEADER
    let platform = ContentBlock.platform.WEB

    if (contentBlocks[position]) {
      atfData = contentBlocks[position][platform]
    }

    position = ContentBlock.position.FOOTER_SECTION_1
    platform = ContentBlock.platform.ALL

    if (contentBlocks[position]) {
      btfData = contentBlocks[position][platform]
    }

    return { atfData, btfData }
  }

  render() {
    const { params, listing } = this.props
    const searchURL = `/${params.slug}`
    const permalink = ''

    return (
      <div id="amp-container__content">
        <Helmet { ...getHelmetData(listing.seo_meta_info, permalink) } />
        <div style={ { height: '56px' } }>
          <div
            style={ {
              display: 'flex',
              justifyContent: 'space-between',
              boxShadow: '0 2px 2px rgba(0, 0, 0, 0.15)',
            } }
          >
            <div style={ {
              display: 'flex',
              alignItems: 'center',
            } }
            >
              <a href="/" >
                <div style={ { padding: '12px' } } >
                  <amp-img
                    src={ BackButton }
                    alt="backbutton"
                    width="24"
                    height="24"
                  />
                </div>
              </a>
              <a href="/" style={ { padding: '0 12px' } }>
                <div style={ { padding: '12px 0' } } >
                  <amp-img
                    src={ Logo }
                    alt="logo"
                    width="32"
                    height="26"
                  />
                </div>
              </a>
            </div>
            <div
              style={ {
                display: 'flex',
                alignItems: 'center',
              } }
            >
              <a href="/cart" >
                <div style={ { padding: '12px' } } >
                  <amp-img
                    src={ ShoppingCart }
                    alt="shopping"
                    width="24"
                    height="24"
                  />
                </div>
              </a>
              <a href={ searchURL }>
                <div style={ { padding: '12px' } } >
                  <amp-img
                    src={ Search }
                    alt="search"
                    width="24"
                    height="24"
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
        { this.ampPageDOM }
      </div>
    )
  }
}

const products = connect(state => ({
  listing: state.listingResults.productResults,
  contentBlocks: state.pageData.contentBlocks,
}))(AmpPages)

export default provideHooks({
  fetch: ({ dispatch, params: { slug } }) => dispatch(() =>
    Promise.all([
      dispatch(Actions.getListing(slug, true, null, 50)),
      dispatch(Actions.getPersonalizedContentBlocks(`/amp/${slug}`)),
    ])),
})(products)
