/* eslint-disable sort-class-members/sort-class-members */
import Endpoints from 'settings/endpoints'
import BaseAPI from '../../BaseAPI'

export default class OrderHistory extends BaseAPI {

  get() {
    return this.client.get(Endpoints.order.history,
      null,
      null,
      { credentials: 'same-origin' },
    )
  }

  getOrderDetails(orderNumber) {
    const endpoint = Endpoints.order.details(orderNumber)
    return this.client.get(
      endpoint,
      null,
      null,
      { credentials: 'same-origin' },
    )
  }
}
