import Endpoints from 'settings/endpoints'
import BaseAPI from '../../BaseAPI'

export default class AvailableStores extends BaseAPI {
  get(params) {
    const endpoint = Endpoints.product.availableStores
    return this.client
      .get(endpoint, params)
      .catch(() => Promise.reject())
  }
}
