export default {
    PRODUCT_CLICKED: 'product_clicked',
    CATEGORY_CLICKED: 'category_clicked',
    SIGNED_UP: 'signed_up',
    LOGGED_IN: 'logged_in',
    ADD_TO_WISHLIST: 'added_to_wishlist',
    ADD_TO_CART: 'added_to_cart',
    FILTERS_APPLIED: 'filters_applied',
    ITEM_SEARCHED: 'item_searched',
    HEADER_ICON_CLICKED: 'header_icon_clicked',
    CONTENT_LABEL_CLICKED: 'content_label_clicked',
    PINCODE_ENTERED: 'pincode_entered',
    BANNER_CLICKED: 'banner_clicked',
    STORE_CLICKED: 'store_clicked',
    PRODUCT_VIEWED: 'product_viewed',
    CATEGORY_VIEWED: 'category_viewed'
}
