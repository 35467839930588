import Spree from 'api/spree'
import { PromiseState } from 'constants/enums'

/* =============
 -   ACTIONS   -
 ============= */

export function get(experimentSlug) {
  const spree = new Spree()

  return {
    type: 'GET_AB_EXPERIMENT',
    payload: spree.experiments.get({
      'slugs[]': experimentSlug,
    }),
    meta: { slug: experimentSlug },
  }
}

/* =============
 -  REDUCERS   -
 ============= */

export function experimentVariant(
  state = { }, action) {
  switch (action.type) {
    case 'GET_AB_EXPERIMENT_PENDING':
      return {
        ...state,
        [action.meta.slug]: {
          promiseState: PromiseState.PENDING,
          experiments: [],
        },
      }

    case 'GET_AB_EXPERIMENT_FULFILLED': {
      const responseData = action.payload.data

      return {
        ...state,
        [action.meta.slug]: {
          promiseState: PromiseState.FULFILLED,
          experiments: responseData.experiments,
        },
      }
    }
    case 'GET_AB_EXPERIMENT_REJECTED':
      return {
        ...state,
        [action.meta.slug]: {
          promiseState: PromiseState.REJECTED,
          experiments: [],
          error: action.payload,
        },
      }

    default:
      return state
  }
}
