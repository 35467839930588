/* eslint-disable jsdoc/check-param-names */
/* eslint-disable no-shadow */
/* eslint-disable max-statements */
/* eslint-disable max-params */
/* eslint-disable max-len */
/* eslint-disable compat/compat */
/* eslint-disable sort-class-members/sort-class-members */
/* eslint-disable camelcase */

import { isGenericProduct, getProductCategoryData } from 'library/utils/product'
import { LoginState } from 'constants/enums'
import Dimension from './eventFactory/dimensions'

import GoogleAnalytics from './GoogleAnalytics'
import GTM from './GTM'
// import Blueshift from './Blueshift'
import Clevertap from './Clevertap'
// import RudderStack from './RudderStack'

const IDLE_TIMEOUT = 1000

// Polyfill for requestIdleCallback

if (!isServer()) {
  window.requestIdleCallback =
    window.requestIdleCallback ||
    function ric(cb) {
      const start = Date.now()
      return setTimeout(() => {
        cb({
          didTimeout: false,
          timeRemaining: () => Math.max(0, 50 - (Date.now() - start)),
        })
      }, 1)
    }

  window.cancelIdleCallback =
    window.cancelIdleCallback ||
    function cic(id) {
      clearTimeout(id)
    }
}

const AnalayticEventTypes = {
  PAGE_VIEW: 'page_view',
  VIEWED_PRODUCT: 'viewed_product',
  ADDED_PRODUCT: 'added_product',
  REMOVED_PRODUCT: 'removed_product',
  PRODUCT_CATEGORY_VIEW: 'product_category_view',
  IDENTIFY: 'identify',
  GENERIC_EVENT: 'generic',
  LOGIN_FAILED: 'login failed',
  LOGGED_IN: 'logged in',
  SIGNED_UP: 'signed up',
  SIGNUP_FAILED: 'signup failed',
  PRODUCT_CLICKED: 'product clicked',
  PRODUCTS_SEARCHED: 'products searched',
  SERVICEABILITY_CHECKED: 'serviceability checked',
  FILTERS_APPLIED: 'filters applied',
  BANNER_CLICKED: 'banner clicked',
  STORE_CLICKED: 'store clicked',
  CATEGORY_CLICKED: 'category clicked',
}

export default class Analytics {
  static CURRENCY = 'INR';

  /**
   * Wraps analytics calls with a promise
   * and delays firing of events
   * using Idle Callback
   */
  static firePromiseWhenIdle(func, analyticsObject, maxTimeout = IDLE_TIMEOUT) {
    /* eslint-disable consistent-return, no-unused-vars */
    return new Promise((resolve, reject) => {
      if (isServer()) {
        return resolve(analyticsObject)
      }
      window.requestIdleCallback(
        () => resolve(func()),
        { timeout: maxTimeout },
      )
    })
  }

  /**
   * Common interface for identifying a user
   *
   * @param {LoginState} state
   * @param {{ userId, name, email, createdAt }} [traits]
   */

  static identify(state, traits) {
    // eslint-disable-next-line default-case
    switch (state) {
      case LoginState.LOGGED_IN: {
        const {
          userId, name, email, createdAt, userCity,
        } = traits
        // Blueshift.identify({
        //   customer_id: userId,
        //   name,
        //   email,
        //   created_at: createdAt,
        //   user_city: userCity,
        // })

        GTM.identify({ name, email, createdAt })

        GoogleAnalytics.setDimension(
          Dimension.LOGIN_STATE.dimensionNumber,
          Dimension.LOGIN_STATE.values.LOGGED_IN,
        )
        break
      }

      case LoginState.LOGGED_OUT:
        GoogleAnalytics.setDimension(
          Dimension.LOGIN_STATE.dimensionNumber,
          Dimension.LOGIN_STATE.values.LOGGED_OUT,
        )
        break

      case LoginState.NEVER_LOGGED_IN:
        GoogleAnalytics.setDimension(
          Dimension.LOGIN_STATE.dimensionNumber,
          Dimension.LOGIN_STATE.values.NEVER_LOGGED_IN,
        )
        break
    }
  }

  /**
   * Common interface for sending page views
   *
   * @param {string} pageType - Description of the page
   */
  static pageView(pageType) {
    const analyticsObject = {
      type: AnalayticEventTypes.PAGE_VIEW,
      pageType,
    }

    return this.firePromiseWhenIdle(() => {
      GoogleAnalytics.pageView()
      GTM.reset()
      GTM.pageView(pageType)
      // Blueshift.pageView()
      Clevertap.pageView(pageType)
    }, analyticsObject, 0)
  }

  // eslint-disable-next-line max-statements
  static _getProductDetails(product, selectedVariantIndex) {
    let currentVariant
    let currentVariantName = ''
    let currentVariantInStock = true
    let heroSku
    if (isGenericProduct(product)) {
      currentVariant = product.variants[selectedVariantIndex]
      currentVariantName = currentVariant.name
      currentVariantInStock = currentVariant.in_stock
      heroSku = currentVariant.sku
    } else if (product.product_template === 'Sofa') {
      currentVariant = product.sets[selectedVariantIndex]
      currentVariantName = `${product.name} (${currentVariant.presentation})`

      const heroSkus = product.sets.filter(
        /* eslint-disable unicorn/prefer-starts-ends-with */
        (set) => ((typeof set.sku === 'string') && set.sku.match(/0032$/)),
      ).map((set) => set.sku)

      /**
       * Lets send the 3-2 seater
       * if found or send the default SKU
       */
      heroSku = heroSkus.length > 0 ? heroSkus[0] : product.sets[0].sku
    }
    const pid = product.id
    const {
      sku, price, discount_price, prebook_status, pre_order_status,
    } = currentVariant
    let availability = ''

    if (currentVariantInStock) {
      availability = 'instock'
    } else if (prebook_status) {
      availability = 'prebook'
    } else if (pre_order_status) {
      availability = 'preorder'
    }

    return {
      id: sku, // Variant SKU
      pid, // Product ID
      currentVariantName, // Variant Name
      sku, // Variant SKU
      price: discount_price, // Variant Price
      currency: this.CURRENCY,
      category: product.primary_taxon.name,
      availability,
      heroSku, // SKU to be sent to the marketing channels
    }
  }

  /**
   * This event fires when a visitor views a product.
   * That view might happen on a page, screen, or preview modal.
   *
   * @param { { id, sku, name, price, category } } product
   * @param { number } selectedVariantIndex
   */
  static viewedProduct(productDetails) {
    const analyticsObject = {
      type: AnalayticEventTypes.VIEWED_PRODUCT,
      productDetails,
    }
    return this.firePromiseWhenIdle(() => {
      // GTM.viewedProduct(productDetails)
      GTM.productViewed(productDetails)
      // Blueshift.viewedProduct(productDetails)
      Clevertap.productViewed(productDetails)
      // GoogleAnalytics.productDetailImpression(productDetails)
    }, analyticsObject, 0)
  }

  /**
   * Fired when a visitor adds a product to their shopping cart.
   *
   * @param { { id, sku, name, price, category } } product
   * @param { number } selectedVariantIndex
   */
  static addedProduct(productDetails) {
    const analyticsObject = {
      type: AnalayticEventTypes.ADDED_PRODUCT,
      productDetails,
    }

    const productData = {
      sku: productDetails.sku,
      product_name: productDetails.name,
      product_category: productDetails.category,
      product_subcategory: productDetails.sub_category,
      product_model: productDetails.model,
      quantity: 1,
    }

    return this.firePromiseWhenIdle(() => {
      GTM.addedProduct(productDetails)
      // Blueshift.addedProduct(productDetails)
      Clevertap.productAdded(productData)
      // GoogleAnalytics.addedProduct(productData)
    }, analyticsObject)
  }

  /**
   * Fired when a visitor removes a product
   * from their shopping cart.
   *
   * @param { { id, sku, name, price, category } } product
   * @param { number } selectedVariantIndex
   */
  static removedProduct(productDetails) {
    const analyticsObject = {
      type: AnalayticEventTypes.REMOVED_PRODUCT,
      productDetails,
    }

    return this.firePromiseWhenIdle(() => {
      GTM.removedProduct(productDetails)
      // Blueshift.removedProduct(productDetails)
    }, analyticsObject)
  }

  /**
   * Common interface for sending products viewed in
   * a page category
   *
   * @param {string} category
   *
   * @param {Array} param - If a flat array, an SKU array
   * is assumed to be specified, else if an array of objects,
   * a standard products array is assumed
   *
   * @param {number} maxLimit - If a product array is specified,
   * limit to these many products
   */
  static productCategoryView(params, productData, maxLimit = 6, isLeafListing = true, loadMore= false) {
    let skus = []
    let formattedProduct = {}
    const param = isLeafListing ? productData.products : productData.permalinks
    const subCategory = params ? params.name : ''
    const productDetails = []
    // eslint-disable-next-line no-param-reassign
    maxLimit = param ? param.length : 0
    const count = param ? param.length : 0
    if (Array.isArray(param) && count) {
      if (typeof param[0] === 'object') {
        const count = param.length

        for (let i = 0; i < count; i++) {
          if (isLeafListing) {
            // eslint-disable-next-line max-depth
            if (param) {
              skus.push(param[i].sku)
              productDetails.push({
                id: param[i].sku, name: param[i].product_name, category: subCategory, variant: param[i].variant_type, position: param[i].position,
              })
            }
          } else {
            const products = param[i].products?.[0]
            // eslint-disable-next-line max-depth
            if (products) {
              skus.push(param[i].sku)
              productDetails.push({
                id: products.sku, name: products.product_name, category: subCategory, variant: products.variant_type, position: param[i].position,
              })
            }
          }
        }
      } else {
        skus = param
      }
    }

    if (productData.seo_meta_info) {
      formattedProduct = getProductCategoryData(productData.seo_meta_info.breadcrumb_info)
    }
    formattedProduct.product_subcategory = subCategory

    const analyticsObject = {
      type: AnalayticEventTypes.PRODUCT_CATEGORY_VIEW,
      category: subCategory,
      param,
      maxLimit,
    }
    return this.firePromiseWhenIdle(() => {
      GTM.productCategoryView(subCategory, skus)

      if(!loadMore) GTM.categoryViewed(params)
      // Blueshift.productCategoryView(subCategory)
      Clevertap.productListViewed(formattedProduct)
      GoogleAnalytics.listingProductImpression(productDetails)
    }, analyticsObject, 0)
  }

  static _normalizeEvent(event) {
    const normalizedEvent = { ...event }
    if (event.category) {
      normalizedEvent.category = event.category.toLowerCase()
    }
    if (event.action) {
      normalizedEvent.action = event.action.toLowerCase()
    }
    if (event.label) {
      // Labels must always be strings
      normalizedEvent.label = event.label.toString().toLowerCase()
    }
    return normalizedEvent
  }

  /**
   * Common interface for generic GA events
   *
   * @param { { category, action, label, value } } event
   */
  static genericEvent(event) {
    const normalizedEvent = this._normalizeEvent(event)
    const analyticsObject = {
      type: AnalayticEventTypes.GENERIC_EVENT,
      normalizedEvent,
    }

    return this.firePromiseWhenIdle(() => {
      /**
       * Enable below event once we have config to
       * check the event config before send the event to
       * blueshift also include the customer_id field in the
       * param of the event
       */
      // Blueshift.genericEvent(normalizedEvent)

      try {
        GTM.genericEvent(normalizedEvent)
        // RudderStack.genericEvent(normalizedEvent)
      } catch (err) {
        console.error(err)
      }
    }, analyticsObject)
  }

  /**
   * Common interface for experiment GA events
   *
   * @param {Array} experiments
   */
  static setExperiments(experiments) {
    const analyticsObject = {
      type: AnalayticEventTypes.PAGE_VIEW,
      experiments,
    }

    return this.firePromiseWhenIdle(() => {
      GoogleAnalytics.setExperiments(experiments)
    }, analyticsObject, 0)
  }

  static signedUp(userData) {
    const analyticsObject = {
      type: AnalayticEventTypes.SIGNED_UP,
      userData,
    }

    return this.firePromiseWhenIdle(() => {
      Clevertap.signedUp(userData)
    }, analyticsObject)
  }

  static signupFailed(userData) {
    const analyticsObject = {
      type: AnalayticEventTypes.SIGNUP_FAILED,
      userData,
    }

    return this.firePromiseWhenIdle(() => {
      Clevertap.signupFailed(userData)
    }, analyticsObject)
  }

  static loggedIn(userData) {
    const analyticsObject = {
      type: AnalayticEventTypes.LOGGED_IN,
      userData,
    }

    return this.firePromiseWhenIdle(() => {
      Clevertap.loggedIn(userData)
    }, analyticsObject)
  }

  static loginFailed(userData) {
    const analyticsObject = {
      type: AnalayticEventTypes.LOGIN_FAILED,
      userData,
    }

    return this.firePromiseWhenIdle(() => {
      Clevertap.loginFailed(userData)
    }, analyticsObject)
  }

  static productClicked(cardData, productPosition, breadcrumbInfo) {
    const { list_id } = getProductCategoryData(breadcrumbInfo)

    const analyticsObject = {
      type: AnalayticEventTypes.PRODUCT_CLICKED,
      cardData,
      productPosition,
      breadcrumbInfo,
    }

    return this.firePromiseWhenIdle(() => {
      Clevertap.productClicked(cardData, productPosition, list_id)
      GoogleAnalytics.listingProductCardClick(cardData, productPosition)
      GTM.productClicked(cardData, productPosition, list_id)
    }, analyticsObject)
  }

  static productsSearched(searchQuery) {
    const analyticsObject = {
      type: AnalayticEventTypes.PRODUCTS_SEARCHED,
      searchQuery,
    }

    return this.firePromiseWhenIdle(() => {
      Clevertap.productsSearched(searchQuery)
    }, analyticsObject)
  }

  static bannerClicked(eventData) {
    const analyticsObject = {
      type: AnalayticEventTypes.BANNER_CLICKED,
      eventData,
    }

    return this.firePromiseWhenIdle(() => {
      GTM.bannerClicked(eventData)
    }, analyticsObject)
  }

  static serviceabilityChecked(eventData) {
    const analyticsObject = {
      type: AnalayticEventTypes.SERVICEABILITY_CHECKED,
      eventData,
    }

    return this.firePromiseWhenIdle(() => {
      Clevertap.serviceabilityChecked(eventData)
      GTM.serviceabilityChecked(eventData)
    }, analyticsObject)
  }

  static categoryClicked(eventData) {
    const analyticsObject = {
      type: AnalayticEventTypes.CATEGORY_CLICKED,
      eventData,
    }

    return this.firePromiseWhenIdle(() => {
      GTM.categoryClicked(eventData)
    }, analyticsObject)
  }

  static filtersApplied(currect_filters) {
    try {
      if (!currect_filters) {
        return
      }
      
      const analyticsObject = {
        type: AnalayticEventTypes.FILTERS_APPLIED,
        currect_filters,
      }
  
      if (currect_filters.price) {
        if(currect_filters.price.min && currect_filters.price.max) {
          currect_filters = {
            ...currect_filters,
            min_price: currect_filters.price.min,
            max_price: currect_filters.price.max
          }
        }
        delete currect_filters["price"]
      }
  
      return this.firePromiseWhenIdle(() => {
        GTM.filtersApplied(currect_filters)
      }, analyticsObject)
    } catch (e) {
      console.log("Error pushing filtersApplied analytics event to GTM", e.message)
    }

   
  }
}

function mapAnalyticsObjectToEvent(object) {
  switch (object.type) {
    case AnalayticEventTypes.PAGE_VIEW:
      Analytics.pageView(object.pageType)
      break
    case AnalayticEventTypes.ADDED_PRODUCT:
      Analytics.addedProduct(object.productDetails)
      break
    case AnalayticEventTypes.VIEWED_PRODUCT:
      break
    case AnalayticEventTypes.PRODUCT_CATEGORY_VIEW:
      break
    case AnalayticEventTypes.REMOVED_PRODUCT:
      Analytics.removedProduct(object.productDetails)
      break
    case AnalayticEventTypes.GENERIC_EVENT:
      Analytics.genericEvent(object.event)
      break
    case AnalayticEventTypes.IDENTIFY:
      Analytics.identify(object.isLoggedIn, object.traits)
      break
    case AnalayticEventTypes.LOGGED_IN:
      Analytics.loggedIn(object.userData)
      break
    case AnalayticEventTypes.LOGIN_FAILED:
      Analytics.loginFailed(object.userData)
      break
    case AnalayticEventTypes.SIGNED_UP:
      Analytics.signedUp(object.userData)
      break
    case AnalayticEventTypes.SIGNUP_FAILED:
      Analytics.signupFailed(object.userData)
      break
    case AnalayticEventTypes.PRODUCTS_SEARCHED:
      Analytics.productsSearched(object.searchQuery)
      break
    case AnalayticEventTypes.PRODUCT_CLICKED:
      Analytics.productClicked(
        object.cardData,
        object.productPosition,
        object.breadcrumbInfo,
      )
      break
    case AnalayticEventTypes.SERVICEABILITY_CHECKED:
      Analytics.serviceabilityChecked(object.eventData)
      break
    case AnalayticEventTypes.BANNER_CLICKED:
      Analytics.bannerClicked(object.eventData)
      break
    case AnalayticEventTypes.CATEGORY_CLICKED:
      Analytics.categoryClicked(object.eventData)
      break
    default:
      Analytics.genericEvent(object.event)
      break
  }
}

if (!isServer() && typeof __INITIAL_ANALYTICS__ !== 'undefined') {
  // Pop out all initial events populated by server
  window.__INITIAL_ANALYTICS__
    .forEach((analyticsObject) => mapAnalyticsObjectToEvent(analyticsObject))
  delete window.__INITIAL_ANALYTICS__
}

export { AnalayticEventTypes, mapAnalyticsObjectToEvent }
