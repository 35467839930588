import Spree from 'api/spree'
import { PromiseState, Status } from 'constants/enums'

/* =============
 -   ACTIONS   -
 ============= */

export function getPersonalizedContentBlocks(path, options) {
  const spree = new Spree()

  return dispatch => dispatch({
    type: 'GET_PERSONALIZED_CONTENT_BLOCKS',
    payload:
      spree.contentBlocks.getPersonalizedContentBlocks(path, options),
  })
}

/* =============
 -  REDUCERS   -
 ============= */

export function contentBlocks(state = {}, action) {
  let contentBlocksObj = {}

  switch (action.type) {
    case 'GET_PERSONALIZED_CONTENT_BLOCKS_PENDING':
      return {
        ...state,
        promiseState: PromiseState.PENDING,
      }

    case 'GET_PERSONALIZED_CONTENT_BLOCKS_FULFILLED':
      if (action.payload.status === Status.SUCCESS) {
        contentBlocksObj = action.payload.data.contents
      }
      return {
        ...state,
        promiseState: PromiseState.FULFILLED,
        ...contentBlocksObj,
      }

    case 'GET_PERSONALIZED_CONTENT_BLOCKS_REJECTED':
      return {
        ...state,
        promiseState: PromiseState.REJECTED,
        error: action.payload,
      }

    case 'SPA_LOCATION_CHANGE':
      return {}

    default:
      return state
  }
}
