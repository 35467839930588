import Spree from 'api/spree'
import { PromiseState } from 'constants/enums'

/* =============
 -   ACTIONS   -
 ============= */

export function get(options) {
  const spree = new Spree()
  return {
    type: 'GET_DELIVERY_SLA',
    payload: spree.product.deliverySLA.get(options),
    meta: {
      pincode: options.pincode,
    },
  }
}

export function setDeliverySLA(slaData) {
  return {
    type: 'SET_DELIVERY_SLA',
    payload: slaData,
  }
}

/* =============
 -  REDUCERS   -
 ============= */

export function deliverySLAData(
  state = { promiseState: PromiseState.PENDING },
  action,
) {
  switch (action.type) {
    case 'GET_DELIVERY_SLA_PENDING':
      return {
        ...state,
        pincode: action.meta.pincode,
        sla: {},
        promiseState: PromiseState.PENDING,
      }

    case 'SET_DELIVERY_SLA':
    case 'GET_DELIVERY_SLA_FULFILLED': {
      const slaData = action.payload
      return {
        ...state,
        pincode: slaData.data.pincode,
        sla: slaData,
        promiseState: PromiseState.FULFILLED,
      }
    }

    case 'GET_DELIVERY_SLA_REJECTED':
      return {
        ...state,
        promiseState: PromiseState.REJECTED,
      }

    default:
      return state
  }
}
