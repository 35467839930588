class SpreeError {
  constructor(json, status, extraInfo) {
    let errorMessage

    if ('message' in json) {
      errorMessage = json.message
    } else if ('error' in json) {
      errorMessage = json.error.message
    } else {
      errorMessage = json
    }

    this.name = 'SpreeError'
    this.message = errorMessage
    this.stack = (new Error(this.message)).stack
    this.error = json.error
    this.status = status
    if (extraInfo) {
      this.url = extraInfo.url
    }
  }

  toString() {
    return `${this.name} - ${this.message}`
  }
}

SpreeError.prototype = Object.create(Error.prototype)

export default SpreeError
