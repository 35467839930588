import { combineReducers } from 'redux'

import { getDetails, details } from './detailsStore'
import {
  get as getOrderHistory,
  fetchOrder,
  orderHistory,
} from './orderHistoryStore'
import {
  get as getCancellationEligibility,
  cancellationEligibility,
} from './orderCancellationEligibilityStore'

/* =============
 -   ACTIONS   -
 ============= */

const actions = {
  getDetails,
  getOrderHistory,
  fetchOrder,
  getCancellationEligibility,
}

/* =============
 -  REDUCERS   -
 ============= */

const reducers = combineReducers({
  details,
  orderHistory,
  cancellationEligibility,
})

export default {
  actions,
  reducers,
}
