import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare'

import SearchIcon from 'units/icons/SearchIcon'
import './MockSearchBar.scss'

/* eslint-disable react/prefer-stateless-function */
export default class MockSearchBar extends React.Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    searchQuery: PropTypes.string,
    childRef: PropTypes.func,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState)
  }

  render() {
    const { isOpen, onClick, searchQuery, childRef } = this.props

    return (
      <div
        className={ classNames('mock-search-bar', { 'mock-search-bar--open': isOpen }) }
        onClick={ onClick }
        ref={ childRef }
      >
        { searchQuery ?
          (<span className="mock-search-bar__query"> { searchQuery } </span>) :
          (<span className="mock-search-bar__placeholder"> Search Urban Ladder </span>)
        }
        <SearchIcon />
      </div>
    )
  }
}
