import React from 'react'
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare'

import classNames from 'classnames'
import { Position } from 'constants/enums'

import './FixedToolbar.scss'

/**
 * A multi-purpose `position:fixed` toolbar
 * which is dynamically sized by its children.
 */
export default class FixedToolbar extends React.Component {

  static propTypes = {
    anchorTo: PropTypes.oneOf([Position.TOP, Position.BOTTOM]).isRequired,
    className: PropTypes.string,
    children: PropTypes.node,
    isHidden: PropTypes.bool,
    /**
     * Fixed elements fall out of flow of the document.
     * If this property is set to false (default), loss in height
     * is compenstated for by adding a padding to body.
     */
    shouldOverlay: PropTypes.bool,
  };

  static defaultProps = {
    anchorTo: Position.BOTTOM,
    isHidden: false,
    shouldOverlay: false,
  };

  componentDidMount() {
    const { anchorTo, shouldOverlay } = this.props
    if (!shouldOverlay) {
      // Make sure the element has been drawn in DOM
      const elementHeight = this.toolbar.clientHeight
      this.addPaddingToBody(anchorTo, elementHeight)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState)
  }

  componentWillUnmount() {
    const { anchorTo, shouldOverlay } = this.props
    if (!shouldOverlay) {
      const elementHeight = this.toolbar.clientHeight
      this.addPaddingToBody(anchorTo, -elementHeight)
    }
  }

  getPaddingTop(element) {
    return parseInt(getComputedStyle(element).paddingTop)
  }

  getPaddingBottom(element) {
    return parseInt(getComputedStyle(element).paddingBottom)
  }

  addPaddingToBody(position, value) {
    const appContainer = document.getElementById('app-container__content')

    if (position === Position.TOP) {
      const paddingTop = `${this.getPaddingTop(appContainer) + value}px`
      requestAnimationFrame(() => {
        appContainer.style.paddingTop = paddingTop
      })
    } else {
      const paddingBottom = `${this.getPaddingBottom(appContainer) + value}px`
      requestAnimationFrame(() => {
        appContainer.style.paddingBottom = paddingBottom
      })
    }
  }

  render() {
    const { anchorTo, isHidden, children, className } = this.props
    const classes = classNames(className, 'fixed-toolbar', `fixed-toolbar--${anchorTo}`, {
      'fixed-toolbar--hidden': isHidden,
    })

    return (
      <div className={ classes } ref={ t => (this.toolbar = t) }>
        { children }
      </div>
    )
  }
}
