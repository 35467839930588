import classNames from 'classnames'
import times from 'lodash/times'
import React from 'react'
import PropTypes from 'prop-types';

import ProductLoaderCard from 'components/ProductLoaderCard'

import './ProductLoaderPanel.scss'

const ProductLoaderPanel = ({ isGrid, isWidget }) => (
  <div
    key="loader"
    className={ classNames('clearfix product-loader-card__wrap',
                          { 'product-loader-card__wrap--widget': isWidget }) }
  >
    {times(10, i =>
      <ProductLoaderCard isGrid={ isGrid } isWidget={ isWidget } key={ i } />,
      )
    }
  </div>
)

ProductLoaderPanel.propTypes = {
  isGrid: PropTypes.bool,
  isWidget: PropTypes.bool,
}

ProductLoaderPanel.defaultProps = {
  isGrid: true,
  isWidget: false,
}

export default ProductLoaderPanel
