import Endpoints from 'settings/endpoints'
import BaseAPI from '../../BaseAPI'

export default class uploads extends BaseAPI {
  get(path, options) {
    const params = {
      'q[relative_path_eq]': path,
      ...options,
    }
    return this.client.get(Endpoints.uploads, params)
  }
}
