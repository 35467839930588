import Spree from 'api/spree'
import { PromiseState } from 'constants/enums'
import Endpoints from 'settings/endpoints'

/* =============
 -   ACTIONS   -
 ============= */

export function get() {
  const spree = new Spree()

  return {
    type: 'GET_BROWSE_CATEGORIES',
    payload: spree.uploads.get(Endpoints.home.browseCategoriesRelativePath),
  }
}

/* =============
 -  REDUCERS   -
 ============= */

export function browseCategories(state = { uploads: [] }, action) {
  switch (action.type) {
    case 'GET_BROWSE_CATEGORIES_PENDING':
      return {
        ...state,
        promiseState: PromiseState.PENDING,
      }

    case 'GET_BROWSE_CATEGORIES_FULFILLED':
      return {
        ...state,
        ...action.payload,
        promiseState: PromiseState.FULFILLED,
      }

    case 'GET_BROWSE_CATEGORIES_REJECTED':
      return {
        ...state,
        promiseState: PromiseState.REJECTED,
        error: action.payload,
      }

    default:
      return state
  }
}
