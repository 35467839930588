import Spree from 'api/spree'
import { PromiseState } from 'constants/enums'

/* =============
 -   ACTIONS   -
 ============= */

export function getCities() {
  const spree = new Spree()

  return {
    type: 'GET_CITIES',
    payload: spree.cities.get(),
  }
}

/* =============
 -  REDUCERS   -
 ============= */

export function cities(state = {}, action) {
  switch (action.type) {
    case 'GET_CITIES_PENDING':
      return {
        ...state,
        promiseState: PromiseState.PENDING,
      }

    case 'GET_CITIES_FULFILLED':
      return {
        ...state,
        promiseState: PromiseState.FULFILLED,
        ...action.payload,
      }

    case 'GET_CITIES_REJECTED':
      return {
        ...state,
        promiseState: PromiseState.REJECTED,
        error: action.payload,
      }

    default:
      return state
  }
}
