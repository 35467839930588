import Endpoints from 'settings/endpoints'
import BaseAPI from '../../BaseAPI'

export default class Subscription extends BaseAPI {
  post(params) {
    const requiredParams = ['subSource', 'email']
    super._schemaCheck(params, requiredParams)
    return this.client.get(Endpoints.subscription, {
      email_id: params.email,
      sub_source: params.subSource,
    }, {
      // The below headers are a workaround for
      // https://urbanladder.atlassian.net/browse/VA-281
      'X-Requested-With': 'XMLHttpRequest',
      Accept: '*/*',
    })
  }
}
