import React from 'react'
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare'

import Href from 'units/Href'

import { SrcParam } from 'constants/enums'
import { addUrlParams, cleanUpTestimonialText, shouldProxyPermalink } from 'library/utils'
import { parseProductName } from 'library/utils/product'
import { getCDNURL } from 'library/utils/URLTransforms'
import { Widget } from 'settings/values'
import Analytics from 'library/analytics'
import Event from 'library/analytics/eventFactory'

import './Testimonial.scss'

/**
 * Changed this to a class from stateless functional component
 * to avoid triggering rerender during pagination
 */

export default class Testimonial extends React.Component {
  static propTypes = {
    testimonial: PropTypes.object.isRequired,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState)
  }

  render() {
    const { testimonial } = this.props
    const productDetails = testimonial.variants
    let productLink = '#'
    let productDetailsHtml = ''
    if (productDetails && productDetails.length) {
      const product = productDetails[0]
      const { productName } = parseProductName(product.name)
      productLink = `/products/${product.product_slug}` || productLink
      productDetailsHtml = (
        <div className="testimonial__product-details">
          <div className="testimonial__product-name">{ productName }</div>
          <Href
            to={ addUrlParams(productLink, { src: SrcParam.testimonial }) }
            className="testimonial__product-link"
            isProxied={ shouldProxyPermalink(productLink) }
          >
            View Product
          </Href>
        </div>
      )
    }

    return (
      <div className="testimonial">
        <div className="testimonial__source">
          <img
            className="testimonial__author-image"
            src={ testimonial.customer_photo }
            alt={ `Testimonial Author ${testimonial.customer_name}` }
          />
          <div className="testimonial__source-description">
            <div className="testimonial__author-name"> { testimonial.customer_name } </div>
            <Href to={ testimonial.link } isExternal onClick={(e) => {
              Analytics.genericEvent({
                category: Event.category.CUSTOMER_STORIES,
                action: Event.action.CLICKED,
                link: testimonial?.link,
                customerName: testimonial?.customer_name,
                customerLink: testimonial?.customer_link
              })
            }}>
              via { testimonial.source }
            </Href>
          </div>
        </div>
        <div className="testimonial__product">
          <img
            src={ getCDNURL(testimonial.image_small) }
            className="testimonial__product-image"
            alt="Testimonial product"
          />
        </div>
        { productDetailsHtml }
        <blockquote className="testimonial__text">
          { cleanUpTestimonialText(
            testimonial.text,
            Widget.TESTIMONIALS_TEXT_LIMIT,
          ) }
        </blockquote>
      </div>
    )
  }
}
