import React from 'react'
import PropTypes from 'prop-types';
import 'units/list.scss'

import Href from 'units/Href'
import Ripple from 'units/Ripple'
import { SrcParam } from 'constants/enums'
import { addUrlParams, shouldProxyPermalink } from 'library/utils'
import Analytics from 'library/analytics'
import Event from 'library/analytics/eventFactory'

const L2ItemList = ({ isVisible, l1ItemName, l2Item }) => {
  const formatSrcParam = (items) => {
    const itemLevelChain = items
      .map(name => name
        .replace(/ ?[^\w\s]/gi, '') // Remove non-word characters
        .replace(/ /gi, '-'), // Replace spaces with hyphen
      )
      .join('_')
      .toLowerCase()

    return `${SrcParam.sidebarPrefix}_${itemLevelChain}`
  }

  const getL3ItemLink = function(l3Item) {
    return addUrlParams(`/${l3Item.permalink}`, {
      src: formatSrcParam([l1ItemName, l2Item.name, l3Item.name]),
    })
  }

  const l2ItemLink = addUrlParams(`/${l2Item.permalink}`, {
    src: formatSrcParam([l2Item.name]),
  })

  if (isVisible) {
    return (
      <div className="nav__l2">
        <Ripple>
          <Href
            className="nav__l2-link"
            to={ l2ItemLink }
            isProxied={ shouldProxyPermalink(l2Item.permalink) }
            { ...(l2Item.no_follow && { rel: 'nofollow' }) }
            shouldReplaceState
          >
            <span className="nav__l2-link-wrapper">
              { l2Item.name }
            </span>
          </Href>
        </Ripple>
        <ul className="nav__l3">
          {
            l2Item.taxons.map(l3Item => (
              <li key={ l3Item.id } className="nav__l3-item">
                <Ripple>
                  <Href
                    to={ getL3ItemLink(l3Item) }
                    onClick={() => {
                      let eventObj = {
                        action: Event.action.CLICKED,
                        product_category: [l3Item.name || ''],
                        page_section: 'burger_menu',
                        page_location: window.location.pathname,
                      }
                      Analytics.categoryClicked(eventObj)
                    }}
                    className="nav__l3-link"
                    isProxied={ shouldProxyPermalink(l3Item.permalink) }
                    { ...(l3Item.no_follow && { rel: 'nofollow' }) }
                    shouldReplaceState
                  >
                    { l3Item.name }
                  </Href>
                </Ripple>
              </li>
            ))
          }
        </ul>
      </div>
    )
  }
  return (
    <div>
      <a
        href={ `/${l2Item.permalink}` }
        { ...(l2Item.no_follow && { rel: 'nofollow' }) }
      >
        { l2Item.name }
      </a>
      <ul>
        {
          l2Item.taxons.map(l3Item =>
            <li key={ l3Item.id } >
              <a
                href={ `/${l3Item.permalink}` }
                { ...(l2Item.no_follow && { rel: 'nofollow' }) }
              >
                { l3Item.name }
              </a>
            </li>,
        ) }
      </ul>
    </div>
  )
}

L2ItemList.propTypes = {
  /**
   * Optimises render of L2ItemList.
   * L2Item list can get pretty long, and if set to false,
   * we render a "lite" version of the list, that reigns in
   * DOM element count and improves render performance
   */
  isVisible: PropTypes.bool.isRequired,
  l2Item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    permalink: PropTypes.string.isRequired,
    taxons: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  l1ItemName: PropTypes.string.isRequired,
}

export default L2ItemList
