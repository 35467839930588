/* eslint-disable max-statements */
import Config from 'settings'
import { getShortMonthNames } from 'library/utils/dateTimeUtils'
import Unicode from 'constants/unicode'
import { DeviceOS } from 'constants/enums'
import qs from 'qs'
import Cookie from './cookieUtils'
import SpreeError from 'api/spree/SpreeError'

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, txt =>
    txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
}

export function getCookieValue(key) {
  const regexp = new RegExp(`(^|;)\\s*${key}\\s*=\\s*([^;]+)`)
  const matches = regexp.exec(document.cookie)
  return matches ? matches.pop() : ''
}

export function getRobotTags(robotInfo) {
  if (!robotInfo) {
    return null
  }
  const result = []
  if (robotInfo.meta_no_index && robotInfo.meta_no_follow) {
    result.push({ name: 'robots', content: 'noindex, nofollow' })
  } else if (robotInfo.meta_no_index) {
    result.push({ name: 'robots', content: 'noindex' })
  } else if (robotInfo.meta_no_follow) {
    result.push({ name: 'robots', content: 'nofollow' })
  }

  return result
}

export function getMetaTags(seoMetaInfo) {
  if (!seoMetaInfo) {
    return null
  }
  const result = Object.keys(seoMetaInfo)
    .filter(key => key.indexOf('meta_') === 0)
    .map(key => ({
      name: key.replace('meta_', ''),
      content: seoMetaInfo[key],
    }))

  return result
}

export function getHelmetData(seoMetaInfo = {}, permalink) {
  const helmetData = {
    title: seoMetaInfo.page_title,
  }

  if (Config.ampPageProductsPermalink.includes(permalink)) {
    helmetData.meta = getMetaTags(seoMetaInfo)

    if (seoMetaInfo && seoMetaInfo.canonical_tag !== undefined) {
      helmetData.link = [{
        rel: 'amphtml',
        href: `${Config.originURL}/amp/${seoMetaInfo.canonical_tag}`,
      }]
    }
  }
  helmetData.meta = getMetaTags(seoMetaInfo)

  if (seoMetaInfo && seoMetaInfo.canonical_tag !== undefined) {
    helmetData.link = [{
      rel: 'canonical',
      href: `${Config.originURL}/${seoMetaInfo.canonical_tag}`,
    }]
  }

  return helmetData
}

export function getRobotsData(taxonData = {}) {
  const helmetData = {}
  helmetData.meta = getRobotTags(taxonData)
  return helmetData
}

export function getHelmetScriptData(seoScriptInfo) {
  const helmetScriptData = {}

  helmetScriptData.script = [{
    type: 'application/ld+json',
    innerHTML: JSON.stringify(seoScriptInfo),
  }]

  return helmetScriptData
}

export function parseUrl(url) {
  // Doesn't support username and password at the moment
  /* RegEx pretty much lifted from RFC 3986 with slight
   modification for optional parts */
  const pattern = '^(([^:/?#]+):)?(//([^/?#]*)?)?([^?#]*)?(\\?([^#]*))?(#(.*))?'
  const [
    ,
    p = '',
    protocol = '',
    h = '',
    host = '',
    pathname = '',
    search = '',
    ,
    hash,
  ] = url.match(pattern)
  const [hostname = '', port = ''] = host.split(':')
  const location = {
    href: url,
    protocol,
    host,
    hostname,
    port,
    pathname,
    search,
    hash,
    origin: `${p}${h}`,
  }

  return location
}

export function getParameterByName(paramName) {
  if (!isServer()) {
    const urlLowercase = window.location.href.toLowerCase()
    const paramLowercase = paramName.replace(/[[\]]/g, '\\$&').toLowerCase()

    const regex = new RegExp(`[?&]${paramLowercase}(=([^&#]*)|&|#|$)`)
    const results = regex.exec(urlLowercase)

    if (!results) return null
    if (!results[2]) return ''
    return decodeURIComponent(results[2].replace(/\+/g, ' '))
  }

  return null
}

export function encodeQueryComponent(component) {
  return encodeURIComponent(component.replace(/(?!\r)\n|\r\n?/g, '\r\n'))
}

export function castToString(value) {
  let val = value
  if (typeof val === 'function') {
    val = value()
  }
  switch (typeof val) {
    case 'undefined':
    case 'object':
      return ''
    case 'number':
      return Number.isNaN(val) ? '' : val.toString()
    default:
      return val.toString()
  }
}

export function flattenParams(params) {
  if (params === null || typeof params !== 'object') {
    const value = castToString(params)
    if (value !== '') {
      return {
        name: value,
        value,
      }
    }
    return []
  }

  const response = []
  const p = { ...params } // Converts the Arrays to object as well
  Object.keys(p).forEach((name) => {
    const value = p[name]
    if (value === null || typeof value !== 'object') { // Is an array or object?
      response.push({
        name,
        value: castToString(value),
      })
    } else {
      flattenParams(value).forEach((param) => {
        let n = name
        if (!Number.isNaN(Number(name))) {
          n = ''
        }
        response.push({
          name: param.name.replace(/^[^[]*/, `${n}[$&]`),
          value: param.value,
        })
      })
    }
  })
  return response
}

export function serializeParams(params) {
  if (params) {
    const str = []
    flattenParams(params).forEach((param) => {
      str.push(`${encodeQueryComponent(param.name)}=${encodeQueryComponent(param.value)}`)
    })
    return str.join('&').replace(/%20/g, '+')
  }
  return ''
}

/* eslint-disable max-depth */
export function serializeForm(form) {
  const params = {}
  let i = 0
  let j = 0
  if (typeof form === 'object' && form.nodeName === 'FORM') {
    for (i = 0; i < form.elements.length; i++) {
      const field = form.elements[i]
      if (field && field.name && field.type !== 'file' && field.type !== 'reset') {
        if (field.type === 'select-multiple') {
          for (j = 0; j < form.elements[i].options.length; j++) {
            if (field.options[j].selected) {
              params[field.name] = field.options[j].value.replace(/%20/g, '+')
            }
          }
        } else if (field && (field.type !== 'submit' && field.type !== 'button')) {
          if ((field.type !== 'checkbox' && field.type !== 'radio') || field.checked) {
            params[field.name] = field.value.replace(/%20/g, '+')
          }
        }
      }
    }
  }
  return params
}


export function shouldProxyPermalink(permalink, allTaxons) {
  if (Config.staticTaxonPages.indexOf(permalink) > -1) {
    return true
  }

  const whitelistRegexArr = [
    // Store Details
    /^\/?(furniture-stores|sofa-stores)\/.+\/.+/]
  const blacklistRegexArr = [
    // Product Details
    /^\/?products\//,
  ]

  if (whitelistRegexArr.some(regex => regex.test(permalink))) {
    return true
  }

  if (blacklistRegexArr.some(regex => regex.test(permalink))) {
    return false
  }

  // proxy if the permalink does not belong to a taxon page
  if (typeof allTaxons === 'object' && allTaxons !== null) {
    return (typeof allTaxons[permalink] === 'undefined')
  }

  return false
}

export function shouldProxyLink(link, allTaxons) {
  const parsePathname = parseUrl(link).pathname
  const permalink = parsePathname[0] === '/' ? parsePathname.substring(1) : parsePathname
  return shouldProxyPermalink(permalink, allTaxons)
}

/**
 * Adds comma separators to an integer
 * according to the Indian Number System.
 * eg. 1234567 => 12,34,567
 * @param number - Number to convert
 */

export function commafyNumber(number) {
  let numberToConvert = number
  if (typeof number !== 'number') {
    numberToConvert = Number(number)
  }
  if (Number.isNaN(Number(number))) {
    numberToConvert = 0
  }

  if (!Number.isFinite(numberToConvert)) {
    return `${numberToConvert}`
  }

  const negativeFlag = numberToConvert < 0

  let strNum = numberToConvert.toString()

  if (negativeFlag) {
    strNum = strNum.substring(1)
  }

  let lastThree = strNum.substring(strNum.length - 3)
  const otherNumbers = strNum.substring(0, strNum.length - 3)
  if (otherNumbers !== '') {
    lastThree = `,${lastThree}`
  }

  const commafiedNum = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree

  return negativeFlag ? `-${commafiedNum}` : commafiedNum
}

/* Beware that this at the moment doesn't
care about duplicate keys */
export function addUrlParams(u, params) {
  const url = u.replace(/([^:])\/\//, '$1/')
  const query = qs.stringify(params, { arrayFormat: 'brackets' })
  if (query === '') {
    return url
  } else if (url.indexOf('?') > -1) {
    return `${url}&${query}`
  }
  return `${url}?${query}`
}

export function getAltText(targetUrl) {
  const parsePathname = parseUrl(targetUrl).pathname
  let altTextSuffix = ''
  if (parsePathname) {
    const pathnameComponents = parsePathname.split('/')
    const lastPathnameComponent = pathnameComponents.pop()
    altTextSuffix = lastPathnameComponent.replace(/_|-/g, ' ')
  }
  return altTextSuffix
}

export function cleanUpTestimonialText(input, cap) {
  const delimiter = '…'
  const limit = Number(cap)

  if (typeof input !== 'string') {
    return ''
  }

  if (Number.isNaN(limit)) {
    return input
  }

  if (input.indexOf('<p>') === 0) {
    /* eslint-disable no-param-reassign */
    input = input.replace(/<p>|<\/p>/ig, '')
  }

  if (input.length <= limit) {
    return input
  }

  const result = input.substr(0, limit)
  const i = result.lastIndexOf(' ')
  if (i === -1) { // assume that we'd otherwise slash a word
    return delimiter
  }
  // truncate up to the last space
  return `${result.substr(0, i)} ${delimiter}`
}

export function humanReadableDate(dateInISO8601) {
  let representableDate = '-'

  if (Number.isNaN(Date.parse(dateInISO8601))) {
    return representableDate
  }

  const tempDate = new Date(dateInISO8601)
  const year = tempDate.getFullYear()
  const month = getShortMonthNames()[tempDate.getMonth()]
  const day = tempDate.getDate()

  function nth(d) {
    if (d > 3 && d < 21) return 'th'
    switch (d % 10) {
      case 1: return 'st'
      case 2: return 'nd'
      case 3: return 'rd'
      default: return 'th'
    }
  }

  representableDate = `${month} ${day}${nth(day)}, ${year}`
  return representableDate
}

export function cleanUpSnippetLinks(snippet = '') {
  return snippet.replace(/https?:\/\/www.urbanladder.com\/?/g, '/')
}

export function getYoutubeSlug(url) {
  if (!url) {
    return null
  }
  // eslint-disable-next-line no-useless-escape
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch?v=|&v=)([^#&\?]*).*/
  const match = url.match(regExp)
  if (match && match[2].length === 11) {
    return match[2]
  }
  return null
}

/**
 * A complement of objectToBase64().
 *
 * @param {string} base64String — A base64 String encoded string
 * @returns {Object} A decoded object
 */

export function base64ToObject(base64String) {
  const nodeDecoder = str => Buffer.from(str, 'base64').toString('utf8')
  const decoder = atob || nodeDecoder

  return JSON.parse(decoder(base64String))
}

/**
 * Returns a JWT token's decoded
 * payload as a JSON Object.
 * Works on both browser and server.
 *
 * @param {string} jwtToken
 * @returns {Object} decoded data in JSON format
 */
export function decodeJWTPayload(jwtToken) {
  return base64ToObject(jwtToken.split('.')[1])
}

/**
 * Removes undefined, null and NaN value
 * containing keys from an object.
 *
 * Note: Does not support nested objects.
 * @param {Object} obj
 * @returns {Object} Compacted object without
 * null / undefined / NaN keys
 */

export function compactObject(obj) {
  const compactedObject = obj
  Object.keys(compactedObject).forEach((key) => {
    const value = compactedObject[key]
    if (value === null ||
      typeof value === 'undefined' ||
      (typeof value === 'number' && Number.isNaN(value))) {
      delete compactedObject[key]
    }
  })
  return compactedObject
}

/**
 * Universal utility to encode a
 * serializable object to Base64.
 *
 * @param {Object} object — A serializable object
 * @return {string} Base64 encoded string.
 */

export function objectToBase64(object) {
  const nodeEncoder = str => Buffer.from(str).toString('base64')
  const encoder = btoa || nodeEncoder

  return encoder(JSON.stringify(object))
}

/**
 * Utility to detect if a user is on 2G.
 * Reference - 2G EDGE speeds.
 * @see http://wicg.github.io/netinfo/#dfn-table-of-maximum-downlink-speeds
 *
 * @returns {boolean}
 * Note: On unsupported browsers, this
 * will always return false.
 *
 * Browser Support:
 * Chrome 48+ Mobile, Firefox Mobile
 */

export function isOn2G() {
  if (
    typeof navigator !== 'undefined' &&
    navigator.connection &&
    navigator.connection.type &&
    navigator.connection.downlinkMax
  ) {
    return navigator.connection.downlinkMax <= 0.384
  }
  return false
}

/**
 * Check if a user is coming from a webview.
 * On SSR, context must be passed to determine if
 * user is coming from a webview.
 */
export function isWebView(context) {
  let isEnvWebView = false
  if (isServer() && context) {
    isEnvWebView = context.ul_app === 'mobile'
  } else {
    isEnvWebView = Cookie.get('ul_app') === 'mobile'
  }
  return isEnvWebView
}

export function getAppVersion(context) {
  let appVersion
  if (isServer() && context) {
    appVersion = context.ul_app_version
  } else {
    appVersion = Cookie.get('ul_app_version')
  }
  return appVersion
}

export function priceInRupees(priceAsNumber) {
  return `${Unicode.INDIAN_RUPEE_SIGN}${commafyNumber(priceAsNumber)}`
}

export function anchorToPageContent() {
  setTimeout(() => {
    window.scrollTo(0, isWebView() ? 0 : 59)
  }, 50)
}

export function deepFlattenArray(list) {
  return list.reduce(
    (a, b) => a.concat(Array.isArray(b) ? deepFlattenArray(b) : b),
    [],
  )
}

/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android',
 * 'Windows Phone', or 'Unknown'.
 *
 * @returns {string}
 */
export function getDeviceOS() {
  const userAgent = navigator?.userAgent || navigator?.vendor || window.opera

  /* Windows Phone must come first because
  its UA also contains "Android" */
  if (/windows phone/i.test(userAgent)) {
    return DeviceOS.WINDOWS_PHONE
  }

  if (/android/i.test(userAgent)) {
    return DeviceOS.ANDROID
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return DeviceOS.IOS
  }

  return DeviceOS.UNKNOWN
}

/**
 * Check if a object doesn't have any keys
 * @returns {boolean}
 */

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

export function parseURL(url) {
  const reURLInformation = new RegExp([
    '^(https?:)//', // protocol
    '(([^:/?#]*)(?::([0-9]+))?)', // host (hostname and port)
    '(/{0,1}[^?#]*)', // pathname
    '(\\?[^#]*|)', // search
    '(#.*|)$', // hash
  ].join(''))
  const match = url.match(reURLInformation)

  return match && {
    protocol: match[1],
    host: match[2],
    hostname: match[3],
    port: match[4],
    pathname: match[5],
    search: match[6],
    hash: match[7],
  }
}

export function clearHashFragment() {
  const { hash } = window.location
  if (hash) {
    const urlWithoutHash = window.location.pathname + window.location.search
    window.history.replaceState('', document.title, urlWithoutHash)
  }
}

export function handleFetchResponse(response) {
  try {
    return response.text().then(text => {
      try {
        const data = JSON.parse(text)
        if (response.ok) {
          return data
        }
        throw new SpreeError(data, response.status, { url: response.url })
      } catch (err) {
        if (err instanceof SpreeError) {
          throw err
        }
        let message = response.statusText + " " + err.message
        console.log("SpreeError", message, response.url, response.status)
        throw new SpreeError({ message: message }, response.status, { url: response.url })
      }
    })
  } catch (err) {
    throw new SpreeError({ message: "Error processing response. " + err.message }, response.status, { url: response.url })
  }
}
