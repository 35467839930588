/* eslint-disable camelcase */
// ∵ API Response uses underscore_cased variables

import { InfiniteLoad } from 'settings/values'
import { PromiseState } from 'constants/enums'
import Spree from 'api/spree'
import { Operation } from "constants/enums";

/* =============
 -   ACTIONS   -
 ============= */

export function getSearchResults(query, options) {
  const spree = new Spree()

  const extendedOptions = {
    per_page: InfiniteLoad.ITEMS_PER_PAGE,
    ...options,
  }

  return (dispatch, getState) => {
    const { filters } = getState()
    return dispatch({
      type: 'GET_PRODUCTS',
      payload: spree.search.find(query, filters, extendedOptions),
      meta: {
        primary: 'productResults',
        dataKey: 'products',
        firstPageResult: !(options && options.page && options.page > 1),
      },
    })
  }
}

export function getNextPageSearchResults(query, options) {
  return (dispatch, getState) => {
    const storeKey = 'productResults'
    const { current_page, pages } = getState().listingResults[storeKey]
    if (current_page !== pages) {
      return dispatch(getSearchResults(
        query,
        { ...options, page: current_page + 1 },
      ))
    }
    return Promise.resolve()
  }
}

export function getListing(permalink, isLeaf, options, is_custom_per_page, operation=null) {
  const spree = new Spree()
  const itemsPerPage = is_custom_per_page || InfiniteLoad.ITEMS_PER_PAGE

  const extendedOptions = {
    per_page: itemsPerPage,
    ...options,
  }

  return (dispatch, getState) => {
    const { filters } = getState()
    return dispatch({
      type: 'GET_PRODUCTS',
      payload: spree.listing.get({
        permalink,
        isLeaf,
        filters,
        extendedOptions,
      }),
      meta: {
        primary: isLeaf ? 'productResults' : 'taxonResults',
        dataKey: isLeaf ? 'products' : 'permalinks',
        firstPageResult: !(options && options.page && options.page > 1),
        operation: operation,
      },
    })
  }
}

export function getNextPageListing(permalink, isLeaf, options, pageNumber = 0, operation = null) {
  return (dispatch, getState) => {
    const storeKey = isLeaf ? 'productResults' : 'taxonResults'
    const { current_page, pages } = getState().listingResults[storeKey]
    if (current_page !== pages || pageNumber > 0) {
      return dispatch(getListing(
        permalink,
        isLeaf,
        { ...options, page: pageNumber>0 ? pageNumber : current_page + 1 },
        null,
        operation,
      ))
    }
    return Promise.resolve()
  }
}

/* =============
 -  REDUCERS   -
 ============= */

/* When moving b/w leaf and non-leaf listing pages, it could
 * happen that a non-leaf-API response might return after the
 * leaf-API response. This would result in the UI showing
 * incorrect data(ex: number of products) if they share the same
 * store. To prevent such issues and to decouple the structure
 * of data expected from the two APIs, it is better to have
 * separare data stores for the two pages.
 */
export function listingResults(state = {
  productResults: {},
  taxonResults: {},
}, action) {
  const updatedState = {}

  switch (action.type) {
    case 'GET_PRODUCTS_PENDING':
      if (action.meta.firstPageResult) {
        updatedState[action.meta.primary] = {
          promiseState: PromiseState.PENDING,
          current_page: 0,
          [action.meta.dataKey]: [],
        }
      } else {
        updatedState[action.meta.primary] = {
          ...state[action.meta.primary],
        }
        updatedState[action.meta.primary].promiseState = PromiseState.PENDING
      }

      return {
        ...state,
        ...updatedState,
      }

    case 'GET_PRODUCTS_FULFILLED':
      updatedState[action.meta.primary] = {
        ...action.payload,
        promiseState: PromiseState.FULFILLED,
        [action.meta.dataKey]: (action.payload.current_page === 1 || action.meta.operation == Operation.none) ?
          action.payload[action.meta.dataKey] : (
            action.meta.operation === Operation.prepend ? 
            [...action.payload[action.meta.dataKey], ...state[action.meta.primary][action.meta.dataKey]]
            :
            state[action.meta.primary][action.meta.dataKey]
            .concat(action.payload[action.meta.dataKey]))
      }

      return {
        ...state,
        ...updatedState,
      }

    case 'GET_PRODUCTS_REJECTED':
      updatedState[action.meta.primary] = {
        promiseState: PromiseState.REJECTED,
        error: action.payload,
      }
      return {
        ...state,
        ...updatedState,
      }

    default:
      return state
  }
}
