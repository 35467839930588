import React from 'react'
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare'
import connect from 'react-redux/lib/components/connect'

import ProductLoaderPanel from 'handlers/Search/ProductLoaderPanel'
import Button from 'units/Button'

import { Orientation, PromiseState } from 'constants/enums'
import { InfiniteLoad } from 'settings/values'
import Actions from 'store/actions'

import BestSellersDOM from './BestSellersDOM'

import Analytics from 'library/analytics'
import Event from 'library/analytics/eventFactory'

class BestSellersPanel extends React.Component {
  static propTypes = {
    bestSellers: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    shouldLoadSection: PropTypes.bool.isRequired,
  };

  static childContextTypes = {
    widgetName: PropTypes.string,
  };

  state = {
    targetRef: React.createRef(null),
    observer: null,
  }

  getChildContext() {
    return {
      widgetName: 'BestSellers',
    }
  }

  componentDidMount() {
    if (this.props.shouldLoadSection) {
      this.handleRetryClick()
    }
    let obs = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if(entry.isIntersecting) {
          Analytics.genericEvent({
            category: Event.category.POPULAR_ACROSS_SITE,
            action:  Event.action.VIEWED,
          })
        }
      })
    }, { threshold: 1 })
    this.setState({ observer: obs })
    if(this.state.targetRef?.current) {
      obs.observe(this.state.targetRef?.current)
    }
  }

  componentWillUnmount() {
    if(this.state.targetRef?.current) {
      this.state.observer?.unobserve(this.state.targetRef?.current)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { shouldLoadSection } = this.props
    if (
      nextProps.shouldLoadSection !== shouldLoadSection &&
      nextProps.shouldLoadSection
    ) {
      this.handleRetryClick()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState)
  }

  handleRetryClick = () => {
    const { dispatch } = this.props
    dispatch(Actions.getBestSellers())
  }

  handleLoadMore = () => {
    const { dispatch } = this.props
    dispatch(Actions.getNextPageBestSellers())
  }

  render() {
    const { bestSellers, shouldLoadSection } = this.props
    if (!shouldLoadSection) {
      return null
    }

    const hasMorePages = bestSellers.current_page < bestSellers.pages
    const hasNoBestSellers = bestSellers.products.length === 0
    let bestSellersDOM = <ProductLoaderPanel isGrid={ false } isWidget />

    if (!hasNoBestSellers) {
      bestSellersDOM = (
        <BestSellersDOM
          onLoadMore={ hasMorePages ? this.handleLoadMore : false }
          threshold={ InfiniteLoad.offset.grid }
          lastLoadedPage={ bestSellers.current_page }
          bestSellers={ bestSellers }
          orientation={ Orientation.HORIZONTAL }
        />
      )
    } else if (bestSellers.promiseState === PromiseState.FULFILLED) {
      bestSellersDOM = null
    } else if (
      bestSellers.promiseState === PromiseState.REJECTED &&
      hasNoBestSellers
    ) {
      bestSellersDOM = (
        <div className="product-container__retry-msg">
          Oops! We had trouble connecting...
          <Button
            className="product-container__retry-btn btn--transparent"
            onClick={ this.handleRetryClick }
          >
            RETRY
          </Button>
        </div>
      )
    }

    return (
      bestSellersDOM ?
        (<div className="bestSellers-panel" ref={this.state.targetRef}>
          <div className="header-title">Popular Across Site</div>
          { bestSellersDOM }
         </div>) :
        null
    )
  }
}

export default connect(state => ({
  bestSellers: state.pageData.bestSellers,
}))(BestSellersPanel)
