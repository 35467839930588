import OAuth from 'library/oauth'
import Actions from 'store/actions'
import Analytics from 'library/analytics'
import Event from 'library/analytics/eventFactory'
import { LoginState } from 'constants/enums'

/* eslint-disable jsdoc/check-param-names */
/**
 * Attempts to automatically login a
 * user through OAuth, provided —
 *
 * 1. User is signed into the provider
 * 2. User has previously authorized
 * the app with required permissions
 *
 * @param {Object} store — A copy of global redux store
 */
/* eslint-enable jsdoc/check-param-names */
export default function attemptAutoLogin(store) {
  const { dispatch } = store
  const oauth = new OAuth({
    googleClientId: GlobalKey.googleClientId,
    facebookAppId: GlobalKey.facebookAppId,
  })

  const fireLoginAnalytics = () => {
    Analytics.genericEvent({
      category: Event.category.AUTO_LOGIN,
      action: Event.action.LOGIN_SUCCESS,
      nonInteraction: true,
    })

    Analytics.identify(LoginState.LOGGED_IN, store.getState().user.auth)
  }
}
