import Spree from 'api/spree'
import { PromiseState, LoginState } from 'constants/enums'

/* =============
 -   ACTIONS   -
 ============= */

export function setAuthInformation(info) {
  return {
    type: 'SET_AUTH_INFO',
    payload: info,
  }
}

export function login(params) {
  const spree = new Spree()
  return dispatch =>
    dispatch({
      type: 'USER_LOGIN',
      payload: spree.auth.login(params),
    }).then(() => {
      const cookieInfo = spree.auth.getAuthInfoFromCookies()
      return dispatch(setAuthInformation(cookieInfo))
    })
}

export function loginWithOAuth(params) {
  const spree = new Spree()
  return dispatch =>
    dispatch({
      type: 'USER_LOGIN_OAUTH',
      payload: spree.auth.loginWithOAuth(params),
    }).then(() => {
      const cookieInfo = spree.auth.getAuthInfoFromCookies()
      return dispatch(setAuthInformation(cookieInfo))
    })
}

export function logout() {
  const spree = new Spree()
  return {
    type: 'USER_LOGOUT',
    payload: spree.auth.logout(),
  }
}

export function signup(params) {
  const spree = new Spree()
  return {
    type: 'USER_SIGNUP',
    payload: spree.auth.signup(params),
  }
}

/* =============
 -  REDUCERS   -
 ============= */
const initialState = {
  loginState: LoginState.NEVER_LOGGED_IN,
  logout: {},
}

export function auth(state = initialState, action) {
  switch (action.type) {
    case 'USER_LOGIN_PENDING':
    case 'USER_LOGIN_OAUTH_PENDING':
      return {
        ...state,
        promiseState: PromiseState.PENDING,
      }

    case 'USER_LOGIN_FULFILLED':
    case 'USER_LOGIN_OAUTH_FULFILLED':
      return {
        ...state,
        promiseState: PromiseState.FULFILLED,
        loginState: LoginState.LOGGED_IN,
        ...action.payload,
      }

    case 'USER_LOGIN_REJECTED':
    case 'USER_LOGIN_OAUTH_REJECTED':
      return {
        ...state,
        promiseState: PromiseState.REJECTED,
      }

    case 'USER_LOGOUT_FULFILLED':
      return {
        ...state,
        loginState: LoginState.LOGGED_OUT,
        logout: {
          promiseState: PromiseState.FULFILLED,
        },
      }

    case 'USER_SIGNUP_PENDING':
      return {
        ...state,
        promiseState: PromiseState.PENDING,
      }

    case 'USER_SIGNUP_FULFILLED':
      return {
        ...state,
        promiseState: PromiseState.FULFILLED,
        ...action.payload,
      }

    case 'USER_SIGNUP_REJECTED':
      return {
        ...state,
        promiseState: PromiseState.REJECTED,
      }

    case 'SET_AUTH_INFO':
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}
