/* eslint-disable sort-class-members/sort-class-members */
import Endpoints from 'settings/endpoints'
import BaseAPI from '../../BaseAPI'

/**
 * A key-value store of client configurations.
 */
export default class RazorpayOffer extends BaseAPI {
  get() {
    return this.client.get(Endpoints.razorpayOffers, {})
  }
}
