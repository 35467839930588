import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types';

import Ripple from 'units/Ripple'
import './HamburgerIcon.scss'

const HamburgerIcon = ({ isActive, onClick, ...others }) => (
  <Ripple centered { ...others } onClick={ onClick } >
    <button
      className={ classNames('hamburger', 'hamburger--arrow', { 'is-active': isActive }) }
      type="button"
    >
      <span className="hamburger-box">
        <span className="hamburger-inner" />
      </span>
    </button>
  </Ripple>
)

HamburgerIcon.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
}

export default HamburgerIcon
