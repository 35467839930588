// key "in_stock" not camel-cased to match API response
/* eslint-disable camelcase, react/no-unknown-property */

import classNames from 'classnames'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

import { AspectRatio } from 'constants/enums'
import Unicode from 'constants/unicode'
import DiscountRibbon from 'components/DiscountRibbon'
import Analytics from 'library/analytics'
import Event from 'library/analytics/eventFactory'
import { addUrlParams, shouldProxyLink } from 'library/utils'
import { parseProductName } from 'library/utils/product'
import CssConfig from 'stylesheets/settings/config.scss'
import Button from 'units/Button'
import Href from 'units/Href'
import Img from 'units/Img'
import Config from 'settings/config'
import { WishlistIconButton } from 'components/ProductCard/WishlistButton'
import { Size } from 'constants/enums'

import './ProductCard.scss'

/**
 * Height of a product card should always
 * be in sync with product loader card
 */
class ProductCard extends PureComponent {
  static propTypes = {
    cardData: PropTypes.object.isRequired,
    className: PropTypes.string,
    srcParam: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
    ]).isRequired,
    isWidget: PropTypes.bool,
    onWishlistClick: PropTypes.func,
    showWishlistButton: PropTypes.bool,
    context: PropTypes.object.isRequired,
    onOOSNotifyClick: PropTypes.func,
    taxon: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      permalink: PropTypes.string,
    }),
    onProductClickedEvent: PropTypes.func,
    pageTitle: PropTypes.string,
  };

  static contextTypes = {
    pageType: PropTypes.string.isRequired,
    widgetName: PropTypes.string,
  };

  getLeftTag() {
    const { cardData } = this.props
    return cardData.discount_tag && cardData.discount_tag !== '' && (
      <DiscountRibbon
        className="product-card__discount-ribbon"
        discountText={ cardData.discount_tag }
      />
    )
  }

  getRightTag() {
    const { cardData, onWishlistClick, showWishlistButton, cardIndex } = this.props

    return showWishlistButton && (
      <div className="product-card__tag-right">
        <WishlistIconButton
          onClick = {(e) => {
            onWishlistClick(e)
            if(!cardData?.isInWishlist) {
              Analytics.genericEvent({
                event_name: Event.eventName.ADD_TO_WISHLIST,
                category: Event.category.ADD_TO_WISHLIST,
                action: Event.action.CLICKED,
                items: [cardData],
                card_index: cardIndex,
                currency: 'INR'
              })
            }
          }}
          isInWishlist = { cardData.isInWishlist  }
          variantId = { cardData.id }
          size = { Size.SMALL }
          className = "product-card__tag-wishlist-btn"
        />
      </div>
    )
  }
  getRightBottomTag() {
    const { cardData } = this.props
    const tagData = cardData.tag
    return tagData && (
      <div className="product-card__tag-right-bottom">
        { tagData }
      </div>
    )
  }

  getPriceValidDate() {
    const currentDate = new Date()
    const newDate = currentDate.setDate(currentDate.getDate() + 30)
    const date = new Date(newDate)
    return (`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`)
  }

  handleImageClick = (productUrl) => {
    const { widgetName, pageType } = this.context
    const { cardData, onProductClickedEvent } = this.props

    Analytics.genericEvent({
      category: Event.category.getCardClickCategory(pageType, widgetName),
      action: Event.action.PRODUCT_CARD_IMAGE_CLICK,
      label: cardData.name,
      productUrl: productUrl
    })

    onProductClickedEvent()
  };

  handleProductDataClick = () => {
    const { widgetName, pageType } = this.context
    const { cardData, onProductClickedEvent } = this.props

    Analytics.genericEvent({
      category: Event.category.getCardClickCategory(pageType, widgetName),
      action: Event.action.PRODUCT_CARD_DATA_CLICK,
      label: cardData.name,
    })

    onProductClickedEvent()
  };

  handleNotifyMeClick = () => {
    const { cardData, onOOSNotifyClick } = this.props
    onOOSNotifyClick({ sku: cardData.sku })
  };

  render() {
    const {
      cardData, className, isWidget, srcParam, context, showWishlistButton,
      onWishlistClick, taxon, pageTitle
    } = this.props

    var { productName, variantName } = parseProductName(cardData.name)
    var detailedProductName = cardData.display_detailed_name

    const brandName = cardData.brand_name

    const emiFrom = !cardData.price_prefix && cardData.emi_from ?
      `(EMI ${cardData.emi_from})` : Unicode.NO_BREAK_SPACE
    const productUrl = addUrlParams(
      cardData.path || cardData.url,
      { src: srcParam },
    )
    const { in_stock = true, pre_order_status = false } = cardData
    const purchasable = in_stock || pre_order_status
    const { isWebView } = context

    const priceValidUntil = this.getPriceValidDate()
    const permalink = taxon ? taxon.permalink : ''
    const image_alt = pageTitle + " Design " + cardData.name
    const image_title = pageTitle + " Furniture " + cardData.name
    const image_description = "Check out Urban Ladder " + cardData.name + " Designs Online " + pageTitle + " Types with Price"

    var productCardName_productName = 'product-card__name';
    var productName_lineCount = 1
    if(detailedProductName){
      productName = detailedProductName
      productCardName_productName = 'product-card__name-line_2'
      productName_lineCount = 2
    }

    return (
      <div
        className={ classNames('product-card', className, { 'product-card--widget': isWidget }) }
        itemScope
        itemType="http://schema.org/Product"
        onClick={() => {
          Analytics.genericEvent({
            category: Event.category.PRODUCT_CARD_DATA,
            action: Event.action.CLICKED,
            productData: cardData
          })
        }}
      >
        <div itemProp="brand" itemType="http://schema.org/Thing" itemScope>
          <meta itemProp="name" content="Urban Ladder" />
        </div>
        <meta itemProp="mpn" content={ cardData.sku } />
        <meta itemProp="sku" content={ cardData.sku } />
        <meta itemProp="description" content={ cardData.images.alt } />
        <meta itemProp="itemCondition" content="https://schema.org/NewCondition" />

        <div className="product-card__image-block">
          { this.getLeftTag() }
          <Href
            className="product-card__image-link"
            to={ productUrl }
            itemProp="url"
            isProxied={ isWebView || shouldProxyLink(productUrl) }
            onClick={ () => this.handleImageClick(productUrl) }
          >
            <Img
              maxWidth={ CssConfig.mdScrMinWidth }
              aspectRatio={ AspectRatio.SQUARE }
              src={ cardData.images.url }
              alt={ image_alt }
              faded={ !purchasable }
              itemProp="image" 
              title={image_title}
              description={image_description}
            />
            { this.getRightBottomTag() }
          </Href>
          { this.getRightTag() }
          { !purchasable &&
            <div className="product-card__notify-btn">
              <Button
                onClick={ this.handleNotifyMeClick }
                type={ Button.type.SECONDARY }
              >
                Notify Me
              </Button>
            </div> }
        </div>
        <Href
          className="product-card__data"
          to={ productUrl }
          isProxied={ isWebView || shouldProxyLink(productUrl) }
          onClick={ this.handleProductDataClick }
        >
          <div itemProp="name">
            { Config.productPermalink.includes(permalink) ?
              (<h2 className={ productCardName_productName }>{ productName }</h2>) :
                (<div className={ productCardName_productName }>{ productName }</div>)
            }
            { variantName && productName_lineCount < 2 && (
              <div className="product-card__variant-name">{ variantName }</div>
            ) }
            { brandName && (
              <div className="product-card__brand-name">{ brandName }</div>
            ) }
          </div>
          <div
            className="product-card__price"
            itemProp="offers"
            itemScope
            itemType="http://schema.org/Offer"
          >
            {
              purchasable ?
                <link itemType="http://schema.org/InStock" itemProp="availability" /> :
                <link itemType="http://schema.org/OutOfStock" itemProp="availability" />
            }
            <meta itemProp="priceCurrency" content="INR" />
            <meta itemProp="price" content={ cardData.discount_price } />
            <meta itemProp="priceValidUntil" content={ priceValidUntil } />
            { cardData.price_prefix && <span className="product-card__price--prefix">
              { cardData.price_prefix } &nbsp;
                                       </span> }
            { cardData.discount_price !== cardData.price && <strike>
              { cardData.display_price }
                                                            </strike> }
            <span className="product-card__price--value">
              { cardData.display_discount_price }
            </span>
            <div className="product-card__emi">{ emiFrom }</div>
          </div>
        </Href>
      </div>
    )
  }
}

const connectedProductCard = connect(state => ({
  context: state.user.context,
}))(ProductCard)

export default connectedProductCard
