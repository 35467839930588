import Spree from 'api/spree'
import { PromiseState } from 'constants/enums'
import Actions from 'store/actions'

/* =============
 -   ACTIONS   -
 ============= */

export function get(setsSlug) {
  const spree = new Spree()

  return dispatch => dispatch({
    type: 'GET_SET_DETAILS',
    payload: spree.product.details.get(setsSlug),
  }).then(({ value: setDetails }) => {
    const partsSKUs = setDetails.data.variants[0].parts.map(part => part.sku)
    return dispatch(Actions.getPartsDetails(partsSKUs))
  })
}

export function getPartsDetails(skus) {
  const spree = new Spree()

  return {
    type: 'GET_PARTS_DETAILS',
    payload: spree.variants.details(skus, { sort: 'price_desc' }),
  }
}

/* =============
 -  REDUCERS   -
 ============= */
export function details(state = {}, action) {
  switch (action.type) {
    case 'GET_SET_DETAILS_PENDING':
      return {
        ...state,
        promiseState: PromiseState.PENDING,
      }

    case 'GET_SET_DETAILS_FULFILLED': {
      const setDetails = {
        ...action.payload.data,
      }
      setDetails.product = setDetails.variants[0]
      delete setDetails.variants
      return {
        ...state,
        ...setDetails,
      }
    }

    case 'GET_SET_DETAILS_REJECTED':
      return {
        ...state,
        promiseState: PromiseState.REJECTED,
        error: action.payload,
      }

    case 'GET_PARTS_DETAILS_PENDING':
      return {
        ...state,
        promiseState: PromiseState.PENDING,
      }

    case 'GET_PARTS_DETAILS_FULFILLED': {
      return {
        ...state,
        promiseState: PromiseState.FULFILLED,
        partsDetails: {
          ...action.payload,
        },
      }
    }

    case 'GET_PARTS_DETAILS_REJECTED':
      return {
        ...state,
        promiseState: PromiseState.REJECTED,
        error: action.payload,
      }

    default:
      return state
  }
}
