
/* =============
 -   ACTIONS   -
 ============= */

export function saveContextInformation(ctx) {
  return {
    type: 'SAVE_CONTEXT_INFO',
    payload: ctx,
  }
}


/* =============
 -  REDUCERS   -
 ============= */

export function context(state = { isWebView: false }, action) {
  switch (action.type) {
    case 'SAVE_CONTEXT_INFO':
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}
