import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types';
import connect from 'react-redux/lib/components/connect'

import Href from 'units/Href'
import ToggleContent from 'components/ToggleContent'
import Img from 'units/Img'

import { ContentBlock as ContentBlockConstant } from 'constants/enums'
import { TextCrop } from 'settings/values'
import Config from 'settings/config'

import { cleanUpSnippetLinks } from 'library/utils'

import './ContentBlock.scss'

const ContentBlock = ({
  contentBlocks, className, platform, position, toggleContent, isProxied, onClick=()=>{}
}) => {
  let contentBlockDOM = null

  if (contentBlocks[position]) {
    const data = contentBlocks[position][platform]
    if (data) {
      switch (data.type) {
        case ContentBlockConstant.type.SNIPPET:
          if (data.body) {
            const contentDOM = (
              <div
                className={ classNames(
                  className,
                  'content-block',
                  position.toLowerCase(),
                ) }
                dangerouslySetInnerHTML={ {
                  __html: cleanUpSnippetLinks(data.body),
                } }
              />
            )
            const toggleHeight =
              toggleContent && data.body.indexOf('expanded-filters') === -1 ?
                TextCrop[position] :
                undefined

            contentBlockDOM = toggleHeight ?
              (<ToggleContent toggleHeight={ toggleHeight }>
                {contentDOM}
               </ToggleContent>) :
              contentDOM
          }
          break

        case ContentBlockConstant.type.UPLOAD:
          if (data.src) {
            const contentDOM = (
              <Img
                className={ classNames(
                  className,
                  'content-block__upload',
                ) }
                src={ data.src }
                alt={ data.title }
                height={ data.attachment_height }
                width={ data.attachment_width }
                imgOptions={ Config.thumborImageOptions }
                onClick={ onClick }
                imgData={data}
                imgPosition={position}
              />
            )

            contentBlockDOM = data.target_url ?
              (
                <Href className="href_wrapper" to={ data.target_url } isProxied={ !!isProxied }>
                  { contentDOM }
                </Href>
              ) : contentDOM
          }
          break

        default:
          contentBlockDOM = null
      }
    }
  }

  return contentBlockDOM
}

ContentBlock.propTypes = {
  contentBlocks: PropTypes.object.isRequired,
  className: PropTypes.string,
  platform: PropTypes.oneOf(Object.values(ContentBlockConstant.platform))
    .isRequired,
  position: PropTypes.string.isRequired,
  toggleContent: PropTypes.bool,
  isProxied: PropTypes.bool,
}

ContentBlock.defaultProps = {
  toggleContent: false,
}

export default connect(state => ({
  contentBlocks: state.pageData.contentBlocks,
}))(ContentBlock)
