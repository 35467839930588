import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types';

import Size from './Size'
import Type from './Type'
import Fill from './Fill'

import './Button.scss'

const Button = ({
  className,
  children,
  disabled,
  isSubmit,
  onClick,
  size,
  type,
  fill,
  hasFullWidth,
}) => (
  <button
    onClick={ onClick }
    className={ classNames(
      className,
      'btn',
      `btn--${size}`,
      `btn--${type}`,
      { 'btn--bordered': fill === Fill.NONE || fill === Fill.TRANSLUCENT },
      { 'btn--translucent': fill === Fill.TRANSLUCENT },
      { 'btn--full-width': hasFullWidth },
    ) }
    type={ isSubmit ? 'submit' : 'button' }
    disabled={ disabled }
  >
    { children }
  </button>
)

Button.fill = Fill
Button.type = Type
Button.size = Size


Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isSubmit: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(Object.values(Size)),
  type: PropTypes.oneOf(Object.values(Type)),
  fill: PropTypes.oneOf(Object.values(Fill)),
  hasFullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
}

Button.defaultProps = {
  size: Size.REGULAR,
  type: Type.PRIMARY,
  fill: Fill.FULL,
  hasFullWidth: false,
  isSubmit: false,
  disabled: false,
}

export default Button
