/**
 * Enums are lists of value-less constants.
 * If the values represent some kind of numeric or textual data,
 * you might want to store them in config or elsewhere.
 * Lets keep the list alphabetized.
 */

export const Align = {
  CENTER: 'center',
  LEFT: 'left',
  RIGHT: 'right',
}

export const AspectRatio = {
  SIXTEEN_NINE: '16-9',
  SQUARE: '1-1',
  TWO_ONE: '2-1',
}

export const WebviewInvoiceDownloadSupportMinVersion = {
  Android: '6.5.0',
  iOS: '5.5.0',
}

export const ContentBlock = {
  platform: {
    ALL: 'ALL',
    APP: 'APP',
    APP_ANDROID: 'APP_ANDROID',
    APP_IOS: 'APP_IOS',
    DESKTOP_WEB: 'DESKTOP_WEB',
    EMAIL: 'EMAIL',
    MOBILE_WEB: 'MOBILE_WEB',
    WEB: 'WEB',
  },
  position: {
    AFTER_BREADCRUMB: 'AFTER_BREADCRUMB',
    AFTER_PAGEHEADER: 'AFTER_PAGEHEADER',
    BEFORE_BREADCRUMB: 'BEFORE_BREADCRUMB',
    FOOTER_SECTION_1: 'FOOTER_SECTION_1',
    SECTION1: 'SECTION1',
    SECTION2: 'SECTION2',
    SECTION3: 'SECTION3',
    SECTION_1: 'SECTION_1',
    SECTION_2: 'SECTION_2',
    SECTION_3: 'SECTION_3',
    SECTION_4: 'SECTION_4',
    SECTION_TOP: 'SECTION_TOP',
  },
  type: {
    SNIPPET: 'snippet',
    UPLOAD: 'upload',
  },
}

export const ClientConfiguration = {
  APP_DOWNLOAD_SHEET_TIMEOUT: 'mweb_app-download-bottom-sheet_timeout',
  EMAIL_SIGNUP_SHEET_TIMEOUT: 'mweb_email-signup-bottom-sheet_timeout',
  GIFT_CARD_CONFIG: 'gift_card_config',
  SOFA_TRIAL_CITIES: 'sofa_trial_cities',
  SHOW_PRODUCT_PROPERTIES_ATF: 'show_product_properties_atf',
  GUMSTACK_LOCATION: 'gumstack_location',
  UL_PHONE_NO: 'ul_phone_number',
  OPEN_FILTERS: 'birbul_open_filters'
}

export const CustomEvents = {
  OPEN_SIDEBAR: 'open_sidebar',
}

export const Direction = {
  DOWN: 'down',
  LEFT: 'left',
  RIGHT: 'right',
  UP: 'up',
}

export const ProductPropertiesButtonTemplate = {
  MORE_INFORMATION: 'More Information ',
  LESS_INFORMATION: 'Less Information ',
}

export const DeviceOS = {
  ANDROID: 'Android',
  IOS: 'iOS',
  WINDOWS_PHONE: 'Windows Phone',
  UNKNOWN: 'Unknown',
}

/**
 * @enum {string}
 */
export const LoginState = {
  LOGGED_IN: 'logged-in',
  LOGGED_OUT: 'logged-out',
  NEVER_LOGGED_IN: 'never-logged-in',
}

export const ProductOptionGroupType = {
  GENERIC: 'generic',
  SET: 'set',
  SWATCH: 'swatch',
}

export const Orientation = {
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL',
}

export const Page = {
  LEAF_LISTING: 'leaf-listing',
  NON_LEAF_LISTING: 'non-leaf-listing',
  NOT_FOUND: '404',
}

export const Platform = {
  ANDROID: 'Android',
  IOS: 'iOS',
  UNKNOWN: 'Unkown',
}

export const Position = {
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
  TOP: 'top',
}

export const ProductTemplateType = {
  CONFIGURABLE_SOFA: 'Configurable Sofa',
  SOFA: 'Sofa',
}

export const PromiseState = {
  FULFILLED: 'fulfilled',
  PENDING: 'pending',
  REJECTED: 'rejected',
}

export const Size = {
  FREE: 'free',
  LARGE: 'large',
  MEDIUM: 'medium',
  REGULAR: 'regular',
  SMALL: 'small',
  TINY: 'tiny',
}

export const SrcParam = {
  bestSellers: 'bestSellers',
  homePageBanner: 'home-banner',
  homePageCategory: 'home-cat',
  leafListing: 'mobile_subcat',
  mobileBreadcrumb: 'mobile_breadcrumb',
  mobileSearch: 'mobile_search',
  nonLeafListing: 'mobile_cat', // TODO: cat_1, cat_2 based on the levels in taxonomy tree
  sidebarPrefix: 'g_topnav',
  testimonial: 'testimonial',
  trackOrder: 'track-order',
  productDetails: 'product-details',
  productPageOffers: 'product_page_offers',
  similarProducts: 'prod_related',
  wishlist: 'room',
  storesListing: 'stores_listing',
  priceListWidget: 'price-list-widget',
}

export const Status = {
  CANCELLED: 'cancelled',
  ERROR: 'error',
  FAILED: 'failed',
  LOADED: 'loaded',
  PENDING: 'pending',
  SUCCESS: 'success',
}

export const ProductDetails = 'PRODUCT DETAILS'

export const Upload = {
  EMAIL_SIGNUP_SHEET: 'Mobile/email_signup_sheet',
}

export const View = {
  GRID: 'grid',
  LIST: 'list',
}

export const SubscriptionType = {
  TAXON: 'taxon',
  PRODUCT: 'product',
}

export const AuthenticationProvider = {
  UL_SITE: 'ul_site',
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
}

export const ProfileActionsList = {
  orders: 'Orders',
  track_order: 'Track Order',
  vouchers: 'Vouchers',
  profile: 'Profile',
  logout: 'Logout',
  login: 'Login',
}

export const CTA = {
  warranty_know_more: 'Know More',
}

export const Operation = {
  append: 'append',
  prepend: 'prepend',
  none: 'none,'
}

export const PaginatedCategories = [
  '/carpet', '/decorative-lights', '/ceiling-lights', '/floor-lamps',
   '/wall-decors', '/wall-art', '/table-lamps',
   '/wall-clocks', '/showpieces', '/window-curtains', 
]
