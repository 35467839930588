import { combineReducers } from 'redux'

import {
  get as getBestSellers,
  getNextPage as getNextPageBestSellers,
  bestSellers,
  init as initBestSellers,
} from './bestSellersStore'
import {
  getPersonalizedContentBlocks,
  contentBlocks
} from './contentBlocksStore'
import {
  getSeoBestSellers,
  seoBestSellers,
} from './seoBestSellersStore'
import {
  get as getTestimonials,
  getNextPage as getNextPageTestimonials,
  init as initTestimonials,
  testimonials,
} from './testimonialsStore'

/* =============
 -   ACTIONS   -
 ============= */

const actions = {
  getBestSellers,
  getNextPageBestSellers,
  initBestSellers,
  getPersonalizedContentBlocks,
  getTestimonials,
  getNextPageTestimonials,
  initTestimonials,
  getSeoBestSellers,
}

/* =============
 -  REDUCERS   -
 ============= */

const reducers = combineReducers({
  bestSellers,
  contentBlocks,
  testimonials,
  seoBestSellers,
})

export default {
  actions,
  reducers,
}
