import Endpoints from 'settings/endpoints'
import BaseAPI from '../../BaseAPI'

export default class Info extends BaseAPI {
  get(slug) {
    const params = {
      slug,
    }
    return this.client.get(Endpoints.product.info, params)
  }
}
