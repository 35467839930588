import React from 'react';
import { Notifier } from '@airbrake/browser';

class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.env = GlobalKey.airbrakeEnv,
    this.airbrake = new Notifier({
      projectId: GlobalKey.airbrakeProjectID,
      projectKey: GlobalKey.airbrakeProjectKey,
      environment: this.env,
    });
    this.airbrake.addFilter((notice) => {
      let env = notice.context.environment
      if (env == 'production' || env == 'staging') {
        return notice;
      }
      return null;
    });
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });    // Send error to Airbrake
    this.airbrake.notify({
      error: error,
      params: {
        info: info
      },
    });
  }

  render() {
    return this.props.children;
  }
}
export default ErrorBoundary;
