import { PromiseState } from 'constants/enums'

/**
 * Execute promises in order at a given concurrency level.
 */
export class PromiseQueue {
  constructor({ concurrency }) {
    this.concurrency = concurrency
    this.promises = []
  }

  getPendingPromises() {
    return this.promises
      .filter(promise => promise.state === PromiseState.PENDING)
  }

  getNextPromiseIndex() {
    return this.promises
      .findIndex(promise => promise.state === 'ready')
  }

  add(promiseExecutor) {
    this.promises.push({
      executor: promiseExecutor,
      state: 'ready',
    })

    this.exec()
  }

  exec() {
    while (this.getPendingPromises().length < this.concurrency) {
      const nextPromiseIndex = this.getNextPromiseIndex()

      if (nextPromiseIndex === -1) {
        return
      }

      this.promises[nextPromiseIndex].state = PromiseState.PENDING
      this.promises[nextPromiseIndex].executor()
        .then(() => {
          this.promises[nextPromiseIndex].state = PromiseState.FULFILLED
          this.exec()
        })
        .catch((err) => {
          console.error(err)
          this.promises[nextPromiseIndex].state = PromiseState.REJECTED
          this.exec()
        })
    }
  }
}
