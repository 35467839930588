/**
 * A store for all static links
 */
/* eslint-disable max-len */
export default {
  APP_STORE_DOWNLOAD: 'https://itunes.apple.com/us/app/urban-ladder/id959886087',
  PLAY_STORE_DOWNLOAD: 'https://play.google.com/store/apps/details?id=com.urbanladder.catalog',
  SOFA_REVIEWS_VIDEO_SLUG: 'EDnvDZ-0990',
  EXCHANGE_PAGE_URL: '/exchange-your-old-furniture',
  CANCELLATION_REASONS_PAGE_URL: 'https://www.urbanladder.com/ineligible-cancellation',
  APSALAR: {
    android:
      pl => PLAY_STORE_DOWNLOAD,
    iOS:
      pl => APP_STORE_DOWNLOAD,
  },
  giftCard: {
    CORPORATE_GIFT_CARD_FORM: 'https://goo.gl/forms/Lmh0Fbw9iKxtzrpO2',
    TNC_LINK: '/gift-card-voucher-tnc',
  },
  CREDIT_CARD_EMI_TERMS: '/emi-credit-cards',
  BFL_TERMS: '/security-payments#bajajfinserve',
  WISHLIST_SHARE_TWITTER: 'http://twitter.com/intent/tweet',
  WISHLIST_SHARE_FACEBOOK: 'http://www.facebook.com/share.php?u=https://www.urbanladder.com/wishlists/',
}
