/* eslint-disable no-confusing-arrow */

export default {
  // New Events
  CATEGORY: 'category',
  PRODUCT: 'product',
  ADD_TO_CART: 'add_to_cart',
  ADD_TO_WISHLIST: 'add_to_wishlist',
  POPULAR_CATEGORIES: 'popular_categories',

  // Common
  SIDEBAR: 'sidebar',
  ALL: 'all',
  APP_DOWNLOAD_BANNER: 'app_download_banner',
  APP_DOWNLOAD: 'app_download',
  SERVICES: 'services',
  WISHLIST: 'wishlist',
  PDP: 'product_display_page',
  PLP: 'product_listing_page',
  CONTENT_LABEL: 'content_label',

  // Header
  OPEN_SIDEBAR: 'sideBar_opened',
  SIDEBAR_DROPDOWN: 'sidebar_dropdown',
  HELP_ICON: 'help',
  WISHLIST_ICON: 'wishlist',
  PROFILE_ICON: 'profile',
  CART_ICON: 'cart',
  SIDEBAR_ARROW: 'sidebar_dropdown',

  // Other Pages
  GIFT_CARD_PURCHASE: 'gift_card_purchase',
  ORDER_CANCELLATION: 'order_cancellation',
  SOFA_TRIAL: 'sofa_home_trial',
  STORE_DETAILS: 'store_details',
  SEE_ALL_STORES: 'see_all_stores',
  FURNITURE_STORES: 'furniture Stores',
  WARRANTY_KNOW_MORE: 'warranty_know_more',
  CUSTOMER_STORIES: 'customer_stories',
  SOCIAL_MEDIA_FOOTER: 'social_media_footer',
  SIMILAR_PRODUCTS: 'similar_products',
  WE_OFFER: 'we_offer',
  SEARCH: 'search',
  FAQ: 'faqs',
  POPULAR_ACROSS_SITE: 'popular_across_site',
  PRODUCT_CARD_DATA: 'product_card',
  LISTING_PAGE_BANNER: 'listing_page_banner',

  // Listing / Search
  getCardViewModeCategory: pageType => `${pageType}_switch_view`,
  getCardClickCategory:
    (pageType, widgetName) => widgetName ? `${pageType}_${widgetName}` :
      pageType,

  // Login / Signup
  AUTO_LOGIN: 'auto_login',
  EMAIL_SIGNUP_BANNER: 'mobile_home_email_banner',
  EMAIL_SIGNUP_SHEET: 'mobile_email_bottom_sheet',
  LOGIN: 'login',
  LOGIN_BOTTOM_SHEET: 'login_sheet',
  LOGIN_PAGE: 'login _page',

  // Product Page
  DELIVERY_SLA: 'delivery sla',
  DELIVERY_SLA_CHANGE: 'delivery_sla_change',
  PRODUCT_PROPERTIES: 'product_properties',
  PRODUCT_IMAGE_SLIDESHOW: 'product_image_slideshow',
  PRODUCT_DIMENSIONS_SLIDESHOW: 'product_dimensions_slideshow',
  PRODUCT_IMAGE_SLIDE: 'product_image_slide',
  PRODUCT_DIMENSIONS_SLIDE: 'product_dimensions_slide',
  SET_CONFIGURATION: 'set_configuration',
  OFFER_DETAILS: 'offer_details',
  VARIANT_SELECTED: 'variant_selection',
  VIEW_PRODUCT_DETAILS: 'view_more/less_information',

  //Accordion
  TOPLEVEL_ACCORDION: 'top_level_accordion',
  LENDER_ACCORDION: 'lender_accordion',

  VIDEO_CALL_SCHEDULE: 'video_call_schedule',

  // Filter
  FILTERS_APPLIED: 'apply_filters',
  EXPOSED_FILTERS: 'exposed_filters',

  // Sort
  SORT_BY: 'sort_by',
  VIEW_BY_PLP: 'view_mode',
}
