import GoogleOAuth from './googleOAuth'
import FacebookOAuth from './facebookOAuth'

export default class OAuth {

  constructor({ googleClientId, facebookAppId }) {
    this.google = new GoogleOAuth(googleClientId)
    this.facebook = new FacebookOAuth(facebookAppId)
  }

  /**
   * Asynchronously loads the javascript SDKs
   * of various oauth providers in a parallel manner.
   *
   * @returns {Promise}
   */
  loadProviders() {
    return Promise.all([
      this.google.loadProvider(),
      this.facebook.loadProvider(),
    ])
  }

  /**
   * Initializes the providers with correct ids.
   *
   * @returns {Promise}
   */
  initProviders = () => Promise.all([
    this.facebook.init(),
    this.google.init(),
  ]);
}
