import DeliverySLA from './deliverySLA'
import Details from './details'
import Info from './info'
import Similar from './similar'
import AvailableStores from './availableStores'

export default {
  Details,
  DeliverySLA,
  Info,
  Similar,
  AvailableStores,
}
