import MrEavesWOFF2 from 'fonts/eaves/woff2/mr-eaves-xl-modern-bold.woff2'
import MrEavesWOFF from 'fonts/eaves/woff/mr-eaves-xl-modern-bold.woff'
import MrEavesTTF from 'fonts/eaves/ttf/mr-eaves-xl-modern-bold.ttf'
import Typography from '../stylesheets/base/typography.scss'

export default function loadMediumFont() {
  const newStyle = document.createElement('style')
  newStyle.appendChild(document.createTextNode(`
  @font-face {
    font-family: 'Mr Eaves XL Modern';
    font-style: normal;
    font-weight: ${Typography.fontWeightBold};
    src: local('Mr Eaves XL Modern'),
         url(${MrEavesWOFF2}) format('woff2'),
         url(${MrEavesWOFF}) format('woff'),
         url(${MrEavesTTF}) format('truetype');
    font-display: optional;
  }
  `))

  document.head.appendChild(newStyle)
}

