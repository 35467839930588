import Spree from 'api/spree'
import { PromiseState } from 'constants/enums'

/* =============
 -   ACTIONS   -
 ============= */

export function getNavigation() {
  const spree = new Spree()

  return {
    type: 'GET_NAVIGATION',
    payload: spree.navigation.get(),
  }
}

export function getInterlinks(permalink) {
  const spree = new Spree()

  return {
    type: 'GET_INTERLINKS',
    payload: spree.interlinks.get(permalink),
  }
}

export function getTaxonDetails(permalink) {
  const spree = new Spree()

  return {
    type: 'GET_TAXON_DETAILS',
    payload: spree.taxon.get(permalink),
  }
}

/* =============
 -  REDUCERS   -
 ============= */

export function navigation(state = {}, action) {
  switch (action.type) {
    case 'GET_NAVIGATION_PENDING':
      return {
        ...state,
        promiseState: PromiseState.PENDING,
      }

    case 'GET_NAVIGATION_FULFILLED':
      return {
        ...state,
        promiseState: PromiseState.FULFILLED,
        ...action.payload,
      }

    case 'GET_NAVIGATION_REJECTED':
      return {
        ...state,
        promiseState: PromiseState.REJECTED,
        error: action.payload,
      }

    default:
      return state
  }
}

export function interlinks(state = {}, action) {
  switch (action.type) {
    case 'GET_INTERLINKS_PENDING':
      return {
        ...state,
        promiseState: PromiseState.PENDING,
      }

    case 'GET_INTERLINKS_FULFILLED':
      return {
        ...state,
        promiseState: PromiseState.FULFILLED,
        categories: action.payload,
      }

    case 'GET_INTERLINKS_REJECTED':
      return {
        ...state,
        promiseState: PromiseState.REJECTED,
        error: action.payload,
      }

    default:
      return state
  }
}

export function taxon(state = {}, action) {
  switch (action.type) {
    case 'GET_TAXON_DETAILS_PENDING':
      return {
        ...state,
        promiseState: PromiseState.PENDING,
      }

    case 'GET_TAXON_DETAILS_FULFILLED':
      return {
        ...state,
        promiseState: PromiseState.FULFILLED,
        ...action.payload,
      }

    case 'GET_TAXON_DETAILS_REJECTED':
      return {
        ...state,
        promiseState: PromiseState.REJECTED,
        error: action.payload,
      }

    default:
      return state
  }
}
