import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types';

import './ProductLoaderCard.scss'

const ProductLoaderCard = ({ isGrid, isWidget }) => (
  <div
    className={ classNames('product-loader-card', {
      'col__lg--1-3 col__md--1-2 col__sm--1-2 product-loader-card--grid': isGrid,
      'product-loader-card--list': !isGrid,
      'product-loader-card--widget': isWidget,
    }) }
  >
    <div className="product-loader-card__content">
      <div className="product-loader-card__image" />
      <div className="product-loader-card__text-content">
        <div className="product-loader-card__l1" />
        <div className="product-loader-card__l2" />
        <div className="product-loader-card__l3" />
      </div>
    </div>
  </div>
)

ProductLoaderCard.propTypes = {
  isGrid: PropTypes.bool.isRequired,
  isWidget: PropTypes.bool,
}

export default ProductLoaderCard
