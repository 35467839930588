/* eslint-disable react/no-unused-prop-types */
/**
 * eslint does not count usage in nextProps, but it should.
 * The error, is therefore, a false positive.
 * @see https://github.com/yannickcr/eslint-plugin-react/issues/944
 */
import React from 'react'
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare'

import Overlay from 'units/Overlay'

import Analytics from 'library/analytics'
import Event from 'library/analytics/eventFactory'
import HistoryState from 'library/utils/historyUtils'

import './SideBar.scss'

export default class SideBar extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    onSideBarClose: PropTypes.func,
    isActive: PropTypes.bool,
    trackOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    category: PropTypes.string,
    label: PropTypes.string,
  };

  constructor(props) {
    super(props)
    this.state = { isActive: props.isActive }
    this.historyState = new HistoryState({
      name: 'sidebar',
      onBackPress: props.onSideBarClose,
    })
  }

  componentDidMount() {
    const { isActive } = this.props
    window.addEventListener('urlchange', this.closeSideBar)

    if (isActive) {
      this.historyState.add()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isActive !== this.state.isActive) {
      this.setState({ isActive: nextProps.isActive })
    }

    if (nextProps.isActive && !this.props.isActive) {
      if (nextProps.trackOpen) {
        const { category, label } = nextProps

        Analytics.genericEvent({
          category: category || Event.category.SIDEBAR,
          action: Event.action.OPEN,
          label: label || '',
        })
      }
      this.historyState.add()
    }

    if (!nextProps.isActive && this.props.isActive) {
      this.historyState.removeIfPossible()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState)
  }

  componentWillUnmount() {
    window.removeEventListener('urlchange', this.closeSideBar)
  }

  closeSideBar = () => {
    this.props.onSideBarClose()
    this.setState({ isActive: false })
    this.historyState.removeIfPossible()
  }

  handleOverlayClick = () => {
    const { onSideBarClose } = this.props
    onSideBarClose()
    this.historyState.removeIfPossible()
  }

  render() {
    const { children, className } = this.props
    const { isActive } = this.state
    return (
      <div>
        <Overlay onClick={ this.handleOverlayClick } isActive={ isActive } />
        <div className={ `full-page sidebar ${className} ${isActive && 'active'}` }>
          { children }
        </div>
      </div>
    )
  }
}
