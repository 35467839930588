import React from 'react'

const HealthCheck = () => {
    return (
      <>
        status: 200
        <br />
        message: healthy!
      </>
    )
  }
  
export default HealthCheck;