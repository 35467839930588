import Spree from 'api/spree'

import { PromiseState } from 'constants/enums'
import {
  isGenericProduct,
  isSofaProduct,
  isConfigurableSofaProduct,
} from 'library/utils/product'
import Actions from 'store/actions'

/* =============
 -   ACTIONS   -
 ============= */

export function get(path, sku) {
  const spree = new Spree()

  return dispatch => dispatch({
    type: 'GET_PRODUCT_DETAILS',
    payload: spree.product.details.get(path, { sku }),
  }).then(({ value: response }) => {
    const productDetails = response.data
    const primaryTaxon = (typeof productDetails === 'undefined') ?
      '' :
      productDetails.primary_taxon.permalink

    if (isConfigurableSofaProduct(productDetails)) {
      return dispatch(Actions.getInterlinks(primaryTaxon))
    }

    let selectedVariantIndex = (() => {
      let obj = []
      if (isGenericProduct(productDetails)) {
        obj = productDetails.variants
      } else if (isSofaProduct(productDetails)) {
        obj = productDetails.sets
      }
      if (sku && obj && obj.length) {
        selectedVariantIndex = obj.findIndex(
          v => v.sku.toLowerCase() === sku.toLowerCase()
        )
      }
      return (selectedVariantIndex > -1 ? selectedVariantIndex : 0)
    })()

    return Promise.all([
      dispatch(Actions.setVariantIndex(selectedVariantIndex)),
      dispatch(Actions.getInterlinks(primaryTaxon)),
    ])
  })
}

export function setVariantIndex(index) {
  return {
    type: 'SET_SELECTED_VARIANT_INDEX',
    index,
  }
}

export function getFaq(params) {
  const spree = new Spree()
  return {
    type: 'GET_FAQ',
    payload: spree.product.details.getFaq(params),
  }
}

export function getSofaConfigurationDetails(path, options) {
  const spree = new Spree()

  return {
    type: 'GET_SOFA_CONFIGURATION_DETAILS',
    payload: spree.product.details.get(path, options),
  }
}

/* =============
 -  REDUCERS   -
 ============= */

export function details(state = {}, action) {
  switch (action.type) {
    case 'GET_PRODUCT_DETAILS_PENDING':
      return {
        ...state,
        selectedVariantIndex: -1,
        promiseState: PromiseState.PENDING,
      }

    case 'GET_PRODUCT_DETAILS_FULFILLED':
      return {
        ...state,
        promiseState: PromiseState.FULFILLED,
        ...action.payload.data,
      }

    case 'GET_PRODUCT_DETAILS_REJECTED':
      return {
        ...state,
        promiseState: PromiseState.REJECTED,
        error: action.payload,
      }

    case 'SPA_LOCATION_CHANGE':
      return {}

    case 'SET_SELECTED_VARIANT_INDEX':
      return {
        ...state,
        selectedVariantIndex: action.index,
      }

    case 'GET_SOFA_CONFIGURATION_DETAILS_FULFILLED':
      return {
        ...state,
        ...action.payload.data,
      }

    default:
      return state
  }
}

export function faqDetails(state = {}, action) {
  switch (action.type) {
    case 'GET_FAQ_FULFILLED':
      return {
        ...state,
        promiseState: PromiseState.FULFILLED,
        ...action.payload,
      }

    case 'GET_FAQ_REJECTED':
      return {
        ...state,
        promiseState: PromiseState.REJECTED,
        error: action.payload,
      }

    case 'GET_FAQ_PENDING':
      return {
        ...state,
        promiseState: PromiseState.PENDING,
      }

    default:
      return state
  }
}
