/* eslint-disable import/no-webpack-loader-syntax */
import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types';

import { Size } from 'constants/enums'

import svgIconDOM from '!!svg-inline-loader!imgs/icons/face.svg'
import '../../stylesheets/base/iconography.scss'

const FaceIcon = ({ size = Size.REGULAR, className }) => {
  const iconClass = classNames('icon__face', 'icon', `icon--${size}`, className)

  return (
    <span
      className={ iconClass }
      dangerouslySetInnerHTML={ { __html: svgIconDOM } }
    />
  )
}

FaceIcon.propTypes = {
  size: PropTypes.oneOf([Size.SMALL, Size.REGULAR, Size.LARGE]),
  className: PropTypes.string,
}

export default FaceIcon
