import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare'
import { connect } from 'react-redux'

import AccordionItem from 'components/AccordionItem'
import ContentBlock from 'components/ContentBlock'
import BestSellersPanel from 'components/smart/BestSellersPanel'
import InterlinksPanel from 'components/smart/InterlinksPanel'
import BestSellersPriceTable from 'components/Footer/BestSellersPriceTable'
import TestimonialsPanel from 'components/smart/TestimonialsPanel'
import Href from 'units/Href'
import FacebookIcon from 'units/icons/FacebookIcon'
import InstagramIcon from 'units/icons/InstagramIcon'
import LinkedinIcon from 'units/icons/LinkedinIcon'
import PinterestIcon from 'units/icons/PinterestIcon'
import TwitterIcon from 'units/icons/TwitterIcon'
import YoutubeIcon from 'units/icons/YoutubeIcon'
import Actions from 'store/actions'

import * as Constants from 'constants/enums'

import AppDownloadBanner from './AppDownloadBanner'
import StaticLinks from './StaticLinks'
import Spree from 'api/spree'
import Config from 'settings'

import './Footer.scss'
import Analytics from 'library/analytics'

class Footer extends React.Component {
  static propTypes = {
    shouldFooterSectionsLoad: PropTypes.bool.isRequired,
    showTestimonials: PropTypes.bool,
    showBestSellers: PropTypes.bool,
    dispatch: PropTypes.func,
    childRef: PropTypes.func,
    cities: PropTypes.object,
    taxon: PropTypes.object,
  };

  state = {
    activeItemIndex: null,
    ul_phone_number: Config.contactInfo.mobile,
    observer: null
  };

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(Actions.getCities())

    const spree = new Spree()
    spree.clientConfiguration
    .get(Constants.ClientConfiguration.UL_PHONE_NO)
    .then((response) => {
      this.setState({ ul_phone_number : response.data.ul_phone_number ? response.data.ul_phone_number : Config.contactInfo.mobile })
    })
    let obs = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          Analytics.genericEvent({
            category: Event.category.SOCIAL_MEDIA_FOOTER,
            action: Event.action.VIEWED,
          })
        }
      });
    }, { threshold: 1 })
    this.setState({ observer: obs })

  if (this.props.childRef?.current) {
    obs.observe(this.props.childRef?.current);
  }
  }

  componentWillUnmount() {
    if (this.props.childRef?.current) {
      this.state.observer?.unobserve(this.props.childRef?.current);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState)
  }

  getHref(link, index, classes) {
    const { ul_phone_number } = this.state

    let { icon,text } = link
    let url = link.link
    if(link?.id){
      text = link.id == 'mobile_number' ? `Call Us - ${ul_phone_number}` : text;
      url = link.id == 'mobile_number' ? `tel:${ul_phone_number}` : url;
    }

    return (
      <Href
        key={ index }
        isExternal={ link.isExternal }
        to={ url }
        className={ classNames(classes, icon && `icon--sm ${icon}`) }
        isProxied={ link.isProxied }
      >
        { text && text }
      </Href>
    )
  }

  handleHeaderClick = (index) => {
    this.setState({
      activeItemIndex: this.state.activeItemIndex === index ? null : index,
    })
  };

  render() {
    const {
      shouldFooterSectionsLoad,
      showTestimonials,
      showBestSellers,
      childRef,
      cities,
      taxon,
    } = this.props

    let citiesData
    if (cities) {
      citiesData = cities.cities
      if (typeof citiesData === 'undefined' || !Array.isArray(citiesData)) {
        citiesData = [];
      }
    }

    let taxonData
    let parentTaxonPage = ""
    if (taxon) {
      taxonData = taxon.taxon
      if(taxonData.is_base_taxonomy)
        parentTaxonPage = taxonData.parent_name + " "
    }
    return (
      <footer ref={ childRef } >
        { showBestSellers &&
          <BestSellersPanel shouldLoadSection={ shouldFooterSectionsLoad } /> }
        {/* { showTestimonials &&
          <TestimonialsPanel shouldLoadSection={ shouldFooterSectionsLoad } /> } */}
        <InterlinksPanel parentTaxonPage = { parentTaxonPage }/> 
        <ContentBlock
          platform={ Constants.ContentBlock.platform.ALL }
          position={ Constants.ContentBlock.position.FOOTER_SECTION_1 }
        />
        { taxonData && taxonData.show_price_list && <BestSellersPriceTable /> }
        <AppDownloadBanner />

        <div data-nosnippet>
          {
            StaticLinks.footerLinks.map((obj, index) =>
              (<AccordionItem
                key={ obj.title }
                header={ obj.title }
                index={ index }
                className="footer__accordion"
                contentClass="footer__links"
                isActive={ this.state.activeItemIndex === index }
                onHeaderClick={ this.handleHeaderClick }
                headerAlign={ Constants.Align.LEFT }
              >
                {
                  obj.links.map((footerLink, findex) =>
                    this.getHref(footerLink, findex, 'footer__link'))
                }
               </AccordionItem>))
          }

          <AccordionItem
            header="Address"
            index={ StaticLinks.footerLinks.length }
            className="footer__accordion"
            contentClass="footer__links"
            isActive={
              this.state.activeItemIndex === StaticLinks.footerLinks.length
            }
            onHeaderClick={ this.handleHeaderClick }
            headerAlign={ Constants.Align.LEFT }
          >
            <div className="footer__registered-address-row">
              <div>{ StaticLinks.registeredAddress.companyName },</div>
              { StaticLinks.registeredAddress.address }
            </div>
            <div className="footer__registered-address-row">
              <b>CIN: </b>{ StaticLinks.registeredAddress.cin }
            </div>
          </AccordionItem>

          <AccordionItem
            header="Popular Furniture Categories"
            index={ StaticLinks.footerLinks.length + 1 }
            className="footer__accordion"
            contentClass="footer__links"
            isActive={
              this.state.activeItemIndex === StaticLinks.footerLinks.length + 1
            }
            onHeaderClick={ this.handleHeaderClick }
            headerAlign={ Constants.Align.LEFT }
          >
            <div className="footer__registered-address-row">

              <div>
                { StaticLinks.furnitureCategories.map((obj, index) => (
                  <Href key={ index } className="footer__popular_furniture" to={ obj.link } isExternal>
                    { StaticLinks.furnitureCategories.length != index + 1 ? `${obj.name},` : `${obj.name}` }
                  </Href>
                )) }

              </div>

            </div>
          </AccordionItem>

          <AccordionItem
            header="Shop Furniture By Room"
            index={ StaticLinks.footerLinks.length + 2 }
            className="footer__accordion"
            contentClass="footer__links"
            isActive={
              this.state.activeItemIndex === StaticLinks.footerLinks.length + 2
            }
            onHeaderClick={ this.handleHeaderClick }
            headerAlign={ Constants.Align.LEFT }
          >
            <div className="footer__registered-address-row">

              <div>
                { StaticLinks.furnitureByRoom.map((obj, index) => (
                  <Href key={ index } className="footer__popular_furniture" to={ obj.link } isExternal>
                    { StaticLinks.furnitureByRoom.length != index + 1 ? `${obj.name},` : `${obj.name}` }
                  </Href>
                )) }

              </div>

            </div>

          </AccordionItem>

          <AccordionItem
            header="Popular Decor Categories"
            index={ StaticLinks.footerLinks.length + 3 }
            className="footer__accordion"
            contentClass="footer__links"
            isActive={
              this.state.activeItemIndex === StaticLinks.footerLinks.length + 3
            }
            onHeaderClick={ this.handleHeaderClick }
            headerAlign={ Constants.Align.LEFT }
          >
            <div className="footer__registered-address-row">

              <div>
                { StaticLinks.decorCategories.map((obj, index) => (
                  <Href key={ index } className="footer__popular_furniture" to={ obj.link } isExternal>
                    { StaticLinks.decorCategories.length != index + 1 ? `${obj.name},` : `${obj.name}` }
                  </Href>
                )) }

              </div>

            </div>

          </AccordionItem>

          <AccordionItem
            header="Popular Mattress Categories"
            index={ StaticLinks.footerLinks.length + 4 }
            className="footer__accordion"
            contentClass="footer__links"
            isActive={
              this.state.activeItemIndex === StaticLinks.footerLinks.length + 4
            }
            onHeaderClick={ this.handleHeaderClick }
            headerAlign={ Constants.Align.LEFT }
          >
            <div className="footer__registered-address-row">

              <div>
                { StaticLinks.mattressCategories.map((obj, index) => (
                  <Href key={ index } className="footer__popular_furniture" to={ obj.link } isExternal>
                    { StaticLinks.mattressCategories.length != index + 1 ? `${obj.name},` : `${obj.name}` }
                  </Href>
                )) }

              </div>

            </div>

          </AccordionItem>

          <AccordionItem
            header="Popular Tableware Categories"
            index={ StaticLinks.footerLinks.length + 5 }
            className="footer__accordion"
            contentClass="footer__links"
            isActive={
              this.state.activeItemIndex === StaticLinks.footerLinks.length + 5
            }
            onHeaderClick={ this.handleHeaderClick }
            headerAlign={ Constants.Align.LEFT }
          >
            <div className="footer__registered-address-row">

              <div>
                { StaticLinks.tablewareCategories.map((obj, index) => (
                  <Href key={ index } className="footer__popular_furniture" to={ obj.link } isExternal>
                    { StaticLinks.tablewareCategories.length != index + 1 ? `${obj.name},` : `${obj.name}` }
                  </Href>
                )) }

              </div>

            </div>

          </AccordionItem>

          <AccordionItem
            header="Delivering in"
            index={ StaticLinks.footerLinks.length + 6 }
            className="footer__accordion"
            contentClass="footer__links"
            isActive={
              this.state.activeItemIndex === StaticLinks.footerLinks.length + 6
            }
            onHeaderClick={ this.handleHeaderClick }
            headerAlign={ Constants.Align.LEFT }
          >
            <div className="footer__registered-address-row">

              <div>
                { citiesData && citiesData.slice(0, 41).map((obj, index) => (
                    <div key={ index } className="footer__cities">
                     { citiesData.slice(0, 41).length != index + 1 ? `${obj.name},` : `${obj.name}` }
                    </div>
                )) }

                <Href className="footer__more_cities" to="/locations" isExternal>
                  ...More
                </Href>
              </div>
            </div>
          </AccordionItem>
        </div>

        <div className="footer__social">
          <Href className="footer__social-icon" to={ StaticLinks.social.facebook } isExternal onClick={(e) => {
            Analytics.genericEvent({
              category: Event.category.SOCIAL_MEDIA_FOOTER,
              action: Event.action.CLICKED,
              socialMedia: 'Facebook',
              link: StaticLinks.social.facebook
            })
          }}>
            <FacebookIcon />
          </Href>
          <Href className="footer__social-icon" to={ StaticLinks.social.twitter } isExternal onClick={(e) => {
            Analytics.genericEvent({
              category: Event.category.SOCIAL_MEDIA_FOOTER,
              action: Event.action.CLICKED,
              socialMedia: 'Twitter',
              link: StaticLinks.social.twitter
            })
          }}>
            <TwitterIcon />
          </Href>
          <Href className="footer__social-icon" to={ StaticLinks.social.pinterest } isExternal onClick={(e) => {
            Analytics.genericEvent({
              category: Event.category.SOCIAL_MEDIA_FOOTER,
              action: Event.action.CLICKED,
              socialMedia: 'Pinterest',
              link: StaticLinks.social.pinterest
            })
          }}>
            <PinterestIcon />
          </Href>
          <Href className="footer__social-icon" to={ StaticLinks.social.youtube } isExternal onClick={(e) => {
            Analytics.genericEvent({
              category: Event.category.SOCIAL_MEDIA_FOOTER,
              action: Event.action.CLICKED,
              socialMedia: 'Youtube',
              link: StaticLinks.social.youtube
            })
          }}>
            <YoutubeIcon />
          </Href>
          <Href className="footer__social-icon" to={ StaticLinks.social.instagram } isExternal onClick={(e) => {
            Analytics.genericEvent({
              category: Event.category.SOCIAL_MEDIA_FOOTER,
              action: Event.action.CLICKED,
              socialMedia: 'Instagram',
              link: StaticLinks.social.instagram
            })
          }}>
            <InstagramIcon />
          </Href>
          <Href className="footer__social-icon" to={ StaticLinks.social.linkedin } isExternal onClick={(e) => {
            Analytics.genericEvent({
              category: Event.category.SOCIAL_MEDIA_FOOTER,
              action: Event.action.CLICKED,
              socialMedia: 'Linkedin',
              link: StaticLinks.social.linkedin
            })
          }}>
            <LinkedinIcon />
          </Href>
        </div>

        <div className="footer__copyright">
          &copy; 2012 - { new Date().getFullYear() } URBAN LADDER
        </div>
      </footer>
    )
  }
}

export default connect(state => ({
  showTestimonials: state.pageData.testimonials.showSection,
  showBestSellers: state.pageData.bestSellers.showSection,
  cities: state.cities.data,
  taxon: state.taxon.data,
}))(Footer)
