import cloneDeep from 'lodash/cloneDeep'

import { DefaultOption } from 'components/Listing/SortPanel/Options'
import Endpoints from 'settings/endpoints'
import BaseAPI from '../../BaseAPI'

export default class Listing extends BaseAPI {

  get(options) {
    const { permalink, isLeaf, filters } = options
    const extendedOptions = options.extendedOptions || {}

    const filterParams = cloneDeep(filters)
    if (filterParams.sort && filterParams.sort === DefaultOption) {
      delete filterParams.sort
    }
    const params = {
      version: '1.1',
      'q[product_taxons_permalink_eq]': permalink,
      ...filterParams,
      ...extendedOptions,
    }

    if ((extendedOptions.page || 1) === 1) {
      params.meta = true
    }
    const endpoint = isLeaf ? Endpoints.listing.leaf : Endpoints.listing.nonleaf
    return this.client.get(endpoint, params)
  }
}
