/* eslint-disable sort-class-members/sort-class-members */

/**
 * A simple class that provides a promise based interface
 * for persistent storage of key-values pairs.
 * It uses Indexed DB and falls back to localStorage.
 *
 * NOTE: Storing may fail for various reasons, and hence only use
 * this for non-critical resources as a progressive enhancement.
 *
 * Supported value types :
 * ---------------------
 * 1. All browsers
 * boolean, number, string, object, array, undefined, and null.
 *
 * 2. Browsers with IDB support
 * All of the above and regexp, date and blobs
 */

import idbKeyval from 'idb-keyval'
import isPlainObject from 'lodash/isPlainObject'

export default class PersistentStorage {
  static storageEngines = {
    IDB: 'indexeddb',
    LOCAL_STORAGE: 'localstorage',
  };

  /**
   * Checks if indexed db is supported
   * and is "sufficiently bug-free" for
   * our idb-keyval implementation.
   *
   * @see http://jensarps.de/2015/12/16/how-to-detect-buggy-indexeddb-implementations/
   */
  static supportsIDB() {
    const env = typeof window === 'object' ? window : self
    const idb = env.indexedDB || env.webkitIndexedDB || env.mozIndexedDB

    if (!idb) {
      return false
    }

    // Safari on iOS <10 have a very buggy implementation of IDB
    // and don't support IDB in WebViews.
    const iOSUARegex = /(iPad|iPhone);.*OS (\d+)_(\d+)/

    const matches = navigator.userAgent.match(iOSUARegex)
    const isOS = matches && matches.length === 4
    if (isOS) {
      const iOSVersion = parseFloat(`${matches[2]}.${matches[3]}`)
      if (iOSVersion <= 10) {
        return false
      }
    }

    return true
  }

  static supportsLocalStorage() {
    const test = '__test'
    try {
      localStorage.setItem(test, test)
      localStorage.removeItem(test)
      return true
    } catch (e) {
      return false
    }
  }

  constructor() {
    this.storageEngine = null

    if (!isServer()) {
      if (PersistentStorage.supportsIDB()) {
        this.storageEngine = PersistentStorage.storageEngines.IDB
      } else if (PersistentStorage.supportsLocalStorage()) {
        this.storageEngine = PersistentStorage.storageEngines.LOCAL_STORAGE
      }
    }
  }

  /**
   * Sets a value
   *
   * @param {string} key
   * @param value
   */
  set(key, value) {
    switch (this.storageEngine) {
      case PersistentStorage.storageEngines.IDB:
        return idbKeyval.set(key, value)

      case PersistentStorage.storageEngines.LOCAL_STORAGE:
        return new Promise((resolve, reject) => {
          if (!PersistentStorage.isSerializable(value)) {
            reject('IDB not supported. Value must be serializable to be stored in localStorage')
          }

          try {
            localStorage.setItem(key, JSON.stringify(value))
            return resolve()
          } catch (e) {
            return reject(e)
          }
        })

      default:
        return Promise.reject('Persistent Sorage Error: No suitable storage engine found.')
    }
  }

  get(key) {
    switch (this.storageEngine) {
      case PersistentStorage.storageEngines.IDB:
        return idbKeyval.get(key)

      case PersistentStorage.storageEngines.LOCAL_STORAGE:
        return new Promise((resolve, reject) => {
          try {
            return resolve(JSON.parse(localStorage.getItem(key)))
          } catch (e) {
            return reject(e)
          }
        })

      default:
        return Promise.reject('Persistent Sorage Error: No suitable storage engine found.')
    }
  }

  static isNotNullOrNaN = val => (
      typeof val === 'undefined' ||
      typeof val === 'string' ||
      typeof val === 'boolean' ||
      typeof val === 'number' ||
      Array.isArray(val) || isPlainObject(val)
  )

  /**
   * Checks if the passed value can
   * be safely serialized.
   *
   * @param value
   * @see http://stackoverflow.com/questions/30579940/reliable-way-to-check-if-objects-is-serializable-in-javascript
   */
  static isSerializable(value) {
    let isNestedSerializable

    if (!PersistentStorage.isNotNullOrNaN(value)) {
      return false
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const property in value) {
      // eslint-disable-next-line no-prototype-builtins
      if (value.hasOwnProperty(property)) {
        if (!PersistentStorage.isNotNullOrNaN(value[property])) {
          return false
        }
        if (typeof value[property] === 'object') {
          isNestedSerializable =
            PersistentStorage.isSerializable(value[property])
          if (!isNestedSerializable) {
            return false
          }
        }
      }
    }
    return true
  }
}
