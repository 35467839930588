import React from 'react'

import Href from 'units/Href'

import { SrcParam } from 'constants/enums'
import { addUrlParams, shouldProxyPermalink } from 'library/utils'
import Config from 'settings'

const NavStaticLinks = () => (
  <div>
    {
      Config.navStaticLinks.map(navStaticLink => (
        <Href
          key={ navStaticLink.permalink }
          className="nav__static-link"
          to={
            addUrlParams(`/${navStaticLink.permalink}`, {
              src: SrcParam.sidebarPrefix,
            })
          }
          isProxied={ shouldProxyPermalink(navStaticLink.permalink) }
          shouldReplaceState
        >
          { navStaticLink.name }
        </Href>
      ))
    }
  </div>
)

export default NavStaticLinks
