import Spree from 'api/spree'
import { PromiseState } from 'constants/enums'

/* =============
 -   ACTIONS   -
 ============= */
export function get(sku) {
  const spree = new Spree()

  return {
    type: 'GET_SIMILAR_SETS',
    payload: spree.recommendations.similarSets(sku),
  }
}

/* =============
 -  REDUCERS   -
 ============= */
export function similarSets(state = { looks: [] }, action) {
  switch (action.type) {
    case 'GET_SIMILAR_SETS_PENDING':
      return {
        ...state,
        promiseState: PromiseState.PENDING,
      }

    case 'GET_SIMILAR_SETS_FULFILLED': {
      return {
        ...state,
        promiseState: PromiseState.FULFILLED,
        looks: action.payload.data.looks,
        ...action.payload,
      }
    }

    case 'GET_SIMILAR_SETS_REJECTED':
      return {
        ...state,
        promiseState: PromiseState.REJECTED,
        error: action.payload,
      }

    default:
      return state
  }
}
