import React from 'react'
import PropTypes from 'prop-types';
import cx from 'classnames'

import Ripple from 'units/Ripple'

import './IconButton.scss'

const IconButton = ({ className, clickable = true, children, ...other }) => (
  <Ripple
    className={ cx('icon-btn', className) }
    disabled={ !clickable }
    centered
    { ...other }
  >
    { children }
  </Ripple>
)

IconButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  clickable: PropTypes.bool,
}

export default IconButton
