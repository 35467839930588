import Endpoints from 'settings/endpoints'
import Cookie from 'library/utils/cookieUtils'
import BaseAPI from '../../BaseAPI'

export default class Experiments extends BaseAPI {
  get(options) {
    return this.client.post(
      Endpoints.experiments,
      options,
      null,
      { credentials: 'same-origin' },
    )
      .then((response) => {
        // set experiment cookies
        const { experiments } = response.data
        if (experiments.length > 0) {
          experiments.forEach((experiment) => {
            const experimentSlug = experiment.slug
            const experimentValue = experiment.assigned_variant.slug
            Cookie.set(experimentSlug, experimentValue)
          })
        }
        return response
      })
  }
}
