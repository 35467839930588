/* eslint-disable sort-class-members/sort-class-members */
/* global FB */
import Spree from 'api/spree'
import { loadScript } from '../utils/DOMUtils'
import Endpoints from '../../settings/endpoints'

export default class FacebookOAuth {
  static loginStatus = {
    // the user is logged in and has authenticated the app
    CONNECTED: 'connected',
    // the user is logged in to Facebook but,
    // has not authenticated the app
    NOT_AUTHORISED: 'not_authorized',
    // the user is either not logged into Facebook
    // or explicitly logged out of your application
    // so it doesn't attempt to connect to Facebook
    UNKNOWN: 'unknown',
  };

  static requiredScopes = ['public_profile', 'email'];
  static requiredPersonalInfoFields = ['email', 'name'];

  constructor(facebookAppId) {
    if (!facebookAppId) {
      throw new Error('A facebook app id must be specified.')
    }

    this.appId = facebookAppId
  }

  loadProvider() {
    if ('FB' in window) {
      return Promise.resolve()
    }
    return loadScript(Endpoints.facebookSDK)
  }

  /**
   * @see https://developers.facebook.com/docs/javascript/reference/FB.init/v2.7
   * @returns {Promise}
   */
  init() {
    FB.init({
      appId: this.appId,
      cookie: false,
      version: 'v3.3',
    })
    return Promise.resolve()
  }

  /**
   * Returns the login status of
   * user, and if already connected,
   * a authResponse too.
   *
   * @returns {Promise.<Object>} - Resolved object contains -
   * response.status
   * response.authResponse
   *
   * @see https://developers.facebook.com/docs/reference/javascript/FB.getLoginStatus
   */
  _getLoginStatus = () => new Promise((resolve) => {
    FB.getLoginStatus((response) => {
      resolve(response)
    })
  });

  /**
    * Gets user related information,
    * depending on the fields passed.
    *
    * @param {[string]} fields
    * @returns {Promise}
    * @see https://developers.facebook.com/docs/graph-api/reference/user
    */
  _getPersonalInformation(fields) {
    return new Promise((resolve) => {
      FB.api('/me', { fields: fields.join(',') }, (response) => {
        resolve(response)
      })
    })
  }

  /**
   * Filters user auth information to a
   * format is accepted by `loginWithOAuth()`
   * in Spree Auth API wrapper.
   *
   * @example (incomplete)
   * { access_token: xxx, expiresIn: yyy, userId: zzz }
   * to
   * { accessToken: xxx, uid: zzz }
   *
   * @param {Object} user information object
   * @return {Object} Spree-Auth compatible user information
   */

  _formatToSpree(userInfo) {
    const spree = new Spree()

    return {
      provider: spree.auth.OAUTH_PROVIDER.FACEBOOK,
      accessToken: userInfo.accessToken,
      email: userInfo.email,
      name: userInfo.name,
      uid: userInfo.userID,
    }
  }

  /**
   * Logs a user in, requesting permissions
   * as specified in the scope.
   *
   * @param {[string]} - scopes
   * @returns {Promise}
   * @see https://developers.facebook.com/docs/facebook-login/permissions
   * @see https://developers.facebook.com/docs/reference/javascript/FB.login/v2.7
   */

  _login(scopes) {
    return new Promise((resolve, reject) => {
      FB.login((response) => {
        if (response.status === FacebookOAuth.loginStatus.CONNECTED) {
          resolve(response)
        } else {
          reject(response)
        }
      }, { scope: scopes.join(',') })
    })
  }

  /**
   * Detects current login state of the user,
   * and accordingly fetches user auth info.
   *
   * Resolved object contains —
   * `accessToken`, `email`, `expiresIn`, `id`,
   * `name`, `signedRequest` and `userID`
   *
   * @returns {Promise}
   */
  login() {
    return this._getLoginStatus()
      .then((response) => {
        switch (response.status) {
          case FacebookOAuth.loginStatus.CONNECTED:
            return this
              ._getPersonalInformation(FacebookOAuth.requiredPersonalInfoFields)
              .then(personalInfo =>
                Promise.resolve(this._formatToSpree({
                  ...response.authResponse,
                  ...personalInfo,
                })))

          case FacebookOAuth.loginStatus.UNKNOWN:
          case FacebookOAuth.loginStatus.NOT_AUTHORISED:
            return this
              ._login(FacebookOAuth.requiredScopes)
              .then(loginResponse =>
                /* eslint-disable function-paren-newline */
                this._getPersonalInformation(
                  FacebookOAuth.requiredPersonalInfoFields)
                  .then(personalInfo =>
                    Promise.resolve(this._formatToSpree({
                      ...loginResponse.authResponse,
                      ...personalInfo,
                    }))))

          default:
            return Promise.reject(new Error('Unhandled login status'))
        }
      })
  }

  /**
   * Log the user out of the site and,
   * in some cases, Facebook too.
   *
   * Also invalidates the FB access token.
   *
   * @see https://developers.facebook.com/docs/reference/javascript/FB.logout
   * @returns {Promise}
   */
  logout() {
    return new Promise((resolve) => {
      FB.logout((response) => {
        resolve(response)
      })
    })
  }

  /**
   * Checks if a user is signed in and
   * has previously authorized the app
   * with required permissions.
   * @return {Promise.<boolean>}
   */

  isAppAuthorized = () =>
    this._getLoginStatus().then(response =>
      Promise.resolve(response.status === FacebookOAuth.loginStatus.CONNECTED))
}
