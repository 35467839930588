import Spree from 'api/spree'
import { PromiseState } from 'constants/enums'

export function getDetails(orderData) {
  const apiClient = new Spree()
  return {
    type: 'GET_ORDER_DETAILS',
    payload: apiClient.order.details(orderData),
    meta: {
      orderNumber: orderData.orderNumber,
    },
  }
}

export function details(state = {}, action) {
  switch (action.type) {
    case 'GET_ORDER_DETAILS_PENDING':
      return {
        ...state,
        promiseState: PromiseState.PENDING,
      }

    case 'GET_ORDER_DETAILS_FULFILLED': {
      const { orderNumber } = action.meta
      const orderDetails = {}
      orderDetails[orderNumber] = action.payload
      orderDetails.promiseState = PromiseState.FULFILLED
      return orderDetails
    }

    case 'GET_ORDER_DETAILS_REJECTED':
      return {
        ...state,
        promiseState: PromiseState.REJECTED,
      }

    default:
      return state
  }
}
