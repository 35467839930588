import Endpoints from 'settings/endpoints'
import BaseAPI from '../../BaseAPI'

export default class Stores extends BaseAPI {
  getAll(params) {
    return this.client.get(Endpoints.stores.all, params)
  }

  getByCity(cityName, params) {
    return this.client.get(Endpoints.stores.byCity(cityName), params)
  }

  /**
   * Gets list of emi options available
   * for a price point and location
   * @param { string } type - furniture-stores/sofa-stores
   * @param { string } city - city name
   * @param { string } location - location name
   */

  get(type, city, location) {
    const param = {
      platform: 'mweb',
    }
    return this.client.get(Endpoints.stores
      .details(type, city, location), param)
  }
}
