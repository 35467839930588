import Endpoints from 'settings/endpoints'
import BaseAPI from '../../BaseAPI'

export default class Search extends BaseAPI {

  find(query, filters = {}, options = {}) {
    super._checkType([query, options, filters], ['string', 'object', 'object'])
    return this.client.get(Endpoints.variants, {
      version: '1.1',
      'q[product_taxons_name_cont]': query,
      ...options,
      ...filters,
    })
  }

  suggest(query) {
    super._checkType([query], ['string'])
    return this.client
               .get(Endpoints.suggestions, { prefix: query })
  }
}
