import React from 'react'
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare'
import connect from 'react-redux/lib/components/connect'

import AccordionItem from 'components/AccordionItem'
import SideBar from 'components/SideBar'
import Href from 'units/Href'

import { SrcParam } from 'constants/enums'
import { addUrlParams, shouldProxyPermalink } from 'library/utils'
import Config from 'settings'

import L2ItemList from './L2ItemList'
import NavStaticLinks from './NavStaticLinks'
import './Nav.scss'

import Analytics from 'library/analytics'
import Event from 'library/analytics/eventFactory'

class Nav extends React.Component {
  static propTypes = {
    taxons: PropTypes.arrayOf(PropTypes.object),
    isActive: PropTypes.bool,
    onNavClose: PropTypes.func,
  };

  state = {
    activeItemIndex: null,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState)
  }

  handleHeaderClick = (l1Item, index) => {
    this.setState({
      activeItemIndex: this.state.activeItemIndex === index ? null : index,
    })
    
    Analytics.genericEvent({
      category: Event.category.SIDEBAR_DROPDOWN ,
      action: Event.action.CLICKED,
      position: index+1,
      name: l1Item?.name,
      id: l1Item?.id,
      l2Items: l1Item?.taxons
    })
  };

  render() {
    const { isActive, onNavClose } = this.props
    const taxons = [Config.homeMenu].concat(this.props.taxons)

    return (
      <SideBar className="nav" isActive={ isActive } onSideBarClose={ onNavClose } trackOpen>
        {
          taxons.map((l1Item, index) => {
            const hasChildren = l1Item.taxons && l1Item.taxons.length > 0

            return hasChildren ? (
              <AccordionItem
                key={ l1Item.id }
                header={ l1Item.name }
                index={ index }
                className="nav__l1"
                isActive={ this.state.activeItemIndex === index }
                onHeaderClick={ () => this.handleHeaderClick(l1Item, index) }
                trackOpen={ { category: 'Sidebar' } }
              >
                {
                  l1Item.taxons.map(l2Item =>
                    <L2ItemList
                      l1ItemName={ l1Item.name }
                      key={ l2Item.id }
                      l2Item={ l2Item }
                      isVisible={ this.state.activeItemIndex === index }
                    />,
                  )
                }
              </AccordionItem>
            ) : (
              <Href
                key={ l1Item.id }
                className="nav__l1--link"
                to={
                  addUrlParams(`/${l1Item.permalink}`, {
                    src: SrcParam.sidebarPrefix,
                  })
                }
                isProxied={ shouldProxyPermalink(l1Item.permalink) }
                { ...(l1Item.no_follow && { rel: 'nofollow' }) }
                shouldReplaceState
              >
                { l1Item.name }
              </Href>
            )
          })
        }
        <NavStaticLinks />
      </SideBar>
    )
  }
}

export default connect(state => ({
  taxons: state.navigation.taxons,
}))(Nav)
