/* eslint-disable max-statements */
/* eslint-disable max-len */
/* global requestIdleCallback, eruda */
/* eslint-disable no-console */
import React from "react";
import { hydrate } from "react-dom";
import Provider from "react-redux/lib/components/Provider";
import browserHistory from "react-router/lib/browserHistory";
import hashHistory from "react-router/lib/hashHistory";
import match from "react-router/lib/match";
import Router from "react-router/lib/Router";
import { syncHistoryWithStore } from "react-router-redux";
import { trigger } from "redial";
import Config from "settings/config";

import attemptAutoLogin from "library/autoLogin";
import Store from "store/configureStore";
import loadMediumFont from "library/fontLoader";
import Polyfills from "library/polyfills";
import { isOn2G, getParameterByName, clearHashFragment } from "library/utils";
import CookieUtils from "library/utils/cookieUtils";
import { loadScript } from "library/utils/DOMUtils";
import splitListing from "library/utils/splitListing";
import Spree from "api/spree";
import { LoginState } from "constants/enums";
import routeGenerator from "./routes";
import OAuth from "./library/oauth";
import Actions from 'store/actions'

let Perf;
if (process.env.NODE_ENV === "development") {
  // Perf = require('react-addons-perf')
}

Polyfills.esMethods.polyfill();
Polyfills.Promise.polyfill();
Polyfills.requestAnimationFrame.polyfill();
Polyfills.smoothScroll.polyfill();
Polyfills.CustomEvent.polyfill();

const store = Store.get();
const { dispatch } = store;
const { leafTaxons, nonLeafTaxons } = splitListing(store.getState().navigation);
const routes = routeGenerator(leafTaxons, nonLeafTaxons);
const { location } = window;
const spree = new Spree();

window.onload = () => {
  loadMediumFont();

  if((spree.auth.getLoginState() === LoginState.NEVER_LOGGED_IN || spree.auth.getLoginState() === LoginState.LOGGED_OUT) && !isOn2G()) {
    googleAutoTap();
  }

  /**
   * Set affiliate cookie if the affiliate params
   * present in the URL params
   */
  const affiliateId = getParameterByName("affid");
  /**
   * parameter named affext1 to avoid user misbehaviour
   * with the parameter its actualy affiliate text
   */
  const affiliateText = getParameterByName("affext1");
  if (affiliateId && affiliateText) {
    const affiliate = {
      aff_id: affiliateId,
      aff_ext: affiliateText,
      created_at: Math.floor(Date.now() / 1000),
    };
    const expiryDate = new Date();
    expiryDate.setDate(
      expiryDate.getDate() + Config.affiliateCookieExpiryInDays
    );
    CookieUtils.set("ul_aff", JSON.stringify(affiliate), {
      expires: expiryDate,
    });
  }
};

const googleAutoTap = () => {
  google.accounts.id.initialize({
    client_id: GlobalKey.googleClientId,
    cancel_on_tap_outside: true,
    prompt_parent_id: "root",
    ux_mode: "redirect",
    itp_support: true,
    callback: credentialResponse
  });

  google.accounts.id.prompt((notification) => {
    if(notification.isDisplayed()) {
      window.hideBottomLogin = true;
      requestIdleCallback(() => {
        attemptAutoLogin(store)
      })
    }else{
      window.hideBottomLogin = false;
    } 
  });
}

function parseJwt(token) {
   var base64Url = token.split('.')[1];
   var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
   var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
   }).join(''));
   return JSON.parse(jsonPayload);
};

function credentialResponse (response) {
  let token = response.credential;
  let userInfo = parseJwt(token);
  let userData = {
    accessToken: token,
    provider:'google_oauth2',
    name: userInfo.name,
    email: userInfo.email,
    uid:userInfo.sub
  }
  
  dispatch(Actions.loginWithOAuth(userData));
  if(window.location.pathname=="/login"){
    location.href = "/";
  }
}
// Clean up any leftover hash fragment from the page url.
// Hash fragments are used by use for consistent back button
// behaviour and may be left over if the component did not
// get a chance to clean the hash fragment from the url
clearHashFragment();

/**
 * Setup mobile debugging using `eruda`
 * @see https://github.com/liriliri/eruda
 */

if (getParameterByName("debug")) {
  loadScript("http://eruda.liriliri.io/eruda.min.js").then(() => {
    eruda.init();
  });
}

/**
 * Returns the correct browser-compatible
 * history synced with redux store
 */
function getHistory() {
  const detectedHistory =
    window.history && window.history.pushState ? browserHistory : hashHistory;

  return syncHistoryWithStore(detectedHistory, store);
}

/**
 * Utility to measure initial
 * react rendering performance
 */

const RenderPerf = {
  startMeasurement: () => {
    if (process.env.NODE_ENV === "development") {
      // Perf.start()
    }
  },

  endMeasurement: () => {
    if (process.env.NODE_ENV === "development") {
      // Perf.stop()
      // const measurements = Perf.getLastMeasurements()
      // console.groupCollapsed('React perf stats ⚡')
      // Perf.printInclusive(measurements)
      // console.log('Time wasted in:')
      // Perf.printWasted(measurements)
      // console.groupEnd()
    }
  },
};

const history = getHistory();

match({ routes, location }, () => {
  RenderPerf.startMeasurement();

  hydrate(
    // eslint-disable-next-line react/jsx-filename-extension
    <Provider store={store}>
      <Router history={history} routes={routes} />
    </Provider>,
    document.getElementById("app-container")
  );

  // RenderPerf.endMeasurement()
});

// Currently, disabled for SEO purpose
// Set screen count
// let screenCount = 1

// Custom urlchange event on each page change
const URLChangeEvent = document.createEvent("Event");
URLChangeEvent.initEvent("urlchange", true, true);

let lastMatchedRoute = null;
let newRoute = "";
console.log("Before history listen");
let shouldRunCode = true;

// -----------------------------------------------

const fetchDataAndStore = (renderProps) => {
  const { components } = renderProps;
  const routeData = renderProps.routes[renderProps.routes.length - 1].data;

  window.pageType = routeData ? routeData.pageType : "";

  const locals = {
    storeState: store.getState(),
    path: renderProps.location.pathname,
    query: renderProps.location.query,
    params: renderProps.params,
    data: routeData,
    dispatch,
  };

  newRoute = `${renderProps.location.pathname}${renderProps.location.search}`;
  
  if (newRoute === "/" && window.location.pathname.length > 1) {
    //refreshed , but not at home page
    history.replace(window.location.pathname + window.location.search)
  }

  if (newRoute.startsWith("/")) {
    newRoute = newRoute.substring(1);
  }

  /**
   * This is to prevent unnecessary fetch triggers that
   * happen when there are URL hash changes via the
   * browserHistory APIs
   */
  if (lastMatchedRoute === newRoute) {
    return;
  }

  if (window.__INITIAL_STATE__) {
    // Server Side Render
    delete window.__INITIAL_STATE__;
    trigger("afterFetch", components, locals);
  } else {
    /**
     * Client Side Render
     * Discount fetch for App,
     * whose state data does not vary across routes
     */
    trigger("fetch", components.slice(1), locals).then(() => {
      locals.storeState = store.getState();
      trigger("analytics", components, locals);
      trigger("afterFetch", components, locals);
    });
  }
  // Fetch deferred, client-only data dependencies:
  trigger("defer", components, locals);
  lastMatchedRoute = newRoute;
};

// *****************************************************************************

// Satisfy async data dependencies of components in SPA mode
history.listen((newLocation) => {
  shouldRunCode = false;
  console.log("Inside History Listen");
  if(!newLocation.pathname.startsWith("/")){
    newLocation.pathname = "/" + newLocation.pathname
  }
  // window.location.reload(false);
  window.dispatchEvent(URLChangeEvent);

  // Disabling interstital images for SEO purpose

  // if (screenCount++ === 2) {
  // window.showInterstitial()
  // }

  match(
    { routes, location: newLocation },
    (error, redirectLocation, renderProps) => {
      fetchDataAndStore(renderProps);
    }
  );
});
if (shouldRunCode) {
  match({ routes, location: "/" }, (error, redirectLocation, renderProps) => {
    shouldRunCode = false;
    fetchDataAndStore(renderProps);
  });
}

export default store;
