import React from 'react'
import PropTypes from 'prop-types';
import classNames from 'classnames'
import './DiscountRibbon.scss'

const DiscountRibbon = ({ discountText, className }) => (
  <div className={ classNames(className, 'ribbon__container') }>
    <span className="ribbon">
      { discountText }
    </span>
  </div>
)

DiscountRibbon.propTypes = {
  discountText: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default DiscountRibbon
