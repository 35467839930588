/* eslint-disable no-undef */
import Spree from 'api/spree'
import { LoginState } from 'constants/enums'

export default class Clevertap {
  static ClevertapEventTypes = {
    LOGIN_FAILED: 'login failed',
    LOGGED_IN: 'login success',
    SIGNED_UP: 'sign up success',
    SIGNUP_FAILED: 'sign up failed',
    PAGE: 'page viewed',
    PRODUCT_LIST_VIEWED: 'product list viewed',
    PRODUCT_CLICKED: 'product clicked',
    PRODUCT_VIEWED: 'product viewed',
    PRODUCTS_SEARCHED: 'products searched',
    PRODUCT_ADDED: 'added to cart',
    PRODUCT_REMOVED: 'removed from cart',
    SERVICEABILITY_CHECKED: 'serviceability checked',
  }

  static createUserProfile(userData) {
    try {
      if (typeof clevertap !== "undefined") {
        clevertap.profile.push({
          Site: {
            "Email": userData.email,
            "User Email": userData.email
          },
        })
      } else {
        console.warn("Clevertap is not defined, ", "createUserProfile")
      }
    } catch (e) {
      console.error("CLEVERTAP EVENT PUSH EXCEPTION ", "createUserProfile", e.message)
    }
  }

  static identifyUser(userData) {
    try {
      const newUser = true
      localStorage.setItem('isUserIdentified', JSON.stringify(newUser))
      if (typeof clevertap !== "undefined") {
        clevertap.onUserLogin.push({
          Site: {
            "Email": userData.email,
            "User Email": userData.email
          },
        })
      } else {
        console.warn("Clevertap is not defined, ", "identifyUser")
      }
    } catch (e) {
      console.error("CLEVERTAP EVENT PUSH EXCEPTION ", "identifyUser", e.message)
    }
  }

  static signedUp(eventData) {
    try {
      this.identifyUser(eventData)
      this.createUserProfile(eventData)
      if (typeof clevertap !== "undefined") {
        clevertap.event.push(this.ClevertapEventTypes.SIGNED_UP, {
          email: eventData.email,
          provider: eventData.provider,
        })
      } else {
        console.warn("Clevertap is not defined, ", this.ClevertapEventTypes.SIGNED_UP)
      }
    } catch (e) {
      console.error("CLEVERTAP EVENT PUSH EXCEPTION ", "signedUp", e.message)
    }
  }

  static signupFailed(eventData) {
    try {
      if (typeof clevertap !== "undefined") {
        clevertap.event.push(this.ClevertapEventTypes.SIGNUP_FAILED, {
          email: eventData.email,
          provider: eventData.provider,
          reason: eventData.failureReason,
        })
      } else {
        console.warn("Clevertap is not defined, ", this.ClevertapEventTypes.SIGNUP_FAILED)
      }
    } catch (e) {
      console.error("CLEVERTAP EVENT PUSH EXCEPTION ", "signupFailed", e.message)
    }
  }

  static loggedIn(eventData) {
    try {
      this.identifyUser(eventData)
      if (typeof clevertap !== "undefined") {
        clevertap.event.push(this.ClevertapEventTypes.LOGGED_IN, {
          email: eventData.email,
          provider: eventData.provider,
        })
      } else {
        console.warn("Clevertap is not defined, ", this.ClevertapEventTypes.LOGGED_IN)
      }
    } catch (e) {
      console.error("CLEVERTAP EVENT PUSH EXCEPTION ", "loggedIn", e.message)
    }
  }

  static loginFailed(eventData) {
    try {
      if (typeof clevertap !== "undefined") {
        clevertap.event.push(this.ClevertapEventTypes.LOGIN_FAILED, {
          email: eventData.email,
          provider: eventData.provider,
          reason: eventData.failureReason,
        })
      } else {
        console.warn("Clevertap is not defined, ", this.ClevertapEventTypes.LOGIN_FAILED)
      }
    } catch (e) {
      console.error("CLEVERTAP EVENT PUSH EXCEPTION ", "loginFailed", e.message)
    }
  }

  static pageView(pageType) {
    try {
      const spree = new Spree()
      const isUserLoggedIn = spree.auth.getLoginState() === LoginState.LOGGED_IN
      const newUser = JSON.parse(localStorage.getItem('isUserIdentified'))

      if (!newUser && isUserLoggedIn) {
        const authInfo = spree.auth.getAuthInfoFromCookies()
        const userData = {
          email: authInfo.email,
        }

        this.identifyUser(userData)
        this.createUserProfile(userData)
      }

      const page_url = window.location.pathname + window.location.search
      if (typeof clevertap !== "undefined") {
        clevertap.event.push(this.ClevertapEventTypes.PAGE, {
          page_category: pageType,
          page_url,
        })
      } else {
        console.warn("Clevertap is not defined, ", this.ClevertapEventTypes.PAGE)
      }
    } catch (e) {
      console.error("CLEVERTAP EVENT PUSH EXCEPTION ", "pageView", e.message)
    }
  }

  static productListViewed(eventData) {
    try {
      const {
        product_category,
        product_subcategory,
        list_id,
        level,
      } = eventData

      if (typeof clevertap !== "undefined") {
        clevertap.event.push(this.ClevertapEventTypes.PRODUCT_LIST_VIEWED, {
          product_category,
          product_subcategory,
          list_id,
          level,
        })
      } else {
        console.warn("Clevertap is not defined, ", this.ClevertapEventTypes.PRODUCT_LIST_VIEWED)
      }
    } catch (e) {
      console.error("CLEVERTAP EVENT PUSH EXCEPTION ", "productListViewed", e.message)
    }
  }

  static productClicked(cardData, productPosition, list_id) {
    try {
      if (typeof clevertap !== "undefined") {
        clevertap.event.push(this.ClevertapEventTypes.PRODUCT_CLICKED, {
          list_id,
          sku: cardData.sku,
          product_name: cardData.name,
          product_category: cardData.category,
          product_subcategory: cardData.sub_category,
          product_position: productPosition,
          product_availability: cardData.availability_text,
        })
      } else {
        console.warn("Clevertap is not defined, ", this.ClevertapEventTypes.PRODUCT_CLICKED)
      }
    } catch (e) {
      console.error("CLEVERTAP EVENT PUSH EXCEPTION ", "productClicked", e.message)
    }
  }

  static productViewed(productDetails) {
    try {
      const {
        sku,
        selling_price,
        discount_in_percentage,
        material,
        finish,
        warranty_in_months,
      } = productDetails

      if (typeof clevertap !== "undefined") {

        clevertap.event.push(this.ClevertapEventTypes.PRODUCT_VIEWED, {
          sku,
          product_name: productDetails.name,
          product_category: productDetails.category,
          product_subcategory: productDetails.sub_category,
          product_model: productDetails.model,
          mrp: productDetails.price,
          selling_price,
          discount_in_percentage,
          material,
          finish,
          product_availability: productDetails.availability,
          warranty_in_months: parseInt(warranty_in_months),
        })
      } else {
        console.warn("Clevertap is not defined, ", this.ClevertapEventTypes.PRODUCT_VIEWED)
      }
    } catch (e) {
      console.error("CLEVERTAP EVENT PUSH EXCEPTION ", "productViewed", e.message)
    }
  }

  static productsSearched(searchQuery) {
    try {
      if (typeof clevertap !== "undefined") {
        clevertap.event.push(this.ClevertapEventTypes.PRODUCTS_SEARCHED, { search_query: searchQuery })
      } else {
        console.warn("Clevertap is not defined, ", this.ClevertapEventTypes.PRODUCTS_SEARCHED)
      }
    } catch (e) {
      console.error("CLEVERTAP EVENT PUSH EXCEPTION ", "productsSearched", e.message)
    }
  }

  static serviceabilityChecked(eventData) {
    try {
      const {
        pincode,
        is_serviceable,
        city,
        state,
      } = eventData
      if (typeof clevertap !== "undefined") {
        clevertap.event.push(this.ClevertapEventTypes.SERVICEABILITY_CHECKED, {
          pincode,
          is_serviceable,
          city,
          state,
        })
      } else {
        console.warn("Clevertap is not defined, ", this.ClevertapEventTypes.SERVICEABILITY_CHECKED)
      }
    } catch (e) {
      console.error("CLEVERTAP EVENT PUSH EXCEPTION ", "serviceabilityChecked", e.message)
    }
  }

  static productAdded(eventData) {
    try {
      const {
        sku,
        product_name,
        product_category,
        product_subcategory,
        product_model,
        quantity,
      } = eventData

      if (typeof clevertap !== "undefined") {
        clevertap.event.push(this.ClevertapEventTypes.PRODUCT_ADDED, {
          sku,
          product_name,
          product_category,
          product_subcategory,
          product_model,
          quantity,
        })
      } else {
        console.warn("Clevertap is not defined, ", this.ClevertapEventTypes.PRODUCT_ADDED)
      }
    } catch (e) {
      console.error("CLEVERTAP EVENT PUSH EXCEPTION ", "productAdded", e.message)
    }
  }
}
