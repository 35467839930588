/* eslint-disable camelcase */
// ∵ API Response uses underscore_cased variables

import Spree from 'api/spree'
import { PromiseState } from 'constants/enums'
import { Widget } from 'settings/values'

/* =============
 -   ACTIONS   -
 ============= */

export function getSeoBestSellers(options) {
  const spree = new Spree()

  return (dispatch) => {
    const params = {
      ...options,
      per_page: Widget.SEO_BEST_SELLER_LIMIT,
      filters: { availability: ['In Stock Only'] },
    }
    const { permalink } = options

    return dispatch({
      type: 'GET_SEO_BESTSELLERS',
      payload: spree.bestSellers.get(params, permalink),
    })
  }
}


/* =============
 -  REDUCERS   -
 ============= */

export function seoBestSellers(
  state = {
    showSection: false,
    products: [],
  },
  action,
) {
  switch (action.type) {
    case 'GET_SEO_BESTSELLERS_PENDING':
      return {
        ...state,
        promiseState: PromiseState.PENDING,
      }

    case 'GET_SEO_BESTSELLERS_FULFILLED':
      return {
        ...state,
        ...action.payload,
        showSection: true,
        products: action.payload.products,
        promiseState: PromiseState.FULFILLED,
      }

    case 'GET_SEO_BESTSELLERS_REJECTED':
      return {
        ...state,
        promiseState: PromiseState.REJECTED,
        error: action.payload,
      }

    case 'SPA_LOCATION_CHANGE':
      return {
        showSection: false,
        products: [],
      }

    default:
      return state
  }
}
