/* eslint-disable sort-class-members/sort-class-members */
/* eslint-disable camelcase */
import Endpoints from 'settings/endpoints'
import { LoginState } from 'constants/enums'
import { compactObject, objectToBase64, base64ToObject } from 'library/utils'
import CookieUtils from 'library/utils/cookieUtils'
import BaseAPI from '../../BaseAPI'

export default class Auth extends BaseAPI {
  /**
   * @enum {string}
   */
  OAUTH_PROVIDER = {
    GOOGLE: 'google_oauth2',
    FACEBOOK: 'facebook',
  };
  static SESSION_EXPIRY_IN_DAYS = 365;

  /**
   * Returns interpreted information about a
   * user, which was set by hercules web.
   *
   * @returns {Object} authInfo
   * @throws Will throw an error if executed in a non-browser
   * environment (depends on presence of cookies)
   */
  getAuthInfoFromCookies() {
    const {
            ul_auth,
            ul_user_traits,
            _hercules_session,
          } = CookieUtils.getAll()
    let decodedTraits = {}

    try {
      decodedTraits = base64ToObject(ul_user_traits)
      // eslint-disable-next-line no-empty
    } catch (e) {
    }

    return compactObject({
      loginState: this.getLoginState(),
      authTime: parseInt(ul_auth) * 100, // hercules sets unix style timestamps
      createdAt: decodedTraits.created_at,
      email: decodedTraits.email,
      userCity: decodedTraits.city,
      name: decodedTraits.name,
      userId: decodedTraits.user_id,
      herculesSession: _hercules_session,
    })
  }

  /**
   * Sets cookies used to identify login state of
   * the user. This does not set _hercules_session;
   * which is set implicitly by the browser
   * when a call to `/api/login` is made (Set-Cookie Header)
   *
   * @param {Object} loginData
   */
  _setLoggedInCookies(loginData) {
    const expiryDate = new Date()
    expiryDate.setDate(expiryDate.getDate() + Auth.SESSION_EXPIRY_IN_DAYS)

    CookieUtils.set('ul_auth_status', 1, { expires: expiryDate })
    CookieUtils.set('ul_user_traits', objectToBase64(loginData), { expires: expiryDate })

    // In use by hercules only
    CookieUtils.set('ul_auth', Math.round(Date.now() / 100))
    CookieUtils.set('user_email', loginData.email)
  }

  renewAuthCookies() {
    const { ul_auth_status, ul_user_traits } = CookieUtils.getAll()
    const expiryDate = new Date()
    expiryDate.setDate(expiryDate.getDate() + Auth.SESSION_EXPIRY_IN_DAYS)

    if (ul_auth_status && ul_user_traits) {
      CookieUtils.set('ul_auth_status', ul_auth_status, { expires: expiryDate })
      CookieUtils.set('ul_user_traits', ul_user_traits, { expires: expiryDate })
    }
  }

  /**
   * Signup a new user.
   * Cart merge happens if a guest token is present in cookies,
   * or if a `orderNo` is specified in params.
   *
   * @param {Object} params
   * @param {string} params.email - Email id of the user
   * @param {string} params.password - password of the user
   * @param {string} [params.orderNo]
   * @returns {Promise}
   */
  signup(params) {
    const requiredParams = ['email', 'password']
    super._schemaCheck(params, requiredParams)
    const paramsToSend = {
      'user[email]': params.email,
      'user[password]': params.password,
    }

    if ('orderNumber' in params) {
      paramsToSend.order_no = params.orderNo
    }
    return this.client.post(Endpoints.users, paramsToSend, null, { credentials: 'same-origin' })
  }

  /**
   * Check if a user is logged in from
   * current cookies' state.
   * Note: This method does not validate a user.
   *
   * @returns {LoginState}
   * @throws Will throw an error if executed in a non-browser
   * environment (depends on presence of cookies)
   */

  getLoginState() {
    const { ul_auth_status, _hercules_session, ul_app } = CookieUtils.getAll()
    /*
     @TODO Remove this flaky test when apps
     implement `ul_auth_status` cookie.

     Apps set _hercules_session when user is logged in.
     Hercules too sets _hercules_session — irrespective of
     login status of user — with a `httpOnly` flag, making
     it unreadable to client-side JS.
     */

    if (ul_auth_status === '1' || (ul_app === 'mobile' && _hercules_session)) {
      return LoginState.LOGGED_IN
    } else if (ul_auth_status === '0' || ul_app === 'mobile') {
      return LoginState.LOGGED_OUT
    }
    return LoginState.NEVER_LOGGED_IN
  }

  /**
   * Log in a user based on email / password combination.
   *
   * @param {Object} params
   * @returns {Promise}
   */
  login(params) {
    const requiredParams = ['email', 'password']
    super._schemaCheck(params, requiredParams)

    const paramsToSend = {
      'user[email]': params.email,
      'user[password]': params.password,
    }
    return this.client.post(Endpoints.login, paramsToSend, null, { credentials: 'same-origin' })
               .then((loginData) => {
                 if (!isServer()) {
                   this._setLoggedInCookies(loginData)
                 }
                 return Promise.resolve(loginData)
               })
  }

  /**
   * Log in a user using `accessToken` received from
   * an OAuth provider.
   *
   * @param {Object} params
   * @param {OAUTH_PROVIDER} params.provider
   * @param {string} params.accessToken
   * @param {string} params.email
   * @param {number} params.uid
   * @returns {Promise}
   */
  loginWithOAuth(params) {
    const requiredParams = ['provider', 'accessToken', 'email', 'name', 'uid']
    super._schemaCheck(params, requiredParams)

    const paramsToSend = {
      'auth[provider]': params.provider,
      'auth[access_token]': params.accessToken,
      'auth[info][email]': params.email,
      'auth[uid]': params.uid,
    }
    return this.client
               .post(Endpoints.login, paramsToSend, null, { credentials: 'same-origin' })
               .then((loginData) => {
                 if (!isServer()) {
                   this._setLoggedInCookies(loginData)
                 }
                 return Promise.resolve(loginData)
               })
  }

  /**
   * Logs out the user, and unsets
   * the session cookies
   */
  logout() {
    return new Promise((resolve) => {
      CookieUtils.set('ul_auth_status', 0)
      CookieUtils.set('ul_auth', 'logged_out')
      CookieUtils.unset('ul_user_traits')
      CookieUtils.unset('user_email')
      CookieUtils.unset('_hercules_session')
      CookieUtils.unset('ul_id')
      resolve()
    })
  }

}
