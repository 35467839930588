const Type = {
  PRIMARY: 'primary',
  PRIMARY_MUTED: 'primary--muted',
  SECONDARY: 'secondary',
  SECONDARY_MUTED: 'secondary--muted',
  TRANSPARENT: 'transparent',
  TOGGLE_UNSELECTED: 'toggle--unselected',
  TOGGLE_SELECTED: 'toggle--selected',
}

export default Type
