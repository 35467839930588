import cloneDeep from 'lodash/cloneDeep'
import React from 'react'
import PropTypes from 'prop-types';
import Analytics from 'library/analytics'
import Event from 'library/analytics/eventFactory'
import './ExposeFilter.scss'

class ExposeFilter extends React.Component {
    static propTypes = {
        isRowEnding: PropTypes.number,
        numOfRows: PropTypes.number,
        appliedFilters: PropTypes.object,
        availableFilters: PropTypes.object,
        handleFilterButtonClick: PropTypes.func,
        handleClearPropertyFilter: PropTypes.func,
        handleClearRangeFilter: PropTypes.func,
    };

    state = {
        currentFilters: cloneDeep(this.props.appliedFilters) || {},
        targetRef: React.createRef(null),
        observer: null,
    };

    componentDidMount() {
        let obs = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if(entry.isIntersecting) {
                    Analytics.genericEvent({
                        category: Event.category.EXPOSED_FILTERS,
                        action: Event.action.VIEWED,
                    })
                }
            })
        }, { threshold: 1 })

        this.setState({ observer: obs })
        if(this.state?.targetRef?.current) {
            obs.observe(this.state?.targetRef?.current)
        }
    }

    componentWillUnmount() {
        if(this.state?.targetRef?.current) {
            this.state.observer?.unobserve(this.state?.targetRef?.current)
        }
    }

    render() {
        const { isRowEnding, numOfRows } = this.props
        return isRowEnding && (numOfRows % 4 === 0) ?
            (
                <section className="product-category__section1" ref={this.state.targetRef}>
                    <div className="product-category__content1">
                        {
                            this.props.availableFilters.range_filters.map(category => (
                                <div key={category.presentation_name} className={this.state.currentFilters && Object.keys(this.state.currentFilters).includes(category.name) ? "product-category__type11" : "product-category__type1"} >
                                    <div className={this.state.currentFilters && Object.keys(this.state.currentFilters).includes(category.name) ? "clear11" : "clear1"} onClick={() => this.props.handleClearRangeFilter(category.name)}>X</div>
                                    <div className={"name"} onClick={() => this.props.handleFilterButtonClick(category.name)}>{category.presentation_name[0].toUpperCase() + category.presentation_name.slice(1)}</div>
                                </div>
                            ))
                        }
                        {
                            this.props.availableFilters.properties_filters.map(category => (
                                <div key={category.presentation_name} className={this.state.currentFilters && Object.keys(this.state.currentFilters).includes(category.name) && this.state.currentFilters[category.name].length>0 ? "product-category__type11" : "product-category__type1"} >
                                    <div className={this.state.currentFilters && Object.keys(this.state.currentFilters).includes(category.name) && this.state.currentFilters[category.name].length>0 ? "clear11" : "clear1"} onClick={() => this.props.handleClearPropertyFilter(category.name)}>X</div>
                                    <div className={"name"} onClick={() => this.props.handleFilterButtonClick(category.name)}>{category.presentation_name[0].toUpperCase() + category.presentation_name.slice(1)}</div>
                                </div>
                            ))
                        }
                    </div>
                </section>
            ) : null
    }
}

export default ExposeFilter
