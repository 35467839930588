import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare'

import { Status, AspectRatio } from 'constants/enums'
import { getCDNURL } from 'library/utils/URLTransforms'

import './Img.scss'
import Analytics from '../../library/analytics';

export default class Img extends React.Component {
  static propTypes = {
    alt: PropTypes.string.isRequired,
    aspectRatio: PropTypes.oneOf(Object.values(AspectRatio)),
    containerClass: PropTypes.string,
    className: PropTypes.string,
    faded: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    maxWidth: PropTypes.string,
    src: PropTypes.string.isRequired,
    imgOptions: PropTypes.string,
    onClick: PropTypes.func,
    description: PropTypes.string,
    title: PropTypes.string,
    imgData: PropTypes.object,
    imgPosition: PropTypes.string,
  };

  static defaultProps = {
    faded: false,
  };

  state = {
    status: Status.LOADED,
  };

  componentDidMount() {
    if (this.img.complete || this.img.readyState === 4) {
      this.handleImageLoaded()
    } else {
      this.showPlaceholder()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState)
  }

  showPlaceholder = () => {
    this.setState({
      status: Status.PENDING,
    })
  };

  handleImageLoaded = () => {
    this.setState({
      status: Status.LOADED,
    })
  };

  handleImageLoadFailed = () => {
    this.setState({
      status: Status.FAILED,
    })
  };

  handleClick = (e) => {
    const { imgData, imgPosition } = this.props
    const eventData = {
      banner_title: imgData?.title || '',
      banner_url: imgData?.src || imgData?.url || '',
      banner_id: imgData?.id || imgData?.object_tags?.[0]?.entity_id || null,
      page_section: imgPosition || '',
    }
    imgData && Analytics.bannerClicked(eventData)
    this.props.onClick && this.props.onClick(e)
  }

  render() {
    const {
      maxWidth,
      faded,
      containerClass,
      className,
      aspectRatio,
      onClick,
      alt,
      width,
      height,
      src,
      imgOptions,
      title,
      description,
      imgPosition,
      imgData,
      ...other
    } = this.props
    const { status } = this.state

    const containerStyle = {
      maxWidth: maxWidth || '100%',
    }

    let aspectClass = false

    if (typeof aspectRatio !== 'undefined') {
      aspectClass = `aspect-${aspectRatio}`
    } else if (
      typeof width === 'number' &&
      typeof height === 'number' &&
      height > 0 &&
      width > 0) {
      containerStyle.paddingTop = `${100 * (height / width)}%`
    }

    return (
      <div
        className={ classNames(containerClass, 'img-container', aspectClass) }
        style={ containerStyle }
        onClick={ this.handleClick }
      >
        <div
          className={ classNames(
            'img-container__content',
            { placeholder: status === Status.PENDING },
          ) }
        >
          <img
            ref={ i => (this.img = i) }
            alt={ alt }
            title={ title }
            description={ description }
            src={ getCDNURL(src, imgOptions) }
            { ...other }
            className={ classNames(className,
              'img-container__img',
              {
                'img-container__img--loaded': status === Status.LOADED,
                'img-container__img--faded': faded,
              }) }
            onLoad={ this.handleImageLoaded }
            onError={ this.handleImageLoadFailed }
          />
        </div>
      </div>
    )
  }
}
