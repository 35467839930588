import React from 'react'
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare'
import { connect } from 'react-redux'

import { PromiseState, Orientation } from 'constants/enums'
import { InfiniteLoad } from 'settings/values'
import Actions from 'store/actions'

import TestimonialsLoaderPanel from './TestimonialsLoaderPanel'
import TestimonialsDOM from './TestimonialsDOM'

import Analytics from 'library/analytics'
import Event from 'library/analytics/eventFactory'

class TestimonialsPanel extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    testimonials: PropTypes.object,
    shouldLoadSection: PropTypes.bool.isRequired,
  };

  state = {
    targetRef: React.createRef(null),
    observer: null
  }

  componentDidMount() {
    if (this.props.shouldLoadSection) {
      this.getTestimonials()
    }
    let obs = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if(entry.isIntersecting) {
          Analytics.genericEvent({
            category: Event.category.CUSTOMER_STORIES,
            action: Event.action.VIEWED,
          })
        }
      })
    }, { threshold: 1 })
    this.setState({ observer: obs})
    if(this.state.targetRef?.current) {
      obs.observe(this.state.targetRef?.current)
    }
  }

  componentWillUnmount() {
    if(this.state.targetRef?.current) {
      this.state.observer?.unobserve(this.state.targetRef?.current)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { shouldLoadSection } = this.props
    if (
      (nextProps.shouldLoadSection !== shouldLoadSection) &&
      (nextProps.shouldLoadSection)
    ) {
      this.getTestimonials()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState)
  }

  getTestimonials = () => {
    const { dispatch } = this.props
    dispatch(Actions.getTestimonials())
  };

  handleLoadMore = () => {
    const { dispatch } = this.props
    dispatch(Actions.getNextPageTestimonials())
  };

  renderStoryTelling = (taxonInspirations, storyTellingTitle) => {
    return (
      <div ref={this.state.targetRef}>
        { taxonInspirations && taxonInspirations.length > 0 &&
          <h2 className="story-telling__title"> { storyTellingTitle } </h2>
        }
        { taxonInspirations && taxonInspirations.map(story => (
          <div key={ story.id } className="story-telling">
            <h3 className="story-telling__header"> { story.title } </h3>
            <p className="story-telling__description"> { story.description } </p>
            <img
              alt={ story.image_url }
              className="story-telling__inspiration-image"
              src={ story.image_url }
            />
          </div>))
        }
      </div>
    )
  }

  render() {
    const { testimonials, shouldLoadSection, taxon } = this.props
    if (!shouldLoadSection) {
      return null
    }

    let taxonInspirations
    if (taxon) {
      taxonInspirations = taxon.taxon_inspirations
    }

    const hasMorePages = testimonials.current_page < testimonials.pages
    const hasNoTestimonials =
      !testimonials.testimonials || testimonials.testimonials.length === 0
    let testimonialsDOM = <TestimonialsLoaderPanel />

    if (!hasNoTestimonials) {
      testimonialsDOM = (
        <TestimonialsDOM
          showLoader={
            hasMorePages && testimonials.promiseState === PromiseState.PENDING
          }
          onLoadMore={ hasMorePages ? this.handleLoadMore : false }
          threshold={ InfiniteLoad.offset.grid }
          lastLoadedPage={ testimonials.current_page }
          testimonials={ testimonials }
          orientation={ Orientation.HORIZONTAL }
        />
      )
    } else if (
      (
        testimonials.promiseState === PromiseState.REJECTED &&
        hasNoTestimonials
      ) ||
      (testimonials.promiseState === PromiseState.FULFILLED)
    ) {
      testimonialsDOM = null
    }

    return (
      testimonialsDOM ?
        <div>
          <div className="header-title">Customer Stories</div>
          { testimonialsDOM }
          { taxon &&
            this.renderStoryTelling(taxonInspirations, taxon.taxon_story_telling_title)
          }
        </div> :
        <div>
          { taxon &&
            this.renderStoryTelling(taxonInspirations, taxon.taxon_story_telling_title)
          }
        </div>
    )
  }
}

export default connect(state => ({
  testimonials: state.pageData.testimonials,
  taxon: state.taxon.data
}))(TestimonialsPanel)
