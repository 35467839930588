import Endpoints from 'settings/endpoints'
import BaseAPI from '../../BaseAPI'

export default class BestSellers extends BaseAPI {
  get(options, permalink) {
    const endpoint = permalink ?
      `${Endpoints.bestSellers}/${permalink}` : Endpoints.bestSellersDefault
    const params = {
      taxonomy_v: '2',
      ...options,
    }
    return this.client.get(endpoint, params)
  }
}
