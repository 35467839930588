import Spree from 'api/spree'
import { PromiseState } from 'constants/enums'

/* =============
 -   ACTIONS   -
 ============= */

export function get(city, params) {
  const spree = new Spree()

  return {
    type: 'GET_STORESLISTING',
    payload: city ?
               spree.stores.getByCity(city, params) :
               spree.stores.getAll(params),
    meta: { city },
  }
}

/* =============
 -  REDUCERS   -
 ============= */

export function storesListing(state = {}, action) {
  switch (action.type) {
    case 'GET_STORESLISTING_PENDING':
      return {
        ...state,
        [action.meta.city]: {
          promiseState: PromiseState.PENDING,
          retailStores: [],
          additionalInfo: {},
        },
      }

    case 'GET_STORESLISTING_FULFILLED': {
      const storesData = action.payload.data

      return {
        ...state,
        [action.meta.city]: {
          promiseState: PromiseState.FULFILLED,
          retailStores: storesData.retail_stores,
          additionalInfo: storesData.additional_info,
        },
      }
    }

    case 'GET_STORESLISTING_REJECTED':
      return {
        ...state,
        [action.meta.city]: {
          promiseState: PromiseState.REJECTED,
          error: action.payload,
          retailStores: [],
          additionalInfo: {},
        },
      }

    default:
      return state
  }
}
