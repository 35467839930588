import Endpoints from 'settings/endpoints'
import BaseAPI from '../../BaseAPI'

export default class StoryTelling extends BaseAPI {
  get(options) {
    const { sku } = options

    const params = {
      'filter[sku]': [sku],
      disable_default_filters: true,
      response_group: 'basic',
      fields: ['linked_inspirations'],
    }
    return this.client.get(Endpoints.storyTelling, params)
  }
}
