import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare'

import Unicode from 'constants/unicode'

import './ToggleContent.scss'

class ToggleContent extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    toggleHeight: PropTypes.number.isRequired,
  };

  state = {
    hasToggle: true,
    isCollapsed: false,
  };

  componentDidMount() {
    this.handleShowHideToggle()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState)
  }

  componentDidUpdate(newProps) {
    if (this.props !== newProps) {
      this.handleShowHideToggle()
    }
  }

  handleShowHideToggle = () => {
    const { hasToggle, isCollapsed } = this.state
    const { toggleHeight } = this.props
    const ele = this.ToggleContent

    if (
      (hasToggle) &&
      (ele && ele.getBoundingClientRect().height <= toggleHeight)
    ) {
      this.setState({ hasToggle: false })
    } else if (ele && !isCollapsed) {
      this.setState({ isCollapsed: true })
    }
  };

  handleToggle = () => {
    this.setState({ isCollapsed: !this.state.isCollapsed })
  };

  render() {
    const { children, toggleHeight } = this.props
    const { hasToggle, isCollapsed } = this.state
    const height = isCollapsed ? toggleHeight : 'auto'
    const toggleContentLinkDOM = isCollapsed ?
      `${Unicode.RIGHT_POINTING_DOUBLE_ANGLE_QUOTATION_MARK} More` :
      `${Unicode.LEFT_POINTING_DOUBLE_ANGLE_QUOTATION_MARK} Less`

    return (
      <div
        ref={ tc => (this.ToggleContent = tc) }
        className={ classNames({ 'toggle-content': hasToggle }) }
      >
        <div className="toggle-content__content" style={ { height } }>
          { children }
        </div>
        { hasToggle && <div className="toggle-content__link" onClick={ this.handleToggle }>
          { toggleContentLinkDOM }
        </div> }
      </div>
    )
  }
}

export default ToggleContent
