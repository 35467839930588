/* eslint-disable camelcase */
// ∵ API Response uses underscore_cased variables

import Spree from 'api/spree'
import { PromiseState } from 'constants/enums'
import { Widget } from 'settings/values'

/* =============
 -   ACTIONS   -
 ============= */

export function get(options) {
  const spree = new Spree()

  return (dispatch) => {
    const params = {
      ...options,
      per_page: Widget.ITEMS_PER_PAGE,
      filters: { availability: ['In Stock Only'] },
    }
    return dispatch({
      type: 'GET_BESTSELLERS',
      payload: spree.bestSellers.get(params),
    })
  }
}

export function getNextPage() {
  return (dispatch, getState) => {
    const { pageData: { bestSellers: { current_page, pages } } } = getState()
    if (current_page !== pages) {
      return dispatch(get({ page: current_page + 1 }))
    }
    return Promise.resolve()
  }
}

export function init(values) {
  return {
    type: 'INIT_BESTSELLERS',
    options: {
      showSection: true,
      ...values,
    },
  }
}

/* =============
 -  REDUCERS   -
 ============= */

export function bestSellers(
  state = {
    showSection: false,
    products: [],
  },
  action,
) {
  let bestSellersObj = {}

  switch (action.type) {
    case 'GET_BESTSELLERS_PENDING':
      return {
        ...state,
        promiseState: PromiseState.PENDING,
      }

    case 'GET_BESTSELLERS_FULFILLED':
      if (action.payload.current_page === 1) {
        bestSellersObj = action.payload.products
      } else {
        bestSellersObj = state.products.concat(action.payload.products)
      }
      return {
        ...state,
        ...action.payload,
        products: bestSellersObj,
        promiseState: PromiseState.FULFILLED,
      }

    case 'GET_BESTSELLERS_REJECTED':
      return {
        ...state,
        promiseState: PromiseState.REJECTED,
        error: action.payload,
      }

    case 'SPA_LOCATION_CHANGE':
      return {
        showSection: false,
        products: [],
      }

    case 'INIT_BESTSELLERS':
      return {
        ...state,
        ...action.options,
      }

    default:
      return state
  }
}
