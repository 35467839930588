export default {
  EXCHANGE_BANNER: 'https://www.ulcdn.net/media/mobile/exchange/exchange-banner-mobile.png',
  FOOTER_APP_DOWNLOAD_BANNER_IMAGE: 'https://www.ulcdn.net/opt/www.ulcdn.net/assets/spree/frontend/appdownload_banner-698d07d8c06c4cabcff5e5cb446518a4.png',
  giftCard: {
    HERO_BANNER: 'https://www.ulcdn.net/media/gift-cards/hero-mobile/Giftcard_M-Web_415x155px.jpg',
    E_GIFT_CARD: 'https://www.ulcdn.net/media/gift-cards/e-gift-card/e-gift-card.jpg',
    PHYSICAL_GIFT_CARD: 'https://www.ulcdn.net/media/gift-cards/physical-gift-card/physical-gift-card.jpg',
  },
  stores: {
    features: {
      VR: 'https://www.ulcdn.net/media/stores/features/VR.png',
      AR: 'https://www.ulcdn.net/media/stores/features/AR.png',
      ONE_TO_ONE: 'https://www.ulcdn.net/media/stores/features/1-to-1.png',
      PICK_UP_DECOR: 'https://www.ulcdn.net/media/stores/features/pick-up-decor.png',
      TOUCH_AND_FEEL: 'https://www.ulcdn.net/media/stores/features/touch-feel.png',
    },
  },
}
