// Suppress ga undefined error
/* eslint-disable no-undef */

/**
 * This class handles events
 * send to the old GA Tracker
 */
import { getCategoryName } from 'library/utils/product'

export default class GoogleAnalytics {
  static gaExperiments = {}

  static pageView() {
    ga('oldTracker.set', {
      page: window.location.pathname + window.location.search,
    })

    ga('oldTracker.send', 'pageview')
  }

  static setDimension(dimension, value) {
    ga('oldTracker.set', dimension, value)
  }

  static setExperiments(experiments) {
    experiments.forEach((experiment) => {
      GoogleAnalytics.gaExperiments[experiment.id] =
        experiment.assigned_variant.id
    })

    const experimentString = Object.keys(GoogleAnalytics.gaExperiments)
      .map(expriment => (`${expriment}.${GoogleAnalytics.gaExperiments[expriment]}`))
      .join('!')

    ga('oldTracker.set', 'exp', experimentString)
  }

  static listingProductImpression(productDetails) {
    productDetails.map((product, index) => {
      var gaTrackerObj={
        id: product.id,
        name: product.name,
        category: getCategoryName(product.category),
        list: 'Category',
        position: product.position?product.position:index+1,
      }
      if(product.variant)
      gaTrackerObj={...gaTrackerObj,variant: product.variant}
      ga('oldTracker.ec:addImpression', gaTrackerObj)
    })

    ga('oldTracker.send', 'pageview')
  }

  static listingProductCardClick(cardData, productPosition) {
    const category = getCategoryName(cardData.sub_category)
    ga('oldTracker.ec:addProduct', {
      id: cardData.sku,
      name: cardData.product_name,
      category: category,
      variant: cardData.variant_type,
      position: productPosition + 1,
    })

    ga('oldTracker.ec:setAction', 'click', { list: 'Category'})
    ga('oldTracker.send', 'event', category, `Listing.${category}`)
  }

  static productDetailImpression(productDetails) {
    ga('oldTracker.ec:addProduct', {
      id: productDetails.sku,
      name: productDetails.name,
      category: getCategoryName(productDetails.sub_category),
    });

    ga('oldTracker.ec:setAction', 'detail', { list: 'Category' });
    ga('oldTracker.send', 'pageview'); 
  }

  static addedProduct(productData) {
    const category = getCategoryName(productData.product_subcategory)
    ga('oldTracker.ec:addProduct', {
      id: productData.sku,
      name: productData.product_name,
      category: category,
      variant: productData.variant,
      price: productData.price,
      quantity: productData.quantity,
    })

    ga('oldTracker.ec:setAction', 'add', { list: 'Category' })
    ga('oldTracker.send', 'event', category, 'click', 'add to cart')
  }
}
