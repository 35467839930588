import { ProductTemplateType } from 'constants/enums'

export function parseProductName(name) {
  let productName = name
  let variantName

  const variantDelimiterIndex = name.indexOf('(')

  if (variantDelimiterIndex > -1) {
    productName = name.substring(0, variantDelimiterIndex).trim()
    variantName = name.substring(productName.length + 1)
  }

  return { productName, variantName }
}

/**
 * Filters an image object array by tags, and transforms
 * them to a format suitable for UI
 *
 * @param {Object[]} images
 * @param {Object} options
 *
 * @param {string[]} options.tags
 * @param {string} options.defaultAlt
 */
export function parseTaggedImages(images, options) {
  const opts = {
    tags: [],
    defaultAlt: '',
    ...options,
  }

  let formatedImages = images
    // Normalize image tags
    .map((image) => {
      let tags = []
      if (image.tags) {
        tags = image.tags.map(tag => tag.toLowerCase())
      } else if (image.tag_name) {
        tags = [image.tag_name.toLowerCase()]
      }
      return { ...image, tags }
    })

  if (opts.tags.length > 0) {
    // Filter images by tags
    formatedImages = formatedImages.filter(image =>
      opts.tags.some(tag => image.tags.includes(tag.toLowerCase())))
  }

  return formatedImages
    // Format image objects
    .map(image => ({
      alt: image.alt || opts.defaultAlt,
      src: image.url,
      zoomSrc: (image.additional_sizes && image.additional_sizes.length) ?
        image.additional_sizes[0].url : image.url,
      // The API doesn't give us a thumbnail image
      // so we make do with the full size low-res version
      thumbnailSrc: image.url,
      width: image.attachment_width,
      height: image.attachment_height,
      caption: image.description,
    }))
}

export function isSofaProduct(details) {
  return details && details.product_template === ProductTemplateType.SOFA
}

export function isConfigurableSofaProduct(details) {
  return details &&
    details.product_template === ProductTemplateType.CONFIGURABLE_SOFA
}

export function isGenericProduct(details) {
  return !isSofaProduct(details) && !isConfigurableSofaProduct(details)
}

/**
 * Workaround to get same configuration SKU for a
 * different fabric type
 *
 * @param fabricSKUPrefix
 * @param previousSetSKU
 * @returns {*}
 */
export function getSofaVariantSKU(fabricSKUPrefix, previousSetSKU) {
  return `${fabricSKUPrefix}${previousSetSKU.substr(previousSetSKU.length - 4)}`
}

// eslint-disable-next-line max-statements
export function formatSofaProductForAnalytics(product, variant) {
  // We send the 3-2 seater
  // if found or send the default SKU
  const threeTwoSeater = product.sets.find(set => set.name === '0032')
  const heroSku = threeTwoSeater || product.sets[0].sku

  return {
    id: variant.sku, // Variant SKU
    pid: product.id, // Product ID
    name: `${product.name} (${variant.presentation})`,
    sku: variant.sku,
    price: variant.discount_price,
    currency: 'INR',
    category: product.primary_taxon.name,
    availability: 'instock',
    heroSku, // SKU to be sent to the marketing channels
  }
}

export function formatGenericProductForAnalytics(product, variant) {
  let material = ''
  let finish = ''
  let warrantyInMonths = 0

  product.product_properties.map((property) => {
    if (property.name === 'warranty_in_months') {
      warrantyInMonths = property.value
    }
  })

  variant.option_values.map((property) => {
    if (property.option_type_presentation === 'material') {
      material = property.presentation
    } else if (property.option_type_presentation === 'Finish') {
      finish = property.presentation
    }
  })

  return {
    id: variant.sku, // Variant SKU
    pid: product.id, // Product ID
    name: variant.name,
    sku: variant.sku,
    price: variant.price,
    selling_price: variant.discount_price,
    currency: 'INR',
    sub_category: variant.sub_category,
    category: variant.category,
    model: variant.model,
    availability: variant.availability_text,
    material,
    warranty_in_months: warrantyInMonths,
    discount_in_percentage: variant.total_discount_percentage,
    finish,
    heroSku: variant.sku, // SKU to be sent to the marketing channels
  }
}

export function formatConfigurableSofaForAnalytics(product, price) {
  return {
    id: product.sku, // Variant SKU
    pid: product.id, // Product ID
    name: product.name,
    sku: product.sku,
    price,
    currency: 'INR',
    category: product.primary_taxon.name,
    availability: 'instock',
    heroSku: product.sku, // SKU to be sent to the marketing channels
  }
}

export function arrayToObject(array, keyField) {
  const result = {}
  array.forEach((object) => {
    result[object[keyField]] = object
  })

  return result
}

export function combineProductPropertiesAndVariantProperties(
  productProperties,
  variantProperties,
) {
  const combinedProperties = {
    ...arrayToObject(productProperties, 'name'),
    ...arrayToObject(variantProperties, 'name'),
  }
  return Object.values(combinedProperties)
}

// eslint-disable-next-line max-statements
export function lineItemChargesValue(lineItem, quantity) {
  let value = 0
  const itemIds = lineItem.item_id.split(',').map(p => p.trim())

  if (quantity) {
    for (let index = 0; index < quantity; index++) {
      const itemId = itemIds[index]
      if ((lineItem.cancellation_eligibility.charges[itemId]).length) {
        for (let index1 = 0; index1 <
          (lineItem.cancellation_eligibility.charges[itemId]).length;
          index1++) {
          value +=
              lineItem.cancellation_eligibility
                .charges[itemId][index1].absolute_value
        }
      }
    }
    return Math.round(value)
  }
  if (Object.keys(lineItem.cancellation_eligibility.charges).length > 1) {
    for (let index = 0;
      index < Object.keys(lineItem.cancellation_eligibility.charges).length;
      index++) {
      const itemId = itemIds[index]
      if ((lineItem.cancellation_eligibility.charges[itemId]).length) {
        for (let index1 = 0; index1 <
          (lineItem.cancellation_eligibility.charges[itemId]).length;
          index1++) {
          value +=
              lineItem.cancellation_eligibility
                .charges[itemId][index1].absolute_value
        }
      }
    }
    return Math.round(value)
  }
  if ((lineItem.cancellation_eligibility.charges[itemIds]).length) {
    for (let index1 = 0; index1 <
      (lineItem.cancellation_eligibility.charges[itemIds]).length;
      index1++) {
      value +=
          lineItem.cancellation_eligibility
            .charges[itemIds][index1].absolute_value
    }
  }
  return Math.round(value)
}

// eslint-disable-next-line max-statements
export function lineItemRefundAmount(lineItem, quantity) {
  let value = 0
  const itemIds = lineItem.item_id.split(',').map(p => p.trim())

  if (quantity) {
    for (let index = 0; index < quantity; index++) {
      const itemId = itemIds[index]
      if ((lineItem.cancellation_eligibility.refund_info[itemId]).length) {
        for (let index1 = 0; index1 <
          (lineItem.cancellation_eligibility.refund_info[itemId]).length;
          index1++) {
          value +=
              lineItem.cancellation_eligibility
                .refund_info[itemId][index1].refund_amount
        }
      }
    }
    return Math.round(value)
  }
  if (Object.keys(lineItem.cancellation_eligibility.refund_info).length > 1) {
    for (let index = 0;
      index < Object.keys(lineItem.cancellation_eligibility.refund_info).length;
      index++) {
      const itemId = itemIds[index]
      if ((lineItem.cancellation_eligibility.refund_info[itemId]).length) {
        for (let index1 = 0; index1 <
          (lineItem.cancellation_eligibility.refund_info[itemId]).length;
          index1++) {
          value +=
              lineItem.cancellation_eligibility
                .refund_info[itemId][index1].refund_amount
        }
      }
    }
    return Math.round(value)
  }
  if ((lineItem.cancellation_eligibility.refund_info[itemIds]).length) {
    for (let index1 = 0; index1 <
      (lineItem.cancellation_eligibility.refund_info[itemIds]).length;
      index1++) {
      value +=
          lineItem.cancellation_eligibility
            .refund_info[itemIds][index1].refund_amount
    }
  }
  return Math.round(value)
}

export function parseName(name) {
  let productName = name

  const variantDelimiterIndex = name.indexOf('(')

  if (variantDelimiterIndex > -1) {
    productName = name.substring(0, variantDelimiterIndex).trim()
  }

  return productName
}

export function getProductCategoryData(breadcrumbInfo) {
  let productId = ''
  let productCategory = ''
  let productLevel = null

  if (breadcrumbInfo) {
    const len = Object.keys(breadcrumbInfo).length
    productCategory = breadcrumbInfo[len - 2].name
    productLevel = (len - 1).toString()

    breadcrumbInfo.map((data) => {
      productId = productId ? (`${productId}/${data.name}`) : data.name
    })
  }

  return {
    list_id: productId,
    product_category: productCategory,
    level: productLevel,
  }
}

export function getCategoryName(category) {
  const newCategory = category
    .replace(/-/g, ' ')
    .replace(/_/g, ' ')

  return newCategory.split(' ').map( w =>  w.substring(0,1).toUpperCase()+ w.substring(1)).join(' ')
}