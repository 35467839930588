import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types';
import shallowCompare from 'react-addons-shallow-compare'

import './Ripple.scss'

/**
 * Adds a ink ripple effect to its immediate child.
 * The ripple's color is automatically set to the
 * computed css `color` attribute.
 *
 *  Adapted from React Toolbox's
 * [implementation.](https://github.com/react-toolbox/react-toolbox/blob/dev/components/ripple)
 *
 */

export default class Ripple extends React.Component {
  static propTypes = {
    /**
     * A multiplier value that controls how far the ripple
     * spreads with respect to its child's container.
     */
    spread: PropTypes.number,
    /**
     * Makes the `<Ripple>` function like any normal `<div>`.
     */
    disabled: PropTypes.bool,
    /**
     * A circular ripple that grows from center
     * regardless of where touch occurred.
     */
    centered: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    onMouseDown: PropTypes.func,
    onTouchStart: PropTypes.func,
    style: PropTypes.object,
  }

  state = {
    active: false,
    left: null,
    restarting: false,
    top: null,
    width: null,
  }

  shouldComponentUpdate(nextProps, nextState) {
    return shallowCompare(this, nextProps, nextState)
  }

  _isTouchRippleReceivingMouseEvent(touch) {
    return this.touch && !touch
  }

  _getDescriptor(pageX, pageY) {
    const {
      left,
      top,
      height,
      width,
    } = this.rippleContainer.getBoundingClientRect()
    const { centered = false, spread = 2 } = this.props
    return {
      left: centered ? 0 : pageX - left - (width / 2) - window.scrollX,
      top: centered ? 0 : pageY - top - (height / 2) - window.scrollY,
      width: centered ? width * (spread / 2) : width * spread,
    }
  }

  start = ({ pageX, pageY }, touch = false) => {
    if (!this._isTouchRippleReceivingMouseEvent(touch)) {
      this.touch = touch
      /**
       * touchStart is triggered on both scroll and click.
       *
       * mouseUp is handled on touchStart as well
       * because the touch can end in scroll/click
       *
       * When the ele is scrolled, Ripple should
       * end on touchMove event i.e on scroll start
       *
       * When the ele is clicked,
       * Ripple should end on MouseUp event
       */
      if (this.touch) {
        document.addEventListener('touchmove', this.handleEnd)
      }
      document.addEventListener('mouseup', this.handleEnd)
      const { top, left, width } = this._getDescriptor(pageX, pageY)

      this.setState(
        { active: false, restarting: true, top, left, width },
        () => {
          this.ripple.offsetWidth // eslint-disable-line no-unused-expressions
          this.setState({ active: true, restarting: false })
        },
      )
    }
  }

  handleEnd = () => {
    if (this.touch) {
      document.removeEventListener('touchmove', this.handleEnd)
    }
    document.removeEventListener('mouseup', this.handleEnd)
    this.setState({ active: false })
  }

  handleMouseDown = (event) => {
    const { disabled, onMouseDown } = this.props
    if (!disabled) this.start(event)
    if (onMouseDown) {
      onMouseDown(event)
    }
  }

  handleTouchStart = (event) => {
    const { disabled, onTouchStart } = this.props
    if (!disabled) this.start(event.changedTouches[0], true)
    if (onTouchStart) {
      onTouchStart(event)
    }
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const {
      children,
      centered,
      className,
      style,
      spread,
      ...other
    } = this.props
    const { active, restarting, left, top, width } = this.state

    const rippleClassName = classNames('ripple--normal', {
      'ripple--active': active,
      'ripple--restarting': restarting,
    })

    const scale = restarting ? 0 : 1
    const translate3dBy = `${-(width / 2) + left}px, ${-(width / 2) + top}px, 0`
    const rippleStyle = {
      width,
      height: width,
      WebkitTransform: `translate3d(${translate3dBy}) scale(${scale})`,
      transform: `translate3d(${translate3dBy}) scale(${scale})`,
    }

    return (
      <div
        className={ classNames('ripple-component', className) }
        style={ { ...style, overflow: centered ? 'visible' : 'hidden' } }
        { ...other }
        onMouseDown={ this.handleMouseDown }
        onTouchStart={ this.handleTouchStart }
        ref={ rc => (this.rippleContainer = rc) }
      >
        {children}
        <span className="ripple-wrapper">
          <span
            ref={ r => (this.ripple = r) }
            className={ rippleClassName }
            style={ rippleStyle }
          />
        </span>
      </div>
    )
  }
}

Ripple.defaultProps = {
  spread: 2,
  disabled: false,
  centered: false,
}
