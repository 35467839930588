import BaseAPI from 'api/BaseAPI'
import Endpoints from 'settings/endpoints'

export default class Inspirations extends BaseAPI {
  get(options = {}) {
    const params = {
      taxonomy_v: '2',
      page_type: 'home',
      version: '0.1',
      ...options,
    }

    return this.client.get(Endpoints.home.inspirations, params)
  }
}
