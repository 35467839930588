const Config = {
  affiliateCookieExpiryInDays: 30,
  analyticsIdleTimeout: 1000,
  defaultErrorMessage: 'Oops, Something went wrong!',
  /**
   * The height of fixed elements on
   * the top of a page.
   */
  giftCardPageAnchorHeight: 92,

  fixedHeaderHeight: 104,

  contactInfo: {
    mobile: '080-46666777',
    email: 'hello@urbanladder.com',
  },
  fallbackToken: '107fd0a0fc914faa981c90588cf7fe6dbd8cdd5578c83386',
  homeMenu: {
    id: 2,
    name: 'Home',
    pretty_name: 'Categories',
    permalink: '',
  },

  originURL: 'https://www.urbanladder.com',
  defaultLoginRedirectPath: '/',

  staticTaxonPages: [
    'exchange',
    'exchange-your-old-furniture',
    'full-house-sale',
    'interior-design-consultation',
    'offer-zone',
    'refer-and-earn',
    'store',
    'the-happily-ever-after-sale',
    'wedding-sale-mattress',
    'ineligible-cancellation',
    'help',
    'seat-treat',
    'collections/offbeat-originals',
    'collections/india-inherited',
    'collections/laidback-luxe',
    'buy-in-bulk',
    'the-design-network',
    'irresistible-sofas',
    'the-grand-sofa-sale',
    'super-savers',
    'republic-day-sale',
    'full-house-sale-all-sofas',
    'sweet-summer-deals',
    'big-little-sale',
    'rds-republic-day-sale',
    'being-at-home',
    'sweet-sale-o-mine',
    'freedom-sale',
    'delightful-diwali-deals',
    'sale',
    'furniture-decor',
    'oasis-collection-hlp',
    'explore-oasis-collection',
    'customizable-sofa'
  ],

  subSource: {
    productSoldout: 'product_soldout',
    sofaTrial: 'sofa-trial',
    homepageSignupBanner: 'mweb-hp-banner-email',
    emailBottomSheet: 'mweb-bottom-sheet-email',
    interestList: 'interest_list',
  },

  deliverySLA: {
    PINCODE_KEY: 'UL-PINCODE',
    DEFAULT_PINCODE: '560001',
  },

  // If the sale percent is above this value,
  // we show a `sale` themed footer toolbar in
  // product pages.
  minSalePercent: 9,

  sofaTrial: {
    PRICE: 299,
    MAX_SELECTIONS: 3,
  },

  /**
   * Pages that depend on user data should not be cached.
   * Routes of such pages to be added here.
   */
  userSpecificPages: ['/orders'],

  // These appear after the taxons returned by taxonomies API.
  navStaticLinks: [
    {
      name: 'Help',
      permalink: 'help',
    },
  ],

  cancellationRefundModes: {
    noRefund: 'NOREFUND',
    fullRefund: 'FULLREFUND',
  },

  BFLMinEligibilityAmount: 50000,

  listingPageContentBlockRows: 3,

  productPermalink: ['sofa', 'mattresses', 'shoe-rack'],

  ampPageProductsPermalink: [
    'shoe-rack', 'dressing-table', 'bookshelf', 'queen-size-bed', 'king-size-bed',
    'wardrobes', 'mattresses', 'wall-shelves', 'prayer-units',
    'kids-study-table', 'curtains', 'chest-of-drawers',
  ],

  thumborImageOptions: '/600x',

  productCategory: [
    {
      name: 'bookshelf',
      category: [
        {
          name: 'Open Bookshelves',
          link: '/open-bookshelves',
        }, {
          name: 'Showcases',
          link: '/showcase',
        }, {
          name: 'Large Bookshelves',
          link: '/large-bookshelf',
        }, {
          name: 'Compact Bookshelves',
          link: '/compact-bookshelf',
        },
      ],
    },
    {
      name: 'tv-units',
      category: [
        {
          name: 'Low TV Units',
          link: '/low-tv-stand',
        },
        {
          name: 'Entertainment Units',
          link: '/entertainment-unit',
        },
        {
          name: 'High TV Units',
          link: '/high-tv-cabinet',
        },
        {
          name: 'Wall Mounted TV Units',
          link: '/wall-mounted-tv-unit',
        },
      ],
    },
    {
      name: 'study-chair',
      category: [
        {
          name: 'Executive Chairs',
          link: '/executive-office-chairs',
        },
        {
          name: 'Ergonomic Chairs',
          link: '/ergonomic-office-chairs',
        },
        {
          name: 'Wooden Chairs',
          link: '/wooden-study-chairs',
        },
        {
          name: 'Kids chairs',
          link: '/kids-study-chair',
        },
      ],
    },
    {
      name: 'wooden-study-chairs',
      category: [
        {
          name: 'All Study Chairs',
          link: '/study-chair',
        },
        {
          name: 'Executive Chairs',
          link: '/executive-office-chairs',
        },
        {
          name: 'Ergonomic Chairs',
          link: '/ergonomic-office-chairs',
        },
        {
          name: 'Kids chairs',
          link: '/kids-study-chair',
        },
      ],
    },
    {
      name: 'open-bookshelves',
      category: [
        {
          name: 'All Bookshelves',
          link: '/bookshelf',
        },
        {
          name: 'Showcases',
          link: '/showcase',
        },
        {
          name: 'Large Bookshelves',
          link: '/large-bookshelf',
        },
        {
          name: 'Compact Bookshelves',
          link: '/compact-bookshelf',
        },
      ],
    },
    {
      name: 'showcase',
      category: [
        {
          name: 'All Bookshelves',
          link: '/bookshelf',
        },
        {
          name: 'Open Bookshelves',
          link: '/open-bookshelves',
        },
        {
          name: 'Large Bookshelves',
          link: '/large-bookshelf',
        },
        {
          name: 'Compact Bookshelves',
          link: '/compact-bookshelf',
        }],
    },
    {
      name: 'dining-tables',
      category: [
        {
          name: '6 Seater Dining Sets',
          link: '/6-seater-dining-table-sets',
        },
        {
          name: '4 Seater Dining Sets',
          link: '/4-seater-dining-table-sets',
        },
        {
          name: '8 Seater Dining Sets',
          link: '/8-seater-dining-table-sets',
        },
        {
          name: 'Folding Dining Sets',
          link: '/folding-dining-table-sets',
        },
      ],
    },
    {
      name: 'wall-mounted-tv-unit',
      category: [
        {
          name: 'All TV Units',
          link: '/tv-units',
        },
        {
          name: 'Low TV Units',
          link: '/low-tv-stand',
        },
        {
          name: 'Entertainment Units',
          link: '/entertainment-unit',
        },
        {
          name: 'High TV Units',
          link: '/high-tv-cabinet',
        },
      ],
    },
    {
      name: 'ergonomic-office-chairs',
      category: [
        {
          name: 'All Study Chairs',
          link: '/study-chair',
        },
        {
          name: 'Executive Chairs',
          link: '/executive-office-chairs',
        },
        {
          name: 'Wooden Chairs',
          link: '/wooden-study-chairs',
        },
        {
          name: 'Kids chairs',
          link: '/kids-study-chair',
        },
      ],
    },
    {
      name: 'executive-office-chairs',
      category: [
        {
          name: 'All Study Chairs',
          link: '/study-chair',
        },
        {
          name: 'Ergonomic Chairs',
          link: '/ergonomic-office-chairs',
        },
        {
          name: 'Wooden Chairs',
          link: '/wooden-study-chairs',
        },
        {
          name: 'Kids chairs',
          link: '/kids-study-chair',
        },
      ],
    },
  ],
}

export default Config
