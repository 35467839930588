import Dimension from 'library/analytics/eventFactory/dimensions'
import { getCookieValue } from 'library/utils'
import Event from 'library/analytics/eventFactory'
export default class GTM {
  static eventType = {
    PAGE_VIEW: 'page_view',
    VIEWED_PRODUCT: 'viewed_product',
    PRODUCT_VIEWED: 'product_viewed',
    ADDED_PRODUCT: 'added_product',
    REMOVED_PRODUCT: 'removed_product',
    VIEWED_PRODUCT_CATEGORY: 'viewed_product_category',
    CATEGORY_VIEWED: 'category_viewed',
    FILTERS_APPLIED: 'filters_applied',
    GENERIC: 'generic',
    GA4_EVENT: 'ga4_event',
  };

  static platformType = {
    MOBILE: 'Mobile'
  }

  /**
   * Pushes an object to GTM data layer
   */
  static push(analyticsObject) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(analyticsObject)
  }

  /**
   * Resets the dataLayer on initialisation.
   * GTM recursively merges with previous data layer
   * objects if not explicitly reset like this.
   */
  static reset() {
    this.push({
      pageType: undefined,
      items: undefined,
      dimensions: undefined,
    })
  }

  static identify(traits) {
    this.push({
      GAEmail: traits.email,
      Identify: '1',
      Joined_at: traits.createdAt,
      Name: traits.name,
    })
  }

  static pageView(pageType) {
    this.push({
      event: this.eventType.PAGE_VIEW,
      pageType,
    })
  }

  static viewedProduct(productDetails) {
    this.push({
      event: this.eventType.VIEWED_PRODUCT,
      items: productDetails,
    })
  }

  static addedProduct(productDetails) {
    this.push({
      event: this.eventType.ADDED_PRODUCT,
      items: productDetails,
    })
  }

  static removedProduct(productDetails) {
    this.push({
      event: this.eventType.REMOVED_PRODUCT,
      items: productDetails,
    })
  }

  static productCategoryView(category, skus) {
    this.push({
      event: this.eventType.VIEWED_PRODUCT_CATEGORY,
      category: category,
      items: {
        skus,
      },
    })
  }

  static serviceabilityChecked(eventData) {
    this.genericEvent({
      event_name: Event.eventName.PINCODE_ENTERED,
      ...eventData
    })
  }

  static bannerClicked(eventData) {
    this.genericEvent({
      event_name: Event.eventName.BANNER_CLICKED,
      category: Event.category.LISTING_PAGE_BANNER,
      action: Event.action.CLICKED,
      ...eventData
    })
  }

  static categoryClicked(eventData) {
    this.genericEvent({
      event_name: Event.eventName.CATEGORY_CLICKED,
      action: Event.action.CLICKED,
      ...eventData
    })
  }


  static productClicked(cardData, productPosition, list_id) {
    this.genericEvent({
      event_name: Event.eventName.PRODUCT_CLICKED,
      category: Event.category.PRODUCT,
      action: Event.action.CLICKED,
      items: [cardData],
      product_position: productPosition,
      list_id
    })
  }

  static _normalizeEvent(event) {
    let k = Object.keys(event)
    const normalizedEvent = { }
    let misc = {}

    k.map(item => {
      switch(item) {
        case 'category': normalizedEvent.category = event.category.toLowerCase()
        break;
        case 'action': normalizedEvent.action = event.action.toLowerCase()
        break;
        case 'items': normalizedEvent.items = event.items
        break;
        case 'page_section': normalizedEvent.section = event.page_section
        break;
        case 'page_title': normalizedEvent.page_title = event.page_title
        break;
        case 'event_name': normalizedEvent.event_name = event.event_name
        break;
        case 'page_type': normalizedEvent.page_type = event.page_type
        break;
        case 'page_location': normalizedEvent.page_location = event.page_location
        break;
        default: 
          misc = {
          ...misc,
          [item] : event[item]
        }
      }
    })
    normalizedEvent.misc = misc

    let source_details = getCookieValue('source_details') ? JSON.parse(decodeURIComponent(getCookieValue('source_details'))) : {}
    normalizedEvent.utm_src = source_details?.utm?.source || ''
    normalizedEvent.utm_med = source_details?.utm?.medium || ''

    const analyticsObject = {
      event: normalizedEvent.event_name ? this.eventType.GA4_EVENT : this.eventType.GENERIC,
      event_label: normalizedEvent.event_name || '',
      platform_type: this.platformType.MOBILE,
      category: normalizedEvent.category,
      action: normalizedEvent.action,
      page: {
        location: normalizedEvent.page_location ? normalizedEvent.page_location : window.location.pathname,
        title: normalizedEvent.page_title ? normalizedEvent.page_title : document.title,
        category: normalizedEvent.page_type ? normalizedEvent.page_type :( window.pageType || ''),
        section: normalizedEvent.section || ''
      },
      items: normalizedEvent.items ? normalizedEvent.items : [],
      misc: {
        utm: {
          source: normalizedEvent.utm_src || '',
          medium: normalizedEvent.utm_med || '',
        },
        timestamp: new Date(),
        ...normalizedEvent.misc,
      },
      utm: {
        source: normalizedEvent.utm_src || '',
        medium: normalizedEvent.utm_med || '',
      },
      timestamp: new Date(),
      ...normalizedEvent.misc,
    }
    return analyticsObject
  }

  static genericEvent(event) {
    let normalizedEvent = this._normalizeEvent(event)

    // if (process.env.NODE_ENV?.toString().toLowerCase() !== 'production' || process.env.DEPLOY_ENV?.toString().toLowerCase() !== 'production')
    if(normalizedEvent.event === this.eventType.GA4_EVENT)      
      console.log(normalizedEvent)

    this.reset()
    this.push(normalizedEvent)
  }

  static filtersApplied(eventData) {
    this.genericEvent({
      event_name: Event.eventName.FILTERS_APPLIED,
      ...eventData
    })
  }

  static productViewed(productDetails) {
    try{
      let productData = {
        product_sku: productDetails.sku,
        product_name: productDetails.name,
        product_category: productDetails.category,
        product_subcategory: productDetails.sub_category,
        variant_name: productDetails.model,
        mrp: productDetails.price,
        selling_price: productDetails.selling_price,
        discount_percent: productDetails.discount_in_percentage,
        material: productDetails.material,
        finish: productDetails.finish,
        product_availability: productDetails.availability,
        warranty_in_months: parseInt(productDetails.warranty_in_months),
      }
      this.genericEvent({
        event_name: Event.eventName.PRODUCT_VIEWED,
        items: [productData]
      })
    } catch(e) {
      console.log("Error pushing productviewed event to GTM", e.message)
    }
    
  }

  static categoryViewed(params) {
    let lineage = params?.lineage || [];
    let category = '';
    let subCategory = '';
    if(lineage.length>1){
      subCategory = lineage[lineage.length-1]?.name?.trim(); 
      category = lineage[lineage.length-2]?.name?.trim(); 
    }
    this.genericEvent({
      event_name: Event.eventName.CATEGORY_VIEWED,
      items: [
        {
          product_category: category,
          product_subcategory: subCategory,
        }
      ],
      product_category: category,
      product_subcategory: subCategory,
    })
  }
}
