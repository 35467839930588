/* eslint-disable sort-class-members/sort-class-members */

/**
 * An store for client side user preferences.
 * Async storage for all preferences.
 * Sync strorage for all temporary preferences.
 */
import Cookie from 'library/utils/cookieUtils'
import PersistentStorage from './PersistentStorage'

class UserPreferences extends PersistentStorage {
  static prefix = 'user_preference_';

  static isValidPreference(preference) {
    const preferenceValues = Object.values(UserPreferences.prototype.preference)
    return preferenceValues.indexOf(preference) > -1
  }

  setPreference(preference, value) {
    if (UserPreferences.isValidPreference(preference)) {
      return super.set(`${UserPreferences.prefix}${preference}`, value)
    }

    return Promise.reject(`${preference} is not a valid preference.
      Preferences must be listed under the UserPreference class to be valid.`)
  }

  getPreference(preference) {
    return super.get(`${UserPreferences.prefix}${preference}`)
  }

  setTemporaryPreference(preference, value, options) {
    if (!UserPreferences.isValidPreference(preference)) {
      throw new Error(`${preference} is not a valid preference.
      Preferences must be listed under the UserPreference class to be valid.`)
    }

    Cookie.set(`${UserPreferences.prefix}${preference}`, value, options)
  }

  getTemporaryPreference(preference) {
    return Cookie.get(`${UserPreferences.prefix}${preference}`)
  }
}

// List of valid preferences
UserPreferences.prototype.preference = {
  PINCODE: 'pincode',
  EMAIL: 'email',
  HAS_SEEN_EMAIL_SIGNUP_SHEET: 'seen-email-signup-sheet',
  CITY: 'city',
}

export default new UserPreferences()
