// eslint-disable-next-line import/prefer-default-export
export function getCDNURL(oldURL = '', imgOptions = '') {
  if (oldURL) {
    return oldURL
    /**
     * The line below needs to be removed
     * when our APIs stop serving cdn. based URLs
     */
      .replace(/^(https?:)?\/\/cdn[1-4]?(-stg\d)?\.urbanladder\.com/, '//www.ulcdn.net')
      .replace(/^(https?:)?\/\//, `//www.ulcdn.net/opt${imgOptions}/`)
  }

  return ''
}
