import classNames from 'classnames'
import React, { Component } from 'react'
import PropTypes from 'prop-types';

import { SubscriptionType } from 'constants/enums'
import SubscriptionModal from 'components/SubscriptionModal'
import Button from 'units/Button'

import './InterestedRangeCard.scss'

class InterestedRangeCard extends Component {

  state = {
    isSubscriptionModalOpen: false,
  }

  static propTypes = {
    className: PropTypes.string,
    taxon: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }

  handleSubscribeClick = () => {
    this.setState({
      isSubscriptionModalOpen: true,
    })
  }

  handleModalClose = () => {
    this.setState({
      isSubscriptionModalOpen: false,
    })
  }

  render() {
    const { className, taxon } = this.props
    const { isSubscriptionModalOpen } = this.state

    return (
      <div className={ classNames('product-card interested-range-card', className) }>
        <h2 className="interested-range-card__title">
          Interested
          <span className="interested-range-card__taxon">
            In Our {taxon.name}?
          </span>
        </h2>
        <p className="interested-range-card__msg">
          Subscribe to get notified when we add more { taxon.name } design
           to our range
        </p>
        <Button
          onClick={ this.handleSubscribeClick }
          size={ Button.size.SMALL }
        >
          Subscribe
        </Button>
        <SubscriptionModal
          isOpen={ isSubscriptionModalOpen }
          onClose={ this.handleModalClose }
          taxon={ taxon }
          subscriptionType={ SubscriptionType.TAXON }
        />
      </div>
    )
  }
}

export default InterestedRangeCard
